import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import PagePreloader from "../generic/PagePreloader/PagePreloader";

const AppRoute = (props) => {
    const { component: Component, layout: Layout, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(props) =>
                Layout ? (
                    <Layout>
                        <Suspense fallback={<PagePreloader />}>
                            <Component {...props}></Component>
                        </Suspense>
                    </Layout>
                ) : (
                    <Suspense fallback={<PagePreloader />}>
                        <Component {...props}></Component>
                    </Suspense>
                )
            }
        ></Route>
    );
};

export default withRouter(AppRoute);
