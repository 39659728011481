import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../generic/Icon/Icon";
import Button from "../generic/Button/Button";
import IconLink from "../generic/IconLink/IconLink";
import TopCommunityContainer from "../TopCommunity/TopCommunityContainer";
import styles from "./Sidebar.module.sass";
import { clearAuthUserData } from "../../redux/reducers/authReducer.js";
import { connect } from "react-redux";

const Sidebar = (props) => {
	const { menus, clearAuthUserData } = props;

	const renderSidebarContent = () => {
		return menus.map((menu, idx) => (
			<div className={styles.sidebar__menu} key={idx}>
				<div className={styles.sidebar__menu_title}>{menu.title}</div>
				{menu.menu.map((m, idx) => {
					// Кнопка
					if (m.isButton) {
						return (
							<Button
								icon={m.icon}
								text={m.text}
								className={styles.sidebar__btn}
								key={idx}
								onClick={() => m.onClick()}
							></Button>
						);
						// Панелька
					} else if (m.isBoard) {
						return (
							<div className={styles.sidebar__board} key={idx}>
								{m.items.map(({ label, value }, idx) => (
									<div
										className={styles.sidebar__board_item}
										key={idx}
									>
										<div
											className={
												styles.sidebar__board_label
											}
										>
											{label}
										</div>
										<div
											className={
												styles.sidebar__board_value
											}
										>
											{value}
										</div>
									</div>
								))}
							</div>
						);
					} else {
						// Ссылка с onClick
						return m.stageOnly && window.location.hostname === "isend.pro" ? null : m.onClick ? (
							<span
								className={styles.sidebar__link}
								key={idx}
								onClick={m.onClick}
							>
								<Icon
									icon={m.icon}
									className={styles.sidebar__link_icon}
								></Icon>
								{m.text}
								<span className={styles.sidebar__link_extra}>
									{m.extraText}
								</span>
							</span>
						) : m.to ? (
							// Ссылка
							<NavLink
								exact
								to={m.to}
								className={styles.sidebar__link}
								activeClassName={styles.active}
								key={idx}
							>
								<Icon
									icon={m.icon}
									className={styles.sidebar__link_icon}
								></Icon>
								{m.text}
								<span className={styles.sidebar__link_extra}>
									{m.extraText}
								</span>
							</NavLink>
						) : null;
					}
				})}
			</div>
		));
	};

	return (
		<div className={styles.sidebar}>
			<header className={styles.sidebar__header}>
				<TopCommunityContainer></TopCommunityContainer>
			</header>

			<div className={styles.sidebar__main}>{renderSidebarContent()}</div>

			<footer className={styles.sidebar__footer}>
				<IconLink icon="exit" onClick={clearAuthUserData}>
					Выход
				</IconLink>
			</footer>
		</div>
	);
};

class SidebarContainer extends React.Component {
	render() {
		return <Sidebar {...this.props} />;
	}
}

const mstp = () => {
	return {};
};

export default connect(mstp, { clearAuthUserData })(SidebarContainer);
