import React from "react";
import Icon from "../Icon/Icon";
import styles from "./ActiveButton.module.sass";

const ActiveButton = (props) => {
    const {
        icon,
        children,
        color,
        tag = "div",
        iconStyle = {},
        className = "",
        size = "",
        ...rest
    } = props;
    let btnClass = "";
    let btnSize = "";
    let CustomTag = tag;

    if (color === "grey") {
        btnClass = styles["button--grey"];
    }
    if (size === "sm") {
        btnSize = styles["button--sm"];
    }

    return (
        <CustomTag
            className={`${styles.button} ${btnClass} ${btnSize} ${className}`}
            {...rest}
        >
            <Icon
                icon={icon}
                className={styles.button__icon}
                style={iconStyle}
            ></Icon>
            <span className={styles.button__text}>{children}</span>
        </CustomTag>
    );
};

export default ActiveButton;
