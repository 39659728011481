import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import styles from "./NoResult.module.sass";

const NoResult = (props) => {
	const {
		text = "",
		img = "",
		link = { text: "", url: "" },
		btn = { text: "", url: "/" },
		className,
	} = props;

	return (
		<div className={`${styles.no_result} ${className}`}>
			<img src={img} alt="character" className={styles.no_result__icon} />
			<div
				className={styles.no_result__text}
				dangerouslySetInnerHTML={{ __html: text }}
			></div>
			{link.url ? (
				link.blank ? (
					<a
						href={link.url}
						onClick={link.onClick}
						className={`link ${styles.no_result__link}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{link.text}
					</a>
				) : link.blank ? (
					<a
						href={link.url}
						rel="noopener noreferrer"
						target="_blank"
						className={`link ${styles.no_result__link}`}
						onClick={link.onClick}
					>
						{link.text}
					</a>
				) : (
					<Link
						to={link.url}
						className={`link ${styles.no_result__link}`}
						onClick={link.onClick}
					>
						{link.text}
					</Link>
				)
			) : (
				<span
					className={`link ${styles.no_result__link}`}
					onClick={link.onClick}
				>
					{link.text}
				</span>
			)}

			{btn.text ? (
				<>
					<br />
					<Button
						to={btn.url}
						icon="help-circle"
						text={btn.text}
					></Button>
				</>
			) : null}
		</div>
	);
};

export default NoResult;
