import React from "react";
import { Link } from "react-router-dom";
import styles from "./DropDownMenu.module.sass";

const DropDownMenu = (props) => {
    const {
        links = [],
        className,
        footerLinks = [],
        isOpen = false,
        ...rest
    } = props;

    return (
        <div
            className={`${styles.dropdown} ${className} ${
                isOpen ? styles["active"] : 0
            }`}
            {...rest}
        >
            <div className={styles.dropdown__triangle}></div>
            {links.length ? (
                <div className={styles.dropdown__menu}>
                    {links.map((l, idx) => (
                        <Link
                            className={`${styles.dropdown__link} dark_link`}
                            key={idx}
                            {...l}
                        >
                            {l.text}
                        </Link>
                    ))}{" "}
                </div>
            ) : null}

            {footerLinks.length ? (
                <div className={styles.dropdown__footer}>
                    {footerLinks.map((l, idx) =>
                        l.to ? (
                            <Link
                                to={l.to}
                                key={idx}
                                className={`${styles.dropdown__link} dark_link`}
                            >
                                {l.text}
                            </Link>
                        ) : (
                            <span
                                key={idx}
                                className={`${styles.dropdown__link} dark_link`}
                                onClick={l.onClick}
                            >
                                {l.text}
                            </span>
                        )
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default DropDownMenu;
