const getInputImagePreview = (input, onLoad) => {
	if (input.files && input.files[0]) {
		let reader = new FileReader();

		reader.onload = (e) => {
			onLoad(e.target.result)
		};

		reader.readAsDataURL(input.files[0]); // convert to base64 string
	}
}

export default getInputImagePreview;