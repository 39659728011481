import React, { useState, useEffect } from "react";
import styles from "./Checkbox.module.sass";
import generateId from "../../../utils/generateId";
import Icon from "../Icon/Icon";
import ReactTooltip from "react-tooltip";

const Checkbox = (props) => {
	const {
		children,
		tooltip,
		className = "",
		size = "",
		checked,
		onChange,
		...rest
	} = props;
	const [isChecked, setChecked] = useState(true);
	const id = generateId();
	const tooltipId = generateId();

	useEffect(() => {
		setChecked(checked);
	}, [checked]);

	const onCheckboxChange = (e) => {
		setChecked(!isChecked);

		if (onChange) {
			onChange(e);
		}
	};

	return (
		<div className={`${styles.checkbox} ${className} ${styles[size]}`}>
			<input
				type="checkbox"
				id={id}
				checked={isChecked}
				onChange={onCheckboxChange}
				{...rest}
			/>
			<label htmlFor={id} className={styles.checkbox__label}>
				<span className={styles.checkbox__icon}>
					<Icon icon="tick" />
				</span>
				{children ? (
					<div className={styles.checkbox__text}>
						{children}

						{tooltip ? (
							<>
								<div
									className="tooltip_trigger block_label__tooltip"
									data-effect="solid"
									data-for={tooltipId}
									data-tip
								></div>
								<ReactTooltip className="tooltip" id={tooltipId}>
									{typeof tooltip === "string" ? <div dangerouslySetInnerHTML={{ __html: tooltip }}></div> : tooltip}
								</ReactTooltip>
							</>
						) : null}
					</div>
				) : null}
			</label>
		</div>
	);
};

export default Checkbox;
