import React, { Component } from "react";
import { connect } from "react-redux";
import TopProfile from "./TopProfile";
import {
	getAuthUserData,
	clearAuthUserData,
} from "../../redux/reducers/authReducer";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

class TopProfileContainer extends Component {
	componentDidMount() {
		this.props.getAuthUserData(this.props.match.params.id);
	}

	render() {
		return <TopProfile {...this.props}></TopProfile>;
	}
}

const mapStateToProps = (state) => {
	return {
		userData: state.auth.userData,
	};
};

export default compose(
	withRouter,
	connect(mapStateToProps, {
		getAuthUserData,
		clearAuthUserData,
	})
)(TopProfileContainer);
