import React, { useEffect, useState, useRef } from "react";
import Icon from "../Icon/Icon";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import AsyncSelect from "react-select/async";
import "./CustomSelect.sass";

const CustomSelect = (props) => {
    const {
        name = "",
        value = "",
        icon,
        options = [],
        isMulti,
        isError,
        disabled = false,
        onChange,
        onBlur,
        className,
        type,
        size = "",
        alwaysPlaceholder = false,
        ...rest
    } = props;

    const [selectValue, setSelectValue] = useState(value);
    const [fullSelectValue, setFullSelectValue] = useState(null);
    const [inputingValue, setInputingValue] = useState("");
    const selectRef = useRef(0);

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    // Кастомная стрелка дропдауна
    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {icon === "plus" || isMulti === true ? (
                        <>
                            <span className="input_select__plus"></span>
                            <span className="input_select__plus"></span>
                        </>
                    ) : (
                        <Icon
                            icon="arrow-down"
                            className="input_select__icon"
                        />
                    )}
                </components.DropdownIndicator>
            )
        );
    };

    // Добавление скролла в меню
    const selectCustomMenuList = (props) => (
        <div {...props.innerProps} className="input_select__menu-list">
            <CustomScrollbar className="input_select__scroll">
                {props.children}
            </CustomScrollbar>
        </div>
    );

    const simulateEventTarget = (value) => {
        const fullValue = value;
        value = value ? value : { value: "", label: "" };

        if (value.length) {
            value = value.map((v) => v.value);
        } else {
            value = value.value;
        }

        return {
            target: { name, value },
            fullValue,
        };
    };

    const getValue = () => {
        if (selectValue === "") {
            return "";
        } else if (type === "creatable") {
            return selectValue && selectValue.length
                ? selectValue.map((value) => ({
                      value,
                      label: value,
                      __isNew__: true,
                  }))
                : [];
        } else {
            if (options) {
                return isMulti && selectValue
                    ? options.filter((o) => selectValue.indexOf(o.value) >= 0)
                    : options.find((o) => o.value === selectValue);
            } else {
                return isMulti ? [] : "";
            }
        }
    };

    const onSelectChange = (e) => {
        if (!alwaysPlaceholder) {
            setSelectValue(e.target.value);
            setFullSelectValue(e.fullValue);
        }
        if (typeof onChange === "function") onChange(e);
    };

    const onSelectBlur = (e) => {
        let searchedOptions = [];
        options.forEach((item) => {
            if (
                inputingValue &&
                item.label.toLowerCase().indexOf(inputingValue.toLowerCase()) >=
                    0
            ) {
                searchedOptions.push(item);
            }
        });

        if (type === "creatable" && inputingValue) {
            setSelectValue([...selectValue, inputingValue]);
            if (typeof onChange === "function")
                onChange(
                    simulateEventTarget([
                        ...getValue(),
                        {
                            value: inputingValue,
                            label: inputingValue,
                            __isNew__: true,
                        },
                    ])
                );
        }

        if (searchedOptions.length) {
            setSelectValue([...selectValue, searchedOptions[0].value]);
            setFullSelectValue([...fullSelectValue, searchedOptions[0].value]);
            if (typeof onChange === "function")
                onChange(
                    simulateEventTarget([...getValue(), searchedOptions[0]])
                );
        }

        if (typeof onBlur === "function") onBlur(e);

        setInputingValue("");
    };

    const onInputChange = (value) => {
        setInputingValue(value);
    };

    return type === "creatable" ? (
        <CreatableSelect
            options={options}
            noOptionsMessage={() => "Не найдено"}
            className={`input_select ${isError ? "error" : ""} ${
                disabled ? "input_select--disabled" : ""
            } ${className} ${isMulti ? "isMulti" : ""} ${
                getValue() && getValue().length ? "input_select--has-value" : ""
            } ${size === "sm" ? "input_select--sm" : ""}`}
            classNamePrefix="input_select"
            components={{
                DropdownIndicator,
                Menu: () => null,
            }}
            onChange={(value) => onSelectChange(simulateEventTarget(value))}
            onBlur={(value) => onSelectBlur(simulateEventTarget(value))}
            onInputChange={onInputChange}
            isMulti={isMulti}
            formatCreateLabel={(userInput) => `Создать: ${userInput}`}
            value={getValue()}
            ref={selectRef}
            {...rest}
        />
    ) : type === "asyncSelect" ? (
        <AsyncSelect
            noOptionsMessage={() => "Не найдено"}
            loadingMessage={() => "Поиск..."}
            className={`input_select ${isError ? "error" : ""} ${
                disabled ? "input_select--disabled" : ""
            } ${className} ${isMulti ? "isMulti" : ""} ${
                getValue() && getValue().length ? "input_select--has-value" : ""
            } ${size === "sm" ? "input_select--sm" : ""}`}
            classNamePrefix="input_select"
            components={{
                DropdownIndicator,
                LoadingIndicator: () => null,
                // Menu: () => null,
            }}
            onChange={(value) => onSelectChange(simulateEventTarget(value))}
            onBlur={(value) => onSelectBlur(simulateEventTarget(value))}
            onInputChange={onInputChange}
            isMulti={isMulti}
            formatCreateLabel={(userInput) => `Создать: ${userInput}`}
            value={fullSelectValue}
            ref={selectRef}
            {...rest}
        />
    ) : (
        <Select
            options={options}
            isMulti={isMulti}
            noOptionsMessage={() => "Не найдено"}
            className={`input_select ${isError ? "error" : ""} ${
                disabled ? "input_select--disabled" : ""
            } ${className} ${isMulti ? "isMulti" : ""} ${
                getValue() && getValue().length ? "input_select--has-value" : ""
            } ${size === "sm" ? "input_select--sm" : ""}`}
            classNamePrefix="input_select"
            value={getValue()}
            components={{
                DropdownIndicator,
                MenuList: selectCustomMenuList,
            }}
            onChange={(value) => onSelectChange(simulateEventTarget(value))}
            onBlur={(value) => onSelectBlur(simulateEventTarget(value))}
            onInputChange={onInputChange}
            {...rest}
        />
    );
};

export default CustomSelect;
