import React, { Component } from "react";
import Icon from "../generic/Icon/Icon";
import styles from "./Preloader.module.sass";

class Preloader extends Component {
	componentDidMount() {

	}

	render() {
		return (
			<div className={styles.preloader_container}>
				<div className={styles.preloader}>
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
					<Icon icon="load-circle" className={styles.preloader__icon} />
				</div> 
			</div>
		);
	}
}

export default Preloader;
