import { testAPI } from "../../api/api";

const SET_CHECK_RESULT = "checkingWork/SET_CHECK_RESULT";
const SET_CHECK_IS_LOADING = "checkingWork/SET_CHECK_IS_LOADING";

const defaultState = {
    checkIsLoading: false,
    checkResult: {},
};

const checkingWorkReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CHECK_RESULT:
            return { ...state, checkResult: action.checkResult };
        case SET_CHECK_IS_LOADING:
            return { ...state, checkIsLoading: action.checkIsLoading };

        default:
            return state;
    }
};

const setCheckResultAction = (checkResult) => ({
    type: SET_CHECK_RESULT,
    checkResult,
});

const setCheckIsLoading = (checkIsLoading) => ({
    type: SET_CHECK_IS_LOADING,
    checkIsLoading,
});

export const checkDistributionWorking = (data) => async (dispatch) => {
    dispatch(setCheckIsLoading(true));
    const response = await testAPI.startTest(data);
    dispatch(setCheckIsLoading(false));
    dispatch(setCheckResultAction(response));
	return response;
};

export default checkingWorkReducer;
