import React, { Component } from "react";
import { connect } from "react-redux";
import Notices from "./Notices";
import {
	getNoticess,
	addNotice,
	addNewNotice,
	removeNotice,
	removeNewNotice,
} from "../../redux/reducers/noticesReducer";
import { compose } from "redux";
import { withRouter } from "react-router";
import generateId from "../../utils/generateId";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

export class NoticesContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
		};
	}

	componentDidMount() {
		this.props.getNoticess();

		// Для отладки
		const query = new URLSearchParams(this.props.location.search);
		if (query.get("test") === "Y") {
			setInterval(() => {
				let notice = {
					id: generateId(),
					title: `API ключ не активен ${generateId()}`,
					text:
						"API ключ вашего сообщества ссылка на сообщество стал не активным. Обновите его, чтобы продолжить работу с isend.pro",
				};
				this.props.addNewNotice(notice);
				this.props.addNotice(notice);
			}, 2000);
		}
	}

	componentDidUpdate(prevProps) {
		// Если нет уведомлений, то закрываем окно
		if (this.props.notices.length === 0 && this.state.isActive) {
			this.props.onClose();
		}

		// Выставляем isActive из пропсов, если он изменился
		if (prevProps.isActive !== this.state.isActive) {
			this.setState((state) => ({
				...state,
				isActive: prevProps.isActive,
			}));
		}

		// Body scroll lock
		if (prevProps.isActive !== this.props.isActive) {
			if (this.props.notices.length && this.props.isActive) {
				disableBodyScroll(document.querySelector("#notices"));
			} else if (!this.props.isActive) {
				clearAllBodyScrollLocks();
			}
		}
	}

	render() {
		const { isActive, removeNotice, ...rest } = this.props;
		return (
			<Notices
				{...rest}
				isActive={isActive}
				onRemoveNotice={removeNotice}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	notices: state.notices.notices,
	newNotices: state.notices.newNotices,
});

const mapDispatchToProps = {
	getNoticess,
	removeNotice,
	removeNewNotice,
	addNotice,
	addNewNotice,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)(NoticesContainer);
