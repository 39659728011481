import React from "react";
import styles from "./UserCard.module.sass";

const UserCard = ({ className = "", photo = "", name = "", info = [] }) => {
    return (
        <div className={`${styles.user} ${className}`}>
            {photo ? (
                <div
                    className={styles.user__photo}
                    style={{ backgroundImage: `url(${photo})` }}
                ></div>
            ) : null}
            <div className={styles.user__info}>
                {name ? <div className={styles.user__name}>{name}</div> : null}
                <div className={styles.user__table}>
                    {info.map((i) => (
                        <div className={styles.user__table_row}>
                            <div className={styles.user__table_td}>
                                {i.label}
                            </div>
                            <div className={styles.user__table_td}>
                                {i.value || "-"}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UserCard;
