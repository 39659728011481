import React from "react";
import styles from "./Header.module.sass";
import { Link, withRouter } from "react-router-dom";
import Icon from "../generic/Icon/Icon";
import TopProfileContainer from "../TopProfile/TopProfileContainer";
import Hamburger from "../generic/Hamburger/Hamburger";
import MobileMenu from "../MobileMenu/MobileMenu";
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from "body-scroll-lock";
import NoticesContainer from "../Notices/NoticesContainer";
import { connect } from "react-redux";
import { compose } from "redux";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isHeaderHover: false,
			isMenuOpen: false,
			isNoticeActive: false,
		};
	}

	componentDidMount() {
		this.mobileMenuEl = document.querySelector("#mobileMenu");
		clearAllBodyScrollLocks();
	}

	componentDidUpdate() {
		if (this.state.isMenuOpen) {
			clearAllBodyScrollLocks();
			setTimeout(
				function() {
					disableBodyScroll(this.mobileMenuEl);
				}.bind(this),
				100
			);
		} else {
			enableBodyScroll(this.mobileMenuEl);
		}
	}

	onHeaderHover = (isHover) => {
		if (window.matchMedia("(min-width: 769px)").matches) {
			if (this.settings.size !== "sm") {
				this.setState({
					...this.state,
					isHeaderHover: isHover,
				});
			}
		}
	};

	onMenuClose = () => {
		this.setState({
			...this.state,
			isMenuOpen: false,
		});
	};

	onMenuBtnClick = () => {
		this.setState({
			...this.state,
			isMenuOpen: true,
		});
	};

	render() {
		const {
			settings = { size: "" },
			menus,
			showMenuCommunityProfile,
			match,
			notices,
		} = this.props;

		this.settings = settings;

		const links = [
			{ text: "Сообщества", icon: "group", url: "/community/" },
			{
				text: "Партнерская программа",
				url: "/cabinet/referrals/",
				icon: "hand-shake",
			},
		];

		return (
			<>
				<MobileMenu
					isActive={this.state.isMenuOpen}
					onClose={this.onMenuClose}
					menus={menus}
					showMenuCommunityProfile={showMenuCommunityProfile}
					id="mobileMenu"
					className={`${this.state.isMenuOpen ? "active" : ""}`}
				></MobileMenu>
				<header
					className={`${styles.header} ${
						settings.size === "sm" ? styles["header--sm"] : ""
					}`}
					onMouseEnter={() => this.onHeaderHover(true)}
					onMouseLeave={() => this.onHeaderHover(false)}
				>
					<div className={settings.size === "sm" ? "" : "container"}>
						<div className={styles.header__main}>
							<div className="row justify-content-between">
								<div
									className={`col ${styles.header__left_col}`}
								>
									<div className={styles.header__burger}>
										<Hamburger
											onClick={this.onMenuBtnClick}
										></Hamburger>
									</div>

									<div
										className={`${styles.header__notice_link} d-md-none`}
										onClick={() =>
											this.setState((state) => ({
												...state,
												isNoticeActive: true,
											}))
										}
									>
										{notices.length ? (
											<div
												className={`${styles.nav__link_notice}`}
											>
												{notices.length}
											</div>
										) : null}
										<Icon
											icon="ringer"
											className={styles.nav__link_icon}
										/>
									</div>

									{match.url === "/community/" ||
									match.url === "/community" ||
									match.url === "/" ? (
										<div className={styles.logo_link}>
											<Icon
												icon="logo"
												className={styles.logo}
											></Icon>
										</div>
									) : (
										<Link
											to="/"
											className={styles.logo_link}
										>
											<Icon
												icon="logo"
												className={styles.logo}
											></Icon>
										</Link>
									)}
								</div>
								<div
									className={`col ${styles.header__right_col}`}
								>
									<div
										className={`${styles.header__notice_link} d-xl-none`}
										onClick={() =>
											this.setState((state) => ({
												...state,
												isNoticeActive: true,
											}))
										}
									>
										{notices.length ? (
											<div
												className={`${styles.nav__link_notice}`}
											>
												{notices.length}
											</div>
										) : null}
										<Icon
											icon="ringer"
											className={styles.nav__link_icon}
										/>
										<span className={styles.nav__link_text}>
											Уведомления
										</span>
									</div>
									<TopProfileContainer
										theme={
											this.state.isHeaderHover
												? "light"
												: ""
										}
									></TopProfileContainer>
								</div>
							</div>
						</div>

						{settings.size !== "sm" && (
							<nav className={styles.nav}>
								{links.map((l, idx) => (
									<Link
										to={l.url}
										className={styles.nav__link}
										key={idx}
									>
										<Icon
											icon={l.icon}
											className={styles.nav__link_icon}
										></Icon>
										<span className={styles.nav__link_text}>
											{l.text}
										</span>
									</Link>
								))}

								<div
									className={`${styles.nav__link} ${styles["nav__link--right"]}`}
									onClick={() =>
										this.setState((state) => ({
											...state,
											isNoticeActive: true,
										}))
									}
								>
									{notices.length ? (
										<div
											className={`${styles.nav__link_notice}`}
										>
											{notices.length}
										</div>
									) : null}
									<Icon
										icon="ringer"
										className={styles.nav__link_icon}
									/>
									<span className={styles.nav__link_text}>
										Уведомления
									</span>
								</div>
							</nav>
						)}
						<NoticesContainer
							className={`${styles.nav__notices} ${
								settings.size === "sm"
									? styles["position2"]
									: ""
							}`}
							listClassName={
								settings.size === "sm"
									? styles["header__notices_list"]
									: ""
							}
							isActive={this.state.isNoticeActive}
							onClose={() =>
								this.setState(state => ({ ...state, isNoticeActive: false }))
							}
							onOpen={() =>
								this.setState(state => ({ ...state, isNoticeActive: true }))
							}
						></NoticesContainer>
					</div>
				</header>
			</>
		);
	}
}

const mstp = (state) => ({
	notices: state.notices.notices,
});

export default compose(connect(mstp, {}), withRouter)(Header);
