import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import Icon from "../generic/Icon/Icon";
import TagList from "../generic/TagList/TagList";
import styles from "./Subscriber.module.sass";
import moment from "moment";

const Subscriber = (props) => {
	let {
		action = "",
		action_name = "",
		subscriber_name = "",
		group_name = "",
		date = "",
		utm = [],
		utm_campaign = [],
		utm_content = [],
		utm_medium = [],
		utm_source = [],
		utm_term = [],
		subscriber_image = "",
		subscriber_link = "https://ok.ru/",
		source_name = "",
		className = "",
		modifier = "",
	} = props;

	const [isExtraOpen, setIsExtraOpen] = useState();

	let allUtms = [];
	if (utm) allUtms.push({ text: utm });
	if (utm_campaign) allUtms.push({ text: `CAMPAIGN: ${utm_campaign}` });
	if (utm_content) allUtms.push({ text: `CONTENT: ${utm_content}` });
	if (utm_medium) allUtms.push({ text: `MEDIUM: ${utm_medium}` });
	if (utm_source) allUtms.push({ text: `SOURCE: ${utm_source}` });
	if (utm_term) allUtms.push({ text: `TERM: ${utm_term}` });

	if (action === "SUBSCRIBE") {
		modifier = styles["subscriber--green"];
	}
	if (action === "UNSUBSCRIBE") {
		modifier = styles["subscriber--red"];
	}

	return (
		<div
			className={`${styles.subscriber} ${
				isExtraOpen ? styles["extra_opened"] : null
			} ${modifier} ${className}`}
		>
			{action_name ? (
				<div className={`${styles.subscriber__status}`}>
					{action_name}
				</div>
			) : null}

			<div
				className={styles.subscriber__head}
				onClick={() => setIsExtraOpen(!isExtraOpen)}
			>
				<div className={styles.subscriber__row}>
					<div className={styles.subscriber__col}>
						<div className={styles.subscriber__header}>
							<a
								href={subscriber_link}
								target="_blank"
								rel="noopener noreferrer"
								className={styles.subscriber__photo}
								style={{
									backgroundImage: `url(${subscriber_image})`,
								}}
								onClick={(e) => e.stopPropagation()}
							>&nbsp;</a>

							<div className={styles.subscriber__header_info}>
								{subscriber_name ? (
									<a
										href={subscriber_link}
										target="_blank"
										rel="noopener noreferrer"
										className={styles.subscriber__name}
										dangerouslySetInnerHTML={{
											__html: subscriber_name,
										}}
										onClick={(e) => e.stopPropagation()}
									></a>
								) : null}
								{group_name ? (
									<div className={styles.subscriber__desc}>
										{group_name}
									</div>
								) : null}
								{date ? (
									<div className={styles.subscriber__date}>
										{moment(
											date,
											"YYYY-MM-DD hh:mm:ss"
										).format("DD.MM.YYYY HH:mm")}
									</div>
								) : null}
							</div>
						</div>
					</div>
					{allUtms.length ? (
						<div className={styles.subscriber__col}>
							<TagList
								tags={allUtms}
								theme="dark"
								noHover={true}
							/>
						</div>
					) : null}
					<div className={`${styles.subscriber__show_more}`}>
						<Icon icon="dots" />
					</div>
				</div>
			</div>

			<AnimateHeight duration={500} height={isExtraOpen ? "auto" : 0}>
				<div className={styles.subscriber__extra}>
					<div className={styles.subscriber__extra_block}>
						Источник:{" "}
						<span className="text_primary">
							{source_name}
						</span>
					</div>
				</div>
			</AnimateHeight>
		</div>
	);
};

export default Subscriber;
