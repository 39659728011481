import React from "react";
import styles from "./ChatbotCard.module.sass";
import Icon from "../../components/generic/Icon/Icon";
import ActiveButton from "../generic/ActiveButton/ActiveButton";
import AnimateHeight from "react-animate-height";
import wrapTextPart from "../../utils/wrapTextPart";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const ChatbotCard = ({
    name = "",
    highlightName = "",
    id = null,
    participants = null,
    actions = null,
    status = false,
    isOpen = false,
    publishDate,
    createdDate,
    onOpenClick = () => {},
    onHeadClick = () => {},
    onStart = () => {},
    onStop = () => {},
    onEdit = () => {},
    onCopy = () => {},
    onRemove = () => {},
}) => {
    const { id: community_id } = useParams();

    // Формирование статуса чатбота
    let statusText = "";
    let statusIcon = "";
    let statusColor = "";
    if (status === true) {
        statusText = "В обработке";
        statusIcon = "play";
        statusColor = "chatbot__status--green";
    } else if (status === false) {
        statusText = "Требует активации";
        statusIcon = "pause";
        statusColor = "chatbot__status--grey";
    }

    if (highlightName) {
        name = wrapTextPart(name, highlightName, "b");
    }

    return (
        <div className={styles.chatbot}>
            <div
                className={styles.chatbot__open}
                onClick={() => onOpenClick(id)}
            >
                <Icon icon="dots" color="#EE8208" />
            </div>
            <div className={styles.chatbot__main}>
                <div
                    className={styles.chatbot__head}
                    onClick={() => onHeadClick(id)}
                >
                    <div className={styles.chatbot__col}>
                        <div
                            className={styles.chatbot__name}
                            dangerouslySetInnerHTML={{ __html: name }}
                        ></div>
                        <div>
                            <span className={styles.chatbot__id_label}>
                                id:
                            </span>
                            <span className={styles.chatbot__id}>{id}</span>
                        </div>
                    </div>
                    <div className={styles.chatbot__col}>
                        {status != null ? (
                            <div
                                className={`${styles.chatbot__status} ${styles[statusColor]}`}
                            >
                                <Icon
                                    icon={statusIcon}
                                    className={styles.chatbot__status_icon}
                                />
                                {statusText}
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.chatbot__col}>
                        <div className={styles.chatbot__info}>
                            {participants ? (
                                <div className={styles.chatbot__info_item}>
                                    <span
                                        className={styles.chatbot__info_label}
                                    >
                                        Участников:
                                    </span>
                                    <span
                                        className={styles.chatbot__info_value}
                                    >
                                        {participants}
                                    </span>
                                </div>
                            ) : null}
                            {actions ? (
                                <div className={styles.chatbot__info_item}>
                                    <span
                                        className={styles.chatbot__info_label}
                                    >
                                        Действий:
                                    </span>
                                    <span
                                        className={styles.chatbot__info_value}
                                    >
                                        {actions}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
                    <div className={styles.chatbot__togglable}>
                        <div className={styles.chatbot__buttons}>
                            <div className={styles.chatbot__button}>
                                {status === true ? (
                                    <ActiveButton
                                        icon="pause"
                                        size="sm"
                                        color="grey"
                                        onClick={() => onStop({ id })}
                                    >
                                        Остановить
                                    </ActiveButton>
                                ) : (
                                    <ActiveButton
                                        icon="play"
                                        size="sm"
                                        onClick={() => onStart({ id })}
                                    >
                                        Запустить
                                    </ActiveButton>
                                )}
                            </div>
                            <div className={styles.chatbot__button}>
                                <Link
                                    to={`/community/${community_id}/bots/${id}/editor`}
                                >
                                    <ActiveButton
                                        icon="pen"
                                        color="grey"
                                        size="sm"
                                        onClick={onEdit}
                                    >
                                        Изменить
                                    </ActiveButton>
                                </Link>
                            </div>
                            <div className={styles.chatbot__button}>
                                <ActiveButton
                                    icon="copy"
                                    color="grey"
                                    size="sm"
                                    onClick={() => onCopy({ id })}
                                >
                                    Создать копию
                                </ActiveButton>
                            </div>
                            <div className={styles.chatbot__button}>
                                <ActiveButton
                                    icon="trash"
                                    color="grey"
                                    size="sm"
                                    onClick={() => onRemove({ id })}
                                >
                                    Удалить
                                </ActiveButton>
                            </div>
                        </div>
                        <div className={styles.chatbot__extra}>
                            {createdDate ? (
                                <div className={styles.chatbot__extra_item}>
                                    Создано: {createdDate}
                                </div>
                            ) : null}
                            {publishDate ? (
                                <div className={styles.chatbot__extra_item}>
                                    Публикация: {publishDate}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </AnimateHeight>
            </div>
        </div>
    );
};

export default ChatbotCard;
