import Axios from "axios";

export default function downloadFileByUrl(dataurl, filename = "") {
    Axios
        .get(dataurl, {
            responseType: "blob",
        })
        .then((response) => {
            var blob = new Blob([response.data], {
                type: response.request.getResponseHeader("Content-Type"),
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });
}
