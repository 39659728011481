import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./StatisticsGraph.module.sass";
import * as Yup from "yup";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import Legend from "../generic/Legend/Legend";
import Input from "../generic/Input/Input";
import Button from "../generic/Button/Button";
import ActiveButton from "../generic/ActiveButton/ActiveButton";
import Subscriber from "../Subscriber/Subscriber";
import { Field, Form, Formik } from "formik";
import { useHistory, withRouter } from "react-router-dom";
import declOfNum from "../../utils/declOfNum";
import moment from "moment";
import "moment/locale/ru";
import { isIE } from "react-device-detect";
import Preloader from "../Preloader/Preloader";
import animatedScrollTo from "../../utils/animateScrollTo";
import getOffset from "../../utils/getOffset";
import getQueryParams from "../../utils/getQueryParams";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

am4core.useTheme(am4themes_animated);

let lastScrollTop = 0;
const StatisticsGraph = (props) => {
	const {
		subscribers = [],
		groups = [],
		subscribeHistory = {
			chartInfo: {
				dates: [],
			},
		},
		getSubscriberHistory,
		isLoading,
		match,
		pagesCount,
	} = props;

	const urlParams = getQueryParams(props.location.search);
	const history = useHistory();
	const [formInitialValues, setFormInitialValues] = useState({
		subscriber_id: urlParams.group_id ? [urlParams.subscriber_id] : [],
		group_id: urlParams.group_id ? [urlParams.group_id] : [],
		remove_group_id: [],
		utm: [],
		utm_source: [],
		utm_medium: [],
		utm_campaign: [],
		utm_content: [],
		utm_term: [],
		source: [],
		date_from: null,
		time_from: null,
		date_to: null,
		time_to: null,
		no_repeated: false,
		community_id: match.params.id,
	});
	const [timeError, setTimeError] = useState("");
	const [showPagesAmount, setShowPagesAmount] = useState(1);
	const [shouldPaginationWork, setShouldPaginationWork] = useState(
		pagesCount > 1 ? true : false
	);
	const [lastSubmitedFormData, setLastSubmitedFormData] = useState(
		formInitialValues
	);
	const { tooltips = {} } = useSelector(state => state.tooltips);

	useEffect(() => {
		if (pagesCount > 1) {
			setShouldPaginationWork(true);
		} else {
			setShouldPaginationWork(false);
		}
	}, [pagesCount]);

	// Подгрузка новых подписчиков при скролле до конца
	const onScrollPagination = () => {
		const pageHeight = document.body.clientHeight;
		const scrollBottom = window.pageYOffset + window.innerHeight;
		const st = window.pageYOffset || document.documentElement.scrollTop;

		if (st > lastScrollTop) {
			// downscroll code
			if (
				scrollBottom >= pageHeight - 20 &&
				!isLoading &&
				shouldPaginationWork &&
				pagesCount > showPagesAmount
			) {
				let newPage = showPagesAmount + 1;
				getSubscriberHistory({
					...lastSubmitedFormData,
					page: newPage,
				});
				setShowPagesAmount(newPage);
			}
		}

		lastScrollTop = st <= 0 ? 0 : st;
	};

	useEffect(() => {
		window.addEventListener("scroll", onScrollPagination);
		window.addEventListener("touchmove", onScrollPagination);

		return () => {
			window.removeEventListener("scroll", onScrollPagination);
			window.removeEventListener("touchmove", onScrollPagination);
		};
		// eslint-disable-next-line
	}, [shouldPaginationWork, isLoading, JSON.stringify(lastSubmitedFormData)]);

	// Для постраничной навигации
	useEffect(() => {
		setFormInitialValues((state) => ({
			...state,
			date_from: moment()
				.subtract(14, "days")
				.format("DD.MM.YYYY"),
			time_from: "00:00",
			date_to: moment().format("DD.MM.YYYY"),
			time_to: moment().format("HH:mm"),
		}));
	}, []);
	// Для постраничной навигации END

	// Формирую данных для графика
	let graphData = [];
	if (subscribeHistory && subscribeHistory.chartInfo) {
		for (const date in subscribeHistory.chartInfo.dates) {
			let subscribe = subscribeHistory.chartInfo.dates[date].subscribe;
			let unsubscribe =
				subscribeHistory.chartInfo.dates[date].unsubscribe;
			graphData.push({ date, subscribe, unsubscribe });
		}
	}

	// Есть ли данные для графика
	let isGraphData = graphData.length ? true : false;

	const chart = useRef(null);

	useLayoutEffect(() => {
		let x = am4core.create("chartdiv", am4charts.XYChart);

		x.language.locale = am4lang_ru_RU;
		if (window.matchMedia("(max-width: 767px)").matches) {
			x.paddingRight = 0;
		} else {
			x.paddingRight = 25;
		}
		x.paddingLeft = 0;

		x.data = [];

		// Set input format for the dates
		x.dateFormatter.inputDateFormat = "yyyy-MM-dd";

		// Create axes
		var dateAxis = x.xAxes.push(new am4charts.DateAxis());
		var valueAxis = x.yAxes.push(new am4charts.ValueAxis());

		dateAxis.fontSize = 12;
		dateAxis.renderer.labels.template.dy = -13;
		dateAxis.renderer.labels.template.location = 0.5;
		dateAxis.renderer.grid.template.location = 0.5;
		dateAxis.renderer.minGridDistance = 15;
		dateAxis.startLocation = 0.5;
		dateAxis.endLocation = 0.5;

		valueAxis.fontSize = 12;
		valueAxis.renderer.minGridDistance = 10;
		valueAxis.min = 0;
		valueAxis.maxPrecision = 0;

		if (window.matchMedia("(max-width: 767px)").matches) {
			valueAxis.fontSize = 10;
			dateAxis.fontSize = 10;
		}

		dateAxis.cursorTooltipEnabled = false;
		valueAxis.cursorTooltipEnabled = false;

		// Text colors
		valueAxis.renderer.labels.template.fill = am4core.color("#545454");
		dateAxis.renderer.labels.template.fill = am4core.color("#545454");

		// Text rotation
		dateAxis.renderer.labels.template.rotation = -45;
		dateAxis.renderer.labels.template.horizontalCenter = "right";

		// Кривая отписок
		var series2 = x.series.push(new am4charts.LineSeries());
		series2.dataFields.valueY = "unsubscribe";
		series2.dataFields.dateX = "date";
		series2.strokeWidth = 2;
		series2.stroke = am4core.color("#C71A1A");
		series2.tensionX = 0.77;

		// Кривая подписок
		var series = x.series.push(new am4charts.LineSeries());
		series.dataFields.valueY = "subscribe";
		series.dataFields.dateX = "date";
		series.strokeWidth = 2;
		series.stroke = am4core.color("#009518");
		series.tensionX = 0.77;
		series.tooltip.getFillFromObject = false;
		series.tooltip.getStrokeFromObject = false;
		series.tooltip.align = "right";
		series.tooltip.label.valign = "center";
		series.tooltip.background.fill = am4core.color("#fff");
		series.tooltip.background.pointerLength = 0;
		series.tooltip.background.cornerRadius = 4;
		series.tooltip.marginRight = 10;
		if (isIE) {
			series.tooltip.label.paddingLeft = 12;
			series.tooltip.label.paddingRight = 12;
			series.tooltip.label.paddingTop = 12;
			series.tooltip.label.paddingBottom = 12;
		} else {
			series.tooltip.label.paddingLeft = 0;
			series.tooltip.label.paddingRight = 0;
			series.tooltip.label.paddingTop = 0;
			series.tooltip.label.paddingBottom = 0;
		}
		series.tooltip.label.marginRight = 10;
		series.tooltip.animationDuration = 0;
		series.tooltip.autoTextColor = false;
		series.tooltip.label.fill = am4core.color("#545454");
		let shadow = series.tooltip.background.filters.getIndex(0);
		shadow.dx = 0;
		shadow.dy = 0;
		shadow.blur = 0;

		// Make bullets grow on hover
		var bullet = series.bullets.push(new am4charts.CircleBullet());
		// bullet.circle.strokeWidth = 3;
		// bullet.circle.stroke = am4core.color("#ffffff");
		bullet.circle.radius = 3;
		bullet.circle.fill = am4core.color("#009518");
		// Make bullets grow on hover
		let bullet2 = series2.bullets.push(new am4charts.Bullet());
		let square = bullet2.createChild(am4core.Rectangle);
		square.width = 6;
		square.height = 6;
		square.horizontalCenter = "middle";
		square.verticalCenter = "middle";
		// square.strokeWidth = 3;
		// square.stroke = am4core.color("#ffffff");
		square.fill = am4core.color("#C71A1A");

		var bullethover = bullet.states.create("hover");
		bullethover.properties.scale = 1.3;
		var bullethover2 = square.states.create("hover");
		bullethover2.properties.scale = 1.3;

		// Tooltip
		x.cursor = new am4charts.XYCursor();
		x.cursor.lineY.disabled = true;
		x.cursor.lineX.disabled = true;
		x.cursor.behavior = "none";
		x.tooltip.getFillFromObject = false;
		x.tooltip.background.fill = am4core.color("#2ac955");

		series.adapter.add("tooltipHTML", function (html, target, key) {
			var values = target.tooltipDataItem.dataContext || {};
			var tooltipHTML = `<div class="graph_tooltip">
				<div class="mb12">
					${values.date ? moment(values.date, "YYYY-MM-DD").format("DD MMM YYYY") : null}
				</div>
				<div class="mb6">
					${values.subscribe != null
					? "Подписалось: <span style='color: #009518'>" +
					values.subscribe +
					"</span>"
					: null
				}
				</div>
				${values.unsubscribe != null
					? "Отписалось: <span style='color: #C71A1A'>" +
					values.unsubscribe +
					"</span>"
					: null
				}
			</div>`;
			return tooltipHTML;
		});

		// Tooltip text для IE
		series.adapter.add("tooltipText", function (html, target, key) {
			var values = target.tooltipDataItem.dataContext;
			var tooltipHTML = `${values.date
				? moment(values.date, "YYYY-MM-DD").format("DD MMM YYYY")
				: null
				}

			Подписалось: [bold]${values.subscribe}[/]
			Отписалось: [bold]${values.unsubscribe}[/]
			`;
			return tooltipHTML;
		});

		valueAxis.renderer.labels.template.adapter.add("text", function (
			text,
			label
		) {
			if (window.matchMedia("(max-width: 767px)").matches) {
				return "";
			} else {
				return text;
			}
		});

		x.zoomOutButton.disabled = true;

		dateAxis.start = 0;
		dateAxis.keepSelection = true;

		chart.current = x;

		return () => {
			x.dispose();
		};
	}, []);

	useLayoutEffect(() => {
		if (isGraphData) {
			chart.current.xAxes.values[0].fontSize = 12;
			chart.current.series.values[0].tooltip.disabled = false;
			chart.current.series.values[0].bulletsContainer.disabled = false;
			chart.current.series.values[1].bulletsContainer.disabled = false;
		} else {
			chart.current.xAxes.values[0].fontSize = 0;
			chart.current.series.values[0].tooltip.disabled = true;
			chart.current.series.values[0].bulletsContainer.disabled = true;
			chart.current.series.values[1].bulletsContainer.disabled = true;
		}

		chart.current.data = graphData;
		/*eslint-disable */
	}, [JSON.stringify(graphData)]); /*eslint-enable */

	// Опции для выбора групп
	const groupsOption = [{ label: "Без группы", value: "WITHOUT" }].concat(
		groups.map((group) => ({
			label: group.name,
			value: group.id,
		}))
	);

	const handleDateChange = (formik) => {
		const { values } = formik;
		const { time_from, time_to, date_from, date_to } = values;

		if (
			time_to &&
			date_from === date_to &&
			moment(time_to, "HH:mm").isBefore(moment(time_from, "HH:mm"))
		) {
			setTimeError("Некорректное время");
		} else {
			setTimeError("");
		}
	};

	const handleDateFromChange = (e, formik) => {
		let { value } = e.target;
		if (!value) {
			formik.setValues({
				...formik.values,
				date_from: value,
				time_from: "",
			});
		}
	};

	const handleDateToChange = (e, formik) => {
		let { value } = e.target;
		if (!value) {
			formik.setValues({
				...formik.values,
				date_to: value,
				time_to: "",
			});
		}
	};

	// Сабмит формы
	const onFormSubmit = (data) => {
		if (timeError) return 0;

		setShowPagesAmount(1);
		getSubscriberHistory(data).then(() => {
			setLastSubmitedFormData(data);
		});
		let graphTop = getOffset(document.querySelector("#chartdiv")).top;
		animatedScrollTo(document.querySelector("html"), graphTop, 500);
	};

	// Валидация
	const validationSchema = Yup.object().shape({
		time_from: Yup.mixed().test(
			"is-time",
			"Некорректное время",
			(value) => {
				return (
					!value ||
					/(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
						value
					)
				);
			}
		),
		time_to: Yup.mixed().test("is-time", "Некорректное время", (value) => {
			return (
				!value ||
				/(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
					value
				)
			);
		}),
	});

	return (
		<>
			<div className={`row ${styles.graph_main}`}>
				{isLoading ? <Preloader /> : null}
				<div className="col-xl-4 order-xl-2">
					<div className="block_label">Показатели</div>
					<div className="grey_block grey_block--not_full mb20">
						<div className="row">
							<div
								className={`col-xl-12 col-md-6 ${styles.graph_main__legend}`}
							>
								<Legend
									icon="legend-circle"
									color="#009518"
									className="fs14"
								>
									<div className="mb4">Подписалось:</div>
									<div>
										<span style={{ color: "#009518" }}>
											{
												subscribeHistory.chartInfo
													.subscribeFull
											}
										</span>{" "}
										{declOfNum(
											+subscribeHistory.chartInfo
												.subscribeFull,
											[
												"пользователь",
												"пользователя",
												"пользователей",
											]
										)}
									</div>
								</Legend>
							</div>
							<div
								className={`col-xl-12 col-md-6 ${styles.graph_main__legend}`}
							>
								<Legend
									icon="legend-square"
									color="#C71A1A"
									className="fs14"
								>
									<div className="mb4">Отписалось:</div>
									<div>
										<span style={{ color: "#C71A1A" }}>
											{
												subscribeHistory.chartInfo
													.unsubscribeFull
											}
										</span>{" "}
										{declOfNum(
											+subscribeHistory.chartInfo
												.unsubscribeFull,
											[
												"пользователь",
												"пользователя",
												"пользователей",
											]
										)}
									</div>
								</Legend>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-8">
					<div className={styles.graph_wrap}>
						<div
							id="chartdiv"
							className={styles.graph}
							style={{ width: "100%", height: "500px" }}
						></div>
					</div>
				</div>
			</div>

			<Formik
				enableReinitialize
				initialValues={formInitialValues}
				onSubmit={onFormSubmit}
				validationSchema={validationSchema}
			>
				{(formik) => (
					<Form className={`form ${styles.graph_form}`}>
						<div className="form__group">
							<div className="row align-items-center">
								<div className="col-md-6 form__group_item">
									<Field
										name="subscriber_id"
										type="select"
										isMulti={true}
										label="Подписчик"
										placeholder="Добавить подписчика"
										options={subscribers.map(
											(subscriber) => ({
												label: subscriber.fullName,
												value: subscriber.id,
											})
										)}
										component={Input}
										tooltip={tooltips.STATISTICS_SUBSCRIBER}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="group_id"
										type="select"
										isMulti={true}
										label="Группа"
										placeholder="Добавить группу"
										component={Input}
										options={
											formik.values.remove_group_id.length
												? groupsOption.filter(
													(s) =>
														!formik.values.remove_group_id.some(
															(id) =>
																id === s.value
														)
												)
												: groupsOption
										}
										tooltip={tooltips.STATISTICS_GROUP}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="remove_group_id"
										type="select"
										isMulti={true}
										label="За исключением группы"
										placeholder="Добавить группу"
										component={Input}
										options={
											formik.values.group_id.length
												? groupsOption.filter(
													(s) =>
														!formik.values.group_id.some(
															(id) =>
																id === s.value
														)
												)
												: groupsOption
										}
										tooltip={tooltips.STATISTICS_EXCLUDE_GROUP}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="utm"
										label="Метка"
										type="creatable"
										isMulti={true}
										placeholder="Добавить свою метку"
										component={Input}
										tooltip={tooltips.STATISTICS_UTM}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="utm_source"
										label="UTM_SOURCE"
										type="creatable"
										isMulti={true}
										placeholder="Добавить utm_source"
										component={Input}
										tooltip={tooltips.STATISTICS_UTM_SOURCE}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="utm_medium"
										label="UTM_MEDIUM"
										type="creatable"
										isMulti={true}
										placeholder="Добавить utm_medium"
										component={Input}
										tooltip={tooltips.STATISTICS_UTM_MEDIUM}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="utm_campaign"
										label="UTM_CAMPAIGN"
										type="creatable"
										isMulti={true}
										placeholder="Добавить utm_campaign"
										component={Input}
										tooltip={tooltips.STATISTICS_UTM_CAMPAIGN}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="utm_content"
										label="UTM_CONTENT"
										type="creatable"
										isMulti={true}
										placeholder="Добавить utm_content"
										component={Input}
										tooltip={tooltips.STATISTICS_UTM_CONTENT}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										name="utm_term"
										label="UTM_TERM"
										type="creatable"
										isMulti={true}
										placeholder="Добавить utm_term"
										component={Input}
										tooltip={tooltips.STATISTICS_UTM_TERM}
									></Field>
								</div>
								<div className="col-md-6 form__group_item">
									<Field
										label="Источник"
										type="select"
										isMulti={true}
										options={[
											{
												label: "Сообщество",
												value: "COMMUNITY",
											},
											{
												label: "Подписная страница",
												value: "SUBSCRIBE_PAGE",
											},
											{
												label: "Вручную",
												value: "MANUALLY",
											},
										]}
										name="source"
										placeholder="Добавить источник"
										component={Input}
										tooltip={tooltips.STATISTICS_SOURCE}
									></Field>
								</div>
								<div className="col-xl-6 form__group_item">
									<div className="row">
										<div className="col-md-6 mb-md-0 mb24">
											<div className="block_label ">
												<span>Дата подписки с {tooltips.STATISTICS_SUBSCRIBE_DATE_FROM ? <span
													className="tooltip_trigger block_label__tooltip"
													data-effect="solid"
													data-for={"STATISTICS_SUBSCRIBE_DATE_FROM"}
													data-tip
												></span> : null}</span>
												{tooltips.STATISTICS_SUBSCRIBE_DATE_FROM ? <ReactTooltip className="tooltip" id={"STATISTICS_SUBSCRIBE_DATE_FROM"}>
													<div dangerouslySetInnerHTML={{ __html: tooltips.STATISTICS_SUBSCRIBE_DATE_FROM }}></div>
												</ReactTooltip> : null}
											</div>
											<div
												className={
													styles.form__footer_inputs
												}
											>
												<div
													className={
														styles.form__date
													}
												>
													<Field
														type="datepicker"
														placeholder="Дата"
														name="date_from"
														mask="date"
														component={Input}
														maxDate={
															formik.values
																.date_to
																? new Date(
																	moment(
																		formik
																			.values
																			.date_to,
																		"DD.MM.YYYY"
																	).format(
																		"YYYY-MM-DD"
																	)
																)
																: false
														}
														onKeyUp={() =>
															handleDateChange(
																formik
															)
														}
														onChange={(e) =>
															handleDateFromChange(
																e,
																formik
															)
														}
														tooltip={tooltips.STATISTICS_SUBSCRIBE_DATE_FROM}
													></Field>
												</div>
												<div
													className={
														styles.form__time
													}
												>
													<Field
														placeholder="Время"
														name="time_from"
														mask="time"
														component={Input}
														onKeyUp={() =>
															handleDateChange(
																formik
															)
														}
														errorMessage={timeError}
													></Field>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="block_label">
												<span>Дата подписки по {tooltips.STATISTICS_SUBSCRIBE_DATE_TO ? <span
													className="tooltip_trigger block_label__tooltip"
													data-effect="solid"
													data-for={"STATISTICS_SUBSCRIBE_DATE_TO"}
													data-tip
												></span> : null}</span>
												{tooltips.STATISTICS_SUBSCRIBE_DATE_TO ? <ReactTooltip className="tooltip" id={"STATISTICS_SUBSCRIBE_DATE_TO"}>
													<div dangerouslySetInnerHTML={{ __html: tooltips.STATISTICS_SUBSCRIBE_DATE_TO }}></div>
												</ReactTooltip> : null}
											</div>
											<div
												className={
													styles.form__footer_inputs
												}
											>
												<div
													className={
														styles.form__date
													}
												>
													<Field
														type="datepicker"
														placeholder="Дата"
														name="date_to"
														mask="date"
														component={Input}
														minDate={
															formik.values
																.date_from
																? new Date(
																	moment(
																		formik
																			.values
																			.date_from,
																		"DD.MM.YYYY"
																	).format(
																		"YYYY-MM-DD"
																	)
																)
																: false
														}
														onKeyUp={() =>
															handleDateChange(
																formik
															)
														}
														onChange={(e) =>
															handleDateToChange(
																e,
																formik
															)
														}
														tooltip={tooltips.STATISTICS_SUBSCRIBE_DATE_TO}
													></Field>
												</div>
												<div
													className={
														styles.form__time
													}
												>
													<Field
														placeholder="Время"
														name="time_to"
														mask="time"
														component={Input}
														onKeyUp={() =>
															handleDateChange(
																formik
															)
														}
													></Field>
												</div>
											</div>
										</div>
										<div
											className={`button_list ${styles.form__footer_right}`}
										></div>
									</div>
								</div>
								<div className="col-md-6 form__group_item">
									<div className="row">
										<div className="col-xl-6">
											<Field
												label="Не учитывать повторные подписки и отписки"
												type="checkbox"
												name="no_repeated"
												size="large"
												component={Input}
												tooltip={tooltips.STATISTICS_REPEATING_SUBSCRIBES}
											></Field>
										</div>
										<div className="col-xl-6">
											<div className={styles.form__buttons}>
												<Button
													onClick={formik.submitForm}
													icon="play-2"
												>
													Показать
												</Button>
												<ActiveButton
													icon="refresh-3"
													color="grey"
													onClick={() => {
														history.push(
															props.match.url
														);
														formik.resetForm();
														getSubscriberHistory({
															community_id:
																match.params.id,
															date_from: moment()
																.subtract(
																	14,
																	"days"
																)
																.format(
																	"DD.MM.YYYY"
																),
														});
														setShowPagesAmount(1);
														setShouldPaginationWork(
															true
														);
														setLastSubmitedFormData(
															formInitialValues
														);
													}}
												>
													Сбросить
												</ActiveButton>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>

			{subscribeHistory.subscribersInfo.length ? (
				<div className={styles.subscribers}>
					{isLoading ? <Preloader /> : null}
					{subscribeHistory.subscribersInfo.map((subscriber, idx) => (
						<Subscriber
							key={idx}
							className={styles.subscribers__item}
							{...subscriber}
						/>
					))}
				</div>
			) : null}
		</>
	);
};

export default withRouter(StatisticsGraph);
