import { clearAllBodyScrollLocks } from "body-scroll-lock";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Icon from "../../components/generic/Icon/Icon";
import AddWebhookPopup from "../../components/popups/AddWebhookPopup/AddWebhookPopup";
import WebhooksInfoCardListContainer from "../../components/WebhooksInfoCardList/WebhooksInfoCardListContainer";
import styles from "./webhooks.module.sass";
import { addWebhook } from "../../redux/reducers/webhooksReducer";
import Preloader from "../../components/Preloader/Preloader";

const Webhooks = ({ addWebhook, isLoading }) => {
    const page = {
        crumbs: [
            { name: "Личный кабинет", path: "/" },
            { name: "Сообщества", path: "/community" },
            { name: "WebHook API", path: "/" },
        ],
    };

    const [isAddWebhookPopupOpen, setIsAddWebhookPopupOpen] = useState(false);

    return (
        <>
            <div className="page_head">
                <Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>
            </div>

            <div className={styles.webhooks}>
                {isLoading ? <Preloader /> : null}
                <div className={styles.webhooks_nav}>
                    <div
                        className={`link ${styles.webhooks_nav__link}`}
                        onClick={() => setIsAddWebhookPopupOpen(true)}
                    >
                        <Icon icon="plus" /> <span>Добавить</span>
                    </div>

                    {/* <div className={styles.webhooks_nav__button}>
                        <Button text="Справка" icon="help-circle" />
                    </div> */}
                </div>

                <WebhooksInfoCardListContainer />
            </div>

            <AddWebhookPopup
                isLoading={isLoading}
                isOpen={isAddWebhookPopupOpen}
                onClose={() => {
                    setIsAddWebhookPopupOpen(false);
                    clearAllBodyScrollLocks();
                }}
                onOpen={() => setIsAddWebhookPopupOpen(true)}
                addWebhook={addWebhook}
            />
        </>
    );
};

const mstp = (state) => ({
    isLoading: state.webhooks.isLoading,
});

export default compose(connect(mstp, { addWebhook }))(Webhooks);
