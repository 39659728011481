import React from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumbs.module.sass";

const Breadcrumbs = (props) => {
	const { crumbs, children } = props;

	return (
		<div className={styles.breadcrumbs}>
			<div className={styles.breadcrumbs_main}>
				{crumbs.map(({ name, path }, key) =>
					key + 1 === crumbs.length ? (
						<span key={key} className={styles.breadcrumbs__link}>
							{name}
						</span>
					) : (
						<Link
							key={key}
							to={path}
							className={styles.breadcrumbs__link}
						>
							{name}
						</Link>
					)
				)}
			</div>

			{children}
		</div>
	);
};

export default Breadcrumbs;
