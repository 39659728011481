import React from "react";
import styles from "./TopCommunity.module.sass";

const TopCommunity = (props) => {
    const {
        children,
        community = {},
        onTitleClick,
        className = "",
        size = "",
    } = props;

    return (
        <div
            className={`${styles.profile} ${className} ${
                size === "sm" ? styles["profile--sm"] : ""
            }`}
        >
            {community.image ? (
                <div
                    className={`${styles.profile__photo} ${
                        onTitleClick ? styles["clickable"] : ""
                    }`}
                    onClick={onTitleClick}
                    style={{
                        backgroundImage: `url(${community.image})`,
                    }}
                ></div>
            ) : (
                <svg
                    viewBox="0 0 240 240"
                    onClick={onTitleClick}
                    className={`${styles.profile__photo} ${
                        onTitleClick ? styles["clickable"] : ""
                    }`}
                >
                    <path
                        className="svg-fill"
                        d="M120 116.3c-7.8 0-14.1-6-15.5-17.5-1.1-10 1.6-20.8 15.4-20.8 13.7 0 16.6 10.8 15.2 20.9-1.6 11.4-7.3 17.4-15.1 17.4zm39.2 4.4c-6.2 0-11.3-4.8-12.4-14.1-.9-7.9 1.3-16.6 12.3-16.6s13.4 8.7 12.2 16.8c-1.3 9.1-5.8 13.9-12.1 13.9zm-78.1-.2c-6.3 0-11.4-4.8-12.4-14.1-1-8 1.2-16.6 12.3-16.6 11 0 13.3 8.6 12.2 16.7-1.3 9.2-5.9 14-12.1 14zm80.5 10.2c6.4.3 13.6 1.3 19.5 3.8 7 2.9 9.5 11 10.5 16.2 0 .2 0 .4.1.6.5 2.7-.3 5.4-2 7.4-1.7 2.1-4.2 3.2-6.9 3.2h-11.1c.1-.7.2-1.4.2-2.1.1-.9 0-1.8 0-2.7l-.3-2.1c-.1-.9-.2-1.7-.4-2.6-.1-1-.3-1.9-.5-2.8-1.2-5.2-3.1-10.4-6.1-14.9-.9-1.4-1.9-2.7-3-4zm-82.8-.2c-1.2 1.3-2.4 2.7-3.4 4.3-1.7 2.7-3.1 5.6-4.2 8.6-.6 1.7-1.1 3.4-1.6 5.2-.2 1-.5 1.9-.7 2.9l-.2 1c-.2 1.2-.4 2.4-.6 3.5-.3 2-.3 4 0 5.9H57.2c-2.8 0-5.4-1.3-7.1-3.4-1.7-2.1-2.3-4.9-1.7-7.6v-.3c1.3-5.3 3.5-13.1 10.7-16.3 5.7-2.5 13.2-3.5 19.7-3.8zm8.9 37.4c-2.7 0-5.2-1.1-6.9-3.2-1.7-2-2.4-4.7-2-7.3.3-1.6.5-2.9.6-3.5 1.5-6.5 4.3-16.4 13.3-20.3 7.8-3.4 18.3-4.7 27-4.9 8.7.2 19.3 1.2 27.8 4.8 8.7 3.7 11.8 14 13 20.4.1.6.3 2 .5 3.7.4 2.6-.3 5.2-2.1 7.2-1.7 2-4.1 3.1-6.8 3.1H87.7z"
                    />
                </svg>
            )}

            <div className={styles.profile__info}>
                <div>
                    <div
                        onClick={onTitleClick}
                        className={`${styles.profile__name} ${
                            onTitleClick ? styles["clickable"] : ""
                        }`}
                        dangerouslySetInnerHTML={{ __html: community.name }}
                    ></div>
                    {community.ok_id ? (
                        <div className={styles.profile__sm_extra}>
                            <span className={styles.profile__id_label}>
                                Id:
                            </span>{" "}
                            <a
                                href={community.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.profile__id}
                            >
                                {community.ok_id}
                            </a>
                        </div>
                    ) : null}
                </div>

                {children ? (
                    <div className={styles.profile__under}>{children}</div>
                ) : null}
            </div>
        </div>
    );
};

export default TopCommunity;
