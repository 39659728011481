import React from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import { withRouter } from "react-router-dom";
import styles from "./SuccessPopup.module.sass";
import Button from "../../generic/Button/Button";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

const SuccessPopup = (props) => {
	const {
		isOpen,
		onOpenPopup,
		onClosePopup,
		title,
		subtitle,
		image,
		closeText,
	} = props;

	return (
		<>
			<PopupMainLayout
				isOpen={isOpen}
				onOpen={onOpenPopup}
				onClose={onClosePopup}
			>
				<div className={styles.popup}>
					{image ? (
						<img src={image} alt="" className={styles.popup__img} />
					) : null}
					{title ? (
						<div className={styles.popup__title}>{title}</div>
					) : null}
					{subtitle ? (
						<div className={styles.popup__subtitle}>{subtitle}</div>
					) : null}
					{closeText ? (
						<Button
							className={styles.popup__btn}
							onClick={() => {
								clearAllBodyScrollLocks();
								onClosePopup();
							}}
						>
							{closeText}
						</Button>
					) : null}
				</div>
			</PopupMainLayout>
		</>
	);
};

export default withRouter(SuccessPopup);
