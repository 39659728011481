import React, { useState, useRef } from "react";
import styles from "./Textarea2.module.sass";
import Icon from "../Icon/Icon";
import TagList from "../TagList/TagList";
import getInputCursorPosition from "../../../utils/getInputCursorPosition";
import getCodeTemplate from "../../../utils/getCodeTemplate";
import OutsideClickHandler from "react-outside-click-handler";
import smileImg from "../../../assets/images/smile.png";
import crossImg from "../../../assets/images/cross.png";
import { Picker } from "emoji-mart";

const Textarea2 = ({
    height = "112px",
    emojiPicker,
    attachDocument = [],
    addedDocuments = [],
    codeTemplate = [],
    className = "",
    name = "",
    onClick = () => {},
    onChange = () => {},
    onKeyUp = () => {},
    onDocumentAdd = () => {},
    onDocumentRemove = () => {},
    ...rest
}) => {
    const [cursorPosition, setCursorPosition] = useState(0);
    const [emojiPickerActive, setEmojiPickerActive] = useState(false);
    let textareaRef = useRef(null);

    // Формирование тегов для документов
    const addedDocumentsTags = addedDocuments.map((d) => {
        if (!d.originalName) return 0;

        const name = d.originalName;
        const re = /(?:\.([^.]+))?$/;
        const ex = re.exec(name)[1];
        if (ex === "png" || ex === "jpg" || ex === "gif" || ex === "svg") {
            return { text: name, icon: "image" };
        } else {
            return { text: name, icon: "doc" };
        }
    });

    // Обработка нажатия в инпуте
    const handleKeyUp = (e) => {
        setCursorPosition(getInputCursorPosition(e.target).start);
        onKeyUp(e);
    };

    // Обработка клика по шаблону кода
    const handleTemplateClick = (e, name) => {
        const template = codeTemplate.find((c) => c.text === name);
        const code = getCodeTemplate(template.text, template.shortTemplate);
        const value = textareaRef.current.value;
        const newValue =
            value.slice(0, cursorPosition) + code + value.slice(cursorPosition);
        textareaRef.current.value = newValue;

        handleChange();
    };

    // Выбор emoji
    const onEmojiSelect = (e) => {
        const value = textareaRef.current.value;
        const newValue =
            value.slice(0, cursorPosition) +
            e.native +
            value.slice(cursorPosition);
        textareaRef.current.value = newValue;

        setCursorPosition((pos) => pos + e.native.length);

        handleChange();
    };

    // Обработка изменения инпута
    const handleChange = (e) => {
        onChange(e || { target: textareaRef.current });
    };

    // Обработка клика в инпуте
    const handleClick = (e) => {
        setCursorPosition(getInputCursorPosition(e.target).start);
        onClick(e || { target: textareaRef.current });
    };

    return (
        <>
            <div
                className={`${styles.textarea_wrap} ${
                    emojiPicker || attachDocument || codeTemplate
                        ? styles["textarea_wrap--with_nav"]
                        : ""
                }`}
            >
                <textarea
                    className={styles.textarea}
                    style={{ minHeight: height }}
                    name={name}
                    onKeyUp={handleKeyUp}
                    onChange={handleChange}
                    onClick={handleClick}
                    ref={textareaRef}
                    {...rest}
                ></textarea>
                {emojiPicker ? (
                    <OutsideClickHandler
                        onOutsideClick={() => setEmojiPickerActive(false)}
                    >
                        <div
                            className={`ps__child ps__child--consume input__textarea_emoji_picker ${
                                emojiPickerActive ? "active" : ""
                            }`}
                            data-emoji-picker
                        >
                            <Picker
                                title="Pick your emoji…"
                                emoji="point_up"
                                onSelect={onEmojiSelect}
                                showPreview={false}
                                showSkinTones={false}
                                i18n={{
                                    search: "Поиск",
                                    categories: {
                                        search: "Результаты поиска",
                                        recent: "Frequently Used",
                                        smileys: "Smileys & Emotion",
                                        people: "People & Body",
                                        nature: "Animals & Nature",
                                        foods: "Food & Drink",
                                        activity: "Activity",
                                        places: "Travel & Places",
                                        objects: "Objects",
                                        symbols: "Symbols",
                                        flags: "Flags",
                                        custom: "Custom",
                                    },
                                }}
                            />
                        </div>
                        {emojiPickerActive ? (
                            <img
                                src={crossImg}
                                className={styles.textarea_emoji}
                                onClick={() => setEmojiPickerActive(false)}
                                alt="close"
                            />
                        ) : (
                            <img
                                onClick={() => setEmojiPickerActive(true)}
                                className={styles.textarea_emoji}
                                src={smileImg}
                                alt="smile"
                            />
                        )}
                    </OutsideClickHandler>
                ) : null}
                <div className={styles.textarea_extra}>
                    {codeTemplate.length ? (
                        <div className={styles.textarea_extra__button}>
                            <Icon icon="code" />
                            <div
                                className={`${styles.button_tooltip} ${styles["button_tooltip--codes"]}`}
                            >
                                <TagList
                                    tags={codeTemplate}
                                    theme="dark"
                                    onClick={handleTemplateClick}
                                />
                            </div>
                        </div>
                    ) : null}
                    {attachDocument.length ? (
                        <div className={styles.textarea_extra__button}>
                            <Icon icon="clip" />
                            <div
                                className={`${styles.button_tooltip} ${styles["button_tooltip--documents"]}`}
                            >
                                {attachDocument.map((d) => (
                                    <div
                                        className={`${styles.button_tooltip__link} grey_link`}
                                    >
                                        {d === "image" ? (
                                            <label>
                                                Загрузить изображение{" "}
                                                <input
                                                    type="file"
                                                    name={`${name}_image`}
                                                    onChange={(e) =>
                                                        onDocumentAdd(
                                                            e,
                                                            "image"
                                                        )
                                                    }
                                                    accept="image/*"
                                                />
                                            </label>
                                        ) : d === "document" ? (
                                            <label>
                                                Загрузить документ{" "}
                                                <input
                                                    type="file"
                                                    multiple
                                                    name={`${name}_document`}
                                                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                                                    onChange={(e) =>
                                                        onDocumentAdd(
                                                            e,
                                                            "document"
                                                        )
                                                    }
                                                />
                                            </label>
                                        ) : null}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            {addedDocumentsTags.length ? (
                <div className={styles.textarea_files}>
                    <div className={styles.textarea_files__label}>
                        Прикреплённые файлы
                    </div>
                    <TagList
                        removable={true}
                        onClickRemove={(e, fileName) =>
                            onDocumentRemove(
                                e,
                                addedDocuments.find(
                                    (d) => d.originalName === fileName
                                )
                            )
                        }
                        tags={addedDocumentsTags}
                    />
                </div>
            ) : null}
        </>
    );
};

export default Textarea2;
