import { referralAPI } from "../../api/api";

const SET_REFERRAL_INFO = "SET_REFERRAL_INFO";
const SET_REFERRAL_INFO_LOADING = "SET_REFERRAL_INFO_LOADING";

const urlParams = new URLSearchParams(window.location.search);
const isTestData = urlParams.get("test") === "Y" ? true : false;

const testData = {
	link: "https://isend.ru?rid=344227",
	sum: "1 000₽",
	partners: [
		{
			sum: "1 000₽",
			userInfo: {
				fullName: "Лаура Баграмян",
				image:
					"https://api.isend.technaxis.com/uploads/subscriber/577840995437.png",
				register_date: "20.11.2020 13:59",
			},
		},
		{
			sum: "500₽",
			userInfo: {
				fullName: "Лаура Баграмян2",
				image:
					"https://api.isend.technaxis.com/uploads/subscriber/577840995437.png",
				register_date: "20.11.2020 13:59",
			},
		},
	],
};
const defaultState = {
	referralInfo: {
		link: "",
		sum: "",
		partners: [],
	},
	referralInfoIsLoading: true,
};

const referralReducer = (state = defaultState, action) => {
	switch (action.type) {
		case SET_REFERRAL_INFO: {
			return {
				...state,
				referralInfo: action.referralInfo,
			};
		}
		case SET_REFERRAL_INFO_LOADING: {
			return {
				...state,
				referralInfoIsLoading: action.referralInfoIsLoading,
			};
		}

		default:
			return state;
	}
};

const setReferralInfo = (referralInfo) => {
	return {
		type: SET_REFERRAL_INFO,
		referralInfo,
	};
};
const setReferralInfoLoading = (referralInfoIsLoading) => {
	return {
		type: SET_REFERRAL_INFO_LOADING,
		referralInfoIsLoading,
	};
};

export const getReferralInfo = () => (dispatch) => {
	dispatch(setReferralInfoLoading(true));
	return referralAPI.getReferralInfo().then((data) => {
		if (isTestData) {
			dispatch(setReferralInfo(testData));
		} else {
			dispatch(setReferralInfo(data));
		}
		dispatch(setReferralInfoLoading(false));
	});
};

export default referralReducer;
