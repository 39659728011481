import React from "react";
import { NavLink } from "react-router-dom";
import wrapTextPart from "../../utils/wrapTextPart";
import styles from "./UserProfile.module.sass";

const UserProfile = (props) => {
    const {
        image = "",
        name = "",
        note = "",
        link = "",
        highlightPart = "",
        onTitleClick,
        onDescClick,
        showEmptyImage,
        target = "_blank",
        size = "",
    } = props;

    // Обертка части названия в тег
    let displayName = name;
    if (highlightPart) {
        displayName = wrapTextPart(name, highlightPart, "b");
    }

    return (
        <div
            className={`${styles.profile} ${
                size === "sm" ? styles["profile--sm"] : ""
            }`}
        >
            {image || showEmptyImage ? (
                link || onTitleClick ? (
                    <NavLink
                        to={link}
                        target={target}
                        rel="noopener noreferrer"
                        className={styles.profile__photo}
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                        onClick={(e) => {
                            if (onTitleClick) {
                                e.preventDefault();
                                onTitleClick();
                            }
                        }}
                    >
                        {" "}
                    </NavLink>
                ) : (
                    <div
                        className={styles.profile__photo}
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    ></div>
                )
            ) : null}

            <div className={styles.profile__info}>
                {name ? (
                    link || onTitleClick ? (
                        <NavLink
                            to={link}
                            target={target}
                            rel="noopener noreferrer"
                            className={styles.profile__name}
                            dangerouslySetInnerHTML={{ __html: displayName }}
                            onClick={(e) => {
                                if (typeof onTitleClick === "function") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onTitleClick();
                                }
                            }}
                        ></NavLink>
                    ) : (
                        <div
                            className={styles.profile__name}
                            dangerouslySetInnerHTML={{ __html: displayName }}
                        ></div>
                    )
                ) : null}
                {note ? (
                    <div
                        className={styles.profile__note}
                        dangerouslySetInnerHTML={{ __html: note }}
                        onClick={onDescClick}
                    ></div>
                ) : null}
            </div>
        </div>
    );
};

export default UserProfile;
