import React, { useEffect, useState } from "react";
import SubscriberGroup from "../SubscriberGroup/SubscriberGroup";
import styles from "./SubscriberGroupList.module.sass";
import Preloader from "../Preloader/Preloader";
import NoResult from "../generic/NoResult/NoResult";
import RoundedButtonList from "../generic/RoundedButtonList/RoundedButtonList";
import Search from "../Search/Search";
import character from "../../assets/images/characters-group.svg";
import CreateGroupPopupContainer from "../popups/CreateGroupPopup/CreateGroupPopupContainer";
import { withRouter } from "react-router-dom";
import getQueryParams from "../../utils/getQueryParams";

let lastScrollTop = 0;
let searchTimer = null;
const SubscriberGroupList = (props) => {
    let {
        isLoading,
        isFirstLoading,
        groups = {},
        sortBy,
        searchText = "",
        setGroupsSorting,
        removeGroup,
        getGroupInfo,
        changeAutosubscribe,
        location,
        getSubscribersGroups2,
    } = props;

    let community_id = props.match.params.id;

    const [openedExtras, updateOpenedExtras] = useState([]);
    const [showPagesAmount, setShowPagesAmount] = useState(1);
    const [groupEditing, setGroupEditing] = useState({
        isEditing: false,
        id: null,
    });

    useEffect(() => {
        if (
            location.search.indexOf("group=") < 0 &&
            location.search.indexOf("create-group=open") < 0
        ) {
            setGroupEditing({ isEditing: false, id: null });
        }
        // eslint-disable-next-line
    }, [location.search]);

	// Если поменялась страница, то меняем её и в локальном стейте
	useEffect(() => {
		setShowPagesAmount(groups.page);
	}, [groups.page]);

    // Для постраничной навигации
    const onScrollPagination = () => {
        const pageHeight = document.body.clientHeight;
        const scrollBottom = window.pageYOffset + window.innerHeight;
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop) {
            // downscroll code
            if (
                scrollBottom >= pageHeight - 20 &&
                !isLoading &&
                groups.pages_count > showPagesAmount
            ) {
                let newPage = showPagesAmount + 1;
                getSubscribersGroups2({
                    community_id,
                    page: newPage,
                    type:
                        sortBy === true
                            ? "visible"
                            : sortBy === true
                            ? "unvisible"
                            : null,
                });
                setShowPagesAmount(newPage);
            }
        }

        lastScrollTop = st <= 0 ? 0 : st;
    };
    useEffect(() => {
        window.addEventListener("scroll", onScrollPagination);
        window.addEventListener("touchmove", onScrollPagination);

        return () => {
            window.removeEventListener("scroll", onScrollPagination);
            window.removeEventListener("touchmove", onScrollPagination);
        };
        // eslint-disable-next-line
    }, [isLoading, groups.pages_count]);
    // Для постраничной навигации END

    useEffect(() => {
        const params = getQueryParams(location.search);
        if (params.group) {
            setGroupEditing({
                isEditing: true,
                id: params.group,
            });
            getGroupInfo(params.group);
        }
        // eslint-disable-next-line
    }, [getGroupInfo, setGroupEditing]);

    // Обновление фильтрации
    const getFilteredGroups = (type = null, search = "") => {
        getSubscribersGroups2({
            community_id,
            type,
            search,
        });
        setShowPagesAmount(1);
    };

    // Кнопки сортировки
    let sortButtons = [
        {
            text: "Все",
            amount: groups.count,
            isActive: true,
            sortby: "all",
            onClick: () => getFilteredGroups(null, searchText),
        },
        {
            text: "Видимые",
            amount: groups.visible_count,
            sortby: true,
            onClick: () => getFilteredGroups("visible", searchText),
        },
        {
            text: "Скрытые",
            amount: groups.unvisible_count,
            sortby: false,
            onClick: () => getFilteredGroups("unvisible", searchText),
        },
    ];

    // Активность фильтра
    sortButtons = sortButtons.map((s) => {
        return { ...s, isActive: s.sortby === sortBy ? true : false };
    });

    // Клик по кнопке сортировки
    const onSortButtonClick = (sortBy) => {
        props.setGroupsSorting(sortBy, searchText);
        updateOpenedExtras([]);
    };

    // При открытии доп информации
    const onExtraPanelOpen = (id) => {
        let newOpenedExtras = [...openedExtras];
        let index = openedExtras.indexOf(id);
        if (index >= 0) {
            newOpenedExtras.splice(index, 1);
        } else {
            newOpenedExtras.push(id);
        }
        updateOpenedExtras(newOpenedExtras);
    };

    // При клике на заголовок группы
    const onGroupTitleClick = (e, id) => {
        e.stopPropagation();
        window.open(`${location.pathname}?group=${id}`, "_blank").focus();
    };

    // Кнопка "Изменить"
    const onGroupEditClick = (id) => {
        window.open(`${location.pathname}?group=${id}`, "_blank").focus();
    };

    // При вводе в поиск
    const onSearchChange = ({ target }) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            let groupSortBy =
                sortBy === true
                    ? "visible"
                    : sortBy === false
                    ? "unvisible"
                    : null;
            getFilteredGroups(groupSortBy, target.value);
        }, 1000);
        setGroupsSorting(sortBy, target.value);
    };

    // Удаление группы
    const onRemoveGroup = (group_id) => {
        removeGroup(group_id, community_id);
    };

    return (
        <>
            <div className={styles.groups}>
                {isLoading ? <Preloader /> : null}
                {isFirstLoading ? null : groups.count > 0 ? (
                    <>
                        <div className={styles.groups__sort}>
                            <div className={styles.groups__sort_btns}>
                                <RoundedButtonList
                                    buttons={sortButtons}
                                    onButtonClick={onSortButtonClick}
                                ></RoundedButtonList>
                            </div>

                            <div className={styles.groups__search}>
                                <Search
                                    placeholder="Поиск по группам"
                                    onChange={onSearchChange}
                                    value={searchText}
                                ></Search>
                            </div>
                        </div>
                        <div>
                            {groups.items.map((g, idx) => (
                                <SubscriberGroup
                                    {...g}
                                    highlightTitlePart={searchText}
                                    key={idx}
                                    className={styles.group_list__item}
                                    isOpen={openedExtras.some(
                                        (o) => o === g.id
                                    )}
                                    onOpenExtra={() => onExtraPanelOpen(g.id)}
                                    onTitleClick={(e) =>
                                        onGroupTitleClick(e, g.id)
                                    }
                                    onRemoveClick={() =>
                                        onRemoveGroup(g.id, community_id)
                                    }
                                    onEditClick={() => onGroupEditClick(g.id)}
                                    onAutosubscribeChange={(e) =>
                                        changeAutosubscribe(
                                            g.id,
                                            e.target.checked,
                                            community_id
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <NoResult
                        text="У вас пока нет групп подписчиков"
                        img={character}
                        link={{
                            text: "Создать",
                            onClick: () =>
                                setGroupEditing({
                                    isEditing: true,
                                    id: null,
                                }),
                        }}
                    ></NoResult>
                )}
            </div>

            <CreateGroupPopupContainer
                onClosePopup={() =>
                    setGroupEditing({
                        isEditing: false,
                        id: null,
                    })
                }
                onOpenPopup={() =>
                    setGroupEditing({
                        isEditing: true,
                        id: null,
                    })
                }
                isOpen={groupEditing.isEditing}
                groupId={groupEditing.id}
            />
        </>
    );
};

export default withRouter(SubscriberGroupList);
