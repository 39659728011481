import React from "react";
import Icon from "../generic/Icon/Icon";
import styles from "./Search.module.sass";

const Search = (props) => {
	let { placeholder = "", value, onChange, theme = "" } = props;

	return (
		<div className={`${styles.search} ${theme ? styles[`search--${theme}`] : null}`}>
			<input
				onChange={onChange}
				type="text"
				className={styles.search__field}
				placeholder={placeholder}
				value={value}
			/>
			<Icon icon="loupe" className={styles.search__icon}></Icon>
		</div>
	);
};

export default Search;
