import React from "react";
import ScrollBox from "../../generic/ScrollBox/ScrollBox";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import PopupFullsizeLayout from "../PopupFullsizeLayout/PopupFullsizeLayout";
import styles from "./SubscriberAddFilter.module.sass";
import { Field, Form, Formik } from "formik";
import Input from "../../generic/Input/Input";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import Button from "../../generic/Button/Button";

const SubscriberAddFilter = ({
    isOpen,
    onOpen,
    onClose,
    handleSubmit,
    initialValues,
}) => {
    const filters = [
        {
            title: "Группы",
            items: [
                {
                    title: "Добавить группу подписчиков",
                    name: "filter[groups_id]",
                },
                {
                    title: "Исключить группу подписчиков",
                    name: "filter[remove_groups_id]",
                },
            ],
        },
        {
            title: "Данные пользователя",
            items: [
                {
                    title: "Добавить подписчика",
                    name: "filter[subscribers_id]",
                },
                { title: "Пол", name: "filter[gender]" },
                { title: "Возраст", name: "filter[age_from]" },
                { title: "Город", name: "filter[city]" },
                { title: "Страна", name: "filter[country]" },
            ],
        },
        {
            title: "Подписка",
            items: [
                {
                    title: "Источник",
                    name: "filter[source]",
                },
                { title: "Даты подписки", name: "filter[subscribe_date_from]" },
            ],
        },
        {
            title: "UTM-метки",
            items: [
                { title: "Добавить свою метку", name: "filter[utm]" },
                { title: "Добавить utm_source", name: "filter[utm_source]" },
                { title: "Добавить utm_medium", name: "filter[utm_medium]" },
                {
                    title: "Добавить utm_campaign",
                    name: "filter[utm_campaign]",
                },
                { title: "Добавить utm_content", name: "filter[utm_content]" },
                { title: "Добавить utm_term", name: "filter[utm_term]" },
            ],
        },
    ];

    const PopupLayout = (props) => {
        if (window.matchMedia("(max-width: 767px)").matches) {
            return <PopupFullsizeLayout {...props} />;
        } else {
            return <PopupMainLayout {...props} />;
        }
    };

    return (
        <PopupLayout
            title="Добавление фильтров"
            subtitle="Выберете фильтры, которые хотите добавить"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <>
                        <ScrollBox className={styles.scrollbox}>
                            <Form>
                                {filters.map((filter, index) => (
                                    <div
                                        className={styles.group}
                                        key={filter.title}
                                    >
                                        <div className={styles.group__title}>
                                            {filter.title}
                                        </div>
                                        {filter.items.map((item) => (
                                            <label
                                                className={styles.group__item}
                                                key={item.name}
                                            >
                                                <div
                                                    className={
                                                        styles.group__item_title
                                                    }
                                                >
                                                    {item.title}
                                                </div>
                                                <Field
                                                    name={item.name}
                                                    type="checkbox"
                                                    size="medium"
                                                    component={Input}
                                                ></Field>
                                            </label>
                                        ))}
                                    </div>
                                ))}
                            </Form>
                        </ScrollBox>
                        <div
                            className="button_list button_list--fullwidth_md"
                            style={{ justifyContent: "flex-end" }}
                        >
                            <div
                                className="grey_link last_on_md"
                                onClick={(e) => {
                                    onClose();
                                    clearAllBodyScrollLocks();
                                }}
                            >
                                Отменить
                            </div>
                            <Button onClick={formik.submitForm}>
                                Добавить фильтры
                            </Button>
                        </div>
                    </>
                )}
            </Formik>
        </PopupLayout>
    );
};

export default SubscriberAddFilter;
