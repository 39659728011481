import React from "react";
import CustomDatepicker from "../CustomDatepicker/CustomDatepicker";
import CustomSelect from "../CustomSelect/CustomSelect";
import Textarea from "../Textarea/Textarea";
import Checkbox from "../Checkbox/Checkbox";
import InputMask from "react-input-mask";
import "./Input.sass";
import ReactTooltip from "react-tooltip";
import generateId from "../../../utils/generateId";
import Radio from "../Radio/Radio";
import Icon from "../Icon/Icon";
import Textarea2 from "../Textarea2/Textarea2";
import { getIn } from "formik";

const Input = (props) => {
    let {
        type = "text",
        field = {},
        form = {},
        label,
        labelSize,
        sideLabel,
        leftLabel,
        tooltip,
        removable = false,
        removeIcon = "",
        onRemove,
        disabled,
        className = "",
        mask,
        errorMessage,
        theme,
        icon,
        inputRef,
        arrayIndex,
        inputTouched,
        noErrorText = false,
        ...rest
    } = props;

    const name = field.name || "";
    let error;
    let touched;
    let masking;
    let id = generateId();

    if (name) {
        error = getIn(form.errors, name);
        touched = getIn(form.touched, name);
    }

    switch (mask) {
        case "date":
            masking = "99.99.9999";
            break;
        case "time":
            masking = "99:99";
            break;
        case "time_single":
            masking = "99";
            break;
        case "age":
            masking = [/[1-9]/, /[0-9]/];
            type = "tel";
            break;
        case "days_count":
            masking = "9999";
            type = "tel";
            break;
        default:
            masking = "";
    }

    const onBlur = (e) => {
        if (field.onBlur) field.onBlur(e);
        if (rest.onBlur) rest.onBlur(e);
    };

    const onChange = (e) => {
        if (field.onChange) field.onChange(e);
        if (rest.onChange) rest.onChange(e);
    };

    return (
        <div
            className={`input_container ${
                leftLabel || sideLabel ? "input_container--sideLabel" : ""
            } ${theme === "flat" ? "input_container--flat" : ""} ${
                icon ? "input_container--iconed" : ""
            } ${
                (error && touched) || (error && type === "textarea")
                    ? "error"
                    : ""
            } ${disabled ? "disabled" : ""}`}
        >
            {/* Label */}
            {(label || removable) && type !== "checkbox" && type !== "radio" ? (
                <div
                    className={`block_label ${
                        labelSize === "sm" ? "block_label--sm" : ""
                    }`}
                >
                    {label ? (
                        <span>
                            {label}{" "}
                            {tooltip ? (
                                <>
                                    <div
                                        className="tooltip_trigger block_label__tooltip"
                                        data-effect="solid"
                                        data-for={id}
                                        data-tip
                                    ></div>
                                    <ReactTooltip className="tooltip" id={id}>
                                        {typeof tooltip === "string" ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: tooltip,
                                                }}
                                            ></div>
                                        ) : (
                                            tooltip
                                        )}
                                    </ReactTooltip>
                                </>
                            ) : null}
                        </span>
                    ) : null}

                    {removable ? (
                        <div
                            className="grey_link block_label__side_link"
                            onClick={onRemove}
                        >
                            {removeIcon ? (
                                <Icon icon={removeIcon} />
                            ) : (
                                "Удалить"
                            )}
                        </div>
                    ) : null}
                </div>
            ) : null}
            {/* Error */}
            {error && touched && type !== "radio" && type !== "textarea" && noErrorText !== true ? (
                <div
                    className={`input_error ${
                        rest?.size === "sm" ? "input_error--sm" : ""
                    }`}
                >
                    {error}
                </div>
            ) : errorMessage &&
              ((typeof inputTouched !== "undefined" && inputTouched) ||
                  typeof inputTouched === "undefined") ? (
                <div
                    className={`input_error ${
                        rest?.size === "sm" ? "input_error--sm" : ""
                    }`}
                >
                    {errorMessage}
                </div>
            ) : null}

            {/* Стандартное поле */}
            {type === "text" || type === "tel" ? (
                <div className="input__inner">
                    {leftLabel ? (
                        <div className="input__left_label">{leftLabel}</div>
                    ) : null}
                    <div class="input_wrap">
                        <InputMask
                            {...field}
                            {...rest}
                            disabled={disabled}
                            className={`${
                                theme === "flat" ? "input_flat" : "input"
                            } ${className} ${
                                rest.size === "sm" ? "input--sm" : ""
                            }`}
                            autoComplete="off"
                            onChange={onChange}
                            onBlur={onBlur}
                            mask={masking}
                            maskPlaceholder=""
                            type={type}
                        />
                    </div>
                    {icon ? (
                        <Icon icon={icon} className="input_container__icon" />
                    ) : null}
                    {sideLabel ? (
                        <div className="input__side_label">{sideLabel}</div>
                    ) : null}
                </div>
            ) : null}

            {/* Textarea */}
            {type === "textarea" ? (
                <>
                    <Textarea
                        {...field}
                        {...rest}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={`input ${className}`}
                        error={error}
                        touched={touched}
                        ref={inputRef}
                    />
                </>
            ) : null}

            {/* Textarea */}
            {type === "textarea2" ? (
                <>
                    <Textarea2
                        {...field}
                        {...rest}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={`input ${className}`}
                        error={error}
                        touched={touched}
                        ref={inputRef}
                    />
                </>
            ) : null}

            {/* Select */}
            {type === "select" || type === "asyncSelect" ? (
                <CustomSelect
                    {...field}
                    {...rest}
                    type={type}
                    className={`${className}`}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            ) : null}

            {/* Creatable */}
            {type === "creatable" ? (
                <CustomSelect
                    {...field}
                    {...rest}
                    type="creatable"
                    className={`${className}`}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            ) : null}

            {/* Datepicker */}
            {type === "datepicker" ? (
                <div className="input__inner">
                    <CustomDatepicker
                        {...field}
                        {...rest}
                        className={`${className}`}
                        disabled={disabled}
                        mask={masking}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                    {sideLabel ? (
                        <div className="input__side_label">{sideLabel}</div>
                    ) : null}
                </div>
            ) : null}

            {/* Checkbox */}
            {type === "checkbox" ? (
                <div className="input__inner">
                    <Checkbox
                        {...field}
                        {...rest}
                        tooltip={tooltip}
                        className={`${className}`}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                    >
                        {label}
                    </Checkbox>
                </div>
            ) : null}

            {/* Radio */}
            {type === "radio" ? (
                <div className="input__inner">
                    <Radio
                        {...field}
                        {...rest}
                        tooltip={tooltip}
                        className={`${className}`}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={error && touched}
                    >
                        {label}
                    </Radio>
                </div>
            ) : null}
        </div>
    );
};

export default Input;
