import React, { useState } from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import MaskedTextInput from "react-text-mask";
import "./CustomDatepicker.sass";
import moment from "moment";

const CustomDatepicker = (props) => {
    const {
        name = "",
        value,
        mask,
        placeholder,
        showTimeSelect = false,
        className = "",
        size = "",
        ...rest
    } = props;

    const [startDate, setStartDate] = useState("");

    React.useEffect(() => {
        setStartDate(value);
    }, [value]);

    const formatDate = (date) => {
        if (showTimeSelect) {
            return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
        } else {
            return date ? moment(date).format("DD.MM.YYYY") : "";
        }
    };

    const getValue = () => {
        if (showTimeSelect) {
            return startDate
                ? moment(startDate, "DD.MM.YYYY HH:mm").toDate()
                : "";
        } else {
            return startDate ? moment(startDate, "DD.MM.YYYY").toDate() : "";
        }
    };

    const simulateEventTarget = (value) => {
        return {
            target: { name, value },
        };
    };

    const onBlur = (e) => {
        if (typeof props.onBlur === "function") {
            props.onBlur(simulateEventTarget(e));
        }
    };

    const onChange = (date) => {
        setStartDate(date);

        if (typeof props.onChange === "function") {
            props.onChange(simulateEventTarget(formatDate(date)));
        }
    };

    const onInputKeyUp = (e) => {
        let { value } = e.currentTarget;

        if (value === "") {
            setStartDate("");
            props.onChange(simulateEventTarget(""));
        }
    };

    return (
        <Datepicker
            {...rest}
            className={`input ${className} ${size === "sm" ? "input--sm" : ""}`}
            calendarClassName="datepicker"
            dateFormat={showTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
            placeholderText={placeholder}
            showTimeSelect={showTimeSelect}
            locale={ru}
            selected={getValue()}
            onChange={onChange}
            onBlur={onBlur}
            customInput={
                <MaskedTextInput
                    onKeyUp={onInputKeyUp}
                    type="tel"
                    mask={[
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                    ]}
                />
            }
        />
    );
};

export default CustomDatepicker;
