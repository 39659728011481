import { botsAPI } from "../../api/api";

const SET_BOTS_LIST = "bots/SET_BOTS_LIST";
const SET_IS_BOTS_LIST_LOADING = "bots/SET_IS_BOTS_LIST_LOADING";
const SET_FLOW = "bots/SET_FLOW";
const SET_BOT_NAME = "bots/SET_BOT_NAME";
const SET_IS_BOT_ACTIVATED = "bots/SET_IS_BOT_ACTIVATED";

const initialState = {
    botName: "",
    isBotActivated: false,
    botsList: [],
    isBotsListLoading: false,
    elements: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_BOTS_LIST:
            return { ...state, botsList: payload };

        case SET_IS_BOTS_LIST_LOADING:
            return { ...state, isBotsListLoading: payload };

        case SET_FLOW:
            return { ...state, elements: payload };

        case SET_BOT_NAME:
            return { ...state, botName: payload };

        case SET_IS_BOT_ACTIVATED:
            return { ...state, isBotActivated: payload };

        default:
            return state;
    }
};

// AC установить список ботов
const setBotsList = (botsList) => {
    return {
        type: SET_BOTS_LIST,
        payload: botsList,
    };
};

// AC установить статус загрузки списка ботов
const setIsBotsListLoading = (isLoading) => {
    return {
        type: SET_IS_BOTS_LIST_LOADING,
        payload: isLoading,
    };
};

// AC установить элементы редактора
const setFlow = (data) => {
    return {
        type: SET_FLOW,
        payload: data,
    };
};

// AC установить название бота
const setBotName = (data) => {
    return {
        type: SET_BOT_NAME,
        payload: data,
    };
};

// AC установить активирован ли бот
const setIsBotActivated = (data) => {
    return {
        type: SET_IS_BOT_ACTIVATED,
        payload: data,
    };
};

// Thunk список ботов
export const getBotsList = (data) => (dispatch) => {
    dispatch(setIsBotsListLoading(true));
    return botsAPI.getBotsList(data).then((data) => {
        dispatch(setBotsList(data || []));
        dispatch(setIsBotsListLoading(false));
    });
};

// Thunk добавление бота
export const addBot = (data) => (dispatch) => {
    dispatch(setIsBotsListLoading(true));
    return botsAPI.addBot(data).then(() => {
        dispatch(getBotsList({ community_id: data.community_id }));
        dispatch(setIsBotsListLoading(false));
    });
};

// Thunk переименовывания бота
export const renameBot = (data) => (dispatch) => {
    return botsAPI.renameBot(data);
};

// Thunk удаление бота
export const removeBot = (data) => (dispatch) => {
    dispatch(setIsBotsListLoading(true));
    return botsAPI.removeBot(data).then(() => {
        dispatch(getBotsList({ community_id: data.community_id }));
        dispatch(setIsBotsListLoading(false));
    });
};

// Thunk активация бота
export const activateBot = (data) => (dispatch) => {
    dispatch(setIsBotsListLoading(true));
    return botsAPI.activateBot(data).then((response) => {
        dispatch(getBotsList({ community_id: data.community_id }));
        dispatch(setIsBotsListLoading(false));
        return response;
    });
};

// Thunk деактивация бота
export const deactivateBot = (data) => (dispatch) => {
    dispatch(setIsBotsListLoading(true));
    return botsAPI.deactivateBot(data).then((response) => {
        dispatch(getBotsList({ community_id: data.community_id }));
        dispatch(setIsBotsListLoading(false));
        return response;
    });
};

// Thunk деактивация бота
export const copyBot = (data) => (dispatch) => {
    dispatch(setIsBotsListLoading(true));
    return botsAPI.copyBot(data).then(() => {
        dispatch(getBotsList({ community_id: data.community_id }));
        dispatch(setIsBotsListLoading(false));
    });
};

// Thunk очистка статистики бота
export const clearBotStatistic = (data) => (dispatch) => {
    return botsAPI.clearBotStatistic(data);
};

// Thunk тестирование бота
export const testBot = (data) => (dispatch) => {
    return botsAPI.testBot(data);
};

// Thunk добавление узла
export const addBotStep = (data) => (dispatch) => {
    return botsAPI.addStep(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk удаление узла
export const removeBotStep = (data) => (dispatch) => {
    return botsAPI.removeStep(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk обновление координат узла
export const updateBotStepCoordinates = (data) => (dispatch) => {
    return botsAPI.updateStepCoordinates(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
    });
};

// Thunk обновление данных сообщения
export const updateBotStepMessage = (data) => (dispatch) => {
    return botsAPI.updateStepMessage(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk обновление данных таймера
export const updateBotStepTimer = (data) => (dispatch) => {
    return botsAPI.updateStepTimer(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk обновление данных действия
export const updateBotStepAction = (data) => (dispatch) => {
    return botsAPI.updateStepAction(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk обновление данных условия
export const updateBotStepCondition = (data) => (dispatch) => {
    return botsAPI.updateStepCondition(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk получения элементов бота
export const getBotData = (data) => (dispatch) => {
    return botsAPI.getData(data).then((data) => {
        dispatch(setFlow(data.map));
        dispatch(setBotName(data.name));
        dispatch(setIsBotActivated(data.status));
        return data.map;
    });
};

// Thunk изменение следующего шага
export const setBotNextStep = (data) => (dispatch) => {
    return botsAPI.setNextStep(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk изменение следующего шага условий
export const setBotConditionNextStep = (data) => (dispatch) => {
    return botsAPI.setConditionNextStep(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};

// Thunk добавление подписчика в бота
export const addBotSubscriber = (data) => (dispatch) => {
    return botsAPI.addSubscriber(data).then((data) => {
        return data;
    });
};

// Thunk удаление подписчика из бота
export const removeBotSubscriber = (data) => (dispatch) => {
    return botsAPI.removeSubscriber(data).then((data) => {
        return data;
    });
};

// Thunk копирование шага
export const copyBotStep = (data) => () => {
    return botsAPI.copyStep(data).then((data) => {
        if (
            (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
            data &&
            !data.success
        ) {
            console.warn("Ошибка в запросе! API вернул:", data);
        }
        return data;
    });
};
