import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../generic/Button/Button";
import Input from "../../generic/Input/Input";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import * as Yup from "yup";
import Progressbar from "../../generic/Progressbar/Progressbar";
import { exportSubscribers } from "../../../redux/reducers/communitiesReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import generateId from "../../../utils/generateId";
import downloadFileByUrl from "../../../utils/downloadFileByUrl";
import { getNoticess } from "../../../redux/reducers/noticesReducer";
import { utilsAPI } from "../../../api/api";
import PopupFullsizeLayout from "../PopupFullsizeLayout/PopupFullsizeLayout";
import Preloader from "../../Preloader/Preloader";
import ReactTooltip from "react-tooltip";

const ExportSubscribersPopup = ({
	isOpen,
	onOpen,
	onClose,
	subscribersAmount,
	subscribersTotalAmount,
	filters = {},
}) => {
	const dispatch = useDispatch();
	const { id: community_id } = useParams();
	const [formProgress, setFormProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const { tooltips } = useSelector(state => state.tooltips);

	useEffect(() => {
		setFormProgress(0);
	}, [isOpen]);

	// Валидация формы
	const validationSchema = Yup.object().shape({
		type: Yup.string().required("Обязательное поле"),
		encoding: Yup.string().required("Обязательное поле"),
	});

	let initialValues = {
		type: "",
		encoding: "",
	};

	const onSubmit = (values) => {
		const key = generateId();

		values = { ...values, ...filters, community_id, key };

		setIsLoading(true);
		dispatch(exportSubscribers(values));

		let progressChecker = setInterval(() => {
			utilsAPI
				.getPercent({ key, type: "export" })
				.then(({ percent, file_url }) => {
					setFormProgress(percent);
					if (percent > 1) setIsLoading(false);
					if (percent === 100) {
						clearInterval(progressChecker);
						setTimeout(() => {
							onClose();
							clearAllBodyScrollLocks();
						}, 500);
						dispatch(getNoticess());
					}
					if (file_url) downloadFileByUrl(file_url, file_url);
				});
		}, 500);
	};

	const PopupLayout = (props) => {
		if (window.matchMedia("(max-width: 767px)").matches) {
			return <PopupFullsizeLayout {...props} />;
		} else {
			return <PopupMainLayout {...props} />;
		}
	};

	return (
		<PopupLayout
			title="Экспорт подписчиков"
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			{isLoading ? <Preloader /> : null}
			<div style={{ marginBottom: 24, textAlign: "center" }}>
				<div style={{ color: "#545454", marginBottom: 12 }}>
					Выбрано подписчиков:
				</div>
				<div
					style={{
						fontSize: 24,
						marginBottom: 12,
						color: "#000",
					}}
				>
					{subscribersAmount}
				</div>
				{subscribersTotalAmount === subscribersAmount ? (
					<div className="text_error">
						Внимание! Выбраны все подписчики.
					</div>
				) : null}
			</div>

			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => (
					<Form className="form">
						<div className="form__group_item">
							<div className="block_label">
								<span>Формат: {tooltips.SUBSCRIBER_EXPORT_FORMAT ? <span
									className="tooltip_trigger block_label__tooltip"
									data-effect="solid"
									data-for={"SUBSCRIBER_EXPORT_FORMAT"}
									data-tip
								></span> : null}</span>
								{tooltips.SUBSCRIBER_EXPORT_FORMAT ? <ReactTooltip className="tooltip" id={"SUBSCRIBER_EXPORT_FORMAT"}>
									<div dangerouslySetInnerHTML={{ __html: tooltips.SUBSCRIBER_EXPORT_FORMAT }}></div>
								</ReactTooltip> : null}
							</div>
							<div className="mb12">
								<Field
									name="type"
									label="Только ID"
									type="radio"
									value="ID"
									id="type_id"
									component={Input}
								></Field>
							</div>
							<div className="mb12">
								<Field
									name="type"
									label="Все данные"
									type="radio"
									value="ALL"
									id="type_all"
									component={Input}
								></Field>
							</div>
							<div className="mb12">
								<Field
									name="type"
									label="Excel"
									type="radio"
									value="EXCEL"
									id="type_excel"
									component={Input}
								></Field>
							</div>
							<div className="">
								<Field
									name="type"
									label="CSV"
									type="radio"
									value="CSV"
									id="type_csv"
									component={Input}
								></Field>
							</div>
						</div>
						<div className="form__group_item">
							<div className="block_label">
								<span>Кодировка: {tooltips.SUBSCRIBER_EXPORT_ENCODE ? <span
									className="tooltip_trigger block_label__tooltip"
									data-effect="solid"
									data-for={"SUBSCRIBER_EXPORT_ENCODE"}
									data-tip
								></span> : null}</span>
								{tooltips.SUBSCRIBER_EXPORT_ENCODE ? <ReactTooltip className="tooltip" id={"SUBSCRIBER_EXPORT_ENCODE"}>
									<div dangerouslySetInnerHTML={{ __html: tooltips.SUBSCRIBER_EXPORT_ENCODE }}></div>
								</ReactTooltip> : null}
							</div>
							<div className="mb12">
								<Field
									name="encoding"
									label="Windows-1251"
									type="radio"
									value="Windows-1251"
									id="Windows-1251"
									component={Input}
								></Field>
							</div>
							<div className="">
								<Field
									name="encoding"
									label="UTF-8"
									type="radio"
									value="UTF-8"
									id="UTF-8"
									component={Input}
								></Field>
							</div>
						</div>
						<div className="form__group_item">
							<Progressbar progress={formProgress} />
						</div>
						<div className="form__group_item">
							<div
								className="button_list button_list--fullwidth_md"
								style={{ justifyContent: "flex-end" }}
							>
								<div
									className="grey_link last_on_md"
									onClick={(e) => {
										onClose();
										clearAllBodyScrollLocks();
									}}
								>
									Закрыть
								</div>
								<Button
									icon="play-2"
									onClick={formik.submitForm}
								>
									Начать
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</PopupLayout>
	);
};

export default ExportSubscribersPopup;
