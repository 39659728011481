import React from "react";
import AnimateHeight from "react-animate-height";
import ActiveButton from "../generic/ActiveButton/ActiveButton";
import Icon from "../generic/Icon/Icon";
import UserProfile from "../UserProfile/UserProfile";
import styles from "./AccessUser.module.sass";

const AccessUser = (props) => {
	const {
		isOpen = false,
		name,
		user_link,
		id,
		user_uid,
		staff_type,
		staff_type_text = "Админ",
		onSettingsToggle,
		highlightPart = "",
		onTitleClick,
		onRemoveClick,
		onEditClick,
	} = props;

	let typeColor = "";

	switch (staff_type) {
		case "LEADER":
			typeColor = "green";
			break;
		case "EDITOR":
			typeColor = "purple";
			break;
		case "OBSERVER":
			typeColor = "purple2";
			break;
		default:
			typeColor = "";
	}

	const settingsToggle = (e) => {
		e.stopPropagation();
		if (typeof onSettingsToggle === "function") onSettingsToggle(id);
	};

	return (
		<div className={`${styles.access} ${isOpen ? styles.active : ""}`}>
			<div className={styles.access__main} onClick={settingsToggle}>
				{staff_type_text ? (
					<div
						className={`${styles.access__type} ${styles[typeColor]}`}
					>
						{staff_type_text}
					</div>
				) : null}

				<header className={styles.access__header}>
					<div className={styles.access__header_col}>
						<UserProfile
							image={`https://api.isend.technaxis.com/uploads/subscriber/577840995437.png`}
							name={name}
							note={`ID <a href='${user_link}' target='_blank'>${user_uid}</a>`}
							highlightPart={highlightPart}
							onTitleClick={onTitleClick}
						></UserProfile>
					</div>
					<div className={styles.access__header_col}>
						<div className="d-none d-md-block">
							<div
								className={styles.access__settings}
								onClick={settingsToggle}
							>
								<Icon
									icon="dots"
									className={styles.access__settings_icon}
								></Icon>
							</div>
						</div>
					</div>
				</header>
			</div>

			<div className={styles.access__more_info_wrap}>
				<AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
					<div className={styles.access__more_info_inner}>
						<div className={styles.access__btns}>
							<div className={styles.access__btns_btn}>
								<ActiveButton icon="pen" color="grey" onClick={onEditClick}>
									Изменить доступ
								</ActiveButton>
							</div>
							<div className={styles.access__btns_btn}>
								<ActiveButton icon="trash" color="grey" onClick={onRemoveClick}>
									Удалить доступ
								</ActiveButton>
							</div>
						</div>
					</div>
				</AnimateHeight>
			</div>

			<div className="d-md-none">
				<div className={styles.access__settings} onClick={settingsToggle}>
					<Icon
						icon="dots"
						className={styles.access__settings_icon}
					></Icon>
				</div>
			</div>
		</div>
	);
};

export default AccessUser;
