import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import WebhooksInfoCardList from "./WebhooksInfoCardList";
import {
    getWebhooks,
    removeWebhook,
    editWebhook,
	clearWebhookErrors,
} from "../../redux/reducers/webhooksReducer";
import { compose } from "redux";
import { withRouter } from "react-router";
import AddWebhookPopup from "../popups/AddWebhookPopup/AddWebhookPopup";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const WebhooksInfoCardListContainer = ({
    webhooks = [],
    getWebhooks,
    match,
    editWebhook,
    removeWebhook,
    isLoading,
	clearWebhookErrors,
    ...rest
}) => {
    useEffect(() => {
        getWebhooks({ community_id: match.params.id });
    }, [getWebhooks, match.params.id]);

    const [openedCards, setOpenedCards] = useState([]);
    const [editPopupSettings, setEditPopupSettings] = useState({
        isOpen: false,
        webhook: {},
    });

    const toggleCardSettings = (id) => {
        if (openedCards.indexOf(id) >= 0) {
            setOpenedCards(openedCards.filter((cardId) => cardId !== id));
        } else {
            setOpenedCards([...openedCards, id]);
        }
    };

    const onEdit = (id) => {
        const webhookToEdit = webhooks.find((webhook) => webhook.id === id);
        setEditPopupSettings({ isOpen: true, webhook: webhookToEdit });
    };

    return (
        <>
            <WebhooksInfoCardList
                {...rest}
                onEdit={onEdit}
                removeWebhook={removeWebhook}
                openedCards={openedCards}
                toggleCardSettings={toggleCardSettings}
                webhooks={webhooks}
				clearWebhookErrors={clearWebhookErrors}
            />

            <AddWebhookPopup
                isLoading={isLoading}
                isEditing={true}
                webhook={editPopupSettings.webhook}
                isOpen={editPopupSettings.isOpen}
                onClose={() => {
                    setEditPopupSettings(false);
                    clearAllBodyScrollLocks();
                }}
                onOpen={() => setEditPopupSettings(true)}
                editWebhook={editWebhook}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    webhooks: state.webhooks.webhookList,
    isLoading: state.webhooks.isLoading,
});

const mapDispatchToProps = { getWebhooks, editWebhook, removeWebhook, clearWebhookErrors };

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(WebhooksInfoCardListContainer);
