import React from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import { disconnectCommunity } from "../../../redux/reducers/communitiesReducer";
import { connect } from "react-redux";
import Button from "../../generic/Button/Button";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

const DisconnectCommunityPopup = (props) => {
	const {onClosePopup, onOpenPopup, isOpen, match, disconnectCommunity} = props;

	const onClickDisconnect = () => {
		disconnectCommunity(match.params.id).then(() => {
			window.location.href = "/community"
		})
	}

	return (
		<PopupMainLayout
			title="Отключение сообщества"
			subtitle="При отключении сообщества будут остановлены все рассылки. Через месяц после отключения будут удалены все данные без возможности восстановления. Продолжить?"
			isOpen={isOpen}
			onClose={onClosePopup}
			onOpen={onOpenPopup}
		>
			<div className="button_list button_list--vertical">
				<span className="link fs14" onClick={onClickDisconnect}>Отключить</span>
				<Button onClick={onClosePopup}>Отмена</Button>
			</div>
		</PopupMainLayout>
	);
};

class DisconnectCommunityPopupContainer extends React.Component {
	render() {
		return <DisconnectCommunityPopup {...this.props} />
	}
}

const mstp = () => {
	return {}
}

export default compose(
	connect(mstp, {disconnectCommunity}),
	withRouter,
)(DisconnectCommunityPopupContainer);