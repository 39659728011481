import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import newslettersReducer from "./reducers/newslettersReducer";
import communitiesReducer from "./reducers/communitiesReducer";
import authReducer from "./reducers/authReducer";
import subscribehistory from "./reducers/subscribehistoryReducer";
import referralReducer from "./reducers/referralReducer";
import staffReducer from "./reducers/staffReducer";
import noticesReducer from "./reducers/noticesReducer";
import webhooksReducer from "./reducers/webhooksReducer";
import checkingWorkReducer from "./reducers/checkingWorkReducer";
import tooltipsReducer from "./reducers/tooltipsReducer";
import botsReducer from "./reducers/botsReducer";
import locationReducer from "./reducers/locationReducer";

let reducers = combineReducers({
	newsletters: newslettersReducer,
	communities: communitiesReducer,
	auth: authReducer,
	subscribeHistory: subscribehistory,
	referral: referralReducer,
	staff: staffReducer,
	notices: noticesReducer,
	webhooks: webhooksReducer,
	checkingWork: checkingWorkReducer,
	tooltips: tooltipsReducer,
	bots: botsReducer,
	location: locationReducer,
});

let store = createStore(reducers, applyMiddleware(thunk));

export default store;
