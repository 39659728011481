import React from "react";
import FrameHeader from "../FrameHeader/FrameHeader";
import Button from "../generic/Button/Button";
import CircleIconText from "../generic/CircleIconText/CircleIconText";
import CircleIconTextList from "../generic/CircleIconTextList/CircleIconTextList";
import CustomScrollbar from "../generic/CustomScrollbar/CustomScrollbar";
import styles from "./AboutServiceFrame.module.sass";

const AboutServiceFrame = () => {
    return (
        <div className="newsletter_frame newsletter_frame--radius">
            <FrameHeader showLink={false} />
            <CustomScrollbar
                className={`newsletter_frame__main ${styles.about__scrollbar}`}
                disablesize="md"
            >
                <div className={styles.about}>
                    <div className={styles.about__section}>
                        <div className={styles.about__title}>
                            iSEND — рассылка сообщений
                        </div>
                        <div className={styles.about__subtitle}>
                            Приложение позволяет пользователям соцсети
                            Одноклассники подписываться на рассылки от
                            сообществ. Для подписчиков можно организовывать как
                            разовые рассылки, так и цепочки авто-сообщений.
                        </div>
                        <Button
                            href={`https://isend.pro/community/`}
                            target="_blank"
                            icon="plus"
                            className={styles.about__btn}
                        >
                            Установить в свое сообщество
                        </Button>
                    </div>
                    <div className={styles.about__section}>
                        <CircleIconTextList
                            className={styles.about__features}
                            items={[
                                {
                                    icon: "chatbubbles",
                                    iconColor: "#3F8AE0",
                                    title: "Чат-боты (скоро)",
                                    text:
                                        "Гибкая настройка чат-ботов в виде визуальной схемы позволит автоматизировать работу с клиентами.",
                                },
                                {
                                    icon: "people",
                                    iconColor: "#3F8AE0",
                                    title: "Персонализация сообщений",
                                    text:
                                        "Кроме имени, в сообщении можно указать страну, город, семейное положение получателя. Кроме этого можно определять различный текст для мужчин и женщин.",
                                },
                                {
                                    icon: "stats-chart",
                                    iconColor: "#4BB34B",
                                    title: "Статистика",
                                    text:
                                        "Подробная статистика по подпискам, отпискам, отправленным сообщениям, прочтениям и переходам по ссылкам.",
                                },
                                {
                                    icon: "images",
                                    iconColor: "#4BB34B",
                                    title: "Медиавложения",
                                    text:
                                        "К сообщению можно добавить фотографию, видеозапись, аудиозапись, плейлисты, документ, запись на стене и товар.",
                                },
                                {
                                    icon: "person-add",
                                    iconColor: "#FFA000",
                                    title: "Ключевые слова (скоро)",
                                    text:
                                        "Позволяют оформлять подписки через сообщения сообщества.",
                                },
                                {
                                    icon: "target",
                                    iconColor: "#792EC0",
                                    title: "Метки",
                                    text:
                                        "Собственная реализация меток для отслеживания каналов привлечения подписчиков.",
                                },
                                {
                                    icon: "warning2",
                                    iconColor: "#FF3347",
                                    title: "Черный список",
                                    text:
                                        "Пользователям сообщения не будут отправляться.",
                                },
                                {
                                    icon: "hand-left",
                                    iconColor: "#FF3347",
                                    title: "Управление доступом",
                                    text:
                                        "Сотрудникам можно выделять доступ для управления рассылками и отслеживания статистики.",
                                },
                            ]}
                        />
                        <Button href="https://vk.com/@isendpro" target="_blank" icon="info" theme="empty">
                            Документация о сервисе
                        </Button>
                    </div>
                    <div className={styles.about__section}>
                        <CircleIconText
                            icon="iS"
                            iconColor="#EE8208"
                            iconSize="lg"
                            title="iSEND | Сервис рассылок"
                            text="Таргетированные рассылки личных сообщений от имени сообществ"
                        />
                    </div>
                </div>
            </CustomScrollbar>
        </div>
    );
};

export default AboutServiceFrame;
