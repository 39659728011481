import React from "react";
import Icon from "../generic/Icon/Icon";
import styles from "./Notices.module.sass";
import CustomScrollbar from "../generic/CustomScrollbar/CustomScrollbar";
import RoundedButton from "../generic/RoundedButton/RoundedButton";
import Notice from "../generic/Notice/Notice";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
    resetNewNoticeTimers,
    startNewNoticeTimers,
} from "../../redux/reducers/noticesReducer";
import { useDispatch } from "react-redux";

const Notices = (props) => {
    const {
        isActive,
        onClose,
        onOpen,
        className = "",
        listClassName = "",
        notices = [],
        newNotices = [],
        onRemoveNotice,
        removeNewNotice,
        id = "notices",
    } = props;

    const dispatch = useDispatch();

    const handleNoticeMouseEnter = (id) => {
        resetNewNoticeTimers({ id });
    };

    const handleNoticeMouseLeave = (id) => {
        dispatch(startNewNoticeTimers(id));
    };

    return (
        <>
            <div className={`${className} ${styles.notices_wrap}`} id={id}>
                {/* Только пришедшие уведомления */}
                <div className={styles.new_notices}>
                    <TransitionGroup className={styles.new_notices__list}>
                        {newNotices.map((notice, index) =>
                            index < 3 ? (
                                <CSSTransition
                                    key={notice.id}
                                    timeout={500}
                                    classNames="fade"
                                >
                                    <Notice
                                        {...notice}
                                        onMouseEnter={() =>
                                            handleNoticeMouseEnter(notice.id)
                                        }
                                        onMouseLeave={() =>
                                            handleNoticeMouseLeave(notice.id)
                                        }
                                        className={styles.notice_item}
                                        onRemoveNotice={() =>
                                            removeNewNotice(notice.id)
                                        }
                                    />
                                </CSSTransition>
                            ) : null
                        )}
                    </TransitionGroup>
                    {notices.length > 3 && newNotices.length ? (
                        <div className="tar">
                            <RoundedButton
                                text="Все уведомления"
                                onClick={onOpen}
                                theme={"primary"}
                            />
                        </div>
                    ) : null}
                </div>

                {/* Все уведомления */}
                {notices.length ? (
                    <div
                        className={`${styles.notices} ${
                            isActive ? styles["active"] : ""
                        } `}
                    >
                        <div
                            className={styles.notices__cross}
                            onClick={onClose}
                        >
                            <Icon icon="cross" />
                        </div>
                        <div className={styles.notices__title}>Уведомления</div>
                        <CustomScrollbar
                            className={`${styles.notices__list} ${listClassName}`}
                        >
                            <div>
                                {notices.map((notice) => (
                                    <Notice
                                        {...notice}
                                        className={styles.notice_item}
                                        key={notice.id}
                                        onRemoveNotice={() =>
                                            onRemoveNotice(notice.id)
                                        }
                                    />
                                ))}
                            </div>
                        </CustomScrollbar>
                    </div>
                ) : null}
            </div>
            {notices.length ? (
                <div
                    className={`${styles.notices_overlay} ${isActive &&
                        styles["active"]}`}
                    onClick={onClose}
                ></div>
            ) : null}
        </>
    );
};

export default Notices;
