import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActiveButton from "../generic/ActiveButton/ActiveButton";
import Icon from "../generic/Icon/Icon";
import InfoCard from "../generic/InfoCard/InfoCard";
import TagList from "../generic/TagList/TagList";
import styles from "./SubscribersList.module.sass";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import {
    getSubscribers,
    unsubscribeFromAll2,
} from "../../redux/reducers/communitiesReducer";
import Preloader from "../Preloader/Preloader";

let lastScrollTop = 0;
const SubscribersList = ({
    setShowPagesAmount,
    showPagesAmount,
    appliedFilters,
    isPaginationDisabled,
    setIsPaginationDisabled,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [openedCards, setOpenedCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { id: community_id } = useParams();

    const toggleCardSettings = (id) => {
        if (openedCards.indexOf(id) >= 0) {
            setOpenedCards(openedCards.filter((cardId) => cardId !== id));
        } else {
            setOpenedCards([...openedCards, id]);
        }
    };

    const { subscribers } = useSelector(
        (state) => state.communities.currentCommunity
    );

    // eslint-disable-next-line
    const onScrollPagination = () => {
        const pageHeight = document.body.clientHeight;
        const scrollBottom = window.pageYOffset + window.innerHeight;
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop) {
            // downscroll code
            if (
                scrollBottom >= pageHeight - 20 &&
                !isLoading &&
                !isPaginationDisabled &&
                subscribers.length
            ) {
                setShowPagesAmount((state) => state + 1);
                setIsLoading(true);
                dispatch(
                    getSubscribers(community_id, {
                        ...appliedFilters,
                        page: showPagesAmount + 1,
                    })
                ).then((data) => {
                    if (
                        !data.subscribers.length ||
                        data.hasNextPage === false
                    ) {
                        setIsPaginationDisabled(true);
                    } else {
                        setIsPaginationDisabled(false);
                    }
                    setIsLoading(false);
                });
            }
        }

        lastScrollTop = st <= 0 ? 0 : st;
    };

    useEffect(() => {
        window.addEventListener("scroll", onScrollPagination);
        window.addEventListener("touchmove", onScrollPagination);

        return () => {
            window.removeEventListener("scroll", onScrollPagination);
            window.removeEventListener("touchmove", onScrollPagination);
        };
    }, [onScrollPagination, subscribers.length]);

    const handleDeleteSubscriber = (subscriber_id) => {
        setIsLoading(true);
        dispatch(unsubscribeFromAll2({ subscriber_id, community_id }))
            .then(() => dispatch(getSubscribers(community_id)))
            .then(() => setIsLoading(false));
    };

    return (
        <>
            {isLoading ? <Preloader /> : null}
            <div>
                {subscribers.map((subscriber) => (
                    <InfoCard
                        size="sm"
                        key={subscriber.id}
                        settingsToggle={() => toggleCardSettings(subscriber.id)}
                        isOpen={openedCards.some((id) => id === subscriber.id)}
                        onDescClick={(e) => e.stopPropagation()}
                        className={styles.subscriber_list__item}
                        image={subscriber.image}
                        title={subscriber.fullName}
                        photoTitleLink={`/cabinet/community/${community_id}/subscriber/${subscriber.id}`}
                        target="_self"
                        desc={`<div class="mb8" style="color: #545454">${
                            subscriber.user_groups.length
                                ? subscriber.user_groups.map(
                                      (group) => ` ${group.name}`
                                  )
                                : "Без группы"
                        }</div> Id: <a target="_blank" class="${
                            styles.id
                        }" href="${subscriber.profile_url}">${
                            subscriber.ok_id
                        }</a>`}
                        footer={
                            <div className={styles.subscriber__info_list}>
                                {subscriber.groups_count != null ? (
                                    <div
                                        className={styles.subscriber__info_item}
                                    >
                                        <Icon
                                            icon="label"
                                            className={
                                                styles.subscriber__info_item_icon
                                            }
                                        />
                                        Подписки:
                                        <div
                                            className={
                                                styles.subscriber__info_item_extra
                                            }
                                        >
                                            {subscriber.groups_count}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        }
                        extraContent={
                            <>
                                <div className={styles.subscriber__extra_items}>
                                    {subscriber.utm.length ? (
                                        <div
                                            className={
                                                styles.subscriber__extra_item
                                            }
                                        >
                                            {subscriber.utm.length ? (
                                                <div
                                                    className={
                                                        styles.subscriber__extra_item_label
                                                    }
                                                >
                                                    Метки
                                                </div>
                                            ) : null}
                                            {subscriber.utm.map(
                                                (utms, index) => (
                                                    <TagList
                                                        tags={utms.map(
                                                            (utm) => ({
                                                                text: (
                                                                    <span
                                                                        style={{
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        {utm}
                                                                    </span>
                                                                ),
                                                            })
                                                        )}
                                                        theme="dark"
                                                        noHover={true}
                                                        className={
                                                            styles.utms_list
                                                        }
                                                        key={index}
                                                    />
                                                )
                                            )}
                                        </div>
                                    ) : null}

                                    {subscriber.user_groups.length ? (
                                        <div
                                            className={
                                                styles.subscriber__extra_item
                                            }
                                        >
                                            {subscriber.user_groups.length ? (
                                                <div
                                                    className={
                                                        styles.subscriber__extra_item_label
                                                    }
                                                >
                                                    Группы
                                                </div>
                                            ) : null}
                                            <TagList
                                                tags={subscriber.user_groups.map(
                                                    (group) => ({
                                                        text: (
                                                            <span
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                {group.name}{" "}
                                                                {group.subscribe_date ? (
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "#999999",
                                                                        }}
                                                                    >
                                                                        (
                                                                        {
                                                                            group.subscribe_date
                                                                        }
                                                                        )
                                                                    </span>
                                                                ) : null}
                                                            </span>
                                                        ),
                                                    })
                                                )}
                                                theme="dark"
                                                noHover={true}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                                <div className="button_list button_list--fullwidth_md">
                                    <ActiveButton
                                        color="grey"
                                        icon="pen"
                                        onClick={() =>
                                            history.push(
                                                `/cabinet/community/${community_id}/subscriber/${subscriber.id}`
                                            )
                                        }
                                    >
                                        Изменить
                                    </ActiveButton>
                                    <div>
                                        <ActiveButton
                                            tag="a"
                                            href={subscriber.profile_url}
                                            target="_blank"
                                            color="grey"
                                            icon="person"
                                        >
                                            Профиль ОК
                                        </ActiveButton>
                                    </div>
                                    <ActiveButton
                                        color="grey"
                                        icon="trash"
                                        onClick={() =>
                                            handleDeleteSubscriber(
                                                subscriber.id
                                            )
                                        }
                                    >
                                        Удалить
                                    </ActiveButton>
                                </div>
                            </>
                        }
                    />
                ))}
            </div>
        </>
    );
};

export default SubscribersList;
