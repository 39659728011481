import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Button from "../../generic/Button/Button";
import Input from "../../generic/Input/Input";
import Preloader from "../../Preloader/Preloader";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import * as Yup from "yup";
import { useParams, withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { addSubscriberUtm } from "../../../redux/reducers/communitiesReducer";
import styles from "./AddSubscriberUtmPopup.sass";

const AddSubscriberToGroupPopup = ({ isOpen, onOpen, onClose }) => {
    const dispatch = useDispatch();
    const { subscriber_id, id: community_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    // Валидация формы
    const validationSchema = Yup.object({
        utm_source: Yup.string(),
        utm_medium: Yup.string(),
        utm_campaign: Yup.string(),
        utm_content: Yup.string(),
        utm_term: Yup.string(),
        atLeastOne: Yup.bool().when(
            [
                "utm_source",
                "utm_medium",
                "utm_campaign",
                "utm_content",
                "utm_term",
            ],
            {
                is: (
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_content,
                    utm_term
                ) =>
                    !utm_source &&
                    !utm_medium &&
                    !utm_campaign &&
                    !utm_content &&
                    !utm_term,
                then: Yup.bool().required(
                    "Минимум 1 поле должно быть заполнено"
                ),
                otherwise: Yup.bool(),
            }
        ),
    });

    let initialValues = {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_content: "",
        utm_term: "",
    };

    const onSubmit = (values) => {
        setIsLoading(true);
        dispatch(
            addSubscriberUtm({ ...values, subscriber_id, community_id })
        ).then(() => {
            setIsLoading(false);
            onClose();
            clearAllBodyScrollLocks();
        });
    };

    return (
        <PopupMainLayout
            title="Добавить UTM метки"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form className={`form ${styles.form}`}>
                        {isLoading ? <Preloader /> : null}
                        <div className="form__group_item">
                            <Field
                                name="utm_source"
                                label="utm_source"
                                placeholder="yandex, google, ..."
                                component={Input}
                            />
                        </div>
                        <div className="form__group_item">
                            <Field
                                name="utm_medium"
                                label="utm_medium"
                                placeholder="social_cpc, email, ..."
                                component={Input}
                            />
                        </div>
                        <div className="form__group_item">
                            <Field
                                name="utm_campaign"
                                label="utm_campaign"
                                placeholder="promo, kupit_velosiped, ..."
                                component={Input}
                            />
                        </div>
                        <div className="form__group_item">
                            <Field
                                name="utm_content"
                                label="utm_content"
                                placeholder="test_A, moskva, ..."
                                component={Input}
                            />
                        </div>
                        <div className="form__group_item">
                            <Field
                                name="utm_term"
                                label="utm_term"
                                placeholder="free, sony_playstation, ..."
                                component={Input}
                            />
                        </div>
                        {formik.errors.atLeastOne ? (
                            <div className="form__group_item">
                                <div className="text_error fz12">
                                    {formik.errors.atLeastOne}
                                </div>
                            </div>
                        ) : null}
                        <div className="form__group_item">
                            <div
                                className="button_list button_list--fullwidth_md"
                                style={{ justifyContent: "flex-end" }}
                            >
                                <div
                                    className="grey_link last_on_md"
                                    onClick={(e) => {
                                        onClose();
                                        clearAllBodyScrollLocks();
                                    }}
                                >
                                    Отменить
                                </div>
                                <Button icon="save" onClick={formik.submitForm}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </PopupMainLayout>
    );
};

export default withRouter(AddSubscriberToGroupPopup);
