import React from "react";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import styles from "./ScrollBox.module.sass";

const ScrollBox = ({ maxHeight = 280, children, className = "" }) => {
    return (
        <div
            className={`${styles.scrollbox} ${className}`}
        >
            <CustomScrollbar style={{ maxHeight }}>
                <div className={styles.scrollbox__inner}>{children}</div>
            </CustomScrollbar>
        </div>
    );
};

export default ScrollBox;
