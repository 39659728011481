import React, { useState } from "react";
import styles from "./Tabs.module.sass";

const Tabs = ({ children = [], defaultTab, ...rest }) => {
    const [activeTab, setActiveTab] = useState(defaultTab);

    return (
        <div className={styles.tabs} {...rest}>
            <div className={styles.tabs__head}>
                {children.map((child, index) => (
                    <div
                        key={index}
                        className={`${styles.tabs__button} ${
                            child.props.tab === activeTab ? styles.active : ""
                        }`}
                        onClick={() => setActiveTab(child.props.tab)}
                    >
                        {child.props.title}
                    </div>
                ))}
            </div>
            <div className={styles.tabs__content}>
                {children.map((child, index) => (
                    <div
                        key={index}
                        className={`${styles.tab} ${
                            child.props.tab === activeTab ? styles.active : ""
                        }`}
                    >
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
