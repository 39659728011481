import React from "react";
import Header from "../components/Header/Header";

const MainLayout = (props) => {
	const { children } = props;

	const menus = [
		{
			title: "Личный кабинет",
			menu: [
				{ text: "Сообщества", to: "/community/", icon: "group" },
				{
					text: "Партнерская программа",
					to: "/cabinet/referrals/",
					icon: "hand-shake",
				},
			],
		},
	];

	return (
		<>
			<Header menus={menus}></Header>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-8">
						<main className="page_main">{children}</main>
					</div>
				</div>
			</div>
		</>
	);
};

export default MainLayout;
