import { commonApi } from "../../api/api";

const SET_CITIES = "localtion/SET_CITIES";
const SET_COUNTRIES = "localtion/SET_COUNTRIES";

const defaultState = {
    cities: [],
    countries: [],
};

const locationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_CITIES: {
            return {
                ...state,
                cities: action.payload,
            };
        }

        case SET_COUNTRIES: {
            return {
                ...state,
                countries: action.payload,
            };
        }

        default:
            return state;
    }
};

const setCitiesAC = (payload) => {
    return {
        type: SET_CITIES,
        payload,
    };
};

const setCountriesAC = (payload) => {
    return {
        type: SET_COUNTRIES,
        payload,
    };
};

export const getCities = () => async (dispatch) => {
    const data = await commonApi.getCities();
    dispatch(setCitiesAC(data));
};

export const getCountries = () => async (dispatch) => {
    const data = await commonApi.getConuntries();
    dispatch(setCountriesAC(data));
};

export default locationReducer;
