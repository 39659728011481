import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TopCommunity from "./TopCommunity";
import { getCurrentCommunity } from "../../redux/reducers/communitiesReducer";

class TopCommunityContainer extends Component {
	componentDidMount() {
		this.props.getCurrentCommunity(this.props.match.params.id);
	}

	render() {
		return <TopCommunity {...this.props}></TopCommunity>;
	}
}

const mstp = (store) => {
	return {
		community: store.communities.currentCommunity.data,
	};
};

const withRouterTopCommunityContainer = withRouter(TopCommunityContainer);

export default connect(mstp, { getCurrentCommunity })(
	withRouterTopCommunityContainer
);
