import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ActiveButton from "../../components/generic/ActiveButton/ActiveButton";
import SubscribersList from "../../components/SubscribersList/SubscribersList";
import styles from "./subscribers.module.sass";
import SubscriberAddFilter from "../../components/popups/SubscriberAddFilter/SubscriberAddFilter";
import { Field, Form, Formik } from "formik";
import Input from "../../components/generic/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getSubscribers } from "../../redux/reducers/communitiesReducer";
import Button from "../../components/generic/Button/Button";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import ImportSubscribersPopup from "../../components/popups/ImportSubscribersPopup/ImportSubscribersPopup";
import Preloader from "../../components/Preloader/Preloader";
import ExportSubscribersPopup from "../../components/popups/ExportSubscribersPopup/ExportSubscribersPopup";
import ExcludeSubscriberFromGroupPopup from "../../components/popups/ExcludeSubscribersFromGroupPopup/ExcludeSubscribersFromGroupPopup";
import AddSubscribersToGroupPopup from "../../components/popups/AddSubscribersToGroupPopup/AddSubscribersToGroupPopup";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../../components/generic/Icon/Icon";
import DropDownMenu from "../../components/generic/DropDownMenu/DropDownMenu";
import { communityAPI } from "../../api/api";
import moment from "moment";
import ReactTooltip from "react-tooltip";

const Subscribers = () => {
	// Хлебные крошки
	const page = {
		crumbs: [
			{ name: "Личный кабинет", path: "/" },
			{ name: "Сообщества", path: "/community" },
			{ name: "Подписчики", path: "/" },
		],
	};

	const dispatch = useDispatch();
	const [subscribersTotalAmount, setSubscribersTotalAmount] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isActionOpen, setIsActionOpen] = useState(false);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isImportOpen, setIsImportOpen] = useState(false);
	const [isExportOpen, setIsExportOpen] = useState(false);
	const [timeError, setTimeError] = useState("");
	const [isExcludeFromGroupOpen, setIsExcludeFromGroupOpen] = useState(false);
	const [isAddToGroupOpen, setIsAddToGroupOpen] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState({ filter: {} });
	const { id: communityId } = useParams();
	const { groups } = useSelector(
		(state) => state.communities.currentCommunity
	);
	const [showPagesAmount, setShowPagesAmount] = useState(1);
	const [appliedFilters, setAppliedFilters] = useState({ filter: {} });
	const [isPaginationDisabled, setIsPaginationDisabled] = useState(false);
	const [filteredSubscribersCount, setFilteredSubscribersCount] = useState(
		null
	);
	const { tooltips } = useSelector((state) => state.tooltips);

	useEffect(() => {
		setIsLoading(true);
		dispatch(getSubscribers(communityId)).then(({ hasNextPage }) => {
			if (hasNextPage) {
				setIsPaginationDisabled(false);
			} else {
				setIsPaginationDisabled(true);
			}
			setIsLoading(false);
		});
		communityAPI
			.getSubscribersCount({ community_id: communityId })
			.then(({ count }) => {
				setSubscribersTotalAmount(count);
			});
		communityAPI
			.getFilteredSubscribersCount(communityId)
			.then(({ count }) => {
				setFilteredSubscribersCount(count);
			});
	}, [dispatch, communityId]);

	// Сабмит попапа фильтров
	const handleSelectFiltersSubmit = (formik) => (values) => {
		setSelectedFilters(values);
		for (let prop in values.filter) {
			if (values.filter[prop] === false) {
				if (prop === "age_from") {
					formik.setFieldValue(`filter[age_from]`, null);
					formik.setFieldValue(`filter[age_to]`, null);
				} else if (prop === "subscribe_date_from") {
					formik.setFieldValue(`filter[subscribe_date_from]`, null);
					formik.setFieldValue(`filter[subscribe_date_to]`, null);
					formik.setFieldValue(`filter[subscribe_time_from]`, null);
					formik.setFieldValue(`filter[subscribe_time_to]`, null);
				} else {
					formik.setFieldValue(`filter[${prop}]`, null);
				}
			}
		}
		setIsFilterOpen(false);
		clearAllBodyScrollLocks();
	};

	// Сабмит показать фильтры
	const handleSubmitFilters = (values) => {
		if (timeError) return 0;
		setIsLoading(true);
		setAppliedFilters(values);
		dispatch(getSubscribers(communityId, values)).then(
			({ hasNextPage }) => {
				if (hasNextPage) {
					setIsPaginationDisabled(false);
				} else {
					setIsPaginationDisabled(true);
				}
				setIsLoading(false);
			}
		);
		setIsPaginationDisabled(false);
		communityAPI
			.getFilteredSubscribersCount(communityId, values)
			.then(({ count }) => {
				setFilteredSubscribersCount(count);
			});
	};

	// Обновление списка подписчиков
	const refreshSubscribers = (values) => {
		setIsLoading(true);
		dispatch(getSubscribers(communityId, values)).then(
			({ hasNextPage }) => {
				if (hasNextPage) {
					setIsPaginationDisabled(false);
				} else {
					setIsPaginationDisabled(true);
				}
				setIsLoading(false);
			}
		);
		setShowPagesAmount(1);
		communityAPI
			.getFilteredSubscribersCount(communityId, values)
			.then(({ count }) => {
				setFilteredSubscribersCount(count);
			});
	};

	// Удаление фильтра
	const handleFilterRemove = (name, formik) => {
		let changedValues = {};
		let values = { ...formik.values };
		if (name === "age_from") {
			changedValues = { age_from: null, age_to: null };
		} else if (name === "subscribe_time_to") {
			changedValues = {
				subscribe_date_from: null,
				subscribe_date_to: null,
				subscribe_time_from: null,
				subscribe_time_to: null,
			};
		} else {
			changedValues = { [name]: null };
		}

		values = { ...values, filter: { ...values.filter, ...changedValues } };
		setSelectedFilters((state) => ({
			...state,
			filter: {
				...state.filter,
				...changedValues,
			},
		}));
		setAppliedFilters((state) => ({
			...state,
			filter: {
				...state.filter,
				...changedValues,
			},
		}));
		for (let prop in changedValues) {
			formik.setFieldValue(`filter[${prop}]`, changedValues[prop]);
		}
		refreshSubscribers(values);
		setShowPagesAmount(1);
		setIsPaginationDisabled(false);
	};

	// Сброси фильтров
	const resetFilters = (formik) => {
		formik.resetForm();
		setSelectedFilters((state) => ({
			...state,
			filter: {},
		}));
		setAppliedFilters((state) => ({
			...state,
			filter: {},
		}));
		setIsLoading(true);
		dispatch(getSubscribers(communityId)).then(({ hasNextPage }) => {
			if (hasNextPage) {
				setIsPaginationDisabled(false);
			} else {
				setIsPaginationDisabled(true);
			}
			setIsLoading(false);
		});
		setShowPagesAmount(1);
		setIsPaginationDisabled(false);
		communityAPI
			.getFilteredSubscribersCount(communityId)
			.then(({ count }) => {
				setFilteredSubscribersCount(count);
			});
	};

	// Получение списка подписчиков для селекта
	const getSubscribersForSelect = (inputValue, callback) => {
		communityAPI
			.findSubscribers({
				community_id: communityId,
				search: inputValue,
			})
			.then((subscribers) => {
				callback(
					subscribers
						? subscribers.map((subscriber) => ({
							label: subscriber.fullName,
							value: subscriber.id,
						}))
						: []
				);
			});
	};

	const navButtons = [
		{
			text: "Импорт",
			icon: "download",
			onClick: () => setIsImportOpen(true),
		},
		{
			text: "Экспорт",
			icon: "export",
			onClick: () => setIsExportOpen(true),
		},
		{
			text: "Исключить из группы",
			icon: "stop",
			onClick: () => setIsExcludeFromGroupOpen(true),
		},
		{
			text: "Добавить в группу",
			icon: "circle-plus",
			onClick: () => setIsAddToGroupOpen(true),
		},
		{
			text: "Обновить",
			icon: "refresh-3",
			onClick: (values) => refreshSubscribers(values),
		},
	];

	const validateTime = (formik) => {
		const { values } = formik;
		const { filter = {} } = values;
		const {
			subscribe_time_from,
			subscribe_time_to,
			subscribe_date_from,
			subscribe_date_to,
		} = filter;

		if (
			subscribe_time_to &&
			subscribe_date_from === subscribe_date_to &&
			moment(subscribe_time_to, "HH:mm").isBefore(
				moment(subscribe_time_from, "HH:mm")
			)
		) {
			setTimeError("Некорректное время");
		} else {
			setTimeError("");
		}
	};

	const handleDateFromChange = (e, formik) => {
		let { value } = e.target;
		if (!value) {
			formik.setValues({
				...formik.values,
				filter: {
					...formik.values.filter,
					subscribe_date_from: value,
					subscribe_time_from: "",
				},
			});
		}
		validateTime(formik);
	};

	const handleDateToChange = (e, formik) => {
		let { value } = e.target;
		if (!value) {
			formik.setValues({
				...formik.values,
				filter: {
					...formik.values.filter,
					subscribe_date_to: value,
					subscribe_time_to: "",
				},
			});
		}
		validateTime(formik);
	};

	return (
		<div className={styles.content}>
			{isLoading ? <Preloader /> : null}
			{/* Добавленные фильтры */}
			<Formik
				initialValues={{ filter: {} }}
				onSubmit={handleSubmitFilters}
			>
				{(formik) => {
					return (
						<Form className={`form ${styles.filter_form}`}>
							<div className="page_head">
								<Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>

								{/* Кнопки в шапке */}
								<div className={styles.action_buttons}>
									<div className="button_list">
										<ActiveButton
											color="grey"
											icon="funnel"
											onClick={() =>
												setIsFilterOpen(true)
											}
										>
											Добавить фильтр
										</ActiveButton>
										{navButtons.map((button, index) => (
											<div
												className="d-none d-xl-block"
												key={index}
											>
												<ActiveButton
													color="grey"
													{...button}
													onClick={() =>
														button.onClick(
															formik.values
														)
													}
												>
													{button.text}
												</ActiveButton>
											</div>
										))}
									</div>
									{/* "Действия" в адаптиве */}
									<div className="d-block d-xl-none">
										<div
											className={`dropdown_link ${isActionOpen ? "active" : ""
												}`}
										>
											<span
												onClick={() =>
													setIsActionOpen(
														(state) => !state
													)
												}
											>
												Действия
											</span>
											<OutsideClickHandler
												onOutsideClick={() =>
													setIsActionOpen(false)
												}
											>
												<div
													onClick={() =>
														setIsActionOpen(
															(state) => !state
														)
													}
												>
													<Icon icon="arrow-down"></Icon>
												</div>

												<DropDownMenu
													isOpen={isActionOpen}
													onOutsideClick={() =>
														setIsActionOpen(false)
													}
													links={navButtons.map(
														(button) => ({
															...button,
															onClick: () =>
																button.onClick(
																	formik.values
																),
														})
													)}
													style={{
														width: 253,
														right: 0,
														top:
															"calc(100% + 20px)",
													}}
												></DropDownMenu>
											</OutsideClickHandler>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								{/* Добавить группу подписчиков */}
								{selectedFilters.filter.groups_id === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[groups_id]"
											type="select"
											placeholder="Группы подписчиков"
											label="Добавить группу подписчиков"
											options={groups.map((group) => ({
												label: group.name,
												value: group.id,
											}))}
											onRemove={() =>
												handleFilterRemove(
													"groups_id",
													formik
												)
											}
											removable
											removeIcon="cross"
											isMulti
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_GROUPS}
										/>
									</div>
								) : null}
								{/* Исключить группу подписчиков */}
								{selectedFilters.filter.remove_groups_id ===
									true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[remove_groups_id]"
											type="select"
											placeholder="Группы подписчиков"
											label="Исключить группу подписчиков"
											options={groups.map((group) => ({
												label: group.name,
												value: group.id,
											}))}
											onRemove={() =>
												handleFilterRemove(
													"remove_groups_id",
													formik
												)
											}
											removable
											removeIcon="cross"
											isMulti
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_GROUPS_EXCLUDE}
										/>
									</div>
								) : null}
								{/* Пол */}
								{selectedFilters.filter.gender === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[gender]"
											type="select"
											options={[
												{
													label: "Мужчина",
													value: "MALE",
												},
												{
													label: "Женщина",
													value: "FEMALE",
												},
											]}
											placeholder="Выберите пол"
											label="Пол"
											onRemove={() =>
												handleFilterRemove(
													"gender",
													formik
												)
											}
											removable
											removeIcon="cross"
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_SEX}
										/>
									</div>
								) : null}
								{/* Возраст */}
								{selectedFilters.filter.age_from === true ? (
									<div className="form__group_item col-md-6">
										<div className="form__double">
											<Field
												name="filter[age_from]"
												type="text"
												placeholder=" "
												label="Возраст"
												leftLabel="от:"
												component={Input}
												tooltip={tooltips.SUBSCRIBER_FILTER_AGE_FROM}
											/>
											<Field
												name="filter[age_to]"
												type="text"
												placeholder=" "
												leftLabel="до:"
												onRemove={() =>
													handleFilterRemove(
														"age_from",
														formik
													)
												}
												removable
												removeIcon="cross"
												component={Input}
											/>
										</div>
									</div>
								) : null}
								{/* Город */}
								{selectedFilters.filter.city === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[city]"
											type="text"
											placeholder="Укажите город"
											label="Город"
											onRemove={() =>
												handleFilterRemove(
													"city",
													formik
												)
											}
											removable
											removeIcon="cross"
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_CITY}
										/>
									</div>
								) : null}
								{/* Страна */}
								{selectedFilters.filter.country === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[country]"
											type="text"
											placeholder="Укажите страну"
											label="Страна"
											onRemove={() =>
												handleFilterRemove(
													"country",
													formik
												)
											}
											removable
											removeIcon="cross"
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_COUNTRY}
										/>
									</div>
								) : null}
								{/* Дата подпски */}
								{selectedFilters.filter.subscribe_date_from ===
									true ? (
									<div
										className={`col-md-6 ${styles.form__dates_item}`}
									>
										<div className="row">
											<div
												className={`col-md-6 form__group_item ${styles.form__date_col}`}
											>
												<div className="block_label ">
													<span>Дата подписки с {tooltips.SUBSCRIBER_FILTER_SUBSCRIBE_DATE_FROM ? <span
														className="tooltip_trigger block_label__tooltip"
														data-effect="solid"
														data-for={"SUBSCRIBER_FILTER_SUBSCRIBE_DATE_FROM"}
														data-tip
													></span> : null}</span>
													{tooltips.SUBSCRIBER_FILTER_SUBSCRIBE_DATE_FROM ? <ReactTooltip className="tooltip" id={"SUBSCRIBER_FILTER_SUBSCRIBE_DATE_FROM"}>
														<div dangerouslySetInnerHTML={{ __html: tooltips.SUBSCRIBER_FILTER_SUBSCRIBE_DATE_FROM }}></div>
													</ReactTooltip> : null}
												</div>
												<div
													className={
														styles.form__footer_inputs
													}
												>
													<div
														className={
															styles.form__date
														}
													>
														<Field
															type="datepicker"
															popperModifiers={{
																offset: {
																	enabled: true,
																	offset:
																		"5px, 10px",
																},
															}}
															placeholder="Дата"
															name="filter[subscribe_date_from]"
															mask="date"
															maxDate={
																formik.values
																	.filter &&
																	formik.values
																		.filter
																		.subscribe_date_to
																	? new Date(
																		moment(
																			formik
																				.values
																				.filter
																				.subscribe_date_to,
																			"DD.MM.YYYY"
																		).format(
																			"YYYY-MM-DD"
																		)
																	)
																	: false
															}
															component={Input}
															onChange={(e) =>
																handleDateFromChange(
																	e,
																	formik
																)
															}
														></Field>
													</div>
													<div
														className={
															styles.form__time
														}
													>
														<Field
															placeholder="Время"
															name="filter[subscribe_time_from]"
															mask="time"
															component={Input}
															onKeyUp={() =>
																validateTime(
																	formik
																)
															}
															errorMessage={
																timeError
															}
														></Field>
													</div>
												</div>
											</div>
											<div
												className={`col-md-6 form__group_item ${styles.form__date_col2}`}
											>
												<div className="block_label">
													<span>Дата подписки по {tooltips.SUBSCRIBER_FILTER_SUBSCRIBE_DATE_TO ? <span
														className="tooltip_trigger block_label__tooltip"
														data-effect="solid"
														data-for={"SUBSCRIBER_FILTER_SUBSCRIBE_DATE_TO"}
														data-tip
													></span>: null}</span>
													{tooltips.SUBSCRIBER_FILTER_SUBSCRIBE_DATE_TO ? <ReactTooltip className="tooltip" id={"SUBSCRIBER_FILTER_SUBSCRIBE_DATE_TO"}>
														<div dangerouslySetInnerHTML={{ __html: tooltips.SUBSCRIBER_FILTER_SUBSCRIBE_DATE_TO }}></div>
													</ReactTooltip> : null}
												</div>
												<div
													className={
														styles.form__footer_inputs
													}
												>
													<div
														className={
															styles.form__date
														}
													>
														<Field
															type="datepicker"
															popperModifiers={{
																offset: {
																	enabled: true,
																	offset:
																		"5px, 10px",
																},
															}}
															minDate={
																formik.values
																	.filter &&
																	formik.values
																		.filter
																		.subscribe_date_from
																	? new Date(
																		moment(
																			formik
																				.values
																				.filter
																				.subscribe_date_from,
																			"DD.MM.YYYY"
																		).format(
																			"YYYY-MM-DD"
																		)
																	)
																	: false
															}
															placeholder="Дата"
															name="filter[subscribe_date_to]"
															mask="date"
															component={Input}
															onChange={(e) =>
																handleDateToChange(
																	e,
																	formik
																)
															}
														></Field>
													</div>
													<div
														className={`${styles.form__time} ${styles.form__cross_input}`}
													>
														<Field
															placeholder="Время"
															name="filter[subscribe_time_to]"
															mask="time"
															onRemove={() =>
																handleFilterRemove(
																	"subscribe_time_to",
																	formik
																)
															}
															removable
															removeIcon="cross"
															component={Input}
															onKeyUp={(e) =>
																handleDateToChange(
																	e,
																	formik
																)
															}
															tooltip={tooltips.SUBSCRIBER_FILTER_TIME}
														></Field>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : null}
								{/* Источник */}
								{selectedFilters.filter.source === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[source]"
											type="select"
											isMulti={true}
											placeholder="Добавить источник"
											label="Источник"
											options={[
												{
													label: "Сообщество",
													value: "COMMUNITY",
												},
												{
													label: "Подписная страница",
													value: "SUBSCRIBE_PAGE",
												},
												{
													label: "Вручную",
													value: "MANUALLY",
												},
											]}
											onRemove={() =>
												handleFilterRemove(
													"source",
													formik
												)
											}
											removable
											removeIcon="cross"
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_SOURCE}
										/>
									</div>
								) : null}
								{/* Подписчики */}
								{selectedFilters.filter.subscribers_id ===
									true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[subscribers_id]"
											type="asyncSelect"
											loadOptions={
												getSubscribersForSelect
											}
											placeholder="Имя, ID или ссылка на профиль"
											label="Подписчик"
											onRemove={() =>
												handleFilterRemove(
													"subscribers_id",
													formik
												)
											}
											isMulti
											removable
											removeIcon="cross"
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_SUBSCRIBER}
										/>
									</div>
								) : null}
								{/* Utm метки */}
								{selectedFilters.filter.utm === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[utm]"
											label="Метка"
											type="creatable"
											isMulti={true}
											placeholder="Добавить свою метку"
											removable
											removeIcon="cross"
											onRemove={() =>
												handleFilterRemove(
													"utm",
													formik
												)
											}
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_ADD_UTM}
										></Field>
									</div>
								) : null}
								{/* UTM_SOURCE метки */}
								{selectedFilters.filter.utm_source === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[utm_source]"
											label="UTM_SOURCE"
											type="creatable"
											isMulti={true}
											placeholder="Добавить utm_source"
											removable
											removeIcon="cross"
											onRemove={() =>
												handleFilterRemove(
													"utm_source",
													formik
												)
											}
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_ADD_UTM_SOURCE}
										></Field>
									</div>
								) : null}
								{/* UTM_MEDIUM метки */}
								{selectedFilters.filter.utm_medium === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[utm_medium]"
											label="UTM_MEDIUM"
											type="creatable"
											isMulti={true}
											placeholder="Добавить utm_medium"
											removable
											removeIcon="cross"
											onRemove={() =>
												handleFilterRemove(
													"utm_medium",
													formik
												)
											}
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_ADD_UTM_MEDIUM}
										></Field>
									</div>
								) : null}
								{/* UTM_CAMPAIGN метки */}
								{selectedFilters.filter.utm_campaign ===
									true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[utm_campaign]"
											label="UTM_CAMPAIGN"
											type="creatable"
											isMulti={true}
											placeholder="Добавить utm_campaign"
											removable
											removeIcon="cross"
											onRemove={() =>
												handleFilterRemove(
													"utm_campaign",
													formik
												)
											}
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_ADD_UTM_COMPAIGN}
										></Field>
									</div>
								) : null}
								{/* UTM_CONTENT метки */}
								{selectedFilters.filter.utm_content === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[utm_content]"
											label="UTM_CONTENT"
											type="creatable"
											isMulti={true}
											placeholder="Добавить utm_content"
											removable
											removeIcon="cross"
											onRemove={() =>
												handleFilterRemove(
													"utm_content",
													formik
												)
											}
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_ADD_UTM_CONTENT}
										></Field>
									</div>
								) : null}
								{/* UTM_TERM метки */}
								{selectedFilters.filter.utm_term === true ? (
									<div className="form__group_item col-md-6">
										<Field
											name="filter[utm_term]"
											label="UTM_TERM"
											type="creatable"
											isMulti={true}
											placeholder="Добавить utm_term"
											removable
											removeIcon="cross"
											onRemove={() =>
												handleFilterRemove(
													"utm_term",
													formik
												)
											}
											component={Input}
											tooltip={tooltips.SUBSCRIBER_FILTER_ADD_UTM_TERM}
										></Field>
									</div>
								) : null}
								{/* Пустой див для отступа */}
								<div></div>
							</div>
							{Object.keys(selectedFilters.filter).some(
								(k) => selectedFilters.filter[k]
							) ? (
								<div className={styles.filter_buttons}>
									<div
										className="button_list button_list--fullwidth_md"
										style={{ justifyContent: "flex-end" }}
									>
										<Button
											onClick={formik.submitForm}
											icon="play-2"
										>
											Показать
										</Button>
										<ActiveButton
											onClick={() => resetFilters(formik)}
											icon="refresh-3"
											color="grey"
										>
											Сбросить
										</ActiveButton>
									</div>
								</div>
							) : null}
							{/* Кол-во подписчиков */}
							<div className={styles.subscriber_amount}>
								Подписчики{" "}
								<span
									className={styles.subscriber_amount__number}
								>
									{filteredSubscribersCount}
								</span>
							</div>

							{/* Список всех подписчиков */}
							<SubscribersList
								showPagesAmount={showPagesAmount}
								setShowPagesAmount={setShowPagesAmount}
								appliedFilters={appliedFilters}
								isPaginationDisabled={isPaginationDisabled}
								setIsPaginationDisabled={
									setIsPaginationDisabled
								}
							/>

							{/* Попап фильтра */}
							<SubscriberAddFilter
								isOpen={isFilterOpen}
								onClose={() => setIsFilterOpen(false)}
								onOpen={() => setIsFilterOpen(true)}
								handleSubmit={handleSelectFiltersSubmit(formik)}
								initialValues={selectedFilters}
							/>

							{/* Попап импорта */}
							<ImportSubscribersPopup
								isOpen={isImportOpen}
								onClose={() => setIsImportOpen(false)}
								onOpen={() => setIsImportOpen(true)}
								afterSubmit={() =>
									refreshSubscribers(formik.values)
								}
							/>

							{/* Попап экспорта */}
							<ExportSubscribersPopup
								isOpen={isExportOpen}
								onClose={() => setIsExportOpen(false)}
								onOpen={() => setIsExportOpen(true)}
								subscribersAmount={filteredSubscribersCount}
								subscribersTotalAmount={subscribersTotalAmount}
								filters={formik.values}
							/>

							{/* Попап исключения из группы */}
							<ExcludeSubscriberFromGroupPopup
								isOpen={isExcludeFromGroupOpen}
								onClose={() => setIsExcludeFromGroupOpen(false)}
								onOpen={() => setIsExcludeFromGroupOpen(true)}
								subscribersAmount={filteredSubscribersCount}
								subscribersTotalAmount={subscribersTotalAmount}
								filters={formik.values}
								afterSubmit={() =>
									refreshSubscribers(formik.values)
								}
							/>

							{/* Добавление в группы */}
							<AddSubscribersToGroupPopup
								isOpen={isAddToGroupOpen}
								onClose={() => setIsAddToGroupOpen(false)}
								onOpen={() => setIsAddToGroupOpen(true)}
								subscribersAmount={filteredSubscribersCount}
								subscribersTotalAmount={subscribersTotalAmount}
								filters={formik.values}
								afterSubmit={() =>
									refreshSubscribers(formik.values)
								}
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default Subscribers;
