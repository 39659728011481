import {
    communityAPI,
    groupAPI,
    subscriberAPI,
    subscribersAPI,
    userAPI,
    utmAPI,
} from "../../api/api";

const SET_COMMUNITIES = "SET_COMMUNITIES";
const SET_COMMUNITIES_ALL = "SET_COMMUNITIES_ALL";
const SET_COMMUNITIES_LOADING = "SET_COMMUNITIES_LOADING";
const SET_COMMUNITIES_FIRST_LOADING = "SET_COMMUNITIES_FIRST_LOADING";
const SET_COMMUNITIES_SORTING = "SET_COMMUNITIES_SORTING";
const SET_CURRENT_COMMUNITY = "SET_CURRENT_COMMUNITY";
const SET_CURRENT_COMMUNITY_GROUPS = "SET_CURRENT_COMMUNITY_GROUPS";
const SET_CURRENT_COMMUNITY_GROUPS2 = "SET_CURRENT_COMMUNITY_GROUPS2";
const SET_GROUPS_SORTING = "SET_GROUPS_SORTING";
const SET_GROUPS_LOADING = "SET_GROUPS_LOADING";
const SET_GROUP_INFO = "SET_GROUP_INFO";
const SET_GROUP_OKINFO = "SET_GROUP_OKINFO";
const SET_GROUPS_FIRST_LOADING = "SET_GROUPS_FIRST_LOADING";
const SET_GROUPS_FIRST_LOADING2 = "SET_GROUPS_FIRST_LOADING2";
const SET_GROUP_INFO_IS_LOADING = "SET_GROUP_INFO_IS_LOADING";
const SET_COMMUNITY_SUBSCRIBERS = "SET_COMMUNITY_SUBSCRIBERS";
const ADD_COMMUNITY_SUBSCRIBERS = "ADD_COMMUNITY_SUBSCRIBERS";
const SET_COMMUNITY_SUBSCRIBER = "SET_COMMUNITY_SUBSCRIBER";
const SET_SUBSCRIBER_IS_LOADING = "SET_SUBSCRIBER_IS_LOADING";
const SET_COMMUNITY_UTMS = "SET_COMMUNITY_UTMS";
const SET_CURRENT_COMMUNITY_LOADING = "SET_CURRENT_COMMUNITY_LOADING";
const SET_COMMUNIY_UTMS_LOADING = "SET_COMMUNIY_UTMS_LOADING";

const defaultState = {
    communities: {
        communities: [],
        count: {
            all: 0,
            connected: 0,
            unconnected: 0,
            archived: 0,
            whose: {
                all: 0,
                own: 0,
                guest: 0,
            },
        },
        page: 0,
        hasNextPage: false,
    },
    communitiesAll: [],
    searchedCommunities: [],
    sortedCommunities: [],
    sortBy: {
        status: "all",
        type: "all",
    },
    searchText: "",
    currentCommunity: {
        data: {},
        groups: [],
        groups2: {
            items: [],
            count: null,
            pages_count: null,
            unvisible_count: null,
            visible_count: null,
        },
        sortedGroups: [],
        searchText: "",
        sortBy: "all",
        isFirstLoading: true,
        isFirstLoading2: true,
        isLoading: true,
        groupInfoIsLoading: false,
        groupData: {},
        groupOkdata: {},
        subscribers_id: [],
        subscribers: [],
        subscriberIsLoading: false,
        subscriber: {
            utms: [],
            marks: [],
            groups: [],
        },
        utms: [],
    },
    isFirstLoading: true,
    isLoading: false,
    isCurrentCommunityLoading: false,
};

const communitiesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_COMMUNITIES: {
            return {
                ...state,
                communities: action.communities,
            };
        }

        case SET_COMMUNITIES_ALL: {
            return {
                ...state,
                communitiesAll: action.communities,
            };
        }

        case SET_CURRENT_COMMUNITY: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    data: action.currentCommunity,
                },
            };
        }

        case SET_CURRENT_COMMUNITY_GROUPS: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    groups: action.groups,
                },
            };
        }

        case SET_CURRENT_COMMUNITY_GROUPS2: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    groups2: {
                        ...state.currentCommunity.groups2,
                        ...action.groups,
                    },
                },
            };
        }

        case SET_COMMUNITIES_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }

        case SET_GROUPS_LOADING: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    isLoading: action.isLoading,
                },
            };
        }

        case SET_GROUPS_FIRST_LOADING: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    isFirstLoading: action.isFirst,
                },
            };
        }

        case SET_GROUPS_FIRST_LOADING2: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    isFirstLoading2: action.isFirst,
                },
            };
        }

        case SET_COMMUNITIES_SORTING: {
            let sortedCommunities = state.communities || [];
            // let searchedCommunities;
            let sortBy = { ...state.sortBy, ...action.sortBy };

            if (state.sortBy.archived === "1" && !action.sortBy.type) {
                sortBy.type = "all";
            }

            if (sortBy.archived === "1") {
                sortBy.status = "";
                // sortedCommunities = sortedCommunities.filter(
                //     (newsletter) => newsletter.archived === "1"
                // );
            } else {
                // sortedCommunities = sortedCommunities.filter(
                //     (newsletter) => newsletter.archived !== "1"
                // );
                /*eslint-disable */
                // for (let sort in sortBy) {
                //     if (sortBy[sort] !== "all") {
                //         sortedCommunities = sortedCommunities.filter(
                //             (newsletter) => {
                //                 return newsletter[sort] === sortBy[sort];
                //             }
                //         );
                //     }
                // }
                /*eslint-enable */
            }

            if (state.newsletters && !sortedCommunities.length) {
                sortBy = {
                    status: "all",
                    type: "all",
                };
                // sortedCommunities = state.newsletters.filter(
                //     (newsletter) => newsletter.archived !== "1"
                // );
            }

            if (action.searchText) {
                // searchedCommunities = sortedCommunities.filter((n) => {
                //     return n.name
                //         .toLowerCase()
                //         .indexOf(action.searchText.toLowerCase()) >= 0
                //         ? true
                //         : false;
                // });
                // sortedCommunities = searchedCommunities.length
                //     ? searchedCommunities
                //     : [];
            }

            return {
                ...state,
                sortedCommunities,
                sortBy,
                searchText: action.searchText,
            };
        }

        case SET_GROUPS_SORTING: {
            let sortedGroups;
            let searchedGroups;
            let { sortBy } = action;

            sortedGroups = state.currentCommunity.groups.filter((n) => {
                if (sortBy === "all") {
                    return state.currentCommunity;
                } else {
                    return n.visible === sortBy;
                }
            });

            if (action.searchText) {
                searchedGroups = sortedGroups.filter((n) => {
                    return n.name
                        .toLowerCase()
                        .indexOf(action.searchText.toLowerCase()) >= 0
                        ? true
                        : false;
                });

                sortedGroups = searchedGroups.length ? searchedGroups : [];
            }

            sortBy = state.currentCommunity.groups.filter(
                (g) => g.visible === sortBy
            ).length
                ? sortBy
                : "all";

            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    sortedGroups,
                    sortBy,
                    searchText: action.searchText,
                },
            };
        }

        case SET_COMMUNITIES_FIRST_LOADING: {
            return {
                ...state,
                isFirstLoading: action.isFirstLoading,
            };
        }

        case SET_GROUP_INFO_IS_LOADING: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    groupInfoIsLoading: action.isLoading,
                },
            };
        }

        case SET_GROUP_INFO: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    groupData: action.groupData,
                },
            };
        }

        case SET_GROUP_OKINFO: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    groupOkdata: action.groupData,
                },
            };
        }

        case SET_COMMUNITY_SUBSCRIBERS: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    subscribers: action.subscribers,
                },
            };
        }

        case ADD_COMMUNITY_SUBSCRIBERS: {
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    subscribers: [
                        ...state.currentCommunity.subscribers,
                        ...action.subscribers,
                    ],
                },
            };
        }

        case SET_COMMUNITY_SUBSCRIBER:
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    subscriber: action.subscriber,
                },
            };

        case SET_SUBSCRIBER_IS_LOADING:
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    subscriberIsLoading: action.subscriberIsLoading,
                },
            };

        case SET_COMMUNITY_UTMS:
            return {
                ...state,
                currentCommunity: {
                    ...state.currentCommunity,
                    utms: action.utms,
                },
            };

        case SET_CURRENT_COMMUNITY_LOADING:
            return {
                ...state,
                isCurrentCommunityLoading: action.isCurrentCommunityLoading,
            };

        case SET_COMMUNIY_UTMS_LOADING:
            return {
                ...state,
                isCommunityUtmsLoading: action.isCommunityUtmsLoading,
            };

        default:
            return state;
    }
};

export const setCommunities = (communities) => {
    return {
        type: SET_COMMUNITIES,
        communities,
    };
};

export const setCommunitiesAll = (communities) => {
    return {
        type: SET_COMMUNITIES_ALL,
        communities,
    };
};

export const setCurrentCommunity = (currentCommunity) => {
    return {
        type: SET_CURRENT_COMMUNITY,
        currentCommunity,
    };
};

export const setCurrentCommunityGroups = (groups) => {
    return {
        type: SET_CURRENT_COMMUNITY_GROUPS,
        groups,
    };
};

export const setCurrentCommunityGroups2 = (groups) => {
    return {
        type: SET_CURRENT_COMMUNITY_GROUPS2,
        groups,
    };
};

export const setIsCommunitiesLoading = (isLoading) => {
    return {
        type: SET_COMMUNITIES_LOADING,
        isLoading,
    };
};

export const setIsCommunitiesFirstLoading = (isFirst) => {
    return {
        type: SET_COMMUNITIES_FIRST_LOADING,
        isFirst,
    };
};

export const setIsGroupsFirstLoading = (isFirst) => {
    return {
        type: SET_GROUPS_FIRST_LOADING,
        isFirst,
    };
};

export const setIsGroupsFirstLoading2 = (isFirst) => {
    return {
        type: SET_GROUPS_FIRST_LOADING2,
        isFirst,
    };
};

export const setIsGroupsLoading = (isLoading) => {
    return {
        type: SET_GROUPS_LOADING,
        isLoading,
    };
};

export const setGroupInfoIsLoading = (isLoading) => {
    return {
        type: SET_GROUP_INFO_IS_LOADING,
        isLoading,
    };
};

export const setCommunitiesSorting = (sortBy, searchText) => {
    return {
        type: SET_COMMUNITIES_SORTING,
        sortBy,
        searchText,
    };
};

export const setGroupsSorting = (sortBy, searchText) => {
    return {
        type: SET_GROUPS_SORTING,
        sortBy,
        searchText,
    };
};

export const setGroupInfo = (groupData) => {
    return {
        type: SET_GROUP_INFO,
        groupData,
    };
};

export const setGroupOkinfo = (groupData) => {
    return {
        type: SET_GROUP_OKINFO,
        groupData,
    };
};

export const setCommunitySubscribers = (subscribers) => {
    return {
        type: SET_COMMUNITY_SUBSCRIBERS,
        subscribers,
    };
};

export const addCommunitySubscribers = (subscribers) => {
    return {
        type: ADD_COMMUNITY_SUBSCRIBERS,
        subscribers,
    };
};

export const setCommunitySubscriber = (subscriber) => {
    return {
        type: SET_COMMUNITY_SUBSCRIBER,
        subscriber,
    };
};

export const setSubscriberIsLoading = (subscriberIsLoading) => {
    return {
        type: SET_SUBSCRIBER_IS_LOADING,
        subscriberIsLoading,
    };
};

export const setCommunityUtms = (utms) => {
    return {
        type: SET_COMMUNITY_UTMS,
        utms,
    };
};

export const setCurrentCommunityLoading = (isLoading) => {
    return {
        type: SET_CURRENT_COMMUNITY_LOADING,
        isCurrentCommunityLoading: isLoading,
    };
};

export const setCommunityUtmsLoading = (isLoading) => {
    return {
        type: SET_COMMUNIY_UTMS_LOADING,
        isCommunityUtmsLoading: isLoading,
    };
};

export const getSubscriberInfo = (data) => (dispatch) => {
    dispatch(setSubscriberIsLoading(true));
    return subscriberAPI.getInfo(data).then((data) => {
        if (data) {
            dispatch(setCommunitySubscriber(data));
        } else {
            dispatch(
                setCommunitySubscriber(defaultState.currentCommunity.subscriber)
            );
        }
        dispatch(setSubscriberIsLoading(false));
        return data;
    });
};

export const getCommunities = (data) => (dispatch, getState) => {
    const { communities } = getState().communities;
    dispatch(setIsCommunitiesLoading(true));
    return communityAPI.getCommunity2(data).then((data) => {
        dispatch(setIsCommunitiesFirstLoading(false));
        if (data.page >= 2) {
            dispatch(
                setCommunities({
                    ...data,
                    communities: [
                        ...communities.communities,
                        ...data.communities,
                    ],
                })
            );
        } else {
            dispatch(
                setCommunities({
                    ...data,
                    communities: data.communities,
                })
            );
        }
        dispatch(setIsCommunitiesLoading(false));
        return data;
    });
};

export const getCommunitiesAll = (data) => (dispatch) => {
    return communityAPI.getCommunity(data).then((data) => {
        dispatch(setCommunitiesAll(data));
        return data;
    });
};

export const getUpdatedCommunities = () => (dispatch) => {
    return userAPI.updateCommunities().then(() => {
        return dispatch(getCommunities());
    });
};

export const getCurrentCommunity = (community_id) => (dispatch, getState) => {
    let { isCurrentCommunityLoading } = getState().communities;
    if (isCurrentCommunityLoading) return;

    dispatch(setCurrentCommunityLoading(true));
    return communityAPI.getCurrentCommunity(community_id).then((data) => {
        if (data) {
            dispatch(setCurrentCommunity(data));
        } else {
            dispatch(setCurrentCommunity({}));
        }
        dispatch(setCurrentCommunityLoading(false));
    });
};

export const getSubscribersGroups = (community_id) => (dispatch, getState) => {
    const { sortBy, searchText } = getState().communities.currentCommunity;

    dispatch(setIsGroupsLoading(true));
    communityAPI.getSubscribersGroups(community_id).then((data) => {
        dispatch(setCurrentCommunityGroups(data));
        dispatch(setGroupsSorting(sortBy, searchText));
        dispatch(setIsGroupsLoading(false));
        dispatch(setIsGroupsFirstLoading(false));
    });
};

export const getSubscribersGroups2 = (data) => (dispatch, getState) => {
    const { sortBy, searchText, groups2 } =
        getState().communities.currentCommunity;
    dispatch(setIsGroupsLoading(true));
    groupAPI.getSubscribersGroups2(data).then((data) => {
        if (data.page > 1) {
            dispatch(
                setCurrentCommunityGroups2({
                    ...data,
                    items: [...groups2.items, ...data.items],
                })
            );
        } else {
            dispatch(setCurrentCommunityGroups2(data));
        }

        dispatch(setGroupsSorting(sortBy, searchText));
        dispatch(setIsGroupsLoading(false));
        dispatch(setIsGroupsFirstLoading2(false));
    });
};

export const getOkSubscribersGroups =
    (community_id, subscriber_uid) => (dispatch) => {
        communityAPI
            .getOkSubscribersGroups(community_id, subscriber_uid)
            .then((data) => {
                dispatch(setCurrentCommunityGroups(data));
            });
    };

export const addSubscriber =
    (
        user_uid,
        group_uid,
        community_uid,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) =>
    (dispatch) => {
        return communityAPI
            .addSubscriber(
                user_uid,
                group_uid,
                source,
                utm,
                utm_source,
                utm_medium,
                utm_campaign,
                utm_content,
                utm_term
            )
            .then((response) => {
                dispatch(getOkSubscribersGroups(community_uid, user_uid));
            });
    };

export const removeSubscriber =
    (
        user_uid,
        group_uid,
        community_uid,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) =>
    (dispatch) => {
        return communityAPI
            .removeSubscriber(
                user_uid,
                group_uid,
                source,
                utm,
                utm_source,
                utm_medium,
                utm_campaign,
                utm_content,
                utm_term
            )
            .then((response) => {
                dispatch(getOkSubscribersGroups(community_uid, user_uid));
            });
    };

export const unsubscribeFromAll =
    (
        user_uid,
        community_uid,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) =>
    (dispatch) => {
        communityAPI
            .unsubscribeFromAll(
                user_uid,
                community_uid,
                source,
                utm,
                utm_source,
                utm_medium,
                utm_campaign,
                utm_content,
                utm_term
            )
            .then((response) => {
                dispatch(getOkSubscribersGroups(community_uid, user_uid));
            });
    };

export const connectCommunity = (community_id, token) => (dispatch) => {
    return communityAPI.connectCommunity(community_id, token);
};

export const disconnectCommunity = (community_id, token) => (dispatch) => {
    return communityAPI.disconnectCommunity(community_id, token);
};

export const getCommunityStatus = (community_uid) => (dispatch) => {
    return communityAPI.getCommunityStatus(community_uid);
};

export const getDisconnectUrl = (community_uid) => (dispatch) => {
    return communityAPI.getDisconnectUrl(community_uid);
};

export const connectFromArchive = (community_id) => (dispatch) => {
    return communityAPI.connectFromArchive(community_id);
};

export const createGroup = (data, community_id) => (dispatch) => {
    dispatch(setGroupInfoIsLoading(true));
    return groupAPI.createGroup(data).then((response) => {
        dispatch(getSubscribersGroups2({ community_id }));
        dispatch(setGroupInfoIsLoading(false));
    });
};

export const editGroup = (data, community_id) => (dispatch) => {
    dispatch(setGroupInfoIsLoading(true));
    return groupAPI.editGroup(data).then((response) => {
        dispatch(getSubscribersGroups2({ community_id }));
        dispatch(setGroupInfoIsLoading(false));
    });
};

export const getGroupInfo = (group_id) => (dispatch) => {
    dispatch(setGroupInfoIsLoading(true));
    return groupAPI.getGroupInfo(group_id).then((data) => {
        dispatch(setGroupInfoIsLoading(false));
        return dispatch(setGroupInfo(data));
    });
};

export const getGroupOkinfo = (group_id, subscriber_uid) => (dispatch) => {
    return groupAPI.getGroupOkinfo(group_id, subscriber_uid).then((data) => {
        dispatch(setGroupOkinfo(data));
        return data;
    });
};

export const removeGroup = (group_id, community_id) => (dispatch, getState) => {
    const { sortBy, searchText } = getState().communities.currentCommunity;
    return groupAPI.removeGroup(group_id).then((data) => {
        dispatch(
            getSubscribersGroups2({
                community_id,
                search: searchText,
                page: 1,
                type:
                    sortBy === true
                        ? "visible"
                        : sortBy === false
                        ? "unvisible"
                        : null,
            })
        );
        return data;
    });
};

export const checkSubscriber = (group_id, subscriber_uid) => (dispatch) => {
    return groupAPI.checkSubscriber(group_id, subscriber_uid);
};

export const changeAutosubscribe =
    (group_id, auto_subscribe, community_id) => (dispatch, getState) => {
        const { sortBy, searchText } = getState().communities.currentCommunity;
        dispatch(setIsGroupsLoading(true));
        return groupAPI
            .changeAutosubscribe(group_id, auto_subscribe)
            .then(() => {
                dispatch(
                    getSubscribersGroups2({
                        community_id,
                        search: searchText,
                        page: 1,
                        type:
                            sortBy === true
                                ? "visible"
                                : sortBy === false
                                ? "unvisible"
                                : null,
                    })
                );
            });
    };

export const getSubscribers = (community_id, filters) => (dispatch) => {
    return communityAPI.getSubscribers(community_id, filters).then((data) => {
        if (data.page > 1) {
            dispatch(addCommunitySubscribers(data.subscribers));
        } else {
            dispatch(setCommunitySubscribers(data.subscribers));
        }
        return data;
    });
};

export const removeSubscriberFromGroup =
    ({ subscriber_id, community_id, group_id }) =>
    (dispatch) => {
        return subscriberAPI
            .removeFromGroup({ group_id, subscriber_id })
            .then((data) => {
                return dispatch(
                    getSubscriberInfo({ subscriber_id, community_id })
                );
            });
    };

export const addSubscriberToGroups = (data) => (dispatch) => {
    return subscriberAPI.addToGroups(data);
};

export const getCommunityUtms = (data) => (dispatch, getState) => {
    const { isCommunityUtmsLoading } = getState().communities;

    if (isCommunityUtmsLoading) return;

    dispatch(setCommunityUtmsLoading(true));
    return utmAPI.getList(data).then((data) => {
        dispatch(setCommunityUtmsLoading(false));
        return dispatch(setCommunityUtms(data));
    });
};

export const unsubscribeFromAll2 = (data) => (dispatch) => {
    dispatch(setSubscriberIsLoading(true));
    return subscriberAPI
        .unsubscribeFromAll2(data)
        .then(() => dispatch(setSubscriberIsLoading(false)));
};

export const addSubscriberUtm = (data) => (dispatch) => {
    const { subscriber_id, community_id } = data;
    return subscriberAPI.addUtm(data).then(() => {
        return dispatch(getSubscriberInfo({ subscriber_id, community_id }));
    });
};

export const deleteSubscriberUtm = (data) => (dispatch) => {
    const { subscriber_id, community_id } = data;
    return subscriberAPI.deleteUtm(data).then(() => {
        return dispatch(getSubscriberInfo({ subscriber_id, community_id }));
    });
};

export const addSubscriberMark = (data) => (dispatch) => {
    const { subscriber_id, community_id } = data;
    return subscriberAPI.addMark(data).then(() => {
        return dispatch(getSubscriberInfo({ subscriber_id, community_id }));
    });
};

export const deleteSubscriberMark = (data) => (dispatch) => {
    const { subscriber_id, community_id } = data;
    return subscriberAPI.deleteMark(data).then(() => {
        return dispatch(getSubscriberInfo({ subscriber_id, community_id }));
    });
};

export const importSubscribers = (data) => (dispatch) => {
    return subscribersAPI.import(data);
};

export const exportSubscribers = (data) => (dispatch) => {
    return subscribersAPI.export(data);
};

export const removeSubscribersFromGroups = (data) => (dispatch) => {
    return subscribersAPI.removeFromGroups(data);
};

export const addSubscribersToGroups = (data) => (dispatch) => {
    return subscribersAPI.addToGroups(data);
};

export default communitiesReducer;
