import React, { useEffect, useRef, useState } from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import Tabs from "../../generic/Tabs/Tabs";
import styles from "./ImportSubscribersPopup.module.sass";
import ActiveButton from "../../generic/ActiveButton/ActiveButton";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../generic/Input/Input";
import Progressbar from "../../generic/Progressbar/Progressbar";
import { useParams } from "react-router";
import {
	getCommunityUtms,
	getSubscribersGroups,
	importSubscribers,
} from "../../../redux/reducers/communitiesReducer";
import Button from "../../generic/Button/Button";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { serialize } from "object-to-formdata";
import * as Yup from "yup";
import CustomScrollbar from "../../generic/CustomScrollbar/CustomScrollbar";
import generateId from "../../../utils/generateId";
import { utilsAPI, subscribersAPI } from "../../../api/api";
import { getNoticess } from "../../../redux/reducers/noticesReducer";
import declOfNum from "../../../utils/declOfNum";
import Icon from "../../generic/Icon/Icon";
import PopupFullsizeLayout from "../PopupFullsizeLayout/PopupFullsizeLayout";
import Preloader from "../../Preloader/Preloader";
import ReactTooltip from "react-tooltip";

const ImportSubscribersPopup = ({
	isOpen = false,
	onOpen,
	onClose,
	afterSubmit,
}) => {
	const dispatch = useDispatch();
	const { id: community_id } = useParams();
	const { groups, utms } = useSelector(
		(state) => state.communities.currentCommunity
	);
	const { tooltips = {} } = useSelector(
		(state) => state.tooltips
	);

	const inputFileRef = useRef(null);
	const [progress, setProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(getSubscribersGroups(community_id));
		dispatch(getCommunityUtms({ community_id }));
	}, [dispatch, community_id]);

	useEffect(() => {
		setProgress(0);
	}, [isOpen]);

	// Изменение файла
	const handleFileChange = (formik) => (e) => {
		const file = e.currentTarget.files[0];
		const serializedData = serialize({ file });
		if (file) {
			formik.setFieldValue("file", file);
			subscribersAPI
				.getImportfileCount(serializedData)
				.then(({ count }) => {
					formik.setFieldValue("usersCount", count);
				});
		} else {
			formik.setFieldValue("file", null);
			formik.setFieldValue("usersCount", null);
		}
	};

	// Удаление файла
	const handleFileRemove = (formik) => {
		formik.setFieldValue("file", null);
		formik.setFieldValue("usersCount", null);
		inputFileRef.current.value = "";
	};

	// Сабмит формы
	const handleSubmit = (values, { setFieldValue }) => {
		const key = generateId();
		values = { ...values, community_id, key };
		const formData = serialize(values);

		setIsLoading(true);
		dispatch(importSubscribers(formData)).then(() => {
			if (typeof afterSubmit === "function") afterSubmit();
		});

		let progressChecker = setInterval(() => {
			utilsAPI.getPercent({ key }).then(({ percent }) => {
				setProgress(percent);
				if (percent > 1) setIsLoading(false);
				if (percent === 100) {
					clearInterval(progressChecker);
					setTimeout(() => {
						onClose();
						clearAllBodyScrollLocks();
					}, 500);
					dispatch(getNoticess());
				}
			});
		}, 500);
	};

	const PopupLayout = (props) => {
		if (window.matchMedia("(max-width: 767px)").matches) {
			return <PopupFullsizeLayout {...props} />;
		} else {
			return <PopupMainLayout {...props} />;
		}
	};

	const initialValues = {
		file: null,
		groups_id: [],
		add_again: false,
		send_auto_messages: false,
		mark: "",
		type: "FILE",
		usersCount: null,
	};

	return (
		<PopupLayout
			title="Импорт подписчиков"
			subtitle="Внимание! В список подписчиков будут добавлены только те пользователи, которые разрешили принимать сообщения от Вашего сообщества."
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			className={styles.popup}
		>
			{isLoading ? <Preloader /> : null}
			<Tabs defaultTab="tab1" style={{ position: "relative" }}>
				{/* Загрузить файлом */}
				<div tab="tab1" title="Загрузка файлом">
					<Formik
						enableReinitialize
						initialValues={initialValues}
						onSubmit={handleSubmit}
						validationSchema={Yup.object().shape({
							file: Yup.mixed().required("Обязательное поле"),
							groups_id: Yup.array().required(
								"Обязательное поле"
							),
						})}
					>
						{(formik) => (
							<Form className="form">
								<div className="form__group_item">
									<div className="block_label">
										<span>Вставьте ссылки или ID пользователей
											Одноклассники (каждая запись с новой
											строки) {tooltips.SUBSCRIBER_FILTER_INSERT_USER_IDS ? <span
												className="tooltip_trigger block_label__tooltip"
												data-effect="solid"
												data-for={"SUBSCRIBER_FILTER_INSERT_USER_IDS"}
												data-tip
											></span> : null}</span>
										{tooltips.SUBSCRIBER_FILTER_INSERT_USER_IDS ? <ReactTooltip className="tooltip" id={"SUBSCRIBER_FILTER_INSERT_USER_IDS"}>
											<div dangerouslySetInnerHTML={{ __html: tooltips.SUBSCRIBER_FILTER_INSERT_USER_IDS }}></div>
										</ReactTooltip> : null}
									</div>
									<ActiveButton
										tag="label"
										icon="download"
										iconStyle={{ top: -2 }}
										color="grey"
										style={{ position: "relative" }}
									>
										{formik.values.file &&
											formik.values.file.name
											? formik.values.file.name
											: "Выбрать файл"}
										<input
											accept=".txt"
											type="file"
											name="file"
											className="hidden_input"
											onChange={handleFileChange(formik)}
											ref={inputFileRef}
										/>

										{formik.errors.file ? (
											<div className="input_error">
												{formik.errors.file}
											</div>
										) : null}
									</ActiveButton>
									{formik.values.usersCount != null ? (
										<div className={styles.import_amount}>
											<div
												className={
													styles.import_amount__label
												}
											>
												Пользователи для группы
												Одноклассники
											</div>
											<div
												className={
													styles.import_amount__value
												}
											>
												{formik.values.usersCount}{" "}
												{declOfNum(
													formik.values.usersCount,
													[
														"запись",
														"записи",
														"записей",
													]
												)}
											</div>
											<div
												className={`link ${styles.import_amount__remove}`}
												onClick={() =>
													handleFileRemove(formik)
												}
											>
												<Icon icon="trash" />
											</div>
										</div>
									) : null}
								</div>
								<div className="form__group_item">
									<Field
										name="groups_id"
										type="select"
										label="Группа подписчиков"
										placeholder="Выбрать"
										options={groups.map((group) => ({
											label: group.name,
											value: group.id,
										}))}
										isMulti
										component={Input}
									/>
								</div>
								<div className="form__group_item">
									<Field
										name="add_again"
										label="Добавить заново"
										tooltip={tooltips.SUBSCRIBER_FILTER_ADD_AGAIN}
										type="checkbox"
										size="large"
										component={Input}
									></Field>
								</div>
								<div className="form__group_item">
									<Field
										name="send_auto_messages"
										label="Отправить автосообщение"
										tooltip={tooltips.SUBSCRIBER_FILTER_SEND_AUTOMESSAGE}
										type="checkbox"
										size="large"
										component={Input}
									></Field>
								</div>
								<div className="form__group_item">
									<Field
										name="mark"
										label="Метка"
										type="select"
										options={utms.map((utm) => ({
											label: utm.name,
											value: utm.id,
										}))}
										placeholder="Выберите метку"
										component={Input}
										tooltip={tooltips.SUBSCRIBER_IMPORT_UTM}
									></Field>
								</div>
								<div className="form__group_item">
									<Progressbar progress={progress} />
								</div>
								<div className="form__group_item">
									<div
										className="button_list button_list--fullwidth_md"
										style={{ justifyContent: "flex-end" }}
									>
										<div
											className="grey_link last_on_md"
											onClick={(e) => {
												onClose();
												clearAllBodyScrollLocks();
											}}
										>
											Закрыть
										</div>
										<Button
											icon="play-2"
											onClick={formik.submitForm}
										>
											Начать
										</Button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>

				{/* Добавить ссылки */}
				<div tab="tab2" title="Добавить ссылки">
					<Formik
						initialValues={{
							links: "",
							groups_id: [],
							add_again: false,
							send_auto_messages: false,
							mark: "",
							type: "LINKS",
						}}
						onSubmit={handleSubmit}
						validationSchema={Yup.object().shape({
							links: Yup.string().required("Обязательное поле"),
							groups_id: Yup.array().required(
								"Обязательное поле"
							),
						})}
					>
						{(formik) => (
							<Form className="form">
								<div className="form__group_item">
									<div
										className={`input_container ${formik.errors.links ? "error" : ""
											}`}
									>
										<div className="block_label">
											<span>Вставьте ссылки или ID пользователей
												Одноклассники (каждая запись с новой
												строки) {tooltips.SUBSCRIBER_FILTER_INSERT_USER_IDS ? <span
													className="tooltip_trigger block_label__tooltip"
													data-effect="solid"
													data-for={"SUBSCRIBER_FILTER_INSERT_USER_IDS2"}
													data-tip
												></span> : null}</span>
											{tooltips.SUBSCRIBER_FILTER_INSERT_USER_IDS ? <ReactTooltip className="tooltip" id={"SUBSCRIBER_FILTER_INSERT_USER_IDS2"}>
												<div dangerouslySetInnerHTML={{ __html: tooltips.SUBSCRIBER_FILTER_INSERT_USER_IDS }}></div>
											</ReactTooltip> : null}
										</div>
										<div className="input">
											<CustomScrollbar
												style={{ height: 72 }}
											>
												<div
													style={{
														minHeight: "100%",
													}}
													contentEditable
													placeholder="Ссылки или ID"
													onInput={(e) =>
														formik.setFieldValue(
															"links",
															e.currentTarget
																.innerText
														)
													}
												></div>
											</CustomScrollbar>
										</div>
										{formik.errors.links ? (
											<div className="input_error">
												{formik.errors.links}
											</div>
										) : null}
									</div>
								</div>
								<div className="form__group_item">
									<Field
										name="groups_id"
										type="select"
										label="Группа подписчиков"
										placeholder="Выбрать"
										options={groups.map((group) => ({
											label: group.name,
											value: group.id,
										}))}
										isMulti
										component={Input}
										tooltip={tooltips.SUBSCRIBER_IMPORT_SUBSCRIBER_GROUP}
									/>
								</div>
								<div className="form__group_item">
									<Field
										name="add_again"
										label="Добавить заново"
										type="checkbox"
										size="large"
										component={Input}
										tooltip={tooltips.SUBSCRIBER_FILTER_ADD_AGAIN}
									></Field>
								</div>
								<div className="form__group_item">
									<Field
										name="send_auto_messages"
										label="Отправить автосообщение"
										type="checkbox"
										size="large"
										component={Input}
										tooltip={tooltips.SUBSCRIBER_FILTER_SEND_AUTOMESSAGE}
									></Field>
								</div>
								<div className="form__group_item">
									<Field
										name="mark"
										label="Метка"
										type="select"
										options={utms.map((utm) => ({
											label: utm.name,
											value: utm.id,
										}))}
										placeholder="Выберите метку"
										component={Input}
										tooltip={tooltips.SUBSCRIBER_IMPORT_UTM}
									></Field>
								</div>
								<div className="form__group_item">
									<Progressbar progress={progress} />
								</div>
								<div className="form__group_item">
									<div
										className="button_list button_list--fullwidth_md"
										style={{ justifyContent: "flex-end" }}
									>
										<div
											className="grey_link last_on_md"
											onClick={(e) => {
												onClose();
												clearAllBodyScrollLocks();
											}}
										>
											Закрыть
										</div>
										<Button
											icon="play-2"
											onClick={formik.submitForm}
										>
											Начать
										</Button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</Tabs>
		</PopupLayout>
	);
};

export default ImportSubscribersPopup;
