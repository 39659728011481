import React from "react";
import Icon from "../Icon/Icon";
import styles from "./SuccessText.module.sass";

const SuccessText = (props) => {
	const { children } = props;

	return (
		<div className={`${styles.success}`}>
			<div className={styles.success__icon}>
				<Icon icon="tick" />
			</div>
			<div className={styles.success__text}>{children}</div>
		</div>
	);
};

export default SuccessText;
