import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import withCommunityRedirect from "../../../hoc/withCommunityRedirect";
import AccessUserListContainer from "../../../components/AccessUserList/AccessUserListContainer";
import { withAccessRedirect } from "../../../hoc/withAccessRedirect";

const Accesses = (props) => {
	const page = {
		crumbs: [
			{ name: "Личный кабинет", path: "/" },
			{ name: "Сообщества", path: "/community" },
			{ name: "Доступы", path: "/" },
		],
	};

	return (
		<>
			<div className="page_head">
				<Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>
			</div>

			<div>
				<AccessUserListContainer />
			</div>
		</>
	);
};

class AccessesContainer extends React.Component {
	componentDidMount() {}

	render() {
		return <Accesses {...this.props} />;
	}
}

const mstp = (store) => {
	return {};
};

export default compose(
	connect(mstp, {}),
	withAccessRedirect,
	withCommunityRedirect,
	withAuthRedirect
)(AccessesContainer);
