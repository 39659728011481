import React from "react";
import UserProfile from "../UserProfile/UserProfile";
import styles from "./Referral.module.sass";

const Referral = (props) => {
	const { 
		// sum, 
		userInfo 
	} = props;

	return (
		<div className={styles.referral}>
			<div className={styles.referral__head}>
				<UserProfile
					image={userInfo.image}
					name={userInfo.fullName}
					note={userInfo.register_date}
				></UserProfile>
			</div>
			{/* {sum != null ? (
				<div className={styles.referral__right}>
					<div className={styles.referral__note}>
						<span className={styles.referral__note_label}>
							Заработано:
						</span>{" "}
						<span className={styles.referral__note_value}>
							{sum}
						</span>
					</div>
				</div>
			) : null} */}
		</div>
	);
};

export default Referral;
