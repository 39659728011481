import React, { useState } from "react";
import styles from "./ReferralInfo.module.sass";
import Icon from "../generic/Icon/Icon";
import SuccessText from "../generic/SuccessText/SuccessText";
import copyToClipboard from "../../utils/copyToClipboard";

const ReferralInfo = (props) => {
	const {
		// sum = "100",
		partnersAmount = "",
		link = "",
		className = "",
	} = props;
	const [isCopied, setIsCopied] = useState(false);

	// Копирование ссылки
	const onCopy = (e) => {
		e.preventDefault();
		copyToClipboard(link);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 3000);
	};

	return (
		<div className={`${styles.referral_info} ${className}`}>
			<div className={styles.referral_info__head}>
				Ваша ссылка для привлечения партнеров:
			</div>
			<div className={styles.referral_info__right}>
				{partnersAmount != null ? (
					<div className={styles.referral_info__note}>
						<span className={styles.referral_info__note_label}>
							Партнёров:
						</span>{" "}
						<span className={styles.referral_info__note_value}>
							{partnersAmount}
						</span>
					</div>
				) : null}
				{/* {sum != null ? (
					<div className={styles.referral_info__note}>
						<span className={styles.referral_info__note_label}>
							Всего заработано:
						</span>{" "}
						<span className={styles.referral_info__note_value}>
							{sum}
						</span>
					</div>
				) : null} */}
			</div>
			{link != null ? (
				<div className={styles.referral_info__extra}>
					<div className={styles.referral_info__link}>
						<div className={`${styles.referral_info__link_value} ${isCopied ? styles.active : ""}`}>
							{link}
							<div
								className={`tooltip tooltip--sm right_tooltip ${
									styles.referral_info__link_tooltip
								} ${isCopied ? "" : "hidden"}`}
							>
								<SuccessText>Скопировано</SuccessText>
							</div>
						</div>
						<a
							href="/"
							onClick={onCopy}
							className={`link`}
						>
							<Icon icon="copy" /> Скопировать ссылку
						</a>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default ReferralInfo;
