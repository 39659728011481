import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    createGroup,
    editGroup,
} from "../../../redux/reducers/communitiesReducer";
import { getBotsList } from "../../../redux/reducers/botsReducer";
import CreateGroupPopup from "./CreateGroupPopup";

class CreateGroupPopupContainer extends Component {
    render() {
        return <CreateGroupPopup {...this.props} />;
    }

    componentDidMount() {
        const community_id = this.props.match.params.id;
        this.props.getBotsList({ community_id });
    }
}

const mapStateToProps = (state) => ({
    groupData: state.communities.currentCommunity.groupData,
    groups: state.communities.currentCommunity.groups,
    isLoading: state.communities.currentCommunity.groupInfoIsLoading,
    community: state.communities.currentCommunity.data,
    tooltips: state.tooltips.tooltips,
    bots: state.bots.botsList,
});

export default connect(mapStateToProps, {
    createGroup,
    editGroup,
    getBotsList,
})(withRouter(CreateGroupPopupContainer));
