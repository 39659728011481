import React from "react";
import Modal from "react-modal";
import Icon from "../../generic/Icon/Icon";
import styles from "./PopupFullsizeLayout.module.sass";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import generateId from "../../../utils/generateId";
import { isSafari } from "react-device-detect";
import CustomScrollbar from "../../generic/CustomScrollbar/CustomScrollbar";

class PopupFullsizeLayout extends React.Component {
	constructor(props) {
		super(props);
		this.generatedId = generateId();
		this.popupContentEl = null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isOpen !== this.isOpen) {
			if (!this.isOpen) {
				clearAllBodyScrollLocks();
			}
		}
	}

	closeModal = () => {
		this.onClose();
		clearAllBodyScrollLocks();

		let menu = document.querySelector("#mobileMenu");
		if (menu && menu.classList.contains("active")) {
			setTimeout(() => {
				disableBodyScroll(menu);
			}, 100);
		}
	};

	onOpen = () => {
		clearAllBodyScrollLocks();
		setTimeout(() => {
			disableBodyScroll(this.popupContentEl);
		}, 100);
	};

	render() {
		const { title, titleIcon, children, isOpen, onClose } = this.props;

		this.onClose = onClose;
		this.isOpen = isOpen;

		return (
			<div>
				<Modal
					ariaHideApp={false}
					isOpen={this.isOpen}
					onAfterOpen={this.onOpen}
					onRequestClose={this.closeModal}
					className={`${styles.popup} ${
						isSafari ? styles["safari"] : ""
					}`}
					overlayClassName={styles.popup__overlay}
					contentRef={(node) => (this.popupContentEl = node)}
					id={this.generatedId}
				>
					<CustomScrollbar className={styles.popup__inner}>
						<div className="container">
							<div className="row justify-content-around">
								<div className="col-xl-10">
									<div className={styles.popup__head}>
										<div className={styles.popup__title}>
											{titleIcon ? (
												<Icon
													icon={titleIcon}
													className={
														styles.popup__title_icon
													}
												></Icon>
											) : null}
											{title}
											<div
												onClick={this.closeModal}
												className={styles.popup__cross}
											>
												<Icon
													icon="cross"
													className={
														styles.popup__cross_icon
													}
												></Icon>
											</div>
										</div>
									</div>

									{children}
								</div>
							</div>
						</div>
					</CustomScrollbar>
				</Modal>
			</div>
		);
	}
}

export default PopupFullsizeLayout;
