import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
	getStaffList,
	setSearchedStaff,
	addStaff,
	editStaff,
	deleteStaff,
} from "../../redux/reducers/staffReducer";
import AccessUserList from "./AccessUserList";

class AccessUserListContainer extends React.Component {
	componentDidMount() {
		this.props.getStaffList(this.props.match.params.id);
	}

	render() {
		return <AccessUserList {...this.props}></AccessUserList>;
	}
}

const mstp = (state) => {
	return {
		userData: state.auth.userData,
		users: state.staff.staffList,
		searchedUsers: state.staff.searchedStaffList,
		searchText: state.staff.searchText,
		isLoading: state.staff.isStaffLoading,
		communityName: state.communities.currentCommunity.data.name,
	};
};

export default compose(
	connect(mstp, {
		getStaffList,
		setSearchedStaff,
		addStaff,
		editStaff,
		deleteStaff,
	}),
	withRouter
)(AccessUserListContainer);
