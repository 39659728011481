import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Button from "../../generic/Button/Button";
import Input from "../../generic/Input/Input";
import Preloader from "../../Preloader/Preloader";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import * as Yup from "yup";
import { useParams, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    addSubscriberMark,
} from "../../../redux/reducers/communitiesReducer";

const AddSubscriberToGroupPopup = ({
    isOpen,
    onOpen,
    onClose,
    addedMarks = [],
}) => {
    const dispatch = useDispatch();
    const { subscriber_id, id: community_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { utms } = useSelector((state) => state.communities.currentCommunity);

    // Валидация формы
    const validationSchema = Yup.object().shape({
        mark: Yup.string().required("Обязательное поле"),
    });

    let initialValues = {
        mark: "",
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        dispatch(
            addSubscriberMark({ ...data, subscriber_id, community_id })
        ).then(() => {
            setIsLoading(false);
            onClose();
            clearAllBodyScrollLocks();
        });
    };

    return (
        <PopupMainLayout
            title="Добавить метку"
            subtitle="Выберите метку, которую хотите присовить пользователю"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            style={{ content: { overflow: "visible" } }}
        >
            {isLoading ? <Preloader /> : null}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form className="form">
                        <div className="form__group_item">
                            <Field
                                name="mark"
                                label="Метка"
                                type="select"
                                placeholder="Выберите метку"
                                options={utms
                                    .filter((utm) =>
                                        !addedMarks.find((mark) => mark.utm_id === utm.id)
                                    )
                                    .map((utm) => ({
                                        label: utm.name,
                                        value: utm.id,
                                    }))}
                                component={Input}
                            />
                        </div>
                        <div className="form__group_item">
                            <div
                                className="button_list button_list--fullwidth_md"
                                style={{ justifyContent: "flex-end" }}
                            >
                                <div
                                    className="grey_link last_on_md"
                                    onClick={(e) => {
                                        onClose();
                                        clearAllBodyScrollLocks();
                                    }}
                                >
                                    Отменить
                                </div>
                                <Button icon="save" onClick={formik.submitForm}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </PopupMainLayout>
    );
};

export default withRouter(AddSubscriberToGroupPopup);
