import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import SubscriberGroupList from "../../components/SubscriberGroupList/SubscriberGroupList";
import {
	getSubscribersGroups2,
	setGroupsSorting,
	removeGroup,
	getGroupInfo,
	changeAutosubscribe,
} from "../../redux/reducers/communitiesReducer";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import withCommunityRedirect from "../../hoc/withCommunityRedirect";
import { withAccessRedirect } from "../../hoc/withAccessRedirect";

class subscriberGroups extends React.Component {
	componentDidMount() {
		this.props.getSubscribersGroups2({community_id: this.props.match.params.id});
	}

	render() {
		const { match, ...rest } = this.props;
		const communityId = match.params.id;

		const page = {
			title: "Группы подписчиков",
			crumbs: [
				{ name: "Сообщества", path: "/community" },
				{
					name: "Группы подписчиков",
					path: `/community/${communityId}/newsletters`,
				},
			],
		};

		return (
			<>
				<div className="page_head">
					<Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>
				</div>

				<SubscriberGroupList {...rest} />
			</>
		);
	}
}

const mstp = (store) => {
	return {
		groups: store.communities.currentCommunity.groups2,
		sortedGroups: store.communities.currentCommunity.sortedGroups,
		sortBy: store.communities.currentCommunity.sortBy,
		searchText: store.communities.currentCommunity.searchText,
		isLoading: store.communities.currentCommunity.isLoading,
		isFirstLoading: store.communities.currentCommunity.isFirstLoading2
	};
};

export default compose(
	connect(mstp, {
		getSubscribersGroups2,
		setGroupsSorting,
		removeGroup,
		getGroupInfo,
		changeAutosubscribe,
	}),
	withRouter,
	withAccessRedirect,
	withCommunityRedirect,
	withAuthRedirect
)(subscriberGroups);
