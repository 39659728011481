import React from "react";
import styles from "./Progressbar.module.sass";

const Progressbar = ({ progress = 0 }) => {
    return (
        <div className={styles.bar}>
            <div
                className={styles.bar__progress}
                style={{ width: `${progress}%` }}
            >
                <div className={styles.bar__value}>{`${progress}%`}</div>
            </div>
        </div>
    );
};

export default Progressbar;
