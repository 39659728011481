import React from "react";
import { Redirect } from "react-router-dom";
import Cookie from "js-cookie";

export const withAuthRedirect = (Component) => {
	class RedirectComponent extends React.Component {
		render() {
			if (!Cookie.get("token") && window.location.pathname !== "/") {
				return <Redirect to="/" />
			} else {
				return <Component {...this.props}></Component>
			}

		}
	}

	return RedirectComponent;
};
