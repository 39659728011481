import MainLayout from "./layouts/MainLayout";
import ControlPanelLayout from "./layouts/ControlPanelLayout";

import index from "./screens/index/index";
import community from "./screens/community/community";
import newsletters from "./screens/newsletters/newsletters";
import NewsletterFrame from "./components/NewsletterFrame/NewsletterFrame";
import subscriberGroups from "./screens/subscriber-groups/subscriber-groups";
import statistics from "./screens/statistics/statistics";
import referrals from "./screens/cabinet/referrals/referrals";
import accesses from "./screens/cabinet/accesses/accesses";
import webhooks from "./screens/webhooks/webhooks";
import subscribers from "./screens/subscribers/subscribers";
import subscriber from "./screens/subscriber/subscriber";
import bots from "./screens/bots/bots";
import React from "react";

export default [
    {
        path: "/",
        Component: index,
    },
    {
        path: "/community/",
        Component: community,
        Layout: MainLayout,
    },
    {
        path: "/community/:id/",
        Component: newsletters,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR"],
    },
    {
        path: "/community/:id/subscriber-groups/",
        Component: subscriberGroups,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR"],
    },
    {
        path: "/community/:id/statistics/",
        Component: statistics,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR", "OBSERVER"],
    },
    {
        path: "/frame/newsletters/",
        Component: NewsletterFrame,
    },
    {
        path: "/cabinet/referrals/",
        Component: referrals,
        Layout: MainLayout,
    },
    {
        path: "/cabinet/community/:id/accesses/",
        Component: accesses,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER"],
    },
    {
        path: "/cabinet/community/:id/webhooks/",
        Component: webhooks,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR"],
    },
    {
        path: "/cabinet/community/:id/subscribers/",
        Component: subscribers,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR"],
    },
    {
        path: "/cabinet/community/:id/subscriber/:subscriber_id/",
        Component: subscriber,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR"],
    },
    {
        path: "/community/:id/bots/",
        Component: bots,
        Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR", "OBSERVER"],
    },
    {
        path: "/community/:id/bots/:bot_id/editor",
        Component: React.lazy(() => import("./screens/bots/editor/editor")),
        // Layout: ControlPanelLayout,
        access: ["OWNER", "LEADER", "EDITOR", "OBSERVER"],
    },
];
