import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import Referral from "../../../components/Referral/Referral";
import ReferralInfo from "../../../components/ReferralInfo/ReferralInfo";
import styles from "./referrals.module.sass";
import { getReferralInfo } from "../../../redux/reducers/referralReducer";
import Preloader from "../../../components/Preloader/Preloader";

const Referrals = (props) => {
	const { referral = {} } = props;

	const page = {
		crumbs: [
			{ name: "Личный кабинет", path: "/community" },
			{ name: "Партнерская программа" },
		],
	};

	return (
		<>
			<div className="page_head2">
				<Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>
			</div>

			<div className={styles.referral_content}>
				{referral.referralInfoIsLoading ? <Preloader /> : null}

				<ReferralInfo
					link={referral.referralInfo.link}
					sum={referral.referralInfo.sum}
					partnersAmount={referral.referralInfo.partners.length}
					className={styles.referral_info}
				></ReferralInfo>

				{referral.referralInfo.partners.map((partner, index) => (
					<div className={styles.partner} key={index}>
						<Referral {...partner}></Referral>
					</div>
				))}
			</div>
		</>
	);
};

class ReferralsContainer extends React.Component {
	componentDidMount() {
		this.props.getReferralInfo();
	}

	render() {
		return <Referrals {...this.props} />;
	}
}

const mstp = (store) => {
	return {
		referral: store.referral,
	};
};

export default compose(
	connect(mstp, { getReferralInfo }),
	withAuthRedirect
)(ReferralsContainer);
