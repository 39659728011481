import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CommunityListContainer from "../../components/CommunityList/CommunityListContainer";
import Icon from "../../components/generic/Icon/Icon";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getUpdatedCommunities, setCommunitiesSorting } from "../../redux/reducers/communitiesReducer";

const Community = (props) => {
	const { getUpdatedCommunities, isCommunitiesLoading, setCommunitiesSorting, searchText } = props;

	const onUpdateList = () => {
		getUpdatedCommunities().then(() => {
			setCommunitiesSorting(
				{
					status: "all",
					type: "all",
				},
				searchText
			);
		});
	}

	const page = {
		title: "Сообщества",
		crumbs: [{ name: "Сообщества", path: "/community" }],
	};

	return (
		<>
			<div className="page_head">
				<Breadcrumbs crumbs={page.crumbs}>
					<div className={`link wrp fs14_md12 ${isCommunitiesLoading ? "disabled" : ""}`} onClick={onUpdateList}>
						<Icon icon="refresh2" />
						<span>Обновить список</span>
					</div>
				</Breadcrumbs>
			</div>
			<CommunityListContainer></CommunityListContainer>
		</>
	);
};

class CommunityContainer extends React.Component {
	render() {
		return <Community {...this.props} />;
	}
}

const mstp = (store) => {
	return {
		isCommunitiesLoading: store.communities.isLoading,
		searchText: store.communities.searchText
	};
};

export default compose(
	connect(mstp, { getUpdatedCommunities, setCommunitiesSorting }),
	withAuthRedirect
)(CommunityContainer);
