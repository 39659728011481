import React, { useRef, useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import Icon from "../Icon/Icon";
import "./Textarea.sass";
import TagList from "../../generic/TagList/TagList";
import getInputCursorPosition from "../../../utils/getInputCursorPosition";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import smileImg from "../../../assets/images/smile.png";
import crossImg from "../../../assets/images/cross.png";
import OutsideClickHandler from "react-outside-click-handler";

const Textarea = React.forwardRef((props, ref) => {
    let {
        add_template = false,
        document_clip = false,
        code_templates = false,
        codeTemplatesList = [],
        onClickCodeTemplate,
        templates = false,
        touched,
        className,
        height,
        preloadedFiles = [],
        onDocumentRemove,
        onImageRemove,
        onDocumentChange,
        onImageChange,
        onEmojiSelect = () => {},
        maxDocumentSize,
        maxImageSize,
        emoji_picker = false,
        error: fieldError,
        ...rest
    } = props;

    let documentsInputRef = useRef(null);
    let imagesInputRef = useRef(null);

    let documentsTooltipRef = useRef(null);
    let codeTooltipRef = useRef(null);

    let [preloadedFilesState, updatePreloadedFilesState] =
        useState(preloadedFiles);
    let [choosenFiles, updateChoosenFiles] = useState({
        images: [],
        documents: [],
        imagesFiles: [],
        documentsFiles: [],
    });
    let [error, setError] = useState("");
    let [cursorPosition, setCursorPosition] = useState({
        start: 0,
        end: 0,
    });
    let [emojiPickerActive, setEmojiPickerActive] = useState(false);
    let tooltipsPosition = "right";
    let tooltipsEvent = "";
    let tooltipsEventOff = "";

    useEffect(() => {
        setError(fieldError);
    }, [fieldError])

    useEffect(() => {
        updatePreloadedFilesState(preloadedFiles);
    }, [JSON.stringify(preloadedFiles)]); // eslint-disable-line react-hooks/exhaustive-deps

    if (window.matchMedia("(max-width: 768px)").matches) {
        tooltipsPosition = "left";
        tooltipsEvent = "click";
        tooltipsEventOff = "click";
    }

    const onDocumentChoose = (e) => {
        let files = { ...choosenFiles };

        if (
            documentsInputRef.current.files.length +
                files.documentsFiles.length +
                files.imagesFiles.length +
                preloadedFilesState.length >
            5
        ) {
            setError("Максимальное кол-во файлов: 5");
        } else {
            for (let i = 0; i < documentsInputRef.current.files.length; ++i) {
                let name = documentsInputRef.current.files.item(i).name;

                if (!files.documents.some((f) => f.text === name)) {
                    let item = documentsInputRef.current.files.item(i);
                    if (maxDocumentSize) {
                        if (maxDocumentSize > item.size / 1000) {
                            addDocument();
                        } else {
                            setError(
                                `Максимальный размер файла ${
                                    maxDocumentSize / 1000
                                } мб`
                            );
                            documentsInputRef.current.value = "";
                        }
                    } else {
                        addDocument();
                    }
                    function addDocument() {
                        setError("");
                        files.documentsFiles.push(item);
                        files.documents.push({ icon: "doc", text: name });
                    }
                }
            }

            updateChoosenFiles(files);
            onDocumentChange(files.documentsFiles, preloadedFilesState);
        }
    };

    const onImageChoose = (e) => {
        let files = { ...choosenFiles };

        if (
            imagesInputRef.current.files.length +
                files.documentsFiles.length +
                files.imagesFiles.length +
                preloadedFilesState.length >
            5
        ) {
            setError("Максимальное кол-во файлов: 5");
        } else {
            for (let i = 0; i < imagesInputRef.current.files.length; ++i) {
                let name = imagesInputRef.current.files.item(i).name;

                if (!files.images.some((f) => f.text === name)) {
                    let item = imagesInputRef.current.files.item(i);
                    if (maxImageSize) {
                        if (maxImageSize > item.size / 1000) {
                            addImage();
                        } else {
                            setError(
                                `Максимальный размер файла ${
                                    maxImageSize / 1000
                                } мб`
                            );
                            imagesInputRef.current.value = "";
                        }
                    } else {
                        addImage();
                    }
                    function addImage() {
                        setError("");
                        files.imagesFiles.push(item);
                        files.images.push({ icon: "image", text: name });
                    }
                }
            }

            updateChoosenFiles(files);
            onImageChange(files.imagesFiles, preloadedFilesState);
        }
    };

    const onClickFileRemove = (e, text, icon) => {
        let files = { ...choosenFiles };
        // let dt = new DataTransfer();

        if (icon === "doc") {
            // for (let file of documentsInputRef.current.files) {
            // 	if (file.name !== text) {
            // 		dt.items.add(file);
            // 	}
            // }
            documentsInputRef.current.value = "";

            files.documentsFiles = files.documentsFiles.filter(
                (f) => f.name !== text
            );
            files.documents = files.documents.filter((f) => f.text !== text);
            // documentsInputRef.current.files = dt.files;
            if (onDocumentRemove) {
                onDocumentRemove(
                    files.documentsFiles,
                    preloadedFilesState.filter((file) => file.text !== text)
                );
            }
        } else if (icon === "image") {
            // for (let file of imagesInputRef.current.files) {
            // 	if (file.name !== text) {
            // 		dt.items.add(file);
            // 	}
            // }
            imagesInputRef.current.value = "";

            files.imagesFiles = files.imagesFiles.filter(
                (f) => f.name !== text
            );
            files.images = files.images.filter((f) => f.text !== text);
            // imagesInputRef.current.files = dt.files;
            if (onImageRemove) {
                onImageRemove(
                    files.imagesFiles,
                    preloadedFilesState.filter((file) => file.text !== text)
                );
            }
        }

        updateChoosenFiles(files);
        updatePreloadedFilesState(
            preloadedFilesState.filter((file) => file.text !== text)
        );
        setError("");
    };

    // Скрыть тултип с шаблонами
    const hideTemplatesTooltip = () => {
        codeTooltipRef.current.tooltipRef.style.setProperty(
            "opacity",
            "0",
            "important"
        );
        codeTooltipRef.current.tooltipRef.style.setProperty(
            "pointer-events",
            "none",
            "important"
        );
        codeTooltipRef.current.tooltipRef.classList.remove("show");
    };

    // Показать тултип с шаблонами
    const showTemplatesTooltip = () => {
        codeTooltipRef.current.tooltipRef.style.setProperty(
            "opacity",
            "1",
            "important"
        );
        codeTooltipRef.current.tooltipRef.style.setProperty(
            "pointer-events",
            "auto",
            "important"
        );
        codeTooltipRef.current.tooltipRef.classList.add("show");
    };

    // Клик по шаблону кода
    const onCodeClick = (e, children, icon) => {
        e.stopPropagation();
        if (onClickCodeTemplate) {
            onClickCodeTemplate(e, children, icon, cursorPosition);
        }

        hideTemplatesTooltip();
        ReactTooltip.hide();
    };

    const onTooltipShow = () => {
        codeTooltipRef.current.tooltipRef.style.opacity = null;
        codeTooltipRef.current.tooltipRef.style.pointerEvents = null;
    };

    const onTextareaChange = (e) => {
        if (typeof rest.onChange === "function") {
            rest.onChange(e);
        }
        setError("");
        if (ref && ref.current) {
            setCursorPosition(getInputCursorPosition(ref.current));
        }
    };

    const onTextareaClick = (e) => {
        if (typeof rest.onClick === "function") {
            rest.onClick(e);
        }
        if (ref && ref.current) {
            setCursorPosition(getInputCursorPosition(ref.current));
        }
    };

    const onTextareaKeypress = (e) => {
        if (typeof rest.onKeypress === "function") {
            rest.onKeyPress(e);
        }
        if (ref && ref.current) {
            setCursorPosition(getInputCursorPosition(ref.current));
        }
    };

    const onTextareaKeyup = (e) => {
        if (typeof rest.onKeypress === "function") {
            rest.onKeyUp(e);
        }
        if (ref && ref.current) {
            setCursorPosition(getInputCursorPosition(ref.current));
        }
    };

    return (
        <>
            <div className="input__textarea">
                <div className="input__textarea_inner">
                    <textarea
                        {...rest}
                        onChange={onTextareaChange}
                        onClick={onTextareaClick}
                        onKeyPress={onTextareaKeypress}
                        onKeyUp={onTextareaKeyup}
                        style={{ minHeight: `${height}px` }}
                        autoComplete="off"
                        className={`ps__child ps__child--consume ${className} ${
                            templates || code_templates || document_clip
                                ? "input--nav"
                                : ""
                        } ${emoji_picker ? "input--emoji_picker" : ""}`}
                        ref={ref}
                    ></textarea>
                    <Icon icon="resize" className="input__resize"></Icon>

                    {add_template ? (
                        <Icon
                            icon="new-document"
                            className="input__right_top_icon"
                        ></Icon>
                    ) : null}

                    {/* Эмоджи пикер */}
                    {emoji_picker ? (
                        <OutsideClickHandler
                            onOutsideClick={() => setEmojiPickerActive(false)}
                        >
                            <div
                                className={`ps__child ps__child--consume input__textarea_emoji_picker ${
                                    emojiPickerActive ? "active" : ""
                                }`}
                                data-emoji-picker
                            >
                                <Picker
                                    title="Pick your emoji…"
                                    emoji="point_up"
                                    onSelect={(e) => {
                                        setCursorPosition((state) => ({
                                            ...state,
                                            start:
                                                state.start + e.native.length,
                                        }));
                                        onEmojiSelect(e, cursorPosition);
                                    }}
                                    showPreview={false}
                                    showSkinTones={false}
                                    i18n={{
                                        search: "Поиск",
                                        categories: {
                                            search: "Результаты поиска",
                                            recent: "Frequently Used",
                                            smileys: "Smileys & Emotion",
                                            people: "People & Body",
                                            nature: "Animals & Nature",
                                            foods: "Food & Drink",
                                            activity: "Activity",
                                            places: "Travel & Places",
                                            objects: "Objects",
                                            symbols: "Symbols",
                                            flags: "Flags",
                                            custom: "Custom",
                                        },
                                    }}
                                />
                            </div>
                            {emojiPickerActive ? (
                                <img
                                    src={crossImg}
                                    className="input__textarea_smile"
                                    onClick={() => setEmojiPickerActive(false)}
                                    alt="close"
                                />
                            ) : (
                                <img
                                    onClick={() => setEmojiPickerActive(true)}
                                    className="input__textarea_smile"
                                    src={smileImg}
                                    alt="smile"
                                />
                            )}
                        </OutsideClickHandler>
                    ) : null}
                </div>
                <div className="input__nav">
                    {/* Кнопка использования шаблона-кода */}
                    {code_templates ? (
                        <>
                            <div
                                className="input__nav_item"
                                data-effect="solid"
                                data-for="code_tooltip"
                                data-event={tooltipsEvent}
                                data-tip
                                onClick={() => {
                                    showTemplatesTooltip();
                                    setTimeout(() => {
                                        showTemplatesTooltip();
                                    }, 200);
                                    ReactTooltip.hide(documentsTooltipRef);
                                }}
                            >
                                <Icon
                                    icon="code"
                                    className="input__nav_icon"
                                ></Icon>
                            </div>
                        </>
                    ) : null}

                    {/* Кнопка прикрепления документа */}
                    {document_clip ? (
                        <>
                            <div
                                className="input__nav_item"
                                data-effect="solid"
                                data-for="document_clip_tooltip"
                                data-event={tooltipsEvent}
                                data-tip
                                onClick={() => {
                                    hideTemplatesTooltip();
                                    ReactTooltip.hide(codeTooltipRef);
                                }}
                            >
                                <Icon
                                    icon="clip"
                                    className="input__nav_icon"
                                ></Icon>
                            </div>
                        </>
                    ) : null}
                </div>

                {code_templates ? (
                    <ReactTooltip
                        clickable={true}
                        delayHide={200}
                        globalEventOff={tooltipsEventOff}
                        className="tooltip"
                        id="code_tooltip"
                        place={tooltipsPosition}
                        isCapture
                        afterHide={onTooltipShow}
                        resizeHide={false}
                        ref={codeTooltipRef}
                    >
                        <TagList
                            className="input__nav_tags"
                            tags={codeTemplatesList}
                            onClick={onCodeClick}
                        ></TagList>
                    </ReactTooltip>
                ) : null}

                {document_clip ? (
                    <ReactTooltip
                        clickable={true}
                        delayHide={200}
                        globalEventOff={tooltipsEventOff}
                        className="tooltip"
                        id="document_clip_tooltip"
                        place={tooltipsPosition}
                        isCapture
                        afterHide={onTooltipShow}
                        resizeHide={false}
                        ref={documentsTooltipRef}
                    >
                        <div className="fs14">
                            <label className="grey_link">
                                Загрузить документ
                                <input
                                    type="file"
                                    name="documents"
                                    className="hidden_input"
                                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                                    onChange={onDocumentChoose}
                                    ref={documentsInputRef}
                                />
                            </label>
                            <br />
                            <br />
                            <label className="grey_link">
                                Загрузить изображение
                                <input
                                    type="file"
                                    name="images"
                                    className="hidden_input"
                                    accept="image/*"
                                    onChange={onImageChoose}
                                    ref={imagesInputRef}
                                    multiple="multiple"
                                />
                            </label>
                        </div>
                    </ReactTooltip>
                ) : null}
            </div>

            {error ? (
                <div className="input_error">{error}</div>
            ) : touched && rest.error ? (
                <div className="input_error">{rest.error}</div>
            ) : null}

            {choosenFiles.images.length ||
            choosenFiles.documents.length ||
            preloadedFilesState.length ? (
                <div className="input__textarea_docs">
                    <div className="input__files_title">
                        Прикреплённые файлы
                    </div>
                    <TagList
                        removable={true}
                        onClickRemove={(e, text, icon) =>
                            onClickFileRemove(e, text, icon)
                        }
                        tags={[
                            ...choosenFiles.images,
                            ...choosenFiles.documents,
                            ...preloadedFilesState,
                        ]}
                    />
                </div>
            ) : null}
        </>
    );
});

export default Textarea;
