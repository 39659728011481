import React, { useEffect, useState } from "react";
import RoundedButtonList from "../generic/RoundedButtonList/RoundedButtonList";
import CustomScrollbar from "../generic/CustomScrollbar/CustomScrollbar";
import Newsletter from "../Newsletter/Newsletter";
import Search from "../Search/Search";
import styles from "./NewsletterList.module.sass";
import character from "../../assets/images/character-2.svg";
import NoResult from "../generic/NoResult/NoResult";
import OnetimeMailingPopupContainer from "../popups/OnetimeMailingPopup/OnetimeMailingPopupContainer";
import Preloader from "../Preloader/Preloader";
import CreateMailingPopup from "../popups/CreateMailingPopup/CreateMailingPopup";
import FilterLinks from "../generic/FilterLinks/FilterLinks";
import getQueryParams from "../../utils/getQueryParams";

const NewsletterList = (props) => {
    let {
        newsletters = [],
        sortedNewsletters = [],
        searchText = "",
        isFirstLoad,
        isLoading = true,
        sortBy = {},
        newsletterInfo,
        newsletterInfoIsLoading,
        location,
    } = props;

    const [isOnetimeMailingOpen, updateIsOnetimeMailingOpen] = useState(false);
    const [isAutomessageOpen, updateIsAutomessageOpen] = useState(false);
	// eslint-disable-next-line
    const [isAutomessage, updateIsAutomessage] = useState(false);
    const [scrollState, setScrollState] = useState(0);
    const [openedExtras, updateOpenedExtras] = useState([]);
    let scrollbarRef;

    useEffect(() => {
        if (
            location.search.indexOf("newsletter=") < 0 &&
            location.search.indexOf("create-newsletter=open") < 0
        ) {
            updateIsOnetimeMailingOpen(false);
            updateIsAutomessageOpen(false);
        }
    }, [location.search]);

    useEffect(() => {
        const params = getQueryParams(location.search);
        if (params.newsletter) {
            props.getNewsletterInfo(params.newsletter);
            updateIsOnetimeMailingOpen(true);
            // updateIsAutomessage(type === "AUTO" ? true : false);
        }
		// eslint-disable-next-line
    }, [updateIsOnetimeMailingOpen]);

    // Узнать кол-во рассылок со определенному параметру
    const getSortAmount = (param, value) => {
        return newsletters
            ? newsletters.filter((n) => {
                  if (value === "ARCHIVED") {
                      return n[param] === value;
                  } else {
                      return n[param] === value && n.type !== "ARCHIVED";
                  }
              }).length
            : 0;
    };

    // Кнопки сортировки
    let sortButtons = [
        {
            text: "Все",
            amount: newsletters
                ? newsletters.filter((n) => n.type !== "ARCHIVED").length
                : 0,
            isActive: true,
            sortby: { status: "all", type: "all" },
        },
        {
            text: "В ожидании",
            amount: getSortAmount("status", "PENDING"),
            sortby: { status: "PENDING", type: "all" },
        },
        {
            text: "В обработке",
            amount: getSortAmount("status", "PROCESSING"),
            sortby: { status: "PROCESSING", type: "all" },
        },
        {
            text: "Завершено",
            amount: getSortAmount("status", "COMPLETED"),
            sortby: { status: "COMPLETED", type: "all" },
        },
        {
            text: "Требует активации",
            amount: getSortAmount("status", "NO_ACTIVE"),
            sortby: { status: "NO_ACTIVE", type: "all" },
        },
        {
            text: "Ошибка",
            amount: getSortAmount("status", "ERROR"),
            sortby: { status: "ERROR", type: "all" },
        },
        {
            text: "В архиве",
            amount: getSortAmount("type", "ARCHIVED"),
            sortby: { type: "ARCHIVED", status: "all" },
        },
    ];

    // Активность кнопки
    sortButtons = sortButtons.map((s) => {
        for (const sort in s.sortby) {
            if (s.sortby[sort] === sortBy[sort]) {
                return { ...s, isActive: true };
            } else {
                return { ...s, isActive: false };
            }
        }
        return 0;
    });

    // Узнать кол-во у второстепенных сортировок
    const getSecondarySortAmount = (type) => {
        return newsletters && newsletters.length
            ? newsletters.filter((n) => {
                  if (sortBy.status === "all") {
                      if (type === "all") {
                          return n.type !== "ARCHIVED";
                      } else {
                          return n.type === type;
                      }
                  } else {
                      if (type === "all") {
                          return (
                              n.type !== "ARCHIVED" &&
                              n.status === sortBy.status
                          );
                      } else {
                          return n.type === type && n.status === sortBy.status;
                      }
                  }
              }).length
            : 0;
    };

    // Второстепенные кнопки сортировки
    let sortButtonsSecondary = [
        {
            text: "Все",
            amount: getSecondarySortAmount("all"),
            isActive: true,
            sortby: { type: "all" },
        },
        {
            text: "Разовая рассылка",
            amount: getSecondarySortAmount("ONE_TIME"),
            sortby: { type: "ONE_TIME" },
        },
        {
            text: "Автосообщение",
            amount: getSecondarySortAmount("AUTO"),
            sortby: { type: "AUTO" },
        },
    ];

    // Активность кнопки
    sortButtonsSecondary = sortButtonsSecondary.map((s) => {
        for (const sort in s.sortby) {
            if (s.sortby[sort] === sortBy[sort]) {
                return { ...s, isActive: true };
            } else {
                return { ...s, isActive: false };
            }
        }
        return 0;
    });

    // Клик по кнопке сортировки
    const onSortButtonClick = (sortBy) => {
        props.setNewslettersSorting(sortBy, searchText);
        updateOpenedExtras([]);
    };

    // Клик по второстепенной сортировке
    const onSortButtonSecondaryClick = (sortBy) => {
        props.setNewslettersSorting(sortBy, searchText);
        updateOpenedExtras([]);
    };

    const onSettingsClick = (id, isOpen) => {
        props.toggleSettings({ id, isOpen: !isOpen });
    };

    const onSearchChange = (e) => {
        props.setNewslettersSorting(sortBy, e.target.value);
    };

    const onActivateNewsletter = (id) => {
        let scrollTop;
        if (window.matchMedia("(min-width: 1201px)").matches) {
            scrollTop = scrollbarRef.scrollTop;
        }
        props.activateNewsletter(id, props.match.params.id).then(() => {
            if (window.matchMedia("(min-width: 1201px)").matches) {
                setScrollState(scrollTop);
            }
        });
    };

    const onDeactivateNewsletter = (id) => {
        let scrollTop;
        if (window.matchMedia("(min-width: 1201px)").matches) {
            scrollTop = scrollbarRef.scrollTop;
        }
        props.deactivateNewsletter(id, props.match.params.id).then(() => {
            if (window.matchMedia("(min-width: 1201px)").matches) {
                setScrollState(scrollTop);
            }
        });
    };

    const onChangeNewsletter = (id, type) => {
        // props.getNewsletterInfo(id);
        // updateIsAutomessage(type === "AUTO" ? true : false);
        // updateIsOnetimeMailingOpen(true);

        window.open(`${location.pathname}?newsletter=${id}`, "_blank").focus();
    };

    const onTitleClick = (id) => {
        // props.getNewsletterInfo(id);
        // updateIsOnetimeMailingOpen(true);

        window.open(`${location.pathname}?newsletter=${id}`, "_blank").focus();
    };

    const onClickRemove = (id) => {
        props.removeNewsletter(id, props.match.params.id);
    };

    const onExtraPanelOpen = (id) => {
        let newOpenedExtras = [...openedExtras];
        let index = openedExtras.indexOf(id);
        if (index >= 0) {
            newOpenedExtras.splice(index, 1);
        } else {
            newOpenedExtras.push(id);
        }
        updateOpenedExtras(newOpenedExtras);
    };

    const onScrollY = (el) => {
        setScrollState(el.scrollTop);
    };

    const onInnerPopupOpen = () => {
        updateIsAutomessageOpen(false);
    };

    const onClickCopy = (id) => {
        props.newsletterCopy(id, props.match.params.id);
    };

    let displayNewsletters = newsletters;
    if (sortedNewsletters.length || searchText) {
        displayNewsletters = sortedNewsletters;
    }

    return (
        <div className={styles.newsletters_wrap}>
            {isLoading ? <Preloader /> : null}
            {isFirstLoad ? null : newsletters && newsletters.length ? (
                <>
                    <div className={styles.newsletters__sort}>
                        <div className={styles.newsletters__sort_btns}>
                            <RoundedButtonList
                                buttons={sortButtons}
                                onButtonClick={onSortButtonClick}
                            ></RoundedButtonList>
                        </div>

                        <div className={styles.newsletters__search}>
                            <Search
                                onChange={onSearchChange}
                                placeholder="Поиск по рассылкам"
                                value={searchText}
                            ></Search>
                        </div>

                        <div className={styles.newsletters__sort_secondary}>
                            <FilterLinks
                                onClick={onSortButtonSecondaryClick}
                                className={
                                    styles.newsletters__sort_btns_secondary
                                }
                                links={sortButtonsSecondary}
                            />
                        </div>
                    </div>

                    {/* {searchText && !displayNewsletters.length ? (
						<p>Не найдено</p>
					) : null} */}

                    <CustomScrollbar
                        className={styles.newsletters__list}
                        disablesize="xl"
                        onScrollY={onScrollY}
                        ref={(ref) => {
                            if (ref && ref._scrollBarRef) {
                                scrollbarRef = ref._scrollBarRef._container;
                                ref._scrollBarRef._container.scrollTop = scrollState;
                            }
                        }}
                    >
                        <div>
                            {displayNewsletters.length
                                ? displayNewsletters.map((n, idx) => (
                                      <div
                                          className={styles.newsletters__item}
                                          key={idx}
                                      >
                                          <Newsletter
                                              {...n}
                                              highlightTitlePart={searchText}
                                              onSettingsClick={() =>
                                                  onSettingsClick(
                                                      n.id,
                                                      n.settings.isOpen
                                                  )
                                              }
                                              onActivateNewsletter={() =>
                                                  onActivateNewsletter(n.id)
                                              }
                                              onDeactivateNewsletter={() =>
                                                  onDeactivateNewsletter(n.id)
                                              }
                                              onChangeNewsletter={() =>
                                                  onChangeNewsletter(
                                                      n.id,
                                                      n.type
                                                  )
                                              }
                                              onTitleClick={() =>
                                                  onTitleClick(n.id)
                                              }
                                              onClickRemove={() =>
                                                  onClickRemove(n.id)
                                              }
                                              isOpen={openedExtras.some(
                                                  (o) => o === n.id
                                              )}
                                              onExtraPanelOpen={() =>
                                                  onExtraPanelOpen(n.id)
                                              }
                                              onClickCopy={() =>
                                                  onClickCopy(n.id)
                                              }
                                          ></Newsletter>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </CustomScrollbar>
                </>
            ) : (
                // Если нет рассылок
                <NoResult
                    text="У вас пока нет рассылок"
                    img={character}
                    link={{
                        text: "Создать",
                        onClick: () => updateIsAutomessageOpen(true),
                    }}
                ></NoResult>
            )}

            <OnetimeMailingPopupContainer
                onClosePopup={() => updateIsOnetimeMailingOpen(false)}
                onOpenPopup={() => {
                    updateIsOnetimeMailingOpen(true);
                }}
                isOpen={isOnetimeMailingOpen}
                newsletterInfo={newsletterInfo}
                isLoading={newsletterInfoIsLoading}
                isAutomessage={isAutomessage}
            ></OnetimeMailingPopupContainer>
            <CreateMailingPopup
                onClosePopup={() => updateIsAutomessageOpen(false)}
                onOpenPopup={() => updateIsAutomessageOpen(true)}
                isOpen={isAutomessageOpen}
                onInnerPopupOpen={onInnerPopupOpen}
            ></CreateMailingPopup>
        </div>
    );
};

export default NewsletterList;
