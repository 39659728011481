import React from "react";
import styles from "./PagePreloader.module.sass";
import Preloader from "../../Preloader/Preloader";

const PagePreloader = () => {
    return (
        <div
            className={`${styles["preloader_container"]} ${styles["active"]}`}
            id="page_preloader"
        >
            <Preloader />
        </div>
    );
};

export default PagePreloader;
