import React, { useEffect, useState } from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import robotImg from "../../../assets/images/robot-character.svg";
import styles from "./CheckingWorkPopup.module.sass";
import Button from "../../generic/Button/Button";
import Preloader from "../../Preloader/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { checkDistributionWorking } from "../../../redux/reducers/checkingWorkReducer";
import CheckList from "../../CheckList/CheckList";
import CustomScrollbar from "../../generic/CustomScrollbar/CustomScrollbar";
import { useParams } from "react-router-dom";

const CheckingWorkPopup = ({ isOpen, onOpen, onClose, ...rest }) => {
    const dispatch = useDispatch();
    const { id: community_id } = useParams();
    const { checkResult, checkIsLoading } = useSelector(
        (state) => state.checkingWork
    );
    const [isResultShowing, setIsResultShowing] = useState(false);

    useEffect(() => {
        setIsResultShowing(false);
    }, [isOpen]);

    const handleCheckStart = async () => {
        await dispatch(checkDistributionWorking({ community_id }));
        setIsResultShowing(true);
    };

    // Формируем массив с результатами
    const checkResultArr = [];
    for (const prop in checkResult) {
        const { result, title, text } = checkResult[prop];
        checkResultArr.push({
            success: result,
            text: title,
            description: text,
        });
    }

    return (
        <PopupMainLayout isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            {checkIsLoading ? <Preloader /> : null}

            {!isResultShowing ? (
                <>
                    <div className="text_center">
                        <img
                            src={robotImg}
                            alt="robot"
                            className={styles.hero_img}
                        />
                    </div>
                    <div className={styles.hero_title}>Проверка работы</div>
                    <div className={styles.hero_text}>
                        <p>
                            Автоматическая проверка работы рассылок и отправки
                            сообщений пользователям ok.ru.
                        </p>
                        <p>Проверка включает в себя:</p>
                        <ul className="ul_dots">
                            <li>подписка на рассылки</li>
                            <li>отписка от рассылок</li>
                            <li>отправка сообщений</li>
                            <li>настройка сообщества на отправку сообщений.</li>
                        </ul>
                    </div>
                    <div className="text_center">
                        <Button onClick={handleCheckStart}>Запустить</Button>
                    </div>
                </>
            ) : null}
            {isResultShowing ? (
                <>
                    <div className={styles.hero_title}>Проверка работы</div>
                    <CustomScrollbar className={styles.content_scroll}>
                        <div className={styles.hero_subtitle}>
                            Результат проверки появится в уведомлениях и придет
                            в виде сообщения от имени сообщества{" "}
                            <a
                                href="https://ok.ru/group/58926806335624/messages"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link"
                            >
                                https://ok.ru/group/58926806335624/messages
                            </a>
                        </div>
                        <div className={styles.check_list}>
                            <CheckList list={checkResultArr} />
                        </div>
                        <div className="text_center">
                            <Button onClick={onClose}>Закрыть</Button>
                        </div>
                    </CustomScrollbar>
                </>
            ) : null}
        </PopupMainLayout>
    );
};

export default CheckingWorkPopup;
