import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import StatisticsGraph from "../../components/StatisticsGraph/StatisticsGraph";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import withCommunityRedirect from "../../hoc/withCommunityRedirect";
import { getSubscribersGroups } from "../../redux/reducers/communitiesReducer";
import {
    getSubscriberHistory,
    resetSubscriberHistory,
    getStatsSubscribers,
} from "../../redux/reducers/subscribehistoryReducer";
import moment from "moment";
import { withAccessRedirect } from "../../hoc/withAccessRedirect";
import getQueryParams from "../../utils/getQueryParams";

class statstics extends React.Component {
    constructor(props) {
        super(props);
        this.community_id = this.props.match.params.id;
        this.urlParams = getQueryParams(this.props.location.search);
    }

    componentDidMount() {
		let urlParamsFilters = {
			group_id: this.urlParams.group_id ? [this.urlParams.group_id] : [],
			subscriber_id: this.urlParams.subscriber_id ? [this.urlParams.subscriber_id] : [],
		}

        this.props.getStatsSubscribers(this.community_id);
        this.props.getSubscribersGroups(this.community_id);
        this.props.getSubscriberHistory({
			page: 1,
            community_id: this.community_id,
            date_from: moment()
                .subtract(14, "days")
                .format("DD.MM.YYYY"),
			...urlParamsFilters,
        });
    }

    componentWillUnmount() {
        this.props.resetSubscriberHistory();
    }

    render() {
        const page = {
            title: "Статистика",
            crumbs: [
                { name: "Сообщества", path: "/community" },
                {
                    name: "Статистика",
                    path: `/statistics`,
                },
            ],
        };

        return (
            <>
                <div className="page_head">
                    <Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>
                </div>

                <StatisticsGraph
					pagesCount={this.props.pagesCount}
                    subscribers={this.props.subscribers}
                    groups={this.props.groups}
                    subscribeHistory={this.props.subscribeHistory}
                    getSubscriberHistory={this.props.getSubscriberHistory}
                    resetSubscriberHistory={this.props.resetSubscriberHistory}
                    isLoading={this.props.isLoading}
                />
            </>
        );
    }
}

const mstp = (store) => {
    return {
        groups: store.communities.currentCommunity.groups,
        subscribers: store.subscribeHistory.statsSubscribers,
        subscribeHistory: store.subscribeHistory.subHistory,
        isLoading: store.subscribeHistory.isLoading,
		pagesCount: store.subscribeHistory.pagesCount,
    };
};

export default compose(
    connect(mstp, {
        getStatsSubscribers,
        getSubscribersGroups,
        getSubscriberHistory,
        resetSubscriberHistory,
    }),
    withRouter,
    withAccessRedirect,
    withCommunityRedirect,
    withAuthRedirect
)(statstics);
