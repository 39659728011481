import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Header from "../components/Header/Header";
import CheckingWorkPopup from "../components/popups/CheckingWorkPopup/CheckingWorkPopup";
import CreateGroupPopupContainer from "../components/popups/CreateGroupPopup/CreateGroupPopupContainer";
import CreateMailingPopup from "../components/popups/CreateMailingPopup/CreateMailingPopup";
import DisconnectCommunityPopup from "../components/popups/DisconnectCommunityPopup/DisconnectCommunityPopup";
import OnetimeMailingPopupContainer from "../components/popups/OnetimeMailingPopup/OnetimeMailingPopupContainer";
import Sidebar from "../components/Sidebar/Sidebar";
import { disconnectCommunity } from "../redux/reducers/communitiesReducer";

const ControlPanelLayout = (props) => {
    const { children, match, location, staff_type } = props;
    const communityId = match.params.id;
    const url = match.url;
    const query = new URLSearchParams(location.search);
    const isDisconnect = query.get("disconnect");

    let menuBtn;

    const [modals, updateModals] = useState({
        OnetimeMailingPopup: {
            isOpen: false,
        },
        CreateMailingPopup: {
            isOpen: false,
        },
        CreateGroupPopup: {
            isOpen: false,
        },
        DisconnectCommunityPopup: {
            isOpen: isDisconnect === "y" ? true : false,
        },
        CheckingWorkPopup: {
            isOpen: false,
        },
    });

    const openPopup = (popup) => {
        updateModals({
            ...modals,
            [popup]: {
                isOpen: true,
            },
        });
    };

    const closePopup = (popup) => {
        updateModals({
            ...modals,
            [popup]: {
                isOpen: false,
            },
        });
    };

    const onInnerPopupOpen = () => {
        updateModals({
            ...modals,
            CreateMailingPopup: {
                isOpen: false,
            },
        });
    };

    if (url.indexOf("/subscriber-groups") >= 0) {
        menuBtn = {
            text: "Создать группу",
            icon: "plus",
            isButton: true,
            onClick: () => openPopup("CreateGroupPopup"),
        };
    } else if (url.indexOf("/statistics") >= 0) {
        menuBtn = null;
    } else {
        menuBtn = {
            text: "Создать рассылку",
            icon: "plus",
            isButton: true,
            onClick: () => openPopup("CreateMailingPopup"),
        };
    }

    useEffect(() => {
        if (
            location.search.indexOf("group=") < 0 &&
            location.search.indexOf("create-group=open") < 0
        ) {
            closePopup("CreateGroupPopup");
        }

        // Открытие попапа при загрузке
        if (location.search.indexOf("popup=create-group") >= 0) {
            openPopup("CreateGroupPopup");
        }
        /*eslint-disable */
    }, [location.search]); /*eslint-enable */

    let menus = [
        // {
        // 	title: "Гостевой доступ",
        // 	menu: [
        // 		{
        // 			items: [
        // 				{ label: "Отправлено:", value: "153 843" },
        // 				{ label: "Доступно:", value: "153 843" },
        // 			],
        // 			isBoard: true,
        // 		},
        // 	],
        // },
        {
            title: "Меню группы",
            menu: [
                { ...menuBtn },
                {
                    text: "Рассылки",
                    to: `/community/${communityId}/`,
                    icon: "mail",
                    access: ["OWNER", "LEADER", "EDITOR"],
                },

                {
                    text: "Группы подписчиков",
                    to: `/community/${communityId}/subscriber-groups/`,
                    icon: "label",
                    access: ["OWNER", "LEADER", "EDITOR"],
                },
                {
                    text: "Подписчики",
                    to: `/cabinet/community/${communityId}/subscribers/`,
                    icon: "group",
                    access: ["OWNER", "LEADER", "EDITOR"],
                    // stageOnly: true
                },
                {
                    text: "Чат-боты",
                    to: `/community/${communityId}/bots/`,
                    icon: "robot",
                    access: ["OWNER", "LEADER", "EDITOR", "OBSERVER"],
                },
                {
                    text: "Статистика",
                    to: `/community/${communityId}/statistics/`,
                    icon: "graph",
                    access: ["OWNER", "LEADER", "EDITOR", "OBSERVER"],
                },
                {
                    text: "Доступы",
                    to: `/cabinet/community/${communityId}/accesses/`,
                    icon: "hierarchy",
                    access: ["OWNER", "LEADER"],
                },
                {
                    text: "WebHook API",
                    to: `/cabinet/community/${communityId}/webhooks/`,
                    icon: "gears",
                    access: ["OWNER", "LEADER", "EDITOR"],
                },
                {
                    text: "Отключить",
                    icon: "disconnect",
                    onClick: () => openPopup("DisconnectCommunityPopup"),
                    access: ["OWNER"],
                },
                {
                    text: "Проверка работы",
                    icon: "check",
                    onClick: () => openPopup("CheckingWorkPopup"),
                    access: ["OWNER", "LEADER", "EDITOR", "OBSERVER"],
                },
            ],
        },
        {
            title: "Личный кабинет",
            menu: [
                { text: "Сообщества", to: "/community/", icon: "group" },
                {
                    text: "Партнерская программа",
                    to: "/cabinet/referrals/",
                    icon: "hand-shake",
                },
            ],
        },
    ];

    // Убираю ссылки на которые нет доступа у юзера
    menus.forEach((item) => {
        item.menu = item.menu.filter((m) =>
            m.access ? (m.access.indexOf(staff_type) >= 0 ? true : false) : true
        );
    });

    return (
        <>
            <div className="sidebared_layout">
                <div className="sidebared_layout__sidebar">
                    <Sidebar menus={menus}></Sidebar>
                </div>
                <div className="sidebared_layout__main">
                    <Header
                        settings={{ size: "sm" }}
                        menus={menus}
                        showMenuCommunityProfile={true}
                    ></Header>
                    <main className="page_main">{children}</main>
                </div>
            </div>
            <CreateMailingPopup
                onClosePopup={() => closePopup("CreateMailingPopup")}
                onOpenPopup={() => openPopup("CreateMailingPopup")}
                isOpen={modals.CreateMailingPopup.isOpen}
                onInnerPopupOpen={onInnerPopupOpen}
            />
            <OnetimeMailingPopupContainer
                onClosePopup={() => {
                    closePopup("OnetimeMailingPopup");
                }}
                onOpenPopup={() => openPopup("OnetimeMailingPopup")}
                isOpen={modals.OnetimeMailingPopup.isOpen}
            />
            <CreateGroupPopupContainer
                onClosePopup={() => closePopup("CreateGroupPopup")}
                onOpenPopup={() => openPopup("CreateGroupPopup")}
                isOpen={modals.CreateGroupPopup.isOpen}
            />
            <DisconnectCommunityPopup
                onClosePopup={() => closePopup("DisconnectCommunityPopup")}
                onOpenPopup={() => openPopup("DisconnectCommunityPopup")}
                isOpen={modals.DisconnectCommunityPopup.isOpen}
            />
            <CheckingWorkPopup
                onClose={() => closePopup("CheckingWorkPopup")}
                onOpen={() => openPopup("CheckingWorkPopup")}
                isOpen={modals.CheckingWorkPopup.isOpen}
            />
        </>
    );
};

class ControlPanelLayoutContainer extends React.Component {
    render() {
        return <ControlPanelLayout {...this.props} />;
    }
}

const mstp = (state) => {
    return {
        staff_type: state.auth.userData.staff_type,
    };
};

export default compose(
    connect(mstp, { disconnectCommunity }),
    withRouter
)(ControlPanelLayoutContainer);
