import React from "react";
import Icon from "../Icon/Icon";
import styles from "./CircleIconText.module.sass";

const CircleIconText = (props) => {
	const { icon, 
		iconColor, 
		iconSize, // "lg"
		title, 
		text } = props;

	return (
		<div className={`${styles.iconed_text} ${iconSize ? styles["iconed_text--icon_lg"] : ""}`}>
			{icon ? (
				<div
					className={styles.iconed_text__icon}
					style={{ backgroundColor: iconColor }}
				>
					<Icon icon={icon} />
				</div>
			) : null}
			<div className={styles.iconed_text__main}>
				{title ? (
					<div className={styles.iconed_text__title}>{title}</div>
				) : null}
				{text ? (
					<div className={styles.iconed_text__subtitle}>{text}</div>
				) : null}
			</div>
		</div>
	);
};

export default CircleIconText;
