import React from "react";
import styles from "./FilterLinks.module.sass";

const FilterLinks = (props) => {
    const { links = [], className = "", onClick } = props;

    const onLinkClick = (sortby) => {
        if (onClick) {
            onClick(sortby);
        }
    };

    return (
        <div className={`${styles.links} ${className}`}>
            {links.map(
                (
                    { text, amount, sortby, isActive, onClick: onClickProps },
                    idx
                ) =>
                    amount ? (
                        <div
                            key={idx}
                            className={`${styles.links__link} ${
                                isActive ? styles["active"] : ""
                            }`}
                            onClick={() => {
                                onLinkClick(sortby);
                                onClickProps();
                            }}
                        >
                            {text} <span>{amount}</span>
                        </div>
                    ) : null
            )}
        </div>
    );
};

export default FilterLinks;
