import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import ActiveButton from "../../components/generic/ActiveButton/ActiveButton";
// import Button from "../../components/generic/Button/Button";
import Search from "../../components/Search/Search";
import NoResult from "../../components/generic/NoResult/NoResult";
import styles from "./bots.module.sass";
import character from "../../assets/images/robot-character2.svg";
import ChatbotCard from "../../components/ChatbotCard/ChatbotCard";
import AddBot from "../../components/popups/AddBot/AddBot";
import Preloader from "../../components/Preloader/Preloader";
import { useDispatch, useSelector } from "react-redux";
import {
    addBot,
    removeBot,
    activateBot,
    deactivateBot,
    getBotsList,
    copyBot,
} from "../../redux/reducers/botsReducer";
import { useParams } from "react-router";

const Bots = () => {
    const page = {
        crumbs: [
            { name: "Личный кабинет", path: "/" },
            { name: "Сообщества", path: "/community" },
            { name: "Чат боты", path: "/" },
        ],
    };

    const { id: community_id } = useParams();

    const dispatch = useDispatch();

    const { botsList, isBotsListLoading } = useSelector((state) => state.bots);

    const [opened, setOpened] = useState([]);
    const [isAddBotOpened, setIsAddBotOpened] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchedBots, setSearchedBots] = useState([]);

    const displayBots = searchText ? searchedBots : botsList;

    useEffect(() => {
        dispatch(getBotsList({ community_id }));
    }, [dispatch, community_id]);

    // Открытие элементов
    const saveOpened = (id) => {
        if (opened.indexOf(id) >= 0) {
            setOpened((opened) => opened.filter((o) => o !== id));
        } else {
            setOpened([...opened, id]);
        }
    };

    // Сохранение бота
    const saveBot = (data) => {
        dispatch(addBot({ ...data, community_id }));
        setIsAddBotOpened(false);
    };

    // Удаление бота
    const hadleRemoveBot = (data) => {
        dispatch(removeBot({ ...data, community_id }));
    };

    // Активация бота
    const handleActivateBot = (data) => {
        dispatch(activateBot({ ...data, community_id }));
    };

    // Деактивация бота
    const handleDeactivateBot = (data) => {
        dispatch(deactivateBot({ ...data, community_id }));
    };

    // Деактивация бота
    const handleCopyBot = (data) => {
        const { id: bot_id } = data;
        dispatch(copyBot({ bot_id, community_id }));
    };

    // Поиск
    const onSearchChange = (e) => {
        setSearchText(e.target.value);
        setSearchedBots(
            botsList.filter(
                (b) =>
                    b.name
                        .toLowerCase()
                        .indexOf(e.target.value.toLowerCase()) >= 0
            )
        );
    };

    return (
        <div className={styles.bots_page}>
            {isBotsListLoading ? <Preloader /> : null}
            <div className={styles.bots_head}>
                <Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>

                {/* Кнопки и поиск */}
                <div className={styles.bots_nav}>
                    <div className={styles.bots_nav__buttons}>
                        {/* <div className={styles.bots_nav__button}>
                            <Button icon="help-circle">Справка</Button>
                        </div> */}
                        {/* <div className={styles.bots_nav__button}>
                            <ActiveButton icon="gear" color="grey">
                                Настройка тегов
                            </ActiveButton>
                        </div> */}
                        <div className={styles.bots_nav__button}>
                            <ActiveButton
                                icon="plus"
                                color="grey"
                                onClick={() => setIsAddBotOpened(true)}
                            >
                                Новый бот
                            </ActiveButton>
                        </div>
                    </div>
                    <div className={styles.bots_nav__right}>
                        <Search
                            onChange={onSearchChange}
                            value={searchText}
                            placeholder="Найти пользователя"
                        />
                    </div>
                </div>
                {/* Кнопки и поиск END */}

                {/* Список тегов */}
                {/* <div className={styles.bots_tags}>
                    <div className={styles.bots_tags__title}>Теги:</div>
                    <TagList
                        theme="dark"
                        noHover
                        tags={[
                            { text: "Тег номер 1" },
                            { text: "Тег номер 1" },
                        ]}
                    />
                </div> */}
                {/* Список тегов END */}
            </div>

            {botsList.length ? (
                /* Список чатботов */
                <div className={styles.bots_list}>
                    {displayBots.map((b) => (
                        <div className={styles.bots_list__item} key={b.id}>
                            <ChatbotCard
                                name={b.name}
                                highlightName={searchText}
                                id={b.id}
                                participants={b.participants}
                                actions={b.actions}
                                status={b.status}
                                publishDate={b.publish_date}
                                createdDate={b.create_date}
                                onOpenClick={saveOpened}
                                onHeadClick={saveOpened}
                                onRemove={hadleRemoveBot}
                                onStart={handleActivateBot}
                                onStop={handleDeactivateBot}
                                onCopy={handleCopyBot}
                                isOpen={opened.some((o) => o === b.id)}
                            />
                        </div>
                    ))}

                    {searchText && !searchedBots.length
                        ? "Чат бот не найден"
                        : null}
                </div>
            ) : (
                /* Список чатботов END */
                <NoResult
                    text="У вас пока нет чат ботов"
                    img={character}
                    link={{
                        text: "Создать?",
                        onClick: () => setIsAddBotOpened(true),
                    }}
                    btn={{
                        text: "Справка",
                    }}
                ></NoResult>
            )}

            {/* Попап добавления бота */}
            <AddBot
                isOpen={isAddBotOpened}
                onOpenPopup={() => setIsAddBotOpened(true)}
                onClosePopup={() => setIsAddBotOpened(false)}
                onSave={saveBot}
            />
            {/* Попап добавления бота END */}
        </div>
    );
};

export default Bots;
