import React from "react";
import AnimateHeight from "react-animate-height";
import UserProfile from "../../UserProfile/UserProfile";
import Icon from "../Icon/Icon";
import styles from "./InfoCard.module.sass";

const InfoCard = ({
    className = "",
    image,
    showEmptyImage = false,
    showImage = true,
    title,
    desc,
    footer,
    highlightTitlePart,
    onTitleClick,
    onDescClick,
    photoTitleLink = "",
    isOpen = false,
    settingsToggle,
    sticker,
    stickerColor,
    headerContent,
    extraContent,
    target,
    size = "",
}) => {
    return (
        <div
            className={`${styles.card} ${
                isOpen ? styles.active : ""
            } ${className} ${size === "sm" ? styles["card--sm"] : ""}`}
        >
            <div className={styles.card__main} onClick={settingsToggle}>
                {/* Стикер в углу */}
                {sticker ? (
                    <div
                        className={`${styles.card__type} ${styles[stickerColor]}`}
                    >
                        {sticker}
                    </div>
                ) : null}

                <header className={styles.card__header}>
                    {/* Картинка с заголовком */}
                    {image || title || desc ? (
                        <div className={styles.card__header_col}>
                            <UserProfile
                                image={showImage ? image : null}
                                name={title}
                                note={desc}
                                highlightPart={highlightTitlePart}
                                onTitleClick={onTitleClick}
                                onDescClick={onDescClick}
                                link={photoTitleLink}
                                target={target}
                                showEmptyImage={showEmptyImage}
								size={size}
                            />
                        </div>
                    ) : null}

                    {/* Контент в шапке */}
                    {headerContent ? (
                        <div className={styles.card__header_content}>
                            {headerContent}
                        </div>
                    ) : null}

                    {/* Кнопка настроек на десктопе */}
                    {extraContent ? (
                        <div className={styles.card__header_settings}>
                            <div className="d-none d-md-block">
                                <div
                                    className={styles.card__settings}
                                    onClick={settingsToggle}
                                >
                                    <Icon
                                        icon="dots"
                                        className={styles.card__settings_icon}
                                    ></Icon>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </header>
                {footer ? (
                    <footer className={styles.card__footer}>{footer}</footer>
                ) : null}
            </div>

            {/* Выезжающий доп контент */}
            {extraContent ? (
                <div className={styles.card__more_info_wrap}>
                    <AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
                        <div className={styles.card__more_info_inner}>
                            {extraContent}
                        </div>
                    </AnimateHeight>
                </div>
            ) : null}

            {/* Кнопка настроек на мобилке */}
            {extraContent ? (
                <div className="d-md-none">
                    <div
                        className={styles.card__settings}
                        onClick={settingsToggle}
                    >
                        <Icon
                            icon="dots"
                            className={styles.card__settings_icon}
                        ></Icon>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default InfoCard;
