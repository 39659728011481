import React from "react";
import InfoCard from "../generic/InfoCard/InfoCard";
import ActiveButton from "../generic/ActiveButton/ActiveButton";
import styles from "./webhooksInfoCardList.module.sass";
import CustomScrollbar from "../generic/CustomScrollbar/CustomScrollbar";

export const webhooksInfoCardList = ({
    webhooks = [],
    onEdit,
    openedCards,
    toggleCardSettings,
    removeWebhook,
    clearWebhookErrors,
}) => {
    return (
        <CustomScrollbar disablesize="xl" className={styles.hook_list}>
            <div>
                {webhooks.map((webhook) => (
                    <InfoCard
                        key={webhook.id}
                        className={styles.hook_list__item}
                        showImage={false}
                        title={webhook.name}
                        desc={`<a target="_blank" class="link break_all" ${
                            window.matchMedia("(max-width: 767px)").matches
                                ? ""
                                : "href='" + webhook.link + "'"
                        }>${webhook.link}</a>`}
                        onTitleClick={() => onEdit(webhook.id)}
                        settingsToggle={() => toggleCardSettings(webhook.id)}
                        isOpen={openedCards.some((id) => id === webhook.id)}
                        extraContent={
                            <>
                                {webhook.last_error ? (
                                    <>
                                        <div className="mb8 fs12">
                                            Последняя ошибка:
                                        </div>
                                        <div className="info_card_block fs12 text_error">
                                            {webhook.last_error}
                                        </div>
                                    </>
                                ) : null}
                                <div className="button_list button_list--fullwidth_md">
                                    {webhook.last_error ? (
                                        <ActiveButton
                                            icon="refresh-3"
                                            color="grey"
                                            onClick={() =>
                                                clearWebhookErrors({
                                                    id: webhook.id,
                                                })
                                            }
                                        >
                                            Сбросить ошибки
                                        </ActiveButton>
                                    ) : null}
                                    <ActiveButton
                                        icon="pen"
                                        color="grey"
                                        onClick={() => onEdit(webhook.id)}
                                    >
                                        Изменить
                                    </ActiveButton>
                                    <ActiveButton
                                        icon="trash"
                                        color="grey"
                                        onClick={() =>
                                            removeWebhook({ id: webhook.id })
                                        }
                                    >
                                        Удалить
                                    </ActiveButton>
                                </div>
                            </>
                        }
                    />
                ))}
            </div>
        </CustomScrollbar>
    );
};

export default webhooksInfoCardList;
