import React, { Component } from "react";
import { connect } from "react-redux";
import CommunityList from "./CommunityList";
import {
	getCommunities,
	setCommunitiesSorting,
	connectFromArchive,
} from "../../redux/reducers/communitiesReducer";

class CommunityListContainer extends Component {
	componentDidMount() {
		this.props.getCommunities();
	}

	render() {
		return <CommunityList {...this.props}></CommunityList>;
	}
}

const mstp = (store) => {
	return {
		communities: store.communities.communities,
		sortedCommunities: store.communities.sortedCommunities,
		isLoading: store.communities.isLoading,
		isFirstLoading: store.communities.isFirstLoading,
		sortBy: store.communities.sortBy,
		searchText: store.communities.searchText,
	};
};

export default connect(mstp, {
	getCommunities,
	setCommunitiesSorting,
	connectFromArchive,
})(CommunityListContainer);
