import React, { useCallback, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import DropDownMenu from "../../components/generic/DropDownMenu/DropDownMenu";
import ActiveButton from "../../components/generic/ActiveButton/ActiveButton";
import styles from "./subscriber.module.sass";
import Icon from "../../components/generic/Icon/Icon";
import Button from "../../components/generic/Button/Button";
import UserCard from "../../components/UserCard/UserCard";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteSubscriberMark,
    deleteSubscriberUtm,
    getCommunityUtms,
    getGroupInfo,
    getSubscriberInfo,
    removeSubscriberFromGroup,
    unsubscribeFromAll2,
} from "../../redux/reducers/communitiesReducer";
import { useHistory, useParams } from "react-router";
import RoundedButtonList from "../../components/generic/RoundedButtonList/RoundedButtonList";
import InfoCard from "../../components/generic/InfoCard/InfoCard";
import Preloader from "../../components/Preloader/Preloader";
import CreateGroupPopupContainer from "../../components/popups/CreateGroupPopup/CreateGroupPopupContainer";
import AddSubscriberToGroupPopup from "../../components/popups/AddSubscriberToGroupPopup/AddSubscriberToGroupPopup";
import AddSubscriberToBot from "../../components/popups/AddSubscriberToBot/AddSubscriberToBot";
import AddSubscriberUtmPopup from "../../components/popups/AddUtmPopup/AddSubscriberUtmPopup";
import AddSubscriberMarkPopup from "../../components/popups/AddSubscriberMarkPopup/AddSubscriberMarkPopup";
import { removeBotSubscriber } from "../../redux/reducers/botsReducer";

const Subscriber = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { subscriber, subscriberIsLoading } = useSelector(
        (state) => state.communities.currentCommunity
    );
    const [isAddGroupOpen, setIsAddGroupOpen] = useState(false);
    const [isAddSubscriberUtmOpen, setIsAddSubscriberUtmOpen] = useState(false);
    const [isAddSubscriberToBotOpen, setIsAddSubscriberToBotOpen] =
        useState(false);
    const [isAddSubscriberMarkOpen, setIsAddSubscriberMarkOpen] =
        useState(false);
    const [editingGroupId, setEditingGroupId] = useState(167);
    const [isOpenGroupEditing, setIsOpenGroupEditing] = useState(false);
    const [isActionsOpen, setIsActionOpen] = useState(false);
    const [openedGroups, setOpenedGroups] = useState([]);
    const { subscriber_id, id: community_id } = useParams();
    const { utms } = useSelector((state) => state.communities.currentCommunity);
    const [filterButtons, setFilterButtons] = useState([
        {
            text: "Группы подписчиков",
            isActive: true,
            showZeroAmount: true,
            tab: "groups",
            onClick: () => tabsToggle("groups"),
        },
        {
            text: "Метки",
            isActive: false,
            showZeroAmount: true,
            tab: "utms",
            onClick: () => tabsToggle("utms"),
        },
        {
            text: "Чат-боты",
            isActive: false,
            showZeroAmount: true,
            tab: "bots",
            onClick: () => tabsToggle("bots"),
        },
    ]);
    const [activeTab, setActiveTab] = useState("groups");
    const subscriberInfo = [
        {
            label: "ID:",
            value: (
                <a
                    href={subscriber.profile_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                >
                    {subscriber.ok_id}
                </a>
            ),
        },
        { label: "Страна:", value: subscriber.country },
        { label: "Город:", value: subscriber.city },
        { label: "День рождения:", value: subscriber.birthday },
        { label: "Дата добавления:", value: subscriber.first_subscribe_date },
    ];

    const redirectToSubscribersPage = useCallback(() => {
        history.push(`/cabinet/community/${community_id}/subscribers`);
    }, [community_id, history]);

    useEffect(() => {
        dispatch(getSubscriberInfo({ subscriber_id, community_id })).then(
            (data) => {
                if (data == null) redirectToSubscribersPage();
            }
        );
    }, [dispatch, subscriber_id, community_id, redirectToSubscribersPage]);

    useEffect(() => {
        dispatch(getCommunityUtms({ community_id }));
    }, [dispatch, community_id]);

    const tabsToggle = (showTabName) => {
        setActiveTab(showTabName);
        setFilterButtons((buttons) =>
            buttons.map((button) => ({
                ...button,
                isActive: button.tab === showTabName,
            }))
        );
    };

    const groupToggle = (id) => {
        if (openedGroups.indexOf(id) >= 0) {
            setOpenedGroups(openedGroups.filter((groupId) => groupId !== id));
        } else {
            setOpenedGroups([...openedGroups, id]);
        }
    };

    const handleRemoveGroup = (group_id) => {
        dispatch(
            removeSubscriberFromGroup({ subscriber_id, community_id, group_id })
        ).then((data) => {
            if (data == null) redirectToSubscribersPage();
        });
    };

    const handleGroupEdit = (id) => {
        dispatch(getGroupInfo(id));
        setEditingGroupId(id);
        setIsOpenGroupEditing(true);
    };

    const handleRemoveSubscriber = () => {
        dispatch(unsubscribeFromAll2({ subscriber_id, community_id })).then(
            () => redirectToSubscribersPage()
        );
    };

    const refreshSubscriberData = () => {
        dispatch(getSubscriberInfo({ subscriber_id, community_id })).then(
            (data) => {
                if (data == null) redirectToSubscribersPage();
            }
        );
    };

    const handleDeleteUtm = (id) => {
        dispatch(deleteSubscriberUtm({ id, community_id, subscriber_id }));
    };

    const handleDeleteMark = (id) => {
        dispatch(deleteSubscriberMark({ id, community_id, subscriber_id }));
    };

    const handleRemoveBotSubscriber = (id) => {
        dispatch(
            removeBotSubscriber({
                bot_id: id,
                community_id,
                subscriber_id,
            })
        ).then(dispatch(getSubscriberInfo({ subscriber_id, community_id })));
    };

    return (
        <div className={styles.content}>
            {subscriberIsLoading ? <Preloader /> : null}
            <div className={styles.head}>
                <ActiveButton
                    icon="arrow-left"
                    color="grey"
                    onClick={() =>
                        history.push(
                            `/cabinet/community/${community_id}/subscribers`
                        )
                    }
                >
                    Назад
                </ActiveButton>
                <div
                    className={`dropdown_link ${isActionsOpen ? "active" : ""}`}
                >
                    <span onClick={() => setIsActionOpen((state) => !state)}>
                        Действия
                    </span>
                    <OutsideClickHandler
                        onOutsideClick={() => setIsActionOpen(false)}
                    >
                        <div onClick={() => setIsActionOpen((state) => !state)}>
                            <Icon icon="arrow-down"></Icon>
                        </div>

                        <DropDownMenu
                            className={styles.actions_dropdown}
                            isOpen={isActionsOpen}
                            onOutsideClick={() => setIsActionOpen(false)}
                            links={[
                                {
                                    text: "Обновить данные",
                                    onClick: refreshSubscriberData,
                                },
                                {
                                    text: "Удалить из базы",
                                    onClick: handleRemoveSubscriber,
                                },
                            ]}
                        ></DropDownMenu>
                    </OutsideClickHandler>
                </div>
            </div>

            <UserCard
                className={styles.profile}
                photo={subscriber.image}
                name={subscriber.fullName}
                info={subscriberInfo}
            />

            <div className={styles.tabs_nav}>
                <RoundedButtonList
                    className={styles.tab_buttons}
                    buttons={filterButtons}
                ></RoundedButtonList>
                {activeTab === "groups" ? (
                    <div
                        className="link fs14"
                        onClick={() => setIsAddGroupOpen(true)}
                    >
                        <Icon icon="plus" /> <span>Добавить группу</span>
                    </div>
                ) : null}
            </div>

            {activeTab === "groups"
                ? subscriber.groups.map((group) => (
                      <InfoCard
                          className={styles.group_item}
                          title={group.name}
                          desc={`Id: <a href=${group.link} target="_blank" class="link ${styles.id}">${group.id}</a>`}
                          settingsToggle={() => groupToggle(group.id)}
                          isOpen={openedGroups.some((id) => id === group.id)}
                          showEmptyImage
                          headerContent={
                              <div className={styles.group_data}>
                                  <div className={styles.group_data__item}>
                                      <div className={styles.group_data__label}>
                                          Дата подписки:
                                      </div>
                                      <div className={styles.group_data__text}>
                                          {group.subscribe_date || "-"}
                                      </div>
                                  </div>
                                  <div className={styles.group_data__item}>
                                      <div className={styles.group_data__label}>
                                          Источник:
                                      </div>
                                      <div className={styles.group_data__text}>
                                          {group.source || "-"}
                                      </div>
                                  </div>
                              </div>
                          }
                          extraContent={
                              <div className="button_list button_list--fullwidth_md">
                                  <ActiveButton
                                      color="grey"
                                      icon="pen"
                                      onClick={() => handleGroupEdit(group.id)}
                                  >
                                      Изменить
                                  </ActiveButton>
                                  <ActiveButton
                                      color="grey"
                                      icon="graph"
                                      onClick={() => {
                                          history.push(
                                              `/community/${community_id}/statistics/?group_id=${group.id}&subscriber_id=${subscriber.id}`
                                          );
                                          window.scrollTo(0, 0);
                                      }}
                                  >
                                      Статистика
                                  </ActiveButton>
                                  <ActiveButton
                                      color="grey"
                                      icon="trash"
                                      onClick={() =>
                                          handleRemoveGroup(group.id)
                                      }
                                  >
                                      Удалить
                                  </ActiveButton>
                              </div>
                          }
                      />
                  ))
                : null}

            <CreateGroupPopupContainer
                onClosePopup={() => setIsOpenGroupEditing(false)}
                onOpenPopup={() => setIsOpenGroupEditing(true)}
                isOpen={isOpenGroupEditing}
                groupId={editingGroupId}
                isGroupPage={false}
                afterSubmit={refreshSubscriberData}
            />
            <AddSubscriberToGroupPopup
                isOpen={isAddGroupOpen}
                onClose={() => setIsAddGroupOpen(false)}
                afterSubmit={refreshSubscriberData}
            />

            {activeTab === "utms" ? (
                <>
                    <div className={styles.item_card_nav}>
                        <div>UTM-метки</div>
                        <div
                            className="link fs14"
                            onClick={() => setIsAddSubscriberUtmOpen(true)}
                        >
                            <Icon icon="plus" /> <span>Добавить метку</span>
                        </div>
                    </div>
                    <div>
                        {subscriber.utms.map((utm) => (
                            <div className={styles.item_card} key={utm.id}>
                                <div>
                                    {utm.values.map(
                                        (u, index) =>
                                            `${index === 0 ? "" : ", "} ${u}`
                                    )}
                                </div>
                                <div
                                    className={`${styles.item_card__trash} link`}
                                    onClick={() => handleDeleteUtm(utm.id)}
                                >
                                    <Icon icon="trash" />
                                </div>
                            </div>
                        ))}
                    </div>
                    <AddSubscriberUtmPopup
                        isOpen={isAddSubscriberUtmOpen}
                        onClose={() => setIsAddSubscriberUtmOpen(false)}
                    />

                    <div className={styles.item_card_nav}>
                        <div>Метки</div>
                        {utms.filter(
                            (utm) =>
                                !subscriber.marks.find(
                                    (mark) => mark.utm_id === utm.id
                                )
                        ).length ? (
                            <div
                                className="link fs14"
                                onClick={() => setIsAddSubscriberMarkOpen(true)}
                            >
                                <Icon icon="plus" /> <span>Добавить метку</span>
                            </div>
                        ) : null}
                    </div>
                    <div>
                        {subscriber.marks.map((mark) => (
                            <div className={styles.item_card} key={mark.id}>
                                <div>{mark.name}</div>
                                <div
                                    className={`${styles.item_card__trash} link`}
                                    onClick={() => handleDeleteMark(mark.id)}
                                >
                                    <Icon icon="trash" />
                                </div>
                            </div>
                        ))}
                    </div>
                    <AddSubscriberMarkPopup
                        isOpen={isAddSubscriberMarkOpen}
                        onClose={() => setIsAddSubscriberMarkOpen(false)}
                        addedMarks={subscriber.marks}
                    />
                </>
            ) : null}

            {activeTab === "bots" ? (
                <>
                    <Button
                        icon="plus"
                        className="mb24"
                        onClick={() => setIsAddSubscriberToBotOpen(true)}
                    >
                        Добавить
                    </Button>
                    {subscriber.bots.length ? (
                        <div className={styles.chatbot_list}>
                            {subscriber.bots.map((b) => (
                                <div className={styles.chatbot} key={b.id}>
                                    <span>{b.name}</span>
                                    <div
                                        className={`link ${styles.chatbot__trash}`}
                                        onClick={() =>
                                            handleRemoveBotSubscriber(b.id)
                                        }
                                    >
                                        <Icon icon="trash" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <AddSubscriberToBot
                        isOpen={isAddSubscriberToBotOpen}
                        onClose={() => setIsAddSubscriberToBotOpen(false)}
                    />
                </>
            ) : null}
        </div>
    );
};

export default Subscriber;
