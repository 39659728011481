import React from "react";
import RoundedButton from "../RoundedButton/RoundedButton";
import styles from "./RoundedButtonList.module.sass";

const RoundedButtonList = (props) => {
    let { buttons, onButtonClick, className = "" } = props;

    return (
        <div className={`${styles.button_list} ${className}`}>
            {buttons &&
                buttons.map(({ showZeroAmount = false, ...b }, idx) =>
                    showZeroAmount ||
                    (showZeroAmount === false && b.amount > 0) ? (
                        <div className={styles.button_list__item} key={idx}>
                            <RoundedButton
                                {...b}
                                onButtonClick={onButtonClick}
                            ></RoundedButton>
                        </div>
                    ) : null
                )}
        </div>
    );
};

export default RoundedButtonList;
