import { staffAPI } from "../../api/api";

const SET_STAFF_LIST = "SET_STAFF_LIST";
const SET_STAFF_LOADING = "SET_STAFF_LOADING";
const SET_SEARCHED_STAFF = "SET_SEARCHED_STAFF";
const SET_ADMIN_LIST = "SET_ADMIN_LIST/staff";

const urlParams = new URLSearchParams(window.location.search);
const isTestData = urlParams.get("test") === "Y" ? true : false;
const testData = [
	{
		id: "1",
		user_id: "1",
		user_uid: "1",
		staff_type: "LEADER",
		staff_type_text: "Администратор",
		leader_id: "string",
		user_link: "https://ok.ru/profile/577840995437",
		name: "Лаура Баграмян",
	},
	{
		id: "2",
		user_id: "2",
		user_uid: "2",
		staff_type: "EDITOR",
		staff_type_text: "Редактор",
		leader_id: "string",
		user_link: "https://ok.ru/profile/577840995437",
		name: "Лаура Баграмян",
	},
	{
		id: "3",
		user_id: "3",
		user_uid: "3",
		staff_type: "OBSERVER",
		staff_type_text: "Наблюдатель",
		leader_id: "string",
		user_link: "https://ok.ru/profile/577840995437",
		name: "Лаура Баграмян",
	},
];

const defaultState = {
	staffList: [],
	searchedStaffList: [],
	searchText: "",
	adminList: [],
	isStaffLoading: true,
};

const staffReducer = (state = defaultState, action) => {
	switch (action.type) {
		case SET_STAFF_LIST: {
			return {
				...state,
				staffList: action.staffList,
			};
		}
		case SET_STAFF_LOADING: {
			return {
				...state,
				isStaffLoading: action.isStaffLoading,
			};
		}
		case SET_SEARCHED_STAFF: {
			let searchedStaffList = [];
			if (action.searchText) {
				searchedStaffList = state.staffList.filter((n) => {
					return n.name
						.toLowerCase()
						.indexOf(action.searchText.toLowerCase()) >= 0
						? true
						: false;
				});
			}
			return {
				...state,
				searchedStaffList,
				searchText: action.searchText,
			};
		}
		case SET_ADMIN_LIST: {
			return {
				...state,
				adminList: action.adminList,
			};
		}

		default:
			return state;
	}
};

const setStaffLoading = (isStaffLoading) => {
	return {
		type: SET_STAFF_LOADING,
		isStaffLoading,
	};
};

export const setSearchedStaff = (searchText) => {
	return {
		type: SET_SEARCHED_STAFF,
		searchText,
	};
};

const setStaffList = (staffList) => {
	return {
		type: SET_STAFF_LIST,
		staffList,
	};
};

const setAdminList = (adminList) => {
	return {
		type: SET_ADMIN_LIST,
		adminList,
	};
};

export const getStaffList = (data) => (dispatch) => {
	dispatch(setStaffLoading(true));
	staffAPI.getStaffList(data).then((response) => {
		if (isTestData) {
			dispatch(setStaffList(testData));
		} else {
			if(response.status !== "error") {
				dispatch(setStaffList(response));
			}
		}
		dispatch(setStaffLoading(false));
	});
};

export const addStaff = (data) => (dispatch) => {
	dispatch(setStaffLoading(true));
	return staffAPI.addStaff(data).then((response) => {
		dispatch(setStaffLoading(false));
		dispatch(getStaffList(data.community_id));
		return response;
	});
};

export const editStaff = (data) => (dispatch) => {
	dispatch(setStaffLoading(true));
	return staffAPI.editStaff(data).then((response) => {
		dispatch(setStaffLoading(false));
		return dispatch(getStaffList(data.community_id));
	});
};

export const deleteStaff = (data) => (dispatch) => {
	dispatch(setStaffLoading(true));
	return staffAPI.removeStaff(data).then((response) => {
		dispatch(setStaffLoading(false));
		return dispatch(getStaffList(data.community_id));
	});
};

export const getAdminList = (data) => (dispatch) => {
	return staffAPI.getAdmins(data).then((response) => {
		return dispatch(setAdminList(response));
	});
};

export default staffReducer;
