import { notificationsAPI } from "../../api/api";

const SET_NOTICES = "SET_NOTICES";
const ADD_NEW_NOTICE = "ADD_NEW_NOTICE";
const ADD_NOTICE = "ADD_NOTICE";
const REMOVE_NOTICE = "REMOVE_NOTICE";
const REMOVE_NEW_NOTICE = "REMOVE_NEW_NOTICE";

const defaultState = {
    newNotices: [],
    notices: [],
};

const noticesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_NOTICES: {
            return {
                ...state,
                notices: action.notices,
            };
        }

        case ADD_NOTICE: {
            let notices = [...state.notices];
            let isNoticeExist = state.notices.some(
                (notice) => notice.id === action.notice.id
            );
            if (!isNoticeExist) notices.push(action.notice);

            return {
                ...state,
                notices: notices,
            };
        }

        case ADD_NEW_NOTICE: {
            let notices = [...state.newNotices];
            let isNoticeExist = state.newNotices.some(
                (notice) => notice.id === action.notice.id
            );
            if (!isNoticeExist) notices.push(action.notice);

            return {
                ...state,
                newNotices: notices,
            };
        }

        case REMOVE_NOTICE: {
            return {
                ...state,
                notices: state.notices.filter(
                    (notice) => notice.id !== action.id
                ),
            };
        }

        case REMOVE_NEW_NOTICE: {
            return {
                ...state,
                newNotices: state.newNotices.filter(
                    (notice) => notice.id !== action.id
                ),
            };
        }

        default:
            return state;
    }
};

const setNoticesAC = (notices) => {
    return {
        type: SET_NOTICES,
        notices,
    };
};

const addNoticeAC = (notice) => {
    return {
        type: ADD_NOTICE,
        notice,
    };
};

const addNewNoticeAC = (notice) => {
    return {
        type: ADD_NEW_NOTICE,
        notice,
    };
};

const removeNewNoticeAC = (id) => {
    return {
        type: REMOVE_NEW_NOTICE,
        id,
    };
};

export const getNoticess = () => (dispatch, getState) => {
    notificationsAPI.getNotifications().then((data) => {
        if (data) {
            data.forEach((item) => {
                const { notices } = getState();
                if (item.showed !== true && notices.newNotices.length < 3) {
                    dispatch(addNewNotice(item));
                }
            });
            dispatch(setNoticesAC(data));
        }
    });
};

export const addNotice = (notice) => (dispatch) => {
    dispatch(addNoticeAC(notice));
};

const newNoticeTimers = [];

export const addNewNotice = (notice) => (dispatch) => {
    let { id } = notice;
    dispatch(addNewNoticeAC(notice));
	newNoticeTimers.push({
        id,
        timer: setTimeout(() => {
            dispatch(removeNewNoticeAC(id));
        }, 6000),
    });
};

export const startNewNoticeTimers = () => (dispatch) => {
	newNoticeTimers.forEach(item => {
		item.timer = setTimeout(() => {
			dispatch(removeNewNoticeAC(item.id));
		}, 6000);
	})
};

export const resetNewNoticeTimers = () => {
	newNoticeTimers.forEach(item => {
		clearTimeout(item.timer);
	})
};

export const removeNotice = (id) => (dispatch) => {
    notificationsAPI.removeNotification(id).then(() => {
        dispatch(getNoticess());
        dispatch(removeNewNoticeAC(id));
    });
};

export const removeNewNotice = (id) => (dispatch) => {
    dispatch(removeNewNoticeAC(id));
};

export default noticesReducer;
