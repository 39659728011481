import React, { useRef } from "react";
import TopCommunity from "../TopCommunity/TopCommunity";
import Icon from "../generic/Icon/Icon";
import styles from "./SubscriberGroup.module.sass";
import AnimateHeight from "react-animate-height";
import Checkbox from "../generic/Checkbox/Checkbox";
import ActiveButton from "../generic/ActiveButton/ActiveButton";
import wrapTextPart from "../../utils/wrapTextPart";
import copyToClipboard from "../../utils/copyToClipboard";

const SubscriberGroup = (props) => {
	const {
		className,
		isOpen = true,
		subscribers_count,
		subscribers_count_lastDay,
		icon,
		name,
		id,
		url,
		link,
		auto_subscribe,
		onOpenExtra,
		highlightTitlePart,
		onTitleClick,
		onEditClick,
		onRemoveClick,
		onAutosubscribeChange,
	} = props;

	const urlInputRef = useRef(null);
	let displayName = name;

	const onHeaderClick = () => {
		onOpenExtra();
	};

	// Обертка части названия в тег
	if (highlightTitlePart) {
		displayName = wrapTextPart(name, highlightTitlePart, "b");
	}

	return (
		<div
			className={`${styles.group} ${className} ${
				isOpen ? styles["group--opened"] : ""
			}`}
		>
			<header className={styles.group__header} onClick={onHeaderClick}>
				<div className={styles.group__main_info}>
					<TopCommunity
						community={{
							image: icon,
							name: displayName,
							ok_id: id,
							url,
						}}
						onTitleClick={onTitleClick}
						size="sm"
					/>
				</div>
				<div className={styles.group__right_info}>
					{subscribers_count != null ? (
						<div className={styles.group__info_item}>
							Подписчиков:{" "}
							<span className={styles.group__info_item_value}>
								{subscribers_count}
							</span>
						</div>
					) : null}
					{subscribers_count_lastDay != null &&
					subscribers_count_lastDay !== "0" ? (
						<div className={styles.group__info_item}>
							<span className={styles.group__info_item_value}>
								{subscribers_count_lastDay > 0
									? `+${subscribers_count_lastDay}`
									: subscribers_count_lastDay}
							</span>
						</div>
					) : null}
				</div>
				<div className={`${styles.group__show_more} d-md-block d-none`}>
					<Icon icon="dots" />
				</div>
			</header>

			<AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
				<div className={styles.group__extra}>
					{link ? (
						<div className={styles.group__extra_block}>
							<div className={styles.group__extra_block_item}>
								<div
									className={styles.group__extra_block_title}
								>
									{link}
									<input
										type="textarea"
										className="hidden_input"
										ref={urlInputRef}
										defaultValue={link}
									/>
								</div>
								<div
									className={styles.group__extra_block_links}
								>
									<div
										className={`${styles.group__extra_block_link} link`}
										onClick={() => copyToClipboard(link)}
									>
										Скопировать ссылку
									</div>
									<a
										href={link}
										// eslint-disable-next-line
										target="_blank"
										className={`${styles.group__extra_block_link} grey_link`}
									>
										<Icon icon="share" />
										<span>Перейти</span>
									</a>
								</div>
							</div>
						</div>
					) : null}

					<Checkbox
						checked={auto_subscribe === true ? true : false}
						className={styles.group__extra_checkbox}
						onChange={onAutosubscribeChange}
					>
						Автоматическая подписка при переходе
					</Checkbox>

					<div className="button_list button_list--fullwidth_md">
						<ActiveButton
							color="grey"
							icon="pen"
							onClick={onEditClick}
						>
							Изменить
						</ActiveButton>
						<ActiveButton
							color="grey"
							icon="trash"
							onClick={onRemoveClick}
						>
							Удалить
						</ActiveButton>
					</div>
				</div>
			</AnimateHeight>
			<div
				className={`${styles.group__show_more} d-md-none d-block`}
				onClick={onOpenExtra}
			>
				<Icon icon="dots" />
			</div>
		</div>
	);
};

export default SubscriberGroup;
