import React from "react";
import Icon from "../Icon/Icon";
import styles from "./TextFeature.module.sass";

const TextFeature = (props) => {
	const { icon, text, feature, theme, className = "" } = props;

	return (
		<div
			className={`${styles.feature} ${
				theme === "sm" ? styles["feature--sm"] : ""
			} ${className}`}
		>
			{icon ? (
				<Icon icon={icon} className={styles.feature__icon}></Icon>
			) : null}
			{text ? <div className={styles.feature__text}>{text}</div> : null}
			{feature != null ? (
				<div className={styles.feature__feature}>{feature}</div>
			) : null}
		</div>
	);
};

export default TextFeature;
