import React from "react";
import CircleIconText from "../CircleIconText/CircleIconText";
import styles from "./CircleIconTextList.module.sass";

const CircleIconTextList = (props) => {
	const { items = [], className, ...rest } = props;

	return items.length ? (
		<div className={`${styles.list} ${className}`} {...rest}>
			{items.map((item, index) => (
				<div className={styles.list__item} key={index}>
					<CircleIconText {...item} />
				</div>
			))}
		</div>
	) : null;
};

export default CircleIconTextList;
