import React from "react";
import styles from "./Radio.module.sass";

const Radio = (props) => {
	const { children, description, id = "", onChange, error, ...rest } = props;

	const onRadioChange = (e) => {
		if(onChange) {
			onChange(e)
		}
	}


	return (
		<div className={`${styles.radio} ${error ? styles.error : ""}`}>
			<input type="radio" id={id} onChange={onRadioChange} {...rest} />
			<label htmlFor={id} className={styles.radio__icon}></label>
			{children || description ? (
				<label htmlFor={id} className={styles.radio__content}>
					{children ? (
						<div className={styles.radio__title}>{children}</div>
					) : null}

					{description ? (
						<div className={styles.radio__description}>
							{description}
						</div>
					) : null}
				</label>
			) : null}
		</div>
	);
};

export default Radio;
