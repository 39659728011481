import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import routes from "../routes";

export const withAccessRedirect = (Component) => {
	
	class RedirectComponent extends React.Component {
		render() {
			const { staff_type, match } = this.props;
			const route = routes.find((r) => r.path === match.path);

			// Если в роуте есть доступ к таому staff_type то пропускаем, иначе редирект
			if(route.access.indexOf(staff_type) >= 0 || !staff_type) {
				return <Component {...this.props}></Component>;
			} else {
				let route = routes.find((r) => r.access ? r.access.indexOf(staff_type) >= 0 : false);
				if(route && route.path) {
					const path = route.path.replace(":id", match.params.id);
					return <Redirect to={path} />;
				} else {
					return <Redirect to="/" />;
				}
			}
		}
	}

	const mstp = (state) => {
		return {
			staff_type: state.auth.userData.staff_type,
		};
	};

	return compose(connect(mstp, {}), withRouter)(RedirectComponent);
};
