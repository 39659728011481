import React from "react";
import { NavLink } from "react-router-dom";
import wrapTextPart from "../../utils/wrapTextPart";
import CustomLink from "../CustomLink/CustomLink";
import styles from "./Community.module.sass";

const Community = (props) => {
    let {
        id,
        ok_id,
        name = "",
        archived = "",
        subscribers_count,
        subscribers_count_today,
        messages_count,
        status = "0",
        image = "",
        url = "",
        highlightTitlePart,
        connect_url,
        onArchivedConnect,
        type,
        api_key_error = "0",
    } = props;

    let modifier = "";

    // Если подключено, то добавляем класс-модификатор
    if (status === "1") {
        modifier = styles["community--green"];
    }
    if (archived === "1") {
        modifier = styles["community--disabled"];
    }
    if (api_key_error === "1") {
        modifier = styles["community--error"];
    }

    // Обертка части названия в тег
    if (highlightTitlePart) {
        name = wrapTextPart(name, highlightTitlePart, "b");
    }

    const communityContent = () => {
        return (
            <div className="row">
                {api_key_error === "1" ? (
                    <div className={styles.community__status}>
                        Ошибка (API ключ не активен)
                    </div>
                ) : status === "1" ? (
                    <div className={styles.community__status}>Подключено</div>
                ) : null}

                {archived === "1" ? (
                    <div className={styles.community__status}>В архиве</div>
                ) : null}

                <div className="col-xl-6 col-md-5">
                    <div className={styles.community__header}>
                        {image ? (
                            <div
                                className={styles.community__photo}
                                style={{ backgroundImage: `url(${image})` }}
                            ></div>
                        ) : (
                            <svg
                                viewBox="0 0 240 240"
                                className={styles.community__photo}
                            >
                                <path
                                    className="svg-fill"
                                    d="M120 116.3c-7.8 0-14.1-6-15.5-17.5-1.1-10 1.6-20.8 15.4-20.8 13.7 0 16.6 10.8 15.2 20.9-1.6 11.4-7.3 17.4-15.1 17.4zm39.2 4.4c-6.2 0-11.3-4.8-12.4-14.1-.9-7.9 1.3-16.6 12.3-16.6s13.4 8.7 12.2 16.8c-1.3 9.1-5.8 13.9-12.1 13.9zm-78.1-.2c-6.3 0-11.4-4.8-12.4-14.1-1-8 1.2-16.6 12.3-16.6 11 0 13.3 8.6 12.2 16.7-1.3 9.2-5.9 14-12.1 14zm80.5 10.2c6.4.3 13.6 1.3 19.5 3.8 7 2.9 9.5 11 10.5 16.2 0 .2 0 .4.1.6.5 2.7-.3 5.4-2 7.4-1.7 2.1-4.2 3.2-6.9 3.2h-11.1c.1-.7.2-1.4.2-2.1.1-.9 0-1.8 0-2.7l-.3-2.1c-.1-.9-.2-1.7-.4-2.6-.1-1-.3-1.9-.5-2.8-1.2-5.2-3.1-10.4-6.1-14.9-.9-1.4-1.9-2.7-3-4zm-82.8-.2c-1.2 1.3-2.4 2.7-3.4 4.3-1.7 2.7-3.1 5.6-4.2 8.6-.6 1.7-1.1 3.4-1.6 5.2-.2 1-.5 1.9-.7 2.9l-.2 1c-.2 1.2-.4 2.4-.6 3.5-.3 2-.3 4 0 5.9H57.2c-2.8 0-5.4-1.3-7.1-3.4-1.7-2.1-2.3-4.9-1.7-7.6v-.3c1.3-5.3 3.5-13.1 10.7-16.3 5.7-2.5 13.2-3.5 19.7-3.8zm8.9 37.4c-2.7 0-5.2-1.1-6.9-3.2-1.7-2-2.4-4.7-2-7.3.3-1.6.5-2.9.6-3.5 1.5-6.5 4.3-16.4 13.3-20.3 7.8-3.4 18.3-4.7 27-4.9 8.7.2 19.3 1.2 27.8 4.8 8.7 3.7 11.8 14 13 20.4.1.6.3 2 .5 3.7.4 2.6-.3 5.2-2.1 7.2-1.7 2-4.1 3.1-6.8 3.1H87.7z"
                                />
                            </svg>
                        )}

                        <div className={styles.community__header_info}>
                            {name ? (
                                <div
                                    className={styles.community__name}
                                    dangerouslySetInnerHTML={{
                                        __html: name,
                                    }}
                                ></div>
                            ) : null}

                            {ok_id ? (
                                <div className={styles.community__id_wrap}>
                                    <span
                                        className={styles.community__id_label}
                                    >
                                        Id:
                                    </span>{" "}
                                    <a
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.community__id}
                                    >
                                        {ok_id}
                                    </a>{" "}
                                    {type != null ? (
                                        <span
                                            className={`${styles.community__id_label} wrp`}
                                        >
                                            {type === "OWNER"
                                                ? "(собственное сообщество)"
                                                : type === "GUEST"
                                                ? "(гостевое сообщество)"
                                                : null}
                                        </span>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-md-7 d-flex justify-content-md-end">
                    <div className={styles.community__info_list}>
                        {status === "1" && messages_count != null ? (
                            <div className={styles.community__info_item}>
                                Сообщения: {messages_count}
                                {messages_count ? (
                                    <span
                                        className={
                                            styles.community__info_item_new
                                        }
                                    >
                                        {messages_count}
                                    </span>
                                ) : null}
                            </div>
                        ) : null}

                        {status === "1" && subscribers_count != null ? (
                            <div className={styles.community__info_item}>
                                Подписчики: {subscribers_count}
                                {subscribers_count_today ? (
                                    <span
                                        className={
                                            styles.community__info_item_new
                                        }
                                    >
                                        {subscribers_count_today > 0
                                            ? `+${subscribers_count_today}`
                                            : subscribers_count_today}
                                    </span>
                                ) : null}
                            </div>
                        ) : null}

                        {status === "0" ? (
                            <div className={styles.community__info_item}>
                                {archived === "1" ? (
                                    <NavLink
                                        to={`/community/${id}`}
                                        className={`link ${styles.community__info_link}`}
                                        onClick={onArchivedConnect}
                                    >
                                        Подключить сообщество
                                    </NavLink>
                                ) : (
                                    <a
                                        href={connect_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`link ${styles.community__info_link}`}
                                    >
                                        Подключить сообщество
                                    </a>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    return status === "1" && api_key_error !== "1" ? (
        <CustomLink
            to={`/community/${id}`}
            className={`${styles.community} ${modifier}`}
        >
            {communityContent()}
        </CustomLink>
    ) : (
        <div className={`${styles.community} ${modifier}`}>
            {communityContent()}
        </div>
    );
};

export default Community;
