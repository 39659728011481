import React, { Component } from "react";
import { connect } from "react-redux";
import OnetimeMailingPopup from "./OnetimeMailingPopup";
import {
	createNewsletter,
	getRecipientsCount,
	editNewsletter,
	sendMeNewsletter,
	newsletterCopy,
	activateNewsletter
} from "../../../redux/reducers/newslettersReducer";
import { withRouter } from "react-router-dom";
import { getSubscribersGroups } from "../../../redux/reducers/communitiesReducer";

class OnetimeMailingPopupContainer extends Component {
	componentDidMount() {
		if(this.props.isOpen) {
			let community_id = this.props.match.params.id;
			this.props.getRecipientsCount({ community_id });
		}
	}

	componentDidUpdate(prevProps) {
		if(prevProps.isOpen === false && this.props.isOpen === true) {
			let community_id = this.props.match.params.id;
			this.props.getSubscribersGroups(community_id);
		}
	}

	render() {
		return <OnetimeMailingPopup {...this.props}></OnetimeMailingPopup>;
	}
}

const mstp = (store) => {
	return {
		subscribersGroups: store.communities.currentCommunity.groups,
		recipientsCount: store.newsletters.recipientsCount,
		isLoading: store.newsletters.currentNewsletterInfoLoading,
		tooltips: store.tooltips.tooltips,
	};
};

const withRouterOnetimeMailingPopupContainer = withRouter(
	OnetimeMailingPopupContainer
);

export default connect(mstp, {
	createNewsletter,
	getSubscribersGroups,
	getRecipientsCount,
	editNewsletter,
	sendMeNewsletter,
	newsletterCopy,
	activateNewsletter
})(withRouterOnetimeMailingPopupContainer);
