import React, { useState } from "react";
import AccessUser from "../AccessUser/AccessUser";
import styles from "./AccessUserList.module.sass";
import CustomScrollbar from "../generic/CustomScrollbar/CustomScrollbar";
import Icon from "../generic/Icon/Icon";
import Search from "../Search/Search";
import Preloader from "../Preloader/Preloader";
import AddStuffPopup from "../popups/AddStuffPopup/AddStuffPopup";
import DeleteStuffPopup from "../popups/DeleteStuffPopup/DeleteStuffPopup";

const AccessUserList = (props) => {
	const {
		users = [],
		isLoading,
		searchText,
		searchedUsers,
		setSearchedStaff,
		addStaff,
		editStaff,
		deleteStaff,
		communityName,
		userData,
	} = props;
	const [openedSettings, setOpenedSettings] = useState([]);
	const [isAddUserOpen, setIsAddUserOpen] = useState(false);
	const [isEditUserOpen, setIsEditUserOpen] = useState(false);
	const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false);
	const [editingUser, setEditingUser] = useState({});

	// При открытии доп информации
	const onSettingsToggle = (id) => {
		let newOpenedSettings = [...openedSettings];
		let index = openedSettings.indexOf(id);
		if (index >= 0) {
			newOpenedSettings.splice(index, 1);
		} else {
			newOpenedSettings.push(id);
		}
		setOpenedSettings(newOpenedSettings);
	};

	// При вводе в поиск
	const onSearchChange = (e) => {
		setSearchedStaff(e.target.value);
	};

	// При клике на имя
	const onTitleClick = (user) => {
		setEditingUser(user);
		setIsEditUserOpen(true);
	};

	// Удаление
	const onRemoveClick = (user) => {
		setIsDeleteUserOpen(true);
		setEditingUser(user);
	};

	let displayUsers = users;
	if (searchText) {
		displayUsers = searchedUsers;
	}

	return (
		<>
			<div className={styles.access_main}>
				{isLoading ? <Preloader /> : null}

				<div className={styles.access_nav}>
					<div
						className={`link ${styles.access_nav__link}`}
						onClick={() => setIsAddUserOpen(true)}
					>
						<Icon icon="plus" /> <span>Добавить сотрудника</span>
					</div>

					<div className={styles.access_nav__search}>
						<Search
							onChange={onSearchChange}
							value={searchText}
							placeholder="Найти пользователя"
						/>
					</div>
				</div>

				<CustomScrollbar
					disablesize="xl"
					className={styles.access_list}
				>
					{displayUsers.length ? (
						displayUsers.map((user, index) => (
							<div
								className={styles.access_list__item}
								key={index}
							>
								<AccessUser
									{...user}
									highlightPart={searchText}
									onSettingsToggle={onSettingsToggle}
									onTitleClick={() => onTitleClick(user)}
									onEditClick={() => onTitleClick(user)}
									onRemoveClick={() => onRemoveClick(user)}
									isOpen={openedSettings.some(
										(o) => o === user.id
									)}
								/>
							</div>
						))
					) : searchText ? (
						<div className={styles.access_list__not_found}>
							Пользователь не найден
						</div>
					) : (
						""
					)}
				</CustomScrollbar>
			</div>
			<AddStuffPopup
				leaders={
					users
						? users.filter((user) => user.staff_type === "LEADER")
						: []
				}
				isOpen={isAddUserOpen}
				onClosePopup={() => setIsAddUserOpen(false)}
				addStaff={addStaff}
				isLoading={isLoading}
				userData={userData}
			/>
			<AddStuffPopup
				leaders={
					users
						? users.filter((user) => user.staff_type === "LEADER")
						: []
				}
				isOpen={isEditUserOpen}
				isEditing={true}
				onClosePopup={() => setIsEditUserOpen(false)}
				editStaff={editStaff}
				isLoading={isLoading}
				user={editingUser}
				userData={userData}
			/>
			<DeleteStuffPopup
				isOpen={isDeleteUserOpen}
				user={editingUser}
				onOpenPopup={() => setIsDeleteUserOpen(true)}
				onClosePopup={() => setIsDeleteUserOpen(false)}
				isLoading={isLoading}
				deleteStaff={deleteStaff}
				communityName={communityName}
			/>
		</>
	);
};

export default AccessUserList;
