import React from "react";
import styles from "./TagList.module.sass";
import Tag from "../Tag/Tag";

const TagList = (props) => {
    const { tags, title, className, ...rest } = props;

    return (
        <>
            {title ? (
                <div className={styles.tag_list__title}>{title}</div>
            ) : null}
            <div className={`${styles.tag_list} ${className}`}>
                {tags.map((t, index) => (
                    <div className={styles.tag_list__item} key={index}>
                        <Tag {...rest} icon={t.icon}>
                            {t.text}
                        </Tag>
                    </div>
                ))}
            </div>
        </>
    );
};

export default TagList;
