import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "../generic/Button/Button";
import CustomScrollbar from "../generic/CustomScrollbar/CustomScrollbar";
import TextFeature from "../generic/TextFeature/TextFeature";
import NoResult from "../generic/NoResult/NoResult";
import Preloader from "../Preloader/Preloader";
import {
    getOkSubscribersGroups,
    addSubscriber,
    removeSubscriber,
    unsubscribeFromAll,
    getCommunityStatus,
    connectCommunity,
    getDisconnectUrl,
    getGroupOkinfo,
    checkSubscriber,
} from "../../redux/reducers/communitiesReducer";
import "./NewsletterFrame.sass";
import character from "../../assets/images/character-2.svg";
import character2 from "../../assets/images/character.svg";
import characterThankyou from "../../assets/images/character-thankyou.svg";
import characterSad from "../../assets/images/character-sad.svg";
import styles from "./Newsletter.module.sass";
import Alert from "../generic/Alert/Alert";
import FrameHeader from "../FrameHeader/FrameHeader";
import addScript from "../../utils/addScript";
import AboutServiceFrame from "../AboutServiceFrame/AboutServiceFrame";
import { communityAPI, subscriberAPI } from "../../api/api";
import SuccessText from "../generic/SuccessText/SuccessText";

class NewsletterFrame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            communityStatus: false,
            isLoading: false,
            disconnectUrl: "",
            isSubscribed: false,
            errorAlert: {
                isActive: false,
                title: "",
                text: "",
            },
            isend_community_id: null,
            subscribedSuccess: false,
            unsubscribedSuccess: false,
        };
        this.connectUrl = `https://isend.pro/community/`;
        this.isSubscribeClick = false;
        this.okApiInited = false;
        this.onConnectClick = this.onConnectClick.bind(this);
    }

    componentDidMount() {
        // Достаем хэш-параметры из урла
        let hashQuery = new URLSearchParams(
            this.props.location.hash.replace("#", "?")
        );

        this.permissions_granted = hashQuery.get("permissions_granted");
        if (hashQuery.get("access_token"))
            this.access_token = hashQuery.get("access_token");

        // Удаляю лишние символы, если они попали
        if (this.community_uid)
            this.community_uid = this.community_uid.replace("/", "");

        // Callback ф-я для ok
        window.API_callback = (method, result, data) => {
            let jsonData, access_token;

            if (data) {
                jsonData = JSON.parse(data);
                access_token = jsonData.access_token;
            }

            if (access_token && this.community_uid) {
                this.access_token = access_token;

                this.setState((state) => ({
                    ...state,
                    isLoading: true,
                }));
                this.props
                    .connectCommunity(this.community_uid, access_token)
                    .then((data) => {
                        // Проверка на ошибки в респонсе
                        if (data.status === "error") {
                            const { title, text } = data;
                            this.setState((state) => ({
                                ...state,
                                errorAlert: {
                                    isActive: true,
                                    title,
                                    text,
                                },
                            }));
                        }
                        return this.props.getCommunityStatus(
                            this.community_uid
                        );
                    })
                    .then((data) => {
                        this.setState((state) => ({
                            ...state,
                            communityStatus: data.status,
                            isLoading: false,
                        }));
                    });
            }

            // Если кликнуто по кнопке "Подписаться"
            if (this.state.isSubscribeClick) {
                let new_window;
                if (this.state.onSubscribeRedirectUrl) {
                    new_window = window.open();
                }

                let groupId =
                    this.props.groupData && this.props.groupData.id
                        ? this.props.groupData.id
                        : this.clickedGroupId;
                this.props
                    .addSubscriber(
                        this.user_id,
                        groupId,
                        this.community_uid,
                        this.subscribeSource,
                        this.utm,
                        this.utm_source,
                        this.utm_medium,
                        this.utm_campaign,
                        this.utm_content,
                        this.utm_term
                    )
                    .then(() => {
                        // Показываем success подписки
                        this.setState((state) => ({
                            ...state,
                            subscribedSuccess: true,
                            unsubscribedSuccess: false,
                        }));

                        this.getGroupInfo();
                        if (this.state.onSubscribeRedirectUrl) {
                            // Если всплывающее окно заблокировано
                            // if (
                            //     new_window == null ||
                            //     typeof new_window == "undefined" ||
                            //     !new_window ||
                            //     new_window.closed ||
                            //     typeof new_window.closed == "undefined"
                            // ) {
                            //     alert(
                            //         "Для корректной работы рассылок сообщества, разрешите всплывающие окна в настройках вашего браузера"
                            //     );
                            // }

                            new_window.location =
                                this.state.onSubscribeRedirectUrl;
                            new_window.focus();
                        }
                    });
            }
        };
        // Callback ф-я для ok END

        // Подключение OK API
        addScript({ src: "//api.ok.ru/js/fapi5.js", async: true }).then(() => {
            let FAPI = window.FAPI;
            let rParams = FAPI.Util.getRequestParameters();
            FAPI.init(
                rParams["api_server"],
                rParams["apiconnection"],
                /*
                 * Первый параметр:
                 * функция, которая будет вызвана после успешной инициализации.
                 */
                () => {
                    // Инициализация OKSDK
                    window.OKSDK.init(
                        {
                            app_id: 512000624476, // id приложения
                            app_key: "CNFIIOJGDIHBABABA", // публичный ключ приложения
                        },
                        () => {
                            this.okApiInited = true;
                            // Разрешение на отправку сообщений, при загрузки страницы, если уже подписан
                            if (
                                (this.community_uid || this.community_id) &&
                                this.state.isSubscribed
                            ) {
                                let askPermInterval = setInterval(() => {
                                    if (window.FAPI) {
                                        window.OKSDK.Widgets.askGroupAppPermissions(
                                            "MESSAGES_FROM_GROUP",
                                            `https://${window.location.hostname}/frame/newsletters`
                                        );
                                        clearInterval(askPermInterval);
                                    }
                                }, 500);
                            }

                            // Нужно ли сразу сделать подключение сообщества
                            if (this.connect_immediately) {
                                this.onConnectClick();
                            }
                        },
                        (error) => {
                            console.error("OKSDK error: " + error);
                        }
                    );
                    // Инициализация OKSDK END
                },
                /*
                 * Второй параметр:
                 * функция, которая будет вызвана, если инициализация не удалась.
                 */
                () => {
                    console.error("Ошибка инициализации api.ok");
                }
            );
        });
        // Подключение OK API END

        // Получение данных из дочернего окна
        window.onmessage = (e) => {
            if (typeof e.data === "object" && e.data.status === "error") {
                const { title, text } = e.data;
                this.setState((state) => ({
                    ...state,
                    errorAlert: {
                        isActive: true,
                        title,
                        text,
                    },
                }));
            }
            if (e.data === "reload") {
                window.location.reload();
            }
            if (e.data === "permissions_granted") {
                let groupId =
                    this.props.groupData && this.props.groupData.id
                        ? this.props.groupData.id
                        : this.clickedGroupId;
                if (groupId && this.user_id && this.community_uid) {
                    this.props
                        .addSubscriber(
                            this.user_id,
                            groupId,
                            this.community_uid,
                            this.subscribeSource,
                            this.utm,
                            this.utm_source,
                            this.utm_medium,
                            this.utm_campaign,
                            this.utm_content,
                            this.utm_term
                        )
                        .then(() => {
                            // Показываем success подписки
                            this.setState((state) => ({
                                ...state,
                                subscribedSuccess: true,
                            }));

                            this.getGroupInfo();
                        });
                }
            }
        };

        // Загрузка списка групп
        if (this.community_uid) {
            this.props.getOkSubscribersGroups(this.community_uid, this.user_id);
        }

        // Получение статуса сообщества
        if (this.community_uid) {
            this.setState({ ...this.state, isLoading: true });
            this.props.getCommunityStatus(this.community_uid).then((data) => {
                this.setState((state) => ({
                    ...state,
                    communityStatus: data.status,
                    isLoading: false,
                }));
            });
        }

        // Получение ссылки на отключение
        if (this.community_uid) {
            this.props.getDisconnectUrl(this.community_uid).then((data) => {
                this.setState((state) => ({
                    ...state,
                    disconnectUrl: data.url,
                }));
            });
        }

        // Отписываем от всех, если передан параметр
        if (this.unsubscribe_all === "y") {
            this.onClickUnsubscribeAll();
        }

        if (this.community_id) {
            this.getGroupInfo().then((data) => {
                // Автоподписка
                if (data.auto_subscribe) {
                    this.onClickAddSubscriber(
                        data.id,
                        data.page_after_subscribe
                    );
                }
            });
        }

        // Если получено разрешение и есть токен в урле, то подключаем сообщество
        if (
            this.permissions_granted === "GROUP_BOT_API_TOKEN" &&
            this.access_token
        ) {
            let errorStatus = {};
            this.props
                .connectCommunity(this.community_uid, this.access_token)
                .then((data) => {
                    // Проверка на ошибки в респонсе
                    if (data.status === "error") {
                        errorStatus = { ...data };
                    }
                    return this.props.getCommunityStatus(this.community_uid);
                })
                .then((data) => {
                    this.setState((state) => ({
                        ...state,
                        communityStatus: data.status,
                        isLoading: false,
                    }));
                    return data;
                })
                .then(() => {
                    if (errorStatus.status === "error") {
                        window.opener.postMessage(errorStatus, "*");
                    } else {
                        window.opener.postMessage("reload", "*");
                    }
                    window.close();
                });
        } else if (hashQuery.get("access_token") || hashQuery.get("error")) {
            // В конце. Если access_token или ошибка передана в хэш параметр, то закрываем это окно
            window.close();
        } else if (this.permissions_granted === "MESSAGES_FROM_GROUP") {
            window.opener.postMessage("permissions_granted", "*");
            if (
                this.onSubscribeRedirectUrl &&
                this.onSubscribeRedirectUrl !== "null"
            ) {
                window.location = this.onSubscribeRedirectUrl;
            } else {
                window.close();
            }
        }

        // Получение внутреннего id сообщества
        communityAPI
            .getId({ community_uid: this.community_uid })
            .then((response) => {
                let id = null;
                if (response) {
                    id = response.id;
                }
                this.setState((state) => ({
                    ...state,
                    isend_community_id: id,
                }));
            });

        // Если в custom_args был unsubscribe, то описываем
        if (this.unsubscribe_distribution_id) {
            subscriberAPI
                .unsubscribeFromDistribution({
                    source: this.subscribeSource,
                    distribution_id: this.unsubscribe_distribution_id,
                    community_uid: this.community_uid,
                    subscriber_uid: this.user_id,
                })
                .then(() => {
                    this.props.getOkSubscribersGroups(
                        this.community_uid,
                        this.user_id
                    );
                });
        }
    }

    // При клике "Подписаться"
    onClickAddSubscriber(group_id, redirect_url) {
        // let isBlockerAlerCalled = false;
        if (this.community_uid || this.community_id) {
            this.clickedGroupId = group_id;
            this.setState({ onSubscribeRedirectUrl: redirect_url });
            this.onSubscribeRedirectUrl = redirect_url;
            let askPermInterval = setInterval(
                function () {
                    if (window.FAPI && this.okApiInited) {
                        this.isSubscribeClick = true;
                        this.setState({ isSubscribeClick: true });

                        // if (this.mob === "true" && !isBlockerAlerCalled) {
                        //     let new_window = window.open();
                        //     // Если всплывающее окно заблокировано
                        //     if (
                        //         new_window == null ||
                        //         typeof new_window == "undefined" ||
                        //         !new_window ||
                        //         new_window.closed ||
                        //         typeof new_window.closed == "undefined"
                        //     ) {
                        //         alert(
                        //             "Для корректной работы рассылок сообщества, разрешите всплывающие окна в настройках вашего браузера"
                        //         );
                        // 		isBlockerAlerCalled = true;
                        //     }
                        // 	new_window.close();
                        // }

                        window.OKSDK.Widgets.askGroupAppPermissions(
                            "MESSAGES_FROM_GROUP",
                            `https://${window.location.hostname}/frame/newsletters/?onSubscribeRedirectUrl=${redirect_url}`
                        );
                        clearInterval(askPermInterval);
                    }
                }.bind(this),
                500
            );
        }
    }

    // Клик по "Отписаться"
    onClickRemoveSubscriber(group_id, redirect_url) {
        let new_window;
        if (redirect_url) {
            new_window = window.open();
        }

        this.isSubscribeClick = false;
        this.props
            .removeSubscriber(
                this.user_id,
                group_id,
                this.community_uid,
                this.subscribeSource,
                this.utm,
                this.utm_source,
                this.utm_medium,
                this.utm_campaign,
                this.utm_content,
                this.utm_term
            )
            .then(() => {
                // Показываем success подписки
                this.setState((state) => ({
                    ...state,
                    subscribedSuccess: false,
                    unsubscribedSuccess: true,
                }));

                this.getGroupInfo();

                if (redirect_url) {
                    // Если всплывающее окно заблокировано
                    // if (
                    //     new_window == null ||
                    //     typeof new_window == "undefined" ||
                    //     !new_window ||
                    //     new_window.closed ||
                    //     typeof new_window.closed == "undefined"
                    // ) {
                    //     alert(
                    //         "Для корректной работы рассылок сообщества, разрешите всплывающие окна в настройках вашего браузера"
                    //     );
                    // }

                    new_window.location = redirect_url;
                    new_window.focus();
                }
            });
    }

    onClickUnsubscribeAll() {
        this.props.unsubscribeFromAll(
            this.user_id,
            this.community_uid,
            this.subscribeSource,
            this.utm,
            this.utm_source,
            this.utm_medium,
            this.utm_campaign,
            this.utm_content,
            this.utm_term
        );
    }

    getGroupInfo() {
        return this.props
            .getGroupOkinfo(this.community_id, this.user_id)
            .then((data) => {
                this.checkSubscriber();
                return data;
            });
    }

    checkSubscriber() {
        return this.props
            .checkSubscriber(this.community_id, this.user_id)
            .then((data) => {
                this.setState((state) => ({
                    ...state,
                    isSubscribed: data.subscribed,
                }));
            });
    }

    onConnectClick() {
        if (this.viewer_type === "ADMIN") {
            window.OKSDK.Widgets.askGroupAppPermissions(
                "GROUP_BOT_API_TOKEN",
                `https://${window.location.hostname}/frame/newsletters?group_id=${this.community_uid}`
            );
        }
    }

    render() {
        const { subscribersGroups, groupData } = this.props;

        // Достаем параметры из урла
        let query = new URLSearchParams(this.props.location.search);

        this.community_uid = query.get("group_id");
        this.user_id = query.get("logged_user_id");
        this.viewer_type = query.get("viewer_type");
        this.mob = query.get("mob");
        this.onSubscribeRedirectUrl = query.get("onSubscribeRedirectUrl");
        this.custom_args = new URLSearchParams(query.get("custom_args"));
        this.community_id = this.custom_args.get("group");
        this.unsubscribe_all = this.custom_args.get("unsubscribe_all");
        this.access_token = this.custom_args.get("access_token");
        // Utm метки
        this.utm = this.custom_args.get("utm") || "";
        this.utm_source = this.custom_args.get("utm_source") || "";
        this.utm_medium = this.custom_args.get("utm_medium") || "";
        this.utm_campaign = this.custom_args.get("utm_campaign") || "";
        this.utm_content = this.custom_args.get("utm_content") || "";
        this.utm_term = this.custom_args.get("utm_term") || "";
        this.unsubscribe_distribution_id =
            this.custom_args.get("unsubscribe") || "";
        // Utm метки END
        this.subscribeSource =
            this.community_id != null ? "SUBSCRIBE_PAGE" : "COMMUNITY";
        // Делать ли подключения сразу
        this.connect_immediately =
            this.custom_args.get("connect_immediately") === "true"
                ? true
                : false;

        return (
            <>
                {this.state.isLoading ? <Preloader /> : null}

                {/* Alert с ошибкой */}
                <Alert
                    isActive={this.state.errorAlert.isActive}
                    title={this.state.errorAlert.title}
                    text={this.state.errorAlert.text}
                    noticeBackground={"rgba(255, 255, 255, 0.8)"}
                    onClose={() =>
                        this.setState((state) => ({
                            ...state,
                            errorAlert: {
                                ...this.state.errorAlert,
                                isActive: false,
                            },
                        }))
                    }
                />

                {this.community_id ? (
                    // Посадочная страница

                    <div className={styles.landing_wrap}>
                        <CustomScrollbar
                            className={`${styles.landing} newsletter_frame_landing`}
                            disablesize="md"
                        >
                            {!(
                                ((this.state.subscribedSuccess &&
                                    groupData.popup_title_after_subscribe) ||
                                    (this.state.unsubscribedSuccess &&
                                        groupData.popup_title_after_unsubscribe)) &&
                                groupData.show_notifications
                            ) ? (
                                <>
                                    <div
                                        className={
                                            styles.landing__header_mob_nav
                                        }
                                    >
                                        <div
                                            className={
                                                styles.landing__header_mob_nav_col
                                            }
                                        >
                                            {groupData.hide_subscribers_amount ? null : (
                                                <TextFeature
                                                    className="mb0"
                                                    icon="group"
                                                    text="Подписчики:"
                                                    feature={
                                                        groupData.subscribers_count
                                                    }
                                                    theme="sm"
                                                />
                                            )}
                                        </div>
                                        {this.state.isSubscribed ? (
                                            <div
                                                className={`${styles.landing__header_nav_col} text_right`}
                                            >
                                                <SuccessText>
                                                    Вы подписаны
                                                </SuccessText>
                                            </div>
                                        ) : null}
                                    </div>
                                    {groupData.title ? (
                                        <header
                                            className={styles.landing__title}
                                        >
                                            {groupData.title}
                                        </header>
                                    ) : null}
                                    {groupData.banner ? (
                                        <img
                                            src={groupData.banner}
                                            alt="banner"
                                            className={styles.landing__banner}
                                        />
                                    ) : null}
                                    {groupData.full_description ? (
                                        <p
                                            className={styles.landing__text}
                                            dangerouslySetInnerHTML={{
                                                __html: groupData.full_description,
                                            }}
                                        ></p>
                                    ) : null}
                                    <footer className={styles.landing__footer}>
                                        {this.state.isSubscribed ? (
                                            <>
                                                <div
                                                    className={`${styles.landing__footer_col} ${styles.landing__footer_unsubscribe_col}`}
                                                >
                                                    {groupData.hide_unsubscribe_button ? null : (
                                                        <a
                                                            href={
                                                                groupData.page_after_unsubscribe
                                                            }
                                                            className="mb0 link fs12"
                                                            theme="empty"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.onClickRemoveSubscriber(
                                                                    groupData.id,
                                                                    groupData.page_after_unsubscribe
                                                                );
                                                            }}
                                                        >
                                                            {groupData.unsubscribe_text ||
                                                                "Отписаться"}
                                                        </a>
                                                    )}
                                                </div>
                                                <div
                                                    className={`${styles.landing__footer_col} text_center hide_sm`}
                                                >
                                                    {groupData.hide_subscribers_amount ? null : (
                                                        <TextFeature
                                                            className="mb0"
                                                            icon="group"
                                                            text="Подписчики:"
                                                            feature={
                                                                groupData.subscribers_count
                                                            }
                                                            theme="sm"
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className={`${styles.landing__footer_col} text_right hide_sm`}
                                                >
                                                    <SuccessText>
                                                        Вы подписаны
                                                    </SuccessText>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`${styles.landing__footer_col} hide_sm`}
                                                />
                                                <div
                                                    className={`${styles.landing__footer_col} text_center hide_sm`}
                                                >
                                                    {groupData.hide_subscribers_amount ? null : (
                                                        <TextFeature
                                                            className="mb0"
                                                            icon="group"
                                                            text="Подписчики:"
                                                            feature={
                                                                groupData.subscribers_count
                                                            }
                                                            theme="sm"
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className={`${styles.landing__footer_col} text_right`}
                                                >
                                                    <Button
                                                        href={
                                                            groupData.page_after_subscribe
                                                        }
                                                        className={`mb0 ${styles.landing__subscribe_btn}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.onClickAddSubscriber(
                                                                groupData.id,
                                                                groupData.page_after_subscribe
                                                            );
                                                        }}
                                                    >
                                                        {groupData.subscribe_text ||
                                                            "Подписаться"}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </footer>
                                </>
                            ) : this.state.subscribedSuccess ? (
                                // Success подписки
                                <div class={styles.subscribe_status}>
                                    <img
                                        src={characterThankyou}
                                        class={styles.subscribe_status__img}
                                        alt="Thank you"
                                    />
                                    {groupData.popup_title_after_subscribe ? (
                                        <div
                                            class={
                                                styles.subscribe_status__title
                                            }
                                        >
                                            {
                                                groupData.popup_title_after_subscribe
                                            }
                                        </div>
                                    ) : null}
                                    {groupData.popup_text_after_subscribe ? (
                                        <div
                                            class={
                                                styles.subscribe_status__text
                                            }
                                        >
                                            {
                                                groupData.popup_text_after_subscribe
                                            }
                                        </div>
                                    ) : null}
                                    <Button
                                        href={groupData.page_placeholder}
                                        target="_blank"
                                    >
                                        Перейти
                                    </Button>
                                </div>
                            ) : this.state.unsubscribedSuccess ? (
                                // Success отписки
                                <div class={styles.subscribe_status}>
                                    <img
                                        src={characterSad}
                                        class={styles.subscribe_status__img}
                                        alt="Sad"
                                    />
                                    {groupData.popup_title_after_unsubscribe ? (
                                        <div
                                            class={
                                                styles.subscribe_status__title
                                            }
                                        >
                                            {
                                                groupData.popup_title_after_unsubscribe
                                            }
                                        </div>
                                    ) : null}
                                    {groupData.popup_text_after_unsubscribe ? (
                                        <div
                                            class={
                                                styles.subscribe_status__text
                                            }
                                        >
                                            {
                                                groupData.popup_text_after_unsubscribe
                                            }
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </CustomScrollbar>
                    </div>
                ) : this.community_uid ? (
                    // Список групп

                    <div
                        className={`newsletter_frame ${
                            this.state.communityStatus === false
                                ? "disabled"
                                : ""
                        }`}
                    >
                        <FrameHeader
                            disabled={this.state.communityStatus === false}
                            showLink={
                                this.viewer_type === "ADMIN" &&
                                this.state.communityStatus === true &&
                                this.state.disconnectUrl
                            }
                            link={{
                                text: "Отключить сообщество",
                                href: this.state.disconnectUrl,
                            }}
                        />
                        <div className="newsletter_frame__main">
                            {this.state.communityStatus === false ? (
                                <NoResult
                                    text="Данное сообщество<br/> не подключено"
                                    link={{
                                        text: "Подключить",
                                        onClick: this.onConnectClick,
                                    }}
                                    img={character2}
                                    className="newsletter_frame__no_result"
                                />
                            ) : subscribersGroups.length ? (
                                <CustomScrollbar
                                    className={`newsletter_frame__list ${
                                        this.unsubscribe_all === "Y"
                                            ? "height2"
                                            : ""
                                    }`}
                                    disablesize="md"
                                >
                                    <div className="newsletter_frame_list">
                                        {subscribersGroups.map((n, idx) => (
                                            <div
                                                className="newsletter_frame_list__item"
                                                key={idx}
                                            >
                                                <div className="newsletter_frame_item">
                                                    <div className="newsletter_frame_item__head">
                                                        {n.title || n.name ? (
                                                            <div className="newsletter_frame_item__title">
                                                                {n.title ||
                                                                    n.name}
                                                            </div>
                                                        ) : null}
                                                        {n.description ? (
                                                            <div className="newsletter_frame_item__subtitle">
                                                                {n.description}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="button_list">
                                                        {n.subscribed ? (
                                                            <Button
                                                                href={
                                                                    n.page_after_unsubscribe ||
                                                                    "/"
                                                                }
                                                                theme="empty"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    this.onClickRemoveSubscriber(
                                                                        n.id,
                                                                        n.page_after_unsubscribe
                                                                    );
                                                                }}
                                                            >
                                                                {n.unsubscribe_text ||
                                                                    "Подписаться"}
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                href={
                                                                    n.page_after_subscribe ||
                                                                    "/"
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    this.onClickAddSubscriber(
                                                                        n.id,
                                                                        n.page_after_subscribe
                                                                    );
                                                                }}
                                                            >
                                                                {n.subscribe_text ||
                                                                    "Подписаться"}
                                                            </Button>
                                                        )}
                                                        <TextFeature
                                                            icon="group"
                                                            text="Подписчики:"
                                                            feature={
                                                                n.subscribers_count
                                                            }
                                                            theme="sm"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CustomScrollbar>
                            ) : (
                                <NoResult
                                    text="Список групп подписчиков пуст"
                                    link={{
                                        text: "Создать",
                                        url: `https://${window.location.hostname}/community/${this.state.isend_community_id}/subscriber-groups/?popup=create-group`,
                                        blank: true,
                                    }}
                                    img={character}
                                    className="newsletter_frame__no_result"
                                />
                            )}
                        </div>
                        {subscribersGroups.length &&
                        this.state.communityStatus === true &&
                        this.unsubscribe_all !== "Y" ? (
                            <div className="newsletter_frame__footer">
                                <div className="button_list button_list--fullwidth_md">
                                    <Button
                                        theme="empty"
                                        className="button_list__right"
                                        onClick={() =>
                                            this.onClickUnsubscribeAll()
                                        }
                                    >
                                        Отписаться от всех рассылок
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : (
                    // Попап о сервисе
                    <AboutServiceFrame />
                )}
            </>
        );
    }
}

const mstp = (store) => {
    return {
        subscribersGroups: store.communities.currentCommunity.groups,
        groupData: store.communities.currentCommunity.groupOkdata,
        newsletters: store.newsletters.newsletters,
    };
};

export default connect(mstp, {
    getOkSubscribersGroups,
    removeSubscriber,
    addSubscriber,
    unsubscribeFromAll,
    getCommunityStatus,
    connectCommunity,
    getDisconnectUrl,
    getGroupOkinfo,
    checkSubscriber,
})(withRouter(NewsletterFrame));
