import React from "react";

import IcomoonReact from "icomoon-react";
import iconSet from "../../../assets/fonts/icomoon/selection.json";

const Icon = (props) => {
	return props.icon ? <IcomoonReact {...props} iconSet={iconSet} /> : null;
};

export default Icon;
