import React from "react";
import { isIOS } from "react-device-detect";
import Icon from "../generic/Icon/Icon";
import styles from "./FrameHeader.module.sass";

const FrameHeader = (props) => {
    const { disabled = false, showLink = false, link = {} } = props;

    return (
        <div>
            <header
                className={`${styles.header} ${
                    disabled ? styles["disabled"] : ""
                } ${isIOS ? styles["ios"] : ""}`}
            >
                <Icon icon="logo" className={styles.header__logo} />
                {showLink ? (
                    <a
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.header_link}
                    >
                        {link.text}
                    </a>
                ) : null}
            </header>
        </div>
    );
};

export default FrameHeader;
