import * as axios from "axios";
import Cookie from "js-cookie";
import getQueryParams from "../utils/getQueryParams";

let baseUrl =
    window.location.hostname === "isend.technaxis.com" ||
    window.location.hostname === "localhost"
        ? "https://api.isend.technaxis.com/"
        : "https://api.isend.pro/";

const instance = axios.create({
    // withCredentials: true,
    baseURL: baseUrl,
});

// Авторизация
export const userAPI = {
    auth() {
        return instance
            .get(
                `login/url?${
                    Cookie.get("rid") ? "referral_id=" + Cookie.get("rid") : ""
                }`
            )
            .then((response) => {
                debugger;

                window.location.href = response.data.url;
            });
    },
    getAuthUser(community_id) {
        return instance
            .post(`user/info`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },
    updateCommunities() {
        return instance
            .post(`user/update_communities`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Сообщества
export const communityAPI = {
    // Получить список сообществ
    getCommunity() {
        return instance
            .post(`community/list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить список сообществ с пагинацией
    getCommunity2(data) {
        return instance
            .post(`community/list_v2`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                type: "all",
                whose: "all",
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить информацию о сообществе
    getCurrentCommunity(community_id) {
        return instance
            .post(`community/info`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получение статус сообщества по uid
    getCommunityStatus(community_uid) {
        return instance
            .post(`community/status`, {
                ...getQueryParams(window.location.search),
                community_uid,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Подключение сообщества
    connectCommunity(community_id, token) {
        token = token ? token : Cookie.get("token");
        return instance
            .post(`community/connect`, {
                ...getQueryParams(window.location.search),
                token,
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Отключение сообщества
    disconnectCommunity(community_id, token) {
        token = token ? token : Cookie.get("token");
        return instance
            .post(`community/disconnect`, {
                ...getQueryParams(window.location.search),
                token,
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить ссылку на отключение сообщества
    getDisconnectUrl(community_uid) {
        return instance
            .post(`community/get_disconnect_url`, {
                ...getQueryParams(window.location.search),
                community_uid,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Подключить сообщество из архива
    connectFromArchive(community_id) {
        return instance
            .post(`community/connect_from_archive`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получение списка подписчиков в сообществе
    getSubscribers(community_id, filters) {
        return instance
            .post(`community/get_subscribers`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
                ...filters,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Кол-во отфильтрованных подписчиков
    getFilteredSubscribersCount(community_id, filters) {
        return instance
            .post(`community/get_filtered_subscribers_count`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
                ...filters,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Проверка доступности токена сообщества
    checkToken(community_id) {
        return instance
            .post(`community/check_token`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить группы подписчиков
    getSubscribersGroups(community_id) {
        return groupAPI.getSubscribersGroups(community_id);
    },

    // Получить группы подписчиков (в одноклассниках)
    getOkSubscribersGroups(community_uid, subscriber_uid) {
        return groupAPI.getOkSubscribersGroups(community_uid, subscriber_uid);
    },

    // Добавление подписчика
    addSubscriber(
        subscriber_uid,
        group_id,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) {
        return subscriberAPI.addSubscriber(
            subscriber_uid,
            group_id,
            source,
            utm,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term
        );
    },

    // Удаление подписчика
    removeSubscriber(
        subscriber_uid,
        group_id,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) {
        return subscriberAPI.removeSubscriber(
            subscriber_uid,
            group_id,
            source,
            utm,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term
        );
    },

    // Отписка от всех групп пользователей
    unsubscribeFromAll(
        subscriber_uid,
        community_uid,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) {
        return subscriberAPI.unsubscribeFromAll(
            subscriber_uid,
            community_uid,
            source,
            utm,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term
        );
    },

    // Получение количества подписчиков в сообществе
    getSubscribersCount(data) {
        return instance
            .post(`community/get_subscribers_count`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Поиск подписчиков по id/Имени/Ссылке
    findSubscribers(data) {
        return instance
            .post(`community/find_subscribers`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получение внутреннего id сообщества
    getId(data) {
        return instance
            .post(`community/get_id`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Группы
export const groupAPI = {
    // Создание группы
    createGroup(data) {
        data.append("token", Cookie.get("token"));

        for (let prop in getQueryParams(window.location.search)) {
            data.append(prop, getQueryParams(window.location.search)[prop]);
        }

        return instance
            .put(`group`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },

    // Редактирование группы
    editGroup(data) {
        data.append("token", Cookie.get("token"));

        for (let prop in getQueryParams(window.location.search)) {
            data.append(prop, getQueryParams(window.location.search)[prop]);
        }

        return instance
            .patch(`group`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },

    // Удаление
    removeGroup(group_id) {
        return instance
            .delete(`group?token=${Cookie.get("token")}&group_id=${group_id}`)
            .then((response) => {
                return response.data;
            });
    },

    // Информация о групп
    getGroupInfo(group_id) {
        return instance
            .post(`group/info`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                group_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить группы подписчиков
    getSubscribersGroups(community_id) {
        return instance
            .post(`group/list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить группы подписчиков с пагинацией
    getSubscribersGroups2(data) {
        return instance
            .post(`group/list_v2`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить группы подписчиков (в одноклассниках)
    getOkSubscribersGroups(community_uid, subscriber_uid) {
        return instance
            .post(`group/oklist`, {
                ...getQueryParams(window.location.search),
                community_uid,
                subscriber_uid,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Информация о группе
    getGroupOkinfo(group_id, subscriber_uid) {
        return instance
            .post(`group/okinfo`, {
                ...getQueryParams(window.location.search),
                group_id,
                subscriber_uid,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Проверка подписан ли пользователь на группу
    checkSubscriber(group_id, subscriber_uid) {
        return instance
            .post(`group/check_subscriber`, {
                ...getQueryParams(window.location.search),
                group_id,
                subscriber_uid,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Изменение чекбокса "Автоматическая подписка при переходе"
    changeAutosubscribe(group_id, auto_subscribe) {
        return instance
            .post(`group/change_autosubscribe`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                group_id,
                auto_subscribe,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Подписчик
export const subscriberAPI = {
    // Добавление подписчика
    addSubscriber(
        subscriber_uid,
        group_id,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) {
        return instance
            .put(`subscriber`, {
                ...getQueryParams(window.location.search),
                subscriber_uid,
                group_id,
                source,
                utm,
                utm_source,
                utm_medium,
                utm_campaign,
                utm_content,
                utm_term,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Удаление подписчика
    removeSubscriber(
        subscriber_uid,
        group_id,
        source,
        utm = "",
        utm_source = "",
        utm_medium = "",
        utm_campaign = "",
        utm_content = "",
        utm_term = ""
    ) {
        return instance
            .delete(
                `subscriber?subscriber_uid=${subscriber_uid}&group_id=${group_id}&source=${source}&utm=${utm}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_content=${utm_content}&utm_term=${utm_term}`
            )
            .then((response) => {
                return response.data;
            });
    },

    // Отписка от всех групп пользователей
    unsubscribeFromAll(
        subscriber_uid,
        community_uid,
        source,
        utm,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term
    ) {
        return instance
            .post(`subscriber/unsubscribe_from_all`, {
                ...getQueryParams(window.location.search),
                subscriber_uid,
                community_uid,
                source,
                utm,
                utm_source,
                utm_medium,
                utm_campaign,
                utm_content,
                utm_term,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получение данных подписчика внутри сообщества
    getInfo(data) {
        return instance
            .post("subscriber/get_info", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Удаление подписчика из группы
    removeFromGroup(data) {
        return instance
            .post("subscriber/remove_from_group", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Дбавление подписчика в группы
    addToGroups(data) {
        return instance
            .post("subscriber/add_to_groups", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Отписка от всех групп пользователей по внутреннему ID
    unsubscribeFromAll2(data) {
        return instance
            .post("subscriber/unsubscribe_from_all_v2", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Добавление UTM метки
    addUtm(data) {
        return instance
            .post("subscriber/add_utm", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Удаление UTM метки
    deleteUtm(data) {
        return instance
            .post("subscriber/delete_utm", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Добавление метки
    addMark(data) {
        return instance
            .post("subscriber/add_mark", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Удаление метки
    deleteMark(data) {
        return instance
            .post("subscriber/delete_mark", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => response.data);
    },

    // Отписка от всех групп пользователей, которые учавствуют в рассылке
    unsubscribeFromDistribution(data) {
        return instance
            .post(`subscriber/unsubscribe_from_distribution`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Рассылки
export const newsletterAPI = {
    // Получить список рассылок
    getNewsletters(id) {
        return instance
            .post(`distribution/list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id: id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Создание рассылки
    createNewsletter(data) {
        data.append("token", Cookie.get("token"));

        for (let prop in getQueryParams(window.location.search)) {
            data.append(prop, getQueryParams(window.location.search)[prop]);
        }

        return instance
            .put(`distribution`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },

    // Отправить себе рассылки
    sendMeNewsletter(data) {
        data.append("token", Cookie.get("token"));

        for (let prop in getQueryParams(window.location.search)) {
            data.append(prop, getQueryParams(window.location.search)[prop]);
        }

        return instance
            .post(`distribution/sendme`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить кол-во получателей
    getRecipientsCount({ community_id, add_group, remove_group, filter }) {
        return instance
            .post(`distribution/subscribers_count`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
                add_group,
                remove_group,
                filter,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Активация рассылки
    activateNewsletter(distribution_id) {
        return instance
            .post(`distribution/activate`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                distribution_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Деактивация рассылки
    deactivateNewsletter(distribution_id) {
        return instance
            .post(`distribution/deactivate`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                distribution_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Информация о рассылке
    getNewsletterInfo(distribution_id) {
        return instance
            .post(`distribution/info`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                distribution_id,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Редактирование рассылки
    editNewsletter(data) {
        data.append("token", Cookie.get("token"));

        for (let prop in getQueryParams(window.location.search)) {
            data.append(prop, getQueryParams(window.location.search)[prop]);
        }

        return instance
            .patch(`distribution`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },

    // Удаление рассылки
    removeNewsletter(distribution_id) {
        return instance
            .delete(
                `distribution?token=${Cookie.get(
                    "token"
                )}&distribution_id=${distribution_id}`
            )
            .then((response) => {
                return response.data;
            });
    },

    // Получить города подписчиков
    getSubscribersCities(data) {
        return instance
            .post(`distribution/city_list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Получить страны подписчиков
    getSubscribersCountries(data) {
        return instance
            .post(`distribution/country_list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },

    // Копирование рассылки
    newsletterCopy(distribution_id) {
        return instance
            .post(`distribution/copy`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                distribution_id,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Статистика подписчиков
export const subscribehistoryAPI = {
    // Список истории по подписчикам
    getSubscriberHistory(data) {
        return instance
            .post(`subscribehistory/list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Получение списка подписчиков в сообществе, по которым есть статистика
    getSubscribers(community_id) {
        return instance
            .post(`subscribehistory/get_subscribers`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Реферальная система
export const referralAPI = {
    // Получение информации реферальной ссылки
    getReferralInfo() {
        return instance
            .post(`referral/info`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Сотрудники
export const staffAPI = {
    // Добавление сотрудника
    addStaff(data) {
        return instance
            .put(`staff`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Изменение сотрудника
    editStaff(data) {
        return instance
            .patch(`staff`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Удаление сотрудника
    removeStaff({ community_id, user_id, all_communities }) {
        return instance
            .delete(
                `staff?token=${Cookie.get(
                    "token"
                )}&community_id=${community_id}&user_id=${user_id}&all_communities=${all_communities}`
            )
            .then((response) => {
                return response.data;
            });
    },
    // Получение типа сотрудника
    getStaffType(data) {
        return instance
            .post(`staff/type`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Получение списка сотрудников
    getStaffList(community_id) {
        return instance
            .post(`staff/list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                community_id,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Получение администраторов
    getAdmins(data) {
        return instance
            .post(`staff/get_admins`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Уведомления
export const notificationsAPI = {
    // Удаление уведомления
    removeNotification(notification_id) {
        return instance
            .delete(
                `notifications?token=${Cookie.get(
                    "token"
                )}&notification_id=${notification_id}`
            )
            .then((response) => {
                return response.data;
            });
    },
    // Получение списка сотрудников
    getNotifications() {
        return instance
            .post(`notifications/list`, {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Webhooks
export const webhooksAPI = {
    // Добавление webhook
    addWebhook(webhook) {
        return instance
            .put("webhook", {
                ...getQueryParams(window.location.search),
                ...webhook,
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },
    // Изменение webhook
    editWebhook(webhook) {
        return instance
            .patch("webhook", {
                ...getQueryParams(window.location.search),
                ...webhook,
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },
    // Удаление webhook
    removeWebhook({ id }) {
        return instance
            .delete(`webhook?token=${Cookie.get("token")}&id=${id}`)
            .then((response) => {
                return response.data;
            });
    },
    // Очистка ошибок
    clearErrors({ id }) {
        return instance
            .post("webhook/clear_errors", {
                ...getQueryParams(window.location.search),
                id,
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },
    // Список webhook'ов
    getWebhooks({ community_id }) {
        return instance
            .post("webhook/list", {
                ...getQueryParams(window.location.search),
                community_id,
                token: Cookie.get("token"),
            })
            .then((response) => {
                return response.data;
            });
    },
};

// UTM
export const utmAPI = {
    // Список Меток в сообществе
    getList(data) {
        return instance
            .post("utm/list", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Подписчики
export const subscribersAPI = {
    // Импорт пользователей
    import(data) {
        data.append("token", Cookie.get("token"));

        for (let prop in getQueryParams(window.location.search)) {
            data.append(prop, getQueryParams(window.location.search)[prop]);
        }

        return instance
            .post("subscribers/import", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },
    // Получение кол-ва записей в файле импорта
    getImportfileCount(data) {
        for (let prop in getQueryParams(window.location.search)) {
            data.append(prop, getQueryParams(window.location.search)[prop]);
        }

        return instance
            .post("subscribers/get_importfile_count", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },
    // Экспорт пользователей
    export(data) {
        return instance
            .post("subscribers/export", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Исключение пользователей из групп
    removeFromGroups(data) {
        return instance
            .post("subscribers/remove_from_groups", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Добавление пользователей в группы
    addToGroups(data) {
        return instance
            .post("subscribers/add_to_groups", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

export const utilsAPI = {
    getPercent(data) {
        return instance
            .post("utils/get_percent", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Тестирование
export const testAPI = {
    startTest(data) {
        return instance
            .post("test/start", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Тултипы
export const tooltipsAPI = {
    // Получение тултипов
    getTooltips() {
        return instance.get("tooltip").then((response) => {
            return response.data;
        });
    },
};

// Боты
export const botsAPI = {
    // Получение списка ботов
    getBotsList(data) {
        return instance
            .post("bots/list", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Добавление бота
    addBot(data) {
        return instance
            .put("bots", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Удаление бота
    removeBot(data) {
        return instance
            .delete(
                `bots?token=${Cookie.get("token")}&community_id=${
                    data.community_id
                }&id=${data.id}`
            )
            .then((response) => {
                return response.data;
            });
    },
    // Активация бота
    activateBot(data) {
        return instance
            .post("bots/activate", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Деактивация бота
    deactivateBot(data) {
        return instance
            .post("bots/deactivate", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Копирование бота
    copyBot(data) {
        return instance
            .post("bots/copy", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Получение статистики шага
    getStepStatistic(data) {
        return instance
            .post("bots/get_statistic", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Очистка статистики бота
    clearBotStatistic(data) {
        return instance
            .post("bots/clear_statistic", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Добавление шага
    addStep(data) {
        return instance
            .post("bots/add_step", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Удаление шага
    removeStep(data) {
        return instance
            .post("bots/remove_step", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Обновление координат шага
    updateStepCoordinates(data) {
        return instance
            .post("bots/update_step_coordinates", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Обновление шага сообщения
    updateStepMessage(formData) {
        formData.append("token", Cookie.get("token"));
        return instance
            .post("bots/update_step_message", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },
    // Обновление шага таймера
    updateStepTimer(formData) {
        formData.append("token", Cookie.get("token"));
        return instance
            .post("bots/update_step_timer", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return response.data;
            });
    },
    // Обновление шага действия
    updateStepAction(data) {
        return instance
            .post("bots/update_step_action", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Обновление шага условия
    updateStepCondition(data) {
        return instance
            .post("bots/update_step_condition", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Получение бота
    getData(data) {
        return instance
            .post("bots/get", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Изменение следующего шага
    setNextStep(data) {
        return instance
            .post("bots/set_next_step", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Обновление данных шага(Условия)
    setConditionNextStep(data) {
        return instance
            .post("bots/update_condition_next_step", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Добавление подписчика в бота
    addSubscriber(data) {
        return instance
            .post("bots/add_subscriber", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Удаление подписчика из бота
    removeSubscriber(data) {
        return instance
            .post("bots/remove_subscriber", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Копирование шага
    copyStep(data) {
        return instance
            .post("bots/copy_step", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
    // Тестирование бота
    testBot(data) {
        return instance
            .post("bots/test", {
                ...getQueryParams(window.location.search),
                token: Cookie.get("token"),
                ...data,
            })
            .then((response) => {
                return response.data;
            });
    },
};

// Общее
export const commonApi = {
    // Получение Городов
    getCities() {
        return instance.post("main/get_cities").then((response) => {
            return response.data;
        });
    },
    // Получение Стран
    getConuntries() {
        return instance.post("main/get_countries").then((response) => {
            return response.data;
        });
    },
    // Получение Мск времени
    getMainTime() {
        return instance.post("main/time").then((response) => {
            return response.data;
        });
    },
};
