const addScript = ({src, async}) => {
	return new Promise((resolve, reject) => {
		let script = document.createElement("script");
		script.src = src;
		script.async = async;
		script.onload = resolve;
		document.body.appendChild(script);
	});
}

export default addScript;