import React from "react";
import styles from "./Hamburger.module.sass";

const Hamburger = (props) => {
	return (
		<div className={styles.hamburger} {...props}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
};

export default Hamburger;
