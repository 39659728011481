import React, { Component } from "react";
import { connect } from "react-redux";
import NewsletterList from "./NewsletterList";
import {
	getNewsletters,
	setNewslettersSorting,
	activateNewsletter,
	deactivateNewsletter,
	getNewsletterInfo,
	removeNewsletter,
	newsletterCopy
} from "../../redux/reducers/newslettersReducer";
import { withRouter } from "react-router-dom";

class NewsletterListContainer extends Component {
	componentDidMount() {
		let community_id = this.props.match.params.id;
		this.props.getNewsletters(community_id).then(() => {
			this.props.setNewslettersSorting(
				{
					status: "PENDING",
					type: "all",
				},
				this.props.searchText
			);
		});
	}

	render() {
		return <NewsletterList {...this.props}></NewsletterList>;
	}
}

const mstp = (store) => {
	return {
		newsletters: store.newsletters.newsletters,
		sortedNewsletters: store.newsletters.sortedNewsletters,
		isFirstLoad: store.newsletters.newslettersFirstLoad,
		isLoading: store.newsletters.isLoading,
		sortBy: store.newsletters.sortBy,
		searchText: store.newsletters.searchText,
		newsletterInfo: store.newsletters.currentNewsletterInfo,
		newsletterInfoIsLoading: store.newsletters.currentNewsletterInfoLoading,
	};
};

export default connect(mstp, {
	getNewsletters,
	setNewslettersSorting,
	activateNewsletter,
	deactivateNewsletter,
	getNewsletterInfo,
	removeNewsletter,
	newsletterCopy
})(withRouter(NewsletterListContainer));
