import { subscribehistoryAPI } from "../../api/api";

const SET_SUB_HISTORY = "SET_SUB_HISTORY";
const ADD_SUB_HISTORY = "ADD_SUB_HISTORY";
const SET_SUB_HISTORY_GRAPH = "SET_SUB_HISTORY_GRAPH";
const SET_SUB_HISTORY_LOADING = "SET_SUB_HISTORY_LOADING";
const SET_STATS_SUBSCRIBERS = "SET_STATS_SUBSCRIBERS";
const SET_PAGES_COUNT = "SET_PAGES_COUNT";

const defaultState = {
	subHistory: {
		chartInfo: {
			dates: {},
			subscribeFull: 0,
			unsubscribeFull: 0,
		},
		subscribersInfo: [],
	},
	pagesCount: null,
	statsSubscribers: [],
	isLoading: false,
};

const subscribehistoryReducer = (state = defaultState, action) => {
	switch (action.type) {
		case SET_SUB_HISTORY: {
			return {
				...state,
				subHistory: {
					...state.subHistory,
					subscribersInfo: action.subHistory
				},
			};
		}

		case ADD_SUB_HISTORY: {
			return {
				...state,
				subHistory: {
					...state.subHistory,
					subscribersInfo: [
						...state.subHistory.subscribersInfo,
						...action.subHistory
					]
				},
			};
		}

		case SET_PAGES_COUNT: {
			return {
				...state,
				pagesCount: action.pagesCount
			};
		}

		case SET_SUB_HISTORY_GRAPH: {
			return {
				...state,
				subHistory: {
					...state.subHistory,
					chartInfo: action.chartInfo
				},
			};
		}

		case SET_STATS_SUBSCRIBERS: {
			return {
				...state,
				statsSubscribers: action.statsSubscribers,
			};
		}

		case SET_SUB_HISTORY_LOADING: {
			return {
				...state,
				isLoading: action.isLoading,
			};
		}

		default:
			return state;
	}
};

const setSubHistory = (subHistory) => {
	return {
		subHistory,
		type: SET_SUB_HISTORY,
	};
};

const addSubHistory = (subHistory) => {
	return {
		subHistory,
		type: ADD_SUB_HISTORY,
	};
}

const setPagesCount = (pagesCount) => {
	return {
		pagesCount,
		type: SET_PAGES_COUNT,
	};
}

const setSubHistoryGraph = (chartInfo) => {
	return {
		chartInfo,
		type: SET_SUB_HISTORY_GRAPH,
	};
};

export const setStatsSubscribers = (statsSubscribers) => {
	return {
		type: SET_STATS_SUBSCRIBERS,
		statsSubscribers,
	};
};

export const setIsSubHistoryLoading = (isLoading) => {
	return {
		type: SET_SUB_HISTORY_LOADING,
		isLoading,
	};
};

export const getSubscriberHistory = (data) => (dispatch) => {
	dispatch(setIsSubHistoryLoading(true));
	return subscribehistoryAPI.getSubscriberHistory(data).then((data) => {
		if (data.subscribersInfo) {
			if(data.page <= 1) {
				dispatch(setSubHistory(data.subscribersInfo));
			} else {
				dispatch(addSubHistory(data.subscribersInfo));
			}
		} else {
			dispatch(setSubHistory([]));
		}

		dispatch(setPagesCount(data.pages_count));

		if (data.chartInfo) {
			dispatch(setSubHistoryGraph(data.chartInfo));
		}
		dispatch(setIsSubHistoryLoading(false));
		return data;
	});
};

export const resetSubscriberHistory = () => (dispatch) => {
	dispatch(
		setSubHistory({
			chartInfo: {
				dates: {},
				subscribeFull: 0,
				unsubscribeFull: 0,
			},
			subscribersInfo: [],
		})
	);
};

export const getStatsSubscribers = (community_id) => (dispatch) => {
	return subscribehistoryAPI.getSubscribers(community_id).then((data) => {
		if (data) {
			dispatch(setStatsSubscribers(data));
		} else {
			dispatch(setStatsSubscribers([]));
		}
	});
};

export default subscribehistoryReducer;
