import React from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import { withRouter } from "react-router-dom";
import { Form, Formik } from "formik";
import styles from "./DeleteStuffPopup.module.sass";
import Button from "../../generic/Button/Button";
import Preloader from "../../Preloader/Preloader";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

const DeleteStuffPopup = (props) => {
	const {
		isOpen,
		onOpenPopup,
		onClosePopup,
		isLoading,
		user,
		communityName,
		deleteStaff,
	} = props;

	const initialValues = {
		all_communities: false,
	};

	const handleSubmit = (data) => {
		data.community_id = props.match.params.id;
		data.user_id = user.user_id;

		deleteStaff(data).then(() => {
			onClosePopup();
			clearAllBodyScrollLocks();
		});
	};

	return (
		<>
			<PopupMainLayout
				title="Удалить доступ"
				subtitle={`Вы действительно хотите удалить доступы к сообществу ${communityName}
				у пользователя ${user.name}`}
				isOpen={isOpen}
				onOpen={onOpenPopup}
				onClose={onClosePopup}
			>
				{isLoading ? <Preloader /> : null}
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={handleSubmit}
				>
					{(formik) => (
						<Form className="form">
							{/* <div className={styles.popup__input}>
								<Field
									name="all_communities"
									label="Удалить из всех сообществ"
									tooltip="Удалить из всех сообществ"
									type="checkbox"
									size="large"
									component={Input}
								></Field>
							</div> */}
							<div className={styles.popup__footer}>
								<div className="button_list button_list--fullwidth_md">
									<a
										href="/"
										className="grey_link"
										onClick={(e) => {
											e.preventDefault();
											onClosePopup();
											clearAllBodyScrollLocks();
										}}
									>
										Отменить
									</a>
									<Button
										icon="trash"
										onClick={formik.submitForm}
									>
										Удалить
									</Button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</PopupMainLayout>
		</>
	);
};

export default withRouter(DeleteStuffPopup);
