import React from "react";
import styles from "./MobileMenu.module.sass";
import Icon from "../generic/Icon/Icon";
import IconLink from "../generic/IconLink/IconLink";
import { NavLink } from "react-router-dom";
import TopProfileContainer from "../TopProfile/TopProfileContainer";
import Button from "../generic/Button/Button";
import TopCommunityContainer from "../TopCommunity/TopCommunityContainer";
import { connect } from "react-redux";
import { clearAuthUserData } from "../../redux/reducers/authReducer";

const MobileMenu = (props) => {
    let {
        isActive = false,
        menus,
        showMenuCommunityProfile = false,
        id = "",
        className = "",
    } = props;

    const onMenuClose = () => {
        props.onClose();
    };

    return (
        <div
            className={`${styles.menu_wrap} ${
                isActive ? styles["active"] : ""
            } ${className}`}
            id={id}
        >
            <div className={styles.menu}>
                <div className={styles.menu__header}>
                    <div className={styles.menu__cross} onClick={onMenuClose}>
                        <Icon
                            icon="cross"
                            className={styles.menu__cross_icon}
                        ></Icon>
                    </div>
                    <div className={styles.menu__profile}>
                        <div className="d-md-none">
                            <TopProfileContainer></TopProfileContainer>
                        </div>
                    </div>
                    {showMenuCommunityProfile ? (
                        <div className={styles.menu__extra_profile}>
                            <TopCommunityContainer></TopCommunityContainer>
                        </div>
                    ) : null}
                </div>
                <div className={styles.menu__main}>
                    {menus.map((m, idx) => (
                        <div className={styles.menu__menu} key={idx}>
                            <div className={styles.menu__menu_title}>
                                {m.title}
                            </div>
                            {m.menu.map(
                                (
                                    {
                                        isButton,
                                        isBoard,
                                        items,
                                        to,
                                        icon,
                                        text,
                                        extraText,
                                        onClick,
                                        stageOnly,
                                    },
                                    idx
                                ) => {
                                    if (isButton) {
                                        // Кнопка
                                        return (
                                            <Button
                                                icon={icon}
                                                text={text}
                                                className={styles.menu__btn}
                                                key={idx}
                                                onClick={() => onClick()}
                                            ></Button>
                                        );
                                    } else if (isBoard) {
                                        // Панелька
                                        return (
                                            <div
                                                className={styles.menu__board}
                                                key={idx}
                                            >
                                                {items.map(
                                                    ({ label, value }, idx) => (
                                                        <div
                                                            className={
                                                                styles.menu__board_item
                                                            }
                                                            key={idx}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.menu__board_label
                                                                }
                                                            >
                                                                {label}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.menu__board_value
                                                                }
                                                            >
                                                                {value}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        );
                                    } else {
                                        if (
                                            stageOnly &&
                                            window.location.hostname === "isend.pro"
                                        ) {
                                            return null;
                                        }
                                        // Ссылка
                                        if (to) {
                                            return (
                                                <NavLink
                                                    exact
                                                    to={to}
                                                    className={
                                                        styles.menu__link
                                                    }
                                                    activeClassName={
                                                        styles.active
                                                    }
                                                    key={idx}
                                                >
                                                    <Icon
                                                        icon={icon}
                                                        className={
                                                            styles.menu__link_icon
                                                        }
                                                    ></Icon>
                                                    {text}
                                                    <span
                                                        className={
                                                            styles.menu__link_extra
                                                        }
                                                    >
                                                        {extraText}
                                                    </span>
                                                </NavLink>
                                            );
                                        } else {
                                            return (
                                                <span
                                                    className={
                                                        styles.menu__link
                                                    }
                                                    onClick={onClick}
                                                    key={idx}
                                                >
                                                    <Icon
                                                        icon={icon}
                                                        className={
                                                            styles.menu__link_icon
                                                        }
                                                    ></Icon>
                                                    {text}
                                                    <span
                                                        className={
                                                            styles.menu__link_extra
                                                        }
                                                    >
                                                        {extraText}
                                                    </span>
                                                </span>
                                            );
                                        }
                                    }
                                }
                            )}
                        </div>
                    ))}
                </div>
                <footer className={styles.menu__footer}>
                    <IconLink
                        to="/"
                        icon="exit"
                        className={styles.menu__footer_link}
                        onClick={props.clearAuthUserData}
                    >
                        Выход
                    </IconLink>
                </footer>
            </div>
            <div className={styles.menu_overlay} onClick={onMenuClose}></div>
        </div>
    );
};

const mstp = (state) => ({});

export default connect(mstp, { clearAuthUserData })(MobileMenu);
