import React from "react";
import AppRoute from "./components/AppRoute/AppRoute";
import { BrowserRouter, Switch } from "react-router-dom";
import routes from "./routes";
import Cookie from "js-cookie";
import { Component } from "react";
import setAppHeight from "./helpers/setAppHeight";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { getTooltips } from "./redux/reducers/tooltipsReducer"
import { connect } from "react-redux";

class App extends Component {
	componentDidMount = () => {
		const loader = document.querySelector("#page_preloader");

		loader.classList.remove("active");

		window.addEventListener("resize", setAppHeight);
		setAppHeight();

		// Получение тултипов 
		this.props.getTooltips();
	};

	componentWillUnmount = () => {
		window.removeEventListener("resize", setAppHeight);
	};

	render() {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get("token");
		// Параметр реферальной ссылки
		const rid = urlParams.get("rid");

		if (token) {
			Cookie.set("token", token, { expires: 29 });
			window.history.replaceState(null, null, window.location.pathname);
		}
		if (rid) {
			Cookie.set("rid", rid, { expires: 29 });
		}

		return (
			<BrowserRouter>
				<>
					<ScrollToTop />
					<Switch>
						{routes.map(({ path, Component, Layout }, key) => (
							<AppRoute
								exact
								path={path}
								component={Component}
								layout={Layout}
								key={key}
							></AppRoute>
						))}
					</Switch>
				</>
			</BrowserRouter>
		);
	}
}

const mstp = () => {
	return {}
}

export default connect(mstp, { getTooltips })(App);