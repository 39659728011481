import React from "react";
import Icon from "../generic/Icon/Icon";
import styles from "./Newsletter.module.sass";
import ActiveButton from "../generic/ActiveButton/ActiveButton";
import AnimateHeight from "react-animate-height";
import wrapTextPart from "../../utils/wrapTextPart";

const Newsletter = (props) => {
	let {
		type,
		name,
		send_date,
		status,
		recipients_count,
		delivered_count,
		error_count,
		read_count,
		transitions,
		text,
		highlightTitlePart,
		onActivateNewsletter,
		onExtraPanelOpen,
		isOpen,
	} = props;

	// Формирование типа рассылки
	let typeClass;
	let typeText;
	if (type === "ONE_TIME") {
		typeClass = styles["newsletter__type--purple"];
		typeText = "Разовая рассылка";
	} else if (type === "AUTO") {
		typeClass = styles["newsletter__type--purple_light"];
		typeText = "Автосообщение";
	} else if (type === "ARCHIVED") {
		typeClass = styles["newsletter__type--grey"];
		typeText = "В архиве";
	}

	// Формирование статуса рассылки
	let statusText = "";
	let statusIcon = "";
	let statusColor = "";
	if (status === "PENDING") {
		statusText = "В ожидании";
		statusIcon = "clock";
		statusColor = "newsletter__status--orange";
	} else if (status === "PROCESSING") {
		statusText = "В обработке";
		statusIcon = "play";
		statusColor = "newsletter__status--green";
	} else if (status === "COMPLETED") {
		statusText = "Завершено";
		statusIcon = "tick";
		statusColor = "purple";
	} else if (status === "NO_ACTIVE") {
		statusText = "Требует активации";
		statusIcon = "pause";
		statusColor = "newsletter__status--grey";
	} else if (status === "ERROR") {
		statusText = "Ошибка";
		statusIcon = "warning";
		statusColor = "newsletter__status--red";
	}

	const onHeadClick = () => {
		// setIsExtraPanelOpen(!isExtraPanelOpen);
		onExtraPanelOpen(!onExtraPanelOpen);
	};

	const onSettingsClick = () => {
		// setIsExtraPanelOpen(!isExtraPanelOpen);
		onExtraPanelOpen(!onExtraPanelOpen);
	};

	const onClickActivate = () => {
		onActivateNewsletter();
	};

	const onClickChange = () => {
		if (typeof props.onChangeNewsletter === "function") {
			props.onChangeNewsletter();
		}
	};

	const onClickCopy = () => {
		if (typeof props.onClickCopy === "function") {
			props.onClickCopy();
		}
	};

	const onTitleClick = (e) => {
		e.stopPropagation();
		if (typeof props.onTitleClick === "function") {
			props.onTitleClick();
		}
	};

	const onClickRemove = () => {
		if (typeof props.onClickRemove === "function") {
			props.onClickRemove();
		}
	};

	const onClickDectivate = () => {
		if (typeof props.onDeactivateNewsletter === "function") {
			props.onDeactivateNewsletter();
		}
	};

	// Обертка части названия в тег
	if (highlightTitlePart) {
		name = wrapTextPart(name, highlightTitlePart, "b");
	}

	return (
		<div className={`${styles.newsletter} ${isOpen ? styles.opened : ""}`}>
			<div className={styles.newsletter__main} onClick={onHeadClick}>
				<div className={`${styles.newsletter__type} ${typeClass}`}>
					{typeText}
				</div>

				<header className={styles.newsletter__header}>
					<div className={styles.newsletter__header_col}>
						{name ? (
							<div className={styles.newsletter__title_wrap}>
								<span
									onClick={onTitleClick}
									className={`${styles.newsletter__title} ${
										type === "ARCHIVED"
											? styles["disabled"]
											: ""
									}`}
									dangerouslySetInnerHTML={{
										__html: name,
									}}
								></span>
							</div>
						) : null}
						{send_date ? (
							<div className={styles.newsletter__date}>
								{send_date}
							</div>
						) : type === "AUTO" ? (
							<div className={styles.newsletter__date}>Сразу</div>
						) : null}
					</div>
					<div className={styles.newsletter__header_col}>
						{status != null ? (
							<div
								className={`${styles.newsletter__status} ${styles[statusColor]}`}
							>
								<Icon
									icon={statusIcon}
									className={styles.newsletter__status_icon}
								/>
								{statusText}
							</div>
						) : null}
					</div>
				</header>

				{recipients_count != null ||
				delivered_count != null ||
				error_count != null ||
				read_count != null ||
				transitions != null ? (
					<footer className={styles.newsletter__footer}>
						<div className={styles.newsletter__info_list}>
							{recipients_count != null ? (
								<div className={styles.newsletter__info_item}>
									<Icon
										icon="group"
										className={
											styles.newsletter__info_item_icon
										}
									/>
									<span className={styles.newsletter__info_item_label}>Получатели:</span>
									<div
										className={
											styles.newsletter__info_item_extra
										}
									>
										{recipients_count}
									</div>
								</div>
							) : null}
							{delivered_count != null ? (
								<div className={styles.newsletter__info_item}>
									<Icon
										icon="plane"
										className={
											styles.newsletter__info_item_icon
										}
									/>
									<span className={styles.newsletter__info_item_label}>Доставлено:</span>
									<div
										className={
											styles.newsletter__info_item_extra
										}
									>
										{delivered_count}
									</div>
								</div>
							) : null}
							{error_count != null ? (
								<div className={styles.newsletter__info_item}>
									<Icon
										icon="warning"
										className={
											styles.newsletter__info_item_icon
										}
									/>
									<span className={styles.newsletter__info_item_label}>Не доставлено:</span>
									<div
										className={
											styles.newsletter__info_item_extra
										}
									>
										{error_count}
									</div>
								</div>
							) : null}
							{read_count != null ? (
								<div className={styles.newsletter__info_item}>
									<Icon
										icon="mail-2"
										className={
											styles.newsletter__info_item_icon
										}
									/>
									<span className={styles.newsletter__info_item_label}>Прочитано:</span>
									<div
										className={
											styles.newsletter__info_item_extra
										}
									>
										{read_count}
									</div>
								</div>
							) : null}
							{transitions != null ? (
								<div className={styles.newsletter__info_item}>
									<Icon
										icon="transition"
										className={
											styles.newsletter__info_item_icon
										}
									/>
									<span className={styles.newsletter__info_item_label}>Переходы:</span>
									<div
										className={
											styles.newsletter__info_item_extra
										}
									>
										{transitions}
									</div>
								</div>
							) : null}

							{/* <div className={styles.newsletter__info_item}>
						<Icon
							icon="log-in"
							className={styles.newsletter__info_item_icon}
						/>
						Отписались:
						<div className={styles.newsletter__info_item_extra}>
							{unsubscribed}
						</div>
					</div> */}
						</div>
					</footer>
				) : null}
			</div>

			<div className={styles.newsletter__more_info_wrap}>
				<AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
					<div className={styles.newsletter__more_info}>
						{text ? (
							<div className={styles.newsletter__more_info_text}>
								{text}
							</div>
						) : null}
						<div className={styles.newsletter__btns}>
							{type === "ARCHIVED" ? null : status ===
									"NO_ACTIVE" || status === "PENDING" ? (
								<div className={styles.newsletter__btns_btn}>
									{status === "NO_ACTIVE" ? (
										<ActiveButton
											icon="play"
											onClick={onClickActivate}
										>
											Активировать
										</ActiveButton>
									) : status === "PENDING" ? (
										<ActiveButton
											icon="pause"
											onClick={onClickDectivate}
										>
											Деактивировать
										</ActiveButton>
									) : null}
								</div>
							) : null}

							<div className={styles.newsletter__btns_btn}>
								<ActiveButton
									icon="pen"
									color="grey"
									onClick={onClickChange}
								>
									Изменить
								</ActiveButton>
							</div>
							<div className={styles.newsletter__btns_btn}>
								<ActiveButton
									icon="copy"
									color="grey"
									onClick={onClickCopy}
								>
									Создать копию
								</ActiveButton>
							</div>
							<div className={styles.newsletter__btns_btn}>
								<ActiveButton
									icon="trash"
									color="grey"
									onClick={onClickRemove}
								>
									Удалить
								</ActiveButton>
							</div>
						</div>
					</div>
				</AnimateHeight>
			</div>

			<div
				className={styles.newsletter__settings}
				onClick={onSettingsClick}
			>
				<Icon
					icon="dots"
					className={styles.newsletter__settings_icon}
				></Icon>
			</div>
		</div>
	);
};

export default Newsletter;
