import { tooltipsAPI } from "../../api/api";

const SET_TOOLTIPS = "tooltips/SET_TOOLTIPS";

const initialState = {
	tooltips: [],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_TOOLTIPS:
			return { ...state, tooltips: payload };

		default:
			return state;
	}
};

const setTooltipsAction = (tooltips) => ({
	type: SET_TOOLTIPS,
	payload: tooltips,
});

export const getTooltips = () => async (dispatch) => {
	const tooltips = await tooltipsAPI.getTooltips();
	dispatch(setTooltipsAction(tooltips));
};