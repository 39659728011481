import React, { useEffect, useState } from "react";
import RoundedButtonList from "../generic/RoundedButtonList/RoundedButtonList";
import Community from "../Community/Community";
import Search from "../Search/Search";
import styles from "./CommunityList.module.sass";
import character from "../../assets/images/character.svg";
import NoResult from "../generic/NoResult/NoResult";
import Preloader from "../Preloader/Preloader";
import FilterLinks from "../generic/FilterLinks/FilterLinks";

let searchTimer = null;
let lastScrollTop = 0;
const CommunityList = (props) => {
    let {
        communities = {},
        searchText,
        isLoading,
        isFirstLoading,
        sortBy = "all",
        connectFromArchive,
        getCommunities,
    } = props;

    const [showPagesAmount, setShowPagesAmount] = useState(1);
    const [isPaginationDisabled, setIsPaginationDisabled] = useState(false);
    const [activeFilters, setActiveFilters] = useState({
        type: "all",
        whose: "all",
    });

    // Изменение фильтра
    const handleFilterChange = (filter) => {
        setActiveFilters((state) => ({ ...state, ...filter }));
        getCommunities({ ...activeFilters, ...filter }).then((data) => {
            setIsPaginationDisabled(!data.hasNextPage);
        });
    };

    // Кнопки сортировки
    let sortButtons = [
        {
            text: "Все",
            amount: communities.count.all,
            isActive: true,
            onClick: () => handleFilterChange({ type: "all", whose: "all" }),
            sortby: { status: "all", archived: "0", type: "all" },
        },
        {
            text: "Подключённые",
            amount: communities.count.connected,
            isActive: false,
            onClick: () => handleFilterChange({ type: "connected", whose: "all" }),
            sortby: { status: "1", archived: "0", type: "all" },
        },
        {
            text: "Не подключённые",
            amount: communities.count.unconnected,
            isActive: false,
            onClick: () => handleFilterChange({ type: "unconnected", whose: "all" }),
            sortby: { status: "0", archived: "0", type: "all" },
        },
        {
            text: "В архиве",
            amount: communities.count.archived,
            isActive: false,
            onClick: () => handleFilterChange({ type: "archived", whose: "all" }),
            sortby: { archived: "1", type: "all" },
        },
    ];

    // Пагинация
    // eslint-disable-next-line
    const onScrollPagination = () => {
        const pageHeight = document.body.clientHeight;
        const scrollBottom = window.pageYOffset + window.innerHeight;
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > lastScrollTop) {
            // downscroll code
            if (
                scrollBottom >= pageHeight - 20 &&
                !isLoading &&
                communities.communities.length &&
                !isPaginationDisabled
            ) {
                setShowPagesAmount((state) => state + 1);
                getCommunities({
                    ...activeFilters,
                    page: showPagesAmount + 1,
                }).then((data) => {
                    if (
                        !data.communities.length ||
                        data.hasNextPage === false
                    ) {
                        setIsPaginationDisabled(true);
                    } else {
                        setIsPaginationDisabled(false);
                    }
                });
            }
        }

        lastScrollTop = st <= 0 ? 0 : st;
    };
    useEffect(() => {
        window.addEventListener("scroll", onScrollPagination);
        window.addEventListener("touchmove", onScrollPagination);

        return () => {
            window.removeEventListener("scroll", onScrollPagination);
            window.removeEventListener("touchmove", onScrollPagination);
        };
    }, [onScrollPagination, communities.communities.length]);
    // Пагинация END

    // Активность кнопки
    sortButtons = sortButtons.map((s) => {
        for (const sort in s.sortby) {
            if (s.sortby[sort] === sortBy[sort]) {
                return { ...s, isActive: true };
            } else {
                return { ...s, isActive: false };
            }
        }
        return 0;
    });

    // Второстепенные кнопки сортировки
    let sortButtonsSecondary = [
        {
            text: "Все",
            amount:
                communities.count.whose !== undefined
                    ? communities.count.whose.all
                    : 0,
            isActive: true,
            onClick: () => handleFilterChange({ whose: "all" }),
            sortby: { type: "all" },
        },
        {
            text: "Собственные",
            amount:
                communities.count.whose !== undefined
                    ? communities.count.whose.own
                    : 0,
            onClick: () => handleFilterChange({ whose: "own" }),
            sortby: { type: "OWNER" },
        },
        {
            text: "Гостевые",
            amount:
                communities.count.whose !== undefined
                    ? communities.count.whose.guest
                    : 0,
            onClick: () => handleFilterChange({ whose: "guest" }),
            sortby: { type: "GUEST" },
        },
    ];

    // Активность кнопки
    sortButtonsSecondary = sortButtonsSecondary.map((s) => {
        for (const sort in s.sortby) {
            if (s.sortby[sort] === sortBy[sort]) {
                return { ...s, isActive: true };
            } else {
                return { ...s, isActive: false };
            }
        }
        return 0;
    });

    const onSearchChange = ({ target }) => {
        props.setCommunitiesSorting(sortBy, target.value);

        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setActiveFilters((state) => ({ ...state, search: target.value }));
            handleFilterChange({
                ...activeFilters,
                search: target.value,
                page: 1,
            });
        }, 1000);
    };

    // Клик по кнопки сортировки
    const onSortButtonClick = (sortBy) => {
        props.setCommunitiesSorting(sortBy, searchText);
        setShowPagesAmount(1);
    };

    // Клик по второстепенной сортировке
    const onSortButtonSecondaryClick = (sortBy) => {
        props.setCommunitiesSorting(sortBy, searchText);
        setShowPagesAmount(1);
    };

    const onArchivedConnect = (community_id) => {
        connectFromArchive(community_id);
    };

    return (
        <div className={styles.communities_wrap}>
            {isLoading ? <Preloader /> : null}

            {isFirstLoading ? null : communities.count.all ? (
                <>
                    <div className={styles.communities__sort}>
                        <div className={styles.communities__sort_btns}>
                            <RoundedButtonList
                                buttons={sortButtons}
                                onButtonClick={onSortButtonClick}
                            ></RoundedButtonList>
                        </div>

                        <div className={styles.communities__search}>
                            <Search
                                onChange={onSearchChange}
                                placeholder="Поиск по сообществам"
                                value={searchText}
                            ></Search>
                        </div>

                        <div className={styles.communities__sort_secondary}>
                            <FilterLinks
                                onClick={onSortButtonSecondaryClick}
                                links={sortButtonsSecondary}
                            />
                        </div>
                    </div>

                    <div>
                        {communities.communities.length
                            ? communities.communities.map((c, idx) => (
                                  <div
                                      className={styles.communities__item}
                                      key={idx}
                                  >
                                      <Community
                                          {...c}
                                          highlightTitlePart={searchText}
                                          onArchivedConnect={() =>
                                              onArchivedConnect(c.id)
                                          }
                                          key={c.id}
                                      ></Community>
                                  </div>
                              ))
                            : ""}
                    </div>
                </>
            ) : (
                // Если нет сообществ
                <NoResult
                    text="У вас пока нет групп"
                    img={character}
                    link={{
                        text: "Создать",
                        url: "https://ok.ru/",
                        blank: true,
                    }}
                ></NoResult>
            )}
        </div>
    );
};

export default CommunityList;
