import React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon/Icon";
import styles from "./Button.module.sass";

const Button = (props) => {
	let {
		to = "",
		href = "",
		target = "",
		icon,
		text,
		className = "",
		children,
		theme, // "empty"
		type,
		onClick,
		disabled,
		...rest
	} = props;

	const renderButton = () => {
		if (to) {
			return (
				<Link
					{...rest}
					to={to}
					className={`${styles.button} ${className} ${
						theme === "empty" ? styles["button--empty"] : ""
					} ${disabled ? styles["disabled"] : ""}`}
				>
					<Icon icon={icon} className={styles.button__icon}></Icon>
					{text} {children}
				</Link>
			);
		} else if (href) {
			return (
				<a
					{...rest}
					href={href}
					target={target}
					className={`${styles.button} ${className} ${
						theme === "empty" ? styles["button--empty"] : ""
					} ${disabled ? styles["disabled"] : ""}`}
					onClick={onClick}
				>
					<Icon icon={icon} className={styles.button__icon}></Icon>
					{text} {children}
				</a>
			);
		} else if (type === "submit") {
			return (
				<button
					{...rest}
					type="submit"
					className={`${styles.button} ${className} ${
						theme === "empty" ? styles["button--empty"] : ""
					} ${disabled ? styles["disabled"] : ""}`}
					onClick={onClick}
				>
					<Icon icon={icon} className={styles.button__icon}></Icon>
					{text} {children}
				</button>
			);
		} else {
			return (
				<div
					{...rest}
					className={`${styles.button} ${className} ${
						theme === "empty" ? styles["button--empty"] : ""
					} ${disabled ? styles["disabled"] : ""}`}
					onClick={onClick}
				>
					<Icon icon={icon} className={styles.button__icon}></Icon>
					{text} {children}
				</div>
			);
		}
	};

	return renderButton();
};

export default Button;
