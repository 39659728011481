import React from "react";
import { compose } from "redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import NewsletterListContainer from "../../components/NewsletterList/NewsletterListContainer";
import { withAccessRedirect } from "../../hoc/withAccessRedirect";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import withCommunityRedirect from "../../hoc/withCommunityRedirect";

const newsletters = () => {
	const page = {
		title: "Рассылки",
		crumbs: [
			{ name: "Сообщества", path: "/community" },
			{ name: "Рассылки", path: "/community/newsletters" },
		],
	};

	return (
		<>
			<div className="page_head">
				<Breadcrumbs crumbs={page.crumbs}></Breadcrumbs>
			</div>
			<NewsletterListContainer></NewsletterListContainer>
		</>
	);
};

export default compose(withAccessRedirect, withCommunityRedirect, withAuthRedirect)(newsletters);
