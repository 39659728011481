import React, { useState } from "react";
import downloadFileByUrl from "../../../utils/downloadFileByUrl";
import Icon from "../Icon/Icon";
import styles from "./Notice.module.sass";

const Notice = (props) => {
    const {
        className = "",
        onRemoveNotice,
		onMouseEnter,
		onMouseLeave,
        title,
        text = "",
        textMaxLength = 130,
        noticeBackground,
    } = props;

    const [showFullText, setShowFullText] = useState(false);

    const shortedText =
        text.length > textMaxLength
            ? text.substr(0, textMaxLength) + "..."
            : text;

    const handleNoticeTextClick = (e) => {
		// Скачка файлов с аттрибутом download
        const target = e.target;
        const { href } = target;
        if (target.hasAttribute("download") && href) {
            e.preventDefault();
            downloadFileByUrl(href, href);
        }
    };

    return (
        <div className={`${styles.notice} ${className}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div
                className={styles.notice__inner}
                style={{ backgroundColor: noticeBackground }}
            >
                <div className={styles.notice__cross} onClick={onRemoveNotice}>
                    <Icon icon="cross" />
                </div>
                {title ? (
                    <div className={styles.notice__title}>{title}</div>
                ) : null}
                <div
                    className={styles.notice__text}
                    onClick={handleNoticeTextClick}
                >
                    {text ? (
                        <span
                            className={styles.notice__text_content}
                            dangerouslySetInnerHTML={{
                                __html: showFullText ? text : shortedText,
                            }}
                        ></span>
                    ) : null}
                    {text.length > textMaxLength && !showFullText ? (
                        <div
                            className={`${styles.notice__show_link} link`}
                            onClick={() =>
                                setShowFullText((showFullText) => !showFullText)
                            }
                        >
                            Просмотреть
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Notice;
