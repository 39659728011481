import React, { useState, useEffect, createRef } from "react";
import Input from "../../generic/Input/Input";
import PopupFullsizeLayout from "../PopupFullsizeLayout/PopupFullsizeLayout";
import Button from "../../generic/Button/Button";
import ActiveButton from "../../generic/ActiveButton/ActiveButton";
import { Formik, Field, Form } from "formik";
import TopCommunityContainer from "../../TopCommunity/TopCommunityContainer";
import TextFeature from "../../generic/TextFeature/TextFeature";
import moment from "moment-timezone";
import * as Yup from "yup";
import styles from "./OnetimeMailingPopup.module.sass";
import Preloader from "../../Preloader/Preloader";
import SuccessText from "../../generic/SuccessText/SuccessText";
import { useHistory } from "react-router-dom";
import ErrorFocus from "../../ErrorFocus/ErrorFocus";
import getCodeTemplate from "../../../utils/getCodeTemplate";
import NewsletterActivatePopup from "../NewsletterActivatePopup/NewsletterActivatePopup";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { commonApi } from "../../../api/api";

const OnetimeMailingPopup = (props) => {
    let {
        isOpen,
        isLoading,
        createNewsletter,
        editNewsletter,
        sendMeNewsletter,
        getRecipientsCount,
        newsletterCopy,
        recipientsCount,
        subscribersGroups = [],
        onOpenPopup,
        onClosePopup,
        isAutomessage,
        newsletterInfo = {},
        match,
        activateNewsletter,
        tooltips,
    } = props;

    let isMobile = false;
    if (window.matchMedia("(max-width: 768px)").matches) {
        isMobile = true;
    }

    isAutomessage =
        newsletterInfo.type === "AUTO" || isAutomessage ? true : false;
    let isSendMe = false;
    let mainlingType = isAutomessage ? "AUTO" : "ONE_TIME";
    let popupTitle = isAutomessage ? "Автосообщение" : "Разовая рассылка";
    let popupTitleIcon = isAutomessage ? "robot" : "mail";
    const messageTextareaRef = createRef();
    const [sendDate, setSendDate] = useState();
    const [sendTime, setSendTime] = useState();
    const [subscriberFilterValue, setSubscriberFilterValue] = useState();

    const history = useHistory();

    // Выставляем фильтры, если они уже есть
    let appliedFiltersFromProps = [];
    if (newsletterInfo.filter) {
        appliedFiltersFromProps = Object.keys(newsletterInfo.filter)
            .filter((f) => newsletterInfo.filter[f].length)
            .map((key) => {
                if (
                    `filter[${key}]` === "filter[max_age]" ||
                    `filter[${key}]` === "filter[min_age]"
                ) {
                    return "age_filter";
                } else {
                    return `filter[${key}]`;
                }
            });
    }
    // Удаляю повторяющиеся фильтры
    appliedFiltersFromProps = appliedFiltersFromProps.filter(
        (a, pos) => appliedFiltersFromProps.indexOf(a) === pos
    );

    const [createdId, setCreatedId] = useState(null);
    const [isActivateOpen, setIsActivateOpen] = useState(false);
    const [appliedFilters, updateAppliedFilters] = useState([]);
    const [showFilterSelect, updateShowFilterSelect] = useState(
        isMobile ? false : true
    );
    const [isMeSended, updateIsMeSended] = useState(false);
    const [choosenFiles, updateChoosenFiles] = useState({
        documents: [],
        images: [],
    });
    let choosenFilesArray = [...choosenFiles.documents, ...choosenFiles.images];
    const [isFutureDateChoosen, setIsFutureDateChoosen] = useState(true);

    const newsLetterId = newsletterInfo.id ? newsletterInfo.id : null;

    useEffect(
        () => {
            updateAppliedFilters(appliedFiltersFromProps);

            updateChoosenFiles({
                documents: newsletterInfo.files
                    ? newsletterInfo.files.map((f) => ({
                          id: f.id,
                          text: f.originalName,
                          icon: "doc",
                      }))
                    : [],
                images: newsletterInfo.images
                    ? newsletterInfo.images.map((i) => ({
                          id: i.id,
                          text: i.originalName,
                          icon: "image",
                      }))
                    : [],
            });

            appliedFiltersFromProps.length || isMobile
                ? updateShowFilterSelect(false)
                : updateShowFilterSelect(true);

            updateIsMeSended(false);
        },
        /*eslint-disable */ [
            JSON.stringify(appliedFiltersFromProps),
            JSON.stringify(newsletterInfo),
            isOpen,
        ]
    ); /*eslint-enable */

    useEffect(
        () => {
            if (isOpen && !isLoading) {
                if (newsletterInfo && newsletterInfo.id) {
                    history.push(
                        `/community/${match.params.id}?newsletter=${newsletterInfo.id}`
                    );
                } else {
                    history.push(
                        `${props.location.pathname}?create-newsletter=open`
                    );
                }
            }
        } /*eslint-disable */,
        [isOpen, isLoading]
    ); /*eslint-enable */

    let subscribersGroupOptions = subscribersGroups.map((s) => {
        return { value: s.id, label: s.name };
    });

    // Настройки выбранных фильров
    const appliedFiltersData = [
        {
            options: [
                { value: "MALE", label: "Мужчины" },
                { value: "FEMALE", label: "Женщины" },
            ],
            value: "filter[gender]",
            label: "Пол",
            type: "select",
            placeholder: "Выберите пол",
        },
        {
            value: "age_filter",
            label: "Возраст",
            someInputs: true,
            inputs: [
                {
                    value: "filter[min_age]",
                    leftLabel: "от:",
                    placeholder: " ",
                    mask: "age",
                },
                {
                    value: "filter[max_age]",
                    leftLabel: "до:",
                    placeholder: " ",
                    mask: "age",
                },
            ],
        },
        {
            value: "filter[country]",
            label: "Страна",
            type: "text",
            placeholder: "Укажите страну",
        },
        {
            value: "filter[city]",
            label: "Город",
            type: "text",
            placeholder: "Укажите город",
        },
    ];

    // Через n-секунд возвращаем значение
    if (isMeSended) {
        setTimeout(() => updateIsMeSended(false), 3000);
    }

    // Добавляющиеся фильтры
    let filterOptions = [
        { value: "filter[gender]", label: "Пол" },
        { value: "age_filter", label: "Возраст" },
        { value: "filter[country]", label: "Страна" },
        { value: "filter[city]", label: "Город" },
    ]
        // Удаляю фильтр, если он уже применен
        .filter((f) => appliedFilters.indexOf(f.value) < 0)
        // Удаляю фильтра, если он select и у него нет option
        .filter((a) => {
            let filterData = appliedFiltersData.find(
                (f) => f.value === a.value
            );
            if (filterData.type === "select" && filterData.options.length) {
                return true;
            } else if (filterData.type !== "select") {
                return true;
            } else {
                return false;
            }
        });

    // Значения инпутов поумолчанию
    const initialValues = {
        name: newsletterInfo.name || "",
        text: newsletterInfo.text || "",
        add_group:
            newsletterInfo.added_groups && newsletterInfo.added_groups.length
                ? newsletterInfo.added_groups
                : "",
        remove_group:
            newsletterInfo.removed_groups &&
            newsletterInfo.removed_groups.length
                ? newsletterInfo.removed_groups
                : "",
        send_date: newsletterInfo.send_date || "",
        send_time: newsletterInfo.send_time || "",
        filter: {
            gender: newsletterInfo.filter ? newsletterInfo.filter.gender : "",
            min_age: newsletterInfo.filter ? newsletterInfo.filter.min_age : "",
            max_age: newsletterInfo.filter ? newsletterInfo.filter.max_age : "",
            city: newsletterInfo.filter ? newsletterInfo.filter.city : "",
            country: newsletterInfo.filter ? newsletterInfo.filter.country : "",
        },
        isSendMe: false,
        images: [],
        files: [],
        images_id: choosenFilesArray
            .filter((c) => c.icon === "image")
            .map((c) => c.id),
        files_id: choosenFilesArray
            .filter((c) => c.icon === "doc")
            .map((c) => c.id),
        is_subscribe_time:
            newsletterInfo.filter &&
            (newsletterInfo.filter.subscribe_time_from ||
                newsletterInfo.filter.subscribe_time_to)
                ? true
                : false,
        subscribe_time_from:
            newsletterInfo.filter && newsletterInfo.filter.subscribe_time_from
                ? newsletterInfo.filter.subscribe_time_from
                : "",
        subscribe_time_to:
            newsletterInfo.filter && newsletterInfo.filter.subscribe_time_to
                ? newsletterInfo.filter.subscribe_time_to
                : "",
        delayed: newsletterInfo.delayed || "",
        delay_days: newsletterInfo.delay_days || "00",
        delay_time: newsletterInfo.delay_time || "00:00",
        delay_type: newsletterInfo.delay_type || "ACROSS",
        replace_links: newsletterInfo.replace_links === "1" ? true : false,
    };

    // Удаление фильтра
    const onFilterRemove = (value, setFieldValue, values) => {
        let filters = appliedFilters.filter((a) => a !== value);

        if (value === "age_filter") {
            setFieldValue("filter[max_age]", "");
            setFieldValue("filter[min_age]", "");
        }

        setFieldValue(value, "");

        if (!filters.length) {
            if (isMobile) {
                updateShowFilterSelect(false);
            } else {
                updateShowFilterSelect(true);
            }
        }
        updateAppliedFilters(filters);

        getRecipientsCount({
            ...values,
            community_id: props.match.params.id,
        });
    };

    // Очистка фильтра
    const onResetFilter = (setFieldValue, values) => {
        setFieldValue("filter[min_age]", "");
        setFieldValue("filter[max_age]", "");
        appliedFilters.map((a) => setFieldValue(a, ""));
        updateAppliedFilters([]);
        isMobile ? updateShowFilterSelect(false) : updateShowFilterSelect(true);
        getRecipientsCount({ community_id: props.match.params.id });
    };

    // Добавление нового фильтра
    const onFilterChange = (e) => {
        let value = e.target.value;
        let filters = [];

        if (value === "filter[min_age]" || value === "filter[max_age]") {
            value = "filter_age";
        }

        if (value) {
            filters = appliedFilters.some((a) => a === value)
                ? appliedFilters
                : [...appliedFilters, value];

            updateAppliedFilters(filters);
            updateShowFilterSelect(false);
        }
    };

    // Получение кол-ва подписчиков
    const calcSubscribersCount = (values) => {
        let data = {
            ...values,
            filter: {...values.filters},
            community_id: props.match.params.id,
        };
        getRecipientsCount(data);
    };

    const onAddFilterClick = () => {
        updateShowFilterSelect(true);
    };

    // При выборе документа
    const onDocumentChange = (files, preloadedIds, formik) => {
        formik.setFieldValue("files", files);
        formik.setFieldValue(
            "files_id",
            preloadedIds.filter((p) => p.icon === "doc").map((p) => p.id)
        );
    };

    // При удалении документа
    const onDocumentRemove = (files, preloadedIds, formik) => {
        formik.setFieldValue("files", files);
        formik.setFieldValue(
            "files_id",
            preloadedIds.filter((p) => p.icon === "doc").map((p) => p.id)
        );
    };

    // При выборе картинки
    const onImageChange = (files, preloadedIds, formik) => {
        formik.setFieldValue("images", files);
        formik.setFieldValue(
            "images_id",
            preloadedIds.filter((p) => p.icon === "image").map((p) => p.id)
        );
    };

    // При удалении картинки
    const onImageRemove = (files, preloadedIds, formik) => {
        formik.setFieldValue("images", files);
        formik.setFieldValue(
            "images_id",
            preloadedIds.filter((p) => p.icon === "image").map((p) => p.id)
        );
    };

    // Копирование рассылки
    const createCopy = () => {
        onCloseClick();
        newsletterCopy(newsletterInfo.id, match.params.id);
    };

    // Клик по текствовому шаблону
    const onClickCodeTemplate = (e, text, cursorPosition, formik) => {
        let template = getCodeTemplate(text);

        formik.setFieldValue(
            "text",
            formik.values.text.substring(0, cursorPosition.start) +
                template +
                formik.values.text.substring(cursorPosition.start)
        );
    };

    // Выбор emoji
    const onEmojiSelect = (emoji, cursorPosition, formik) => {
        formik.setFieldValue(
            "text",
            formik.values.text.substring(0, cursorPosition.start) +
                emoji.native +
                formik.values.text.substring(cursorPosition.start)
        );
    };

    // При клике на закрытие
    const onCloseClick = () => {
        onClosePopup();
        history.push(`${props.location.pathname}`);
    };

    const codeTemplatesList = [
        { text: "Имя" },
        { text: "Полное имя" },
        { text: "Пол" },
        { text: "Дата" },
        { text: "Отписаться" },
        { text: "Отписаться от всех" },
    ];

    // Активация рассылки из попапа
    const handleActivate = () => {
        activateNewsletter(
            newsletterInfo.id || createdId,
            props.match.params.id
        ).then(() => {
            onCloseClick();
            setIsActivateOpen();
            clearAllBodyScrollLocks();
        });
    };

    // Валидация даты рассылки
    useEffect(() => {
        setIsFutureDateChoosen(true);

        if (sendDate && sendTime && sendTime.length === 5) {
            commonApi.getMainTime().then((time) => {
                let inputTime = moment.tz(
                    `${sendDate} ${sendTime}`,
                    "DD.MM.YYYY HH:mm:ss",
                    "Europe/Moscow"
                );
                let minTime = moment.tz(
                    time,
                    "DD.MM.YYYY HH:mm:ss",
                    "Europe/Moscow"
                );
                if (inputTime.isAfter(minTime)) {
                    setIsFutureDateChoosen(true);
                } else {
                    setIsFutureDateChoosen(false);
                }
            });
        }
    }, [sendDate, sendTime]);

    useEffect(() => {
        if(subscriberFilterValue) {
            const {add_group, remove_group, ...filters} = subscriberFilterValue;
            calcSubscribersCount({
                filters: { ...filters },
                add_group,
                remove_group
            })
        }
        /*eslint-disable */
    }, [JSON.stringify(subscriberFilterValue)]);
    /*eslint-enable */

    const handleSubmit = (info) => (data, formik) => {
        const formData = new FormData();

        for (let item in data) {
            let itemData = data[item];

            if (item === "subscribe_time_from") {
                formData.append("filter[subscribe_time_from]", itemData);
            } else if (item === "subscribe_time_to") {
                formData.append("filter[subscribe_time_to]", itemData);
            } else if (item === "filter") {
                for (let filter in data.filter) {
                    formData.append(`filter[${filter}]`, data.filter[filter]);
                }
            } else {
                if (typeof itemData === "object") {
                    for (let i = 0; i < itemData.length; i++) {
                        formData.append(`${item}[]`, itemData[i]);
                    }
                } else {
                    item = item === "add_group" ? "add_group[]" : item;
                    item = item === "remove_group" ? "remove_group[]" : item;

                    formData.append(item, itemData);
                }
            }
        }

        formData.append("type", mainlingType);
        formData.append("community_id", props.match.params.id);

        if (isSendMe) {
            // Отправить мне
            formData.append("send_me", true);
            // Если существующая рассылка
            if (Object.keys(newsletterInfo).length) {
                formData.append("id", newsletterInfo.id);
            }
            sendMeNewsletter(formData, props.match.params.id).then(() => {
                updateIsMeSended(true);
            });
        } else {
            if (Object.keys(newsletterInfo).length) {
                // Редактирование существующей рассылки
                formData.append("distribution_id", newsLetterId);
                editNewsletter(formData, props.match.params.id).then(() => {
                    setIsActivateOpen(true);
                });
            } else {
                // Создание новой рассылки
                createNewsletter(formData, props.match.params.id).then(
                    (data) => {
                        setIsActivateOpen(true);
                        setCreatedId(data.id);
                    }
                );
            }
            updateChoosenFiles({
                documents: [],
                images: [],
            });
        }
    };

    // При изменении формы
    const onFormChange = () => {};

    return (
        <>
            <PopupFullsizeLayout
                title={popupTitle}
                titleIcon={popupTitleIcon}
                isOpen={isOpen}
                onOpen={onOpenPopup}
                onClose={onCloseClick}
            >
                {isLoading ? <Preloader /> : null}
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit()}
                    validationSchema={() =>
                        Yup.lazy((values) => {
                            return Yup.object().shape({
                                name: Yup.mixed().required("Обязательное поле"),
                                text: Yup.string()
                                    .required("Обязательное поле")
                                    .max(4096, "Слишком длинное сообщение"),
                                add_group:
                                    Yup.mixed().required("Обязательное поле"),
                                send_date:
                                    isAutomessage || isSendMe
                                        ? null
                                        : Yup.mixed().required(
                                              "Обязательное поле"
                                          ),
                                send_time:
                                    isAutomessage || isSendMe
                                        ? null
                                        : Yup.mixed()
                                              .required("Обязательное поле")
                                              .test(
                                                  "is-time",
                                                  "Некорректное время",
                                                  (value) => {
                                                      return /(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
                                                          value
                                                      );
                                                  }
                                              ),
                                subscribe_time_from: values.is_subscribe_time
                                    ? Yup.mixed()
                                          .required("Обязательное поле")
                                          .test(
                                              "is-time",
                                              "Некорректное время",
                                              (value) => {
                                                  return /(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
                                                      value
                                                  );
                                              }
                                          )
                                    : null,
                                subscribe_time_to: values.is_subscribe_time
                                    ? Yup.mixed()
                                          .required("Обязательное поле")
                                          .test(
                                              "is-time",
                                              "Некорректное время",
                                              (value) => {
                                                  return /(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
                                                      value
                                                  );
                                              }
                                          )
                                    : null,
                                delay_time: values.delayed
                                    ? Yup.mixed()
                                          .required("Обязательное поле")
                                          .test(
                                              "is-time",
                                              "Некорректное время",
                                              (value) => {
                                                  return /(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
                                                      value
                                                  );
                                              }
                                          )
                                    : null,
                            });
                        })
                    }
                >
                    {(v) => {
                        const subscriberFilterValueNew = { ...v.values.filter, add_group: v.values.add_group, remove_group: v.values.remove_group };
                        if(subscriberFilterValueNew && JSON.stringify(subscriberFilterValueNew) !== JSON.stringify(subscriberFilterValue)) {
                            setSubscriberFilterValue({ ...v.values.filter, add_group: v.values.add_group, remove_group: v.values.remove_group })
                        }
                        const sendDate = v.values?.send_date;
                        const sendTime = v.values?.send_time;
                        if(sendDate && sendTime) {
                            setSendDate(sendDate);
                            setSendTime(sendTime);
                        }
                        return (
                            <Form
                                className={`form ${styles.popup} ${
                                    newsletterInfo && newsletterInfo.id
                                        ? styles["editing"]
                                        : null
                                }`}
                                onChange={onFormChange}
                            >
                                <div className="row">
                                    {/* Основная колонка формы */}
                                    <div
                                        className={`col-xl-7 ${styles.main_col}`}
                                    >
                                        <div className="form__item">
                                            <Field
                                                name="name"
                                                label="Название"
                                                type="text"
                                                placeholder="Моя рассылка"
                                                component={Input}
                                                tooltip={
                                                    tooltips.NEWSLETTER_NAME
                                                }
                                            ></Field>
                                        </div>
                                        <div className="form__group">
                                            <div className="form__group_item">
                                                <Field
                                                    name="text"
                                                    label="Сообщение"
                                                    placeholder="Введите текст"
                                                    type="textarea"
                                                    tooltip={
                                                        tooltips.NEWSLETTER_TEXT
                                                    }
                                                    templates={true}
                                                    preloadedFiles={
                                                        choosenFilesArray
                                                    }
                                                    code_templates={true}
                                                    emoji_picker={true}
                                                    document_clip={true}
                                                    component={Input}
                                                    codeTemplatesList={
                                                        codeTemplatesList
                                                    }
                                                    onClickCodeTemplate={(
                                                        e,
                                                        text,
                                                        icon,
                                                        cursorPosition
                                                    ) =>
                                                        onClickCodeTemplate(
                                                            e,
                                                            text,
                                                            cursorPosition,
                                                            v
                                                        )
                                                    }
                                                    onDocumentChange={(
                                                        ref,
                                                        preloadedIds
                                                    ) =>
                                                        onDocumentChange(
                                                            ref,
                                                            preloadedIds,
                                                            v
                                                        )
                                                    }
                                                    maxDocumentSize="15000"
                                                    onImageChange={(
                                                        ref,
                                                        preloadedIds
                                                    ) =>
                                                        onImageChange(
                                                            ref,
                                                            preloadedIds,
                                                            v
                                                        )
                                                    }
                                                    maxImageSize="15000"
                                                    onDocumentRemove={(
                                                        ref,
                                                        preloadedIds
                                                    ) =>
                                                        onDocumentRemove(
                                                            ref,
                                                            preloadedIds,
                                                            v
                                                        )
                                                    }
                                                    onImageRemove={(
                                                        ref,
                                                        preloadedIds
                                                    ) =>
                                                        onImageRemove(
                                                            ref,
                                                            preloadedIds,
                                                            v
                                                        )
                                                    }
                                                    inputRef={
                                                        messageTextareaRef
                                                    }
                                                    onEmojiSelect={(
                                                        emoji,
                                                        cursorPosition
                                                    ) =>
                                                        onEmojiSelect(
                                                            emoji,
                                                            cursorPosition,
                                                            v
                                                        )
                                                    }
                                                ></Field>
                                            </div>
                                        </div>
                                        <div className="form__group">
                                            <div className="form__group_item">
                                                <Field
                                                    name="add_group"
                                                    label="Добавить группу подписчиков"
                                                    placeholder="Группы подписчиков"
                                                    type="select"
                                                    icon="plus"
                                                    tooltip={
                                                        tooltips.NEWSLETTER_ADD_GROUP
                                                    }
                                                    options={[
                                                        {
                                                            value: "ALL",
                                                            label: "Все подписчики",
                                                        },
                                                    ].concat(
                                                        v.values.remove_group
                                                            .length
                                                            ? subscribersGroupOptions.filter(
                                                                  (s) =>
                                                                      !v.values.remove_group.some(
                                                                          (
                                                                              id
                                                                          ) =>
                                                                              id ===
                                                                              s.value
                                                                      )
                                                              )
                                                            : subscribersGroupOptions
                                                    )}
                                                    component={Input}
                                                    isMulti={true}
                                                ></Field>
                                            </div>
                                            <div className="form__group_item">
                                                <Field
                                                    name="remove_group"
                                                    label="Исключить группу подписчиков"
                                                    placeholder="Группы подписчиков"
                                                    type="select"
                                                    icon="plus"
                                                    tooltip={
                                                        tooltips.NEWSLETTER_REMOVE_GROUP
                                                    }
                                                    options={
                                                        v.values.add_group
                                                            .length
                                                            ? subscribersGroupOptions.filter(
                                                                  (s) =>
                                                                      !v.values.add_group.some(
                                                                          (
                                                                              id
                                                                          ) =>
                                                                              id ===
                                                                              s.value
                                                                      )
                                                              )
                                                            : subscribersGroupOptions
                                                    }
                                                    component={Input}
                                                    isMulti={true}
                                                ></Field>
                                            </div>
                                        </div>
                                        {isAutomessage ? (
                                            <div className="form__group">
                                                <div className="form__group_item">
                                                    <Field
                                                        name="delayed"
                                                        label="Отложенное время отправки"
                                                        type="checkbox"
                                                        size="large"
                                                        tooltip={
                                                            tooltips.NEWSLETTER_DELAYED
                                                        }
                                                        component={Input}
                                                        onChange={() => {
                                                            v.setFieldValue(
                                                                "delay_days",
                                                                "0"
                                                            );
                                                            v.setFieldValue(
                                                                "delay_type",
                                                                "ACROSS"
                                                            );
                                                            v.setFieldValue(
                                                                "delay_time",
                                                                "00:00"
                                                            );
                                                        }}
                                                    ></Field>
                                                </div>
                                                {v.values.delayed ? (
                                                    <div className="form__group_item">
                                                        <div className="inputs_inline2">
                                                            <Field
                                                                name="delay_days"
                                                                type="tel"
                                                                placeholder=" "
                                                                leftLabel="Через"
                                                                sideLabel="Дней"
                                                                component={
                                                                    Input
                                                                }
                                                                mask="days_count"
                                                                className="input_days"
                                                            ></Field>
                                                            <Field
                                                                name="delay_type"
                                                                type="select"
                                                                component={
                                                                    Input
                                                                }
                                                                options={[
                                                                    {
                                                                        label: "через",
                                                                        value: "ACROSS",
                                                                    },
                                                                    {
                                                                        label: "в",
                                                                        value: "IN",
                                                                    },
                                                                ]}
                                                                className="input_delay_select"
                                                            ></Field>
                                                            <Field
                                                                name="delay_time"
                                                                type="tel"
                                                                placeholder="Время"
                                                                sideLabel={
                                                                    v.values
                                                                        .delay_type ===
                                                                    "IN"
                                                                        ? "МСК"
                                                                        : null
                                                                }
                                                                component={
                                                                    Input
                                                                }
                                                                mask="time"
                                                                className="input_time"
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        {isAutomessage ? (
                                            <div className="form__group">
                                                <div className="form__group_item">
                                                    <Field
                                                        name="is_subscribe_time"
                                                        label="Подписались за время"
                                                        type="checkbox"
                                                        size="large"
                                                        component={Input}
                                                        onChange={() => {
                                                            v.setFieldValue(
                                                                "subscribe_time_from",
                                                                ""
                                                            );
                                                            v.setFieldValue(
                                                                "subscribe_time_to",
                                                                ""
                                                            );
                                                        }}
                                                        tooltip={
                                                            tooltips.NEWSLETTER_SUBSCRIBED_IN_TIME
                                                        }
                                                    ></Field>
                                                </div>
                                                {v.values.is_subscribe_time ? (
                                                    <div className="form__group_item">
                                                        <div className="inputs_inline">
                                                            <Field
                                                                name="subscribe_time_from"
                                                                type="tel"
                                                                placeholder="Время от"
                                                                component={
                                                                    Input
                                                                }
                                                                mask="time"
                                                                className="input_time2"
                                                            ></Field>
                                                            <span className="inputs_inline__sep">
                                                                —
                                                            </span>
                                                            <Field
                                                                type="tel"
                                                                name="subscribe_time_to"
                                                                placeholder="Время до"
                                                                sideLabel="МСК"
                                                                component={
                                                                    Input
                                                                }
                                                                mask="time"
                                                                className="input_time2"
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        {isAutomessage ? null : (
                                            <div className="form__item">
                                                <div className="block_label">
                                                    Дата рассылки
                                                </div>
                                                <div className="inputs_inline">
                                                    <Field
                                                        name="send_date"
                                                        type="datepicker"
                                                        placeholder="Дата"
                                                        component={Input}
                                                        mask="date"
                                                        className="input_date"
                                                        minDate={moment()
                                                            .tz("Europe/Moscow")
                                                            .toDate()}
                                                        maxDate={moment()
                                                            .tz("Europe/Moscow")
                                                            .add(5, "years")
                                                            .toDate()}
                                                    ></Field>
                                                    <Field
                                                        type="tel"
                                                        name="send_time"
                                                        placeholder="Время"
                                                        sideLabel="МСК"
                                                        component={Input}
                                                        mask="time"
                                                        className="input_time"
                                                        errorMessage={
                                                            isFutureDateChoosen
                                                                ? ""
                                                                : "Вы выбрали время в прошлом"
                                                        }
                                                    ></Field>
                                                </div>
                                            </div>
                                        )}
                                        <div className="form__item">
                                            <Field
                                                name="replace_links"
                                                type="checkbox"
                                                size="large"
                                                label="Считать переходы по ссылкам"
                                                component={Input}
                                                tooltip={
                                                    tooltips.NEWSLETTER_REPLACE_LINKS
                                                }
                                            ></Field>
                                        </div>
                                    </div>

                                    <div className="col-xl-5">
                                        {/* Фильтр */}
                                        <div className="block_label">
                                            Фильтр
                                        </div>
                                        <div className="grey_block mb24">
                                            <div className="form__group">
                                                {appliedFilters.map(
                                                    (a, idx) => {
                                                        a =
                                                            appliedFiltersData.find(
                                                                (f) =>
                                                                    f.value ===
                                                                    a
                                                            );
                                                        return a ? (
                                                            a.someInputs ? (
                                                                <div
                                                                    className="form__group_item"
                                                                    key={idx}
                                                                >
                                                                    <div className="form__double">
                                                                        {a.inputs.map(
                                                                            (
                                                                                i,
                                                                                idx
                                                                            ) => (
                                                                                <Field
                                                                                    name={
                                                                                        i.value
                                                                                    }
                                                                                    label={
                                                                                        idx ===
                                                                                        0
                                                                                            ? a.label
                                                                                            : ""
                                                                                    }
                                                                                    isMulti={
                                                                                        i.isMulti
                                                                                    }
                                                                                    removable={
                                                                                        idx ===
                                                                                        a
                                                                                            .inputs
                                                                                            .length -
                                                                                            1
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onRemove={() =>
                                                                                        onFilterRemove(
                                                                                            a.value,
                                                                                            v.setFieldValue,
                                                                                            v.values
                                                                                        )
                                                                                    }
                                                                                    placeholder={
                                                                                        i.placeholder
                                                                                    }
                                                                                    labelSize="sm"
                                                                                    type={
                                                                                        i.type
                                                                                    }
                                                                                    leftLabel={
                                                                                        i.leftLabel
                                                                                    }
                                                                                    options={
                                                                                        i.options
                                                                                    }
                                                                                    component={
                                                                                        Input
                                                                                    }
                                                                                    key={
                                                                                        idx
                                                                                    }
                                                                                    mask={
                                                                                        i.mask
                                                                                    }
                                                                                ></Field>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className="form__group_item"
                                                                    key={idx}
                                                                >
                                                                    <Field
                                                                        name={
                                                                            a.value
                                                                        }
                                                                        label={
                                                                            a.label
                                                                        }
                                                                        isMulti={
                                                                            a.isMulti
                                                                        }
                                                                        removable={
                                                                            true
                                                                        }
                                                                        onRemove={() =>
                                                                            onFilterRemove(
                                                                                a.value,
                                                                                v.setFieldValue,
                                                                                v.values
                                                                            )
                                                                        }
                                                                        placeholder={
                                                                            a.placeholder
                                                                        }
                                                                        labelSize="sm"
                                                                        type={
                                                                            a.type
                                                                        }
                                                                        options={
                                                                            a.options
                                                                        }
                                                                        component={
                                                                            Input
                                                                        }
                                                                    ></Field>
                                                                </div>
                                                            )
                                                        ) : null;
                                                    }
                                                )}
                                                {showFilterSelect ? (
                                                    <div className="form__group_item">
                                                        <Input
                                                            name="filter"
                                                            label="Тип фильтра"
                                                            labelSize="sm"
                                                            placeholder="Выберите фильтр"
                                                            type="select"
                                                            value=""
                                                            options={
                                                                filterOptions
                                                            }
                                                            onChange={
                                                                onFilterChange
                                                            }
                                                            removable={true}
                                                            onRemove={() =>
                                                                updateShowFilterSelect(
                                                                    false
                                                                )
                                                            }
                                                        ></Input>
                                                    </div>
                                                ) : null}

                                                <div className="form__group_item">
                                                    <div className="button_list">
                                                        {filterOptions.length ? (
                                                            <Button
                                                                icon="plus"
                                                                onClick={
                                                                    onAddFilterClick
                                                                }
                                                            >
                                                                Добавить фильтр
                                                            </Button>
                                                        ) : null}
                                                        {!appliedFilters.length &&
                                                        isMobile ? null : (
                                                            <div
                                                                className="grey_link"
                                                                onClick={() =>
                                                                    onResetFilter(
                                                                        v.setFieldValue
                                                                    )
                                                                }
                                                            >
                                                                Сбросить
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Сообщество */}
                                        {recipientsCount != null ? (
                                            <div className="grey_block">
                                                <TopCommunityContainer>
                                                    {isAutomessage ? null : (
                                                        <TextFeature
                                                            icon="group"
                                                            text="Количество получателей:"
                                                            feature={recipientsCount}
                                                        />
                                                    )}
                                                </TopCommunityContainer>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className={styles.popup__footer}>
                                    {/* Кнопки формы */}
                                    <div className="button_list button_list--fullwidth_md">
                                        <Button
                                            icon="save"
                                            onClick={() => {
                                                isSendMe = false;
                                                v.submitForm();
                                            }}
                                        >
                                            Сохранить
                                        </Button>
                                        {newsletterInfo && newsletterInfo.id ? (
                                            <ActiveButton
                                                icon="copy"
                                                color="grey"
                                                onClick={createCopy}
                                            >
                                                Создать копию
                                            </ActiveButton>
                                        ) : null}
                                        <ActiveButton
                                            icon="plane"
                                            color="grey"
                                            onClick={() => {
                                                isSendMe = true;
                                                v.submitForm();
                                            }}
                                        >
                                            Отправить мне
                                        </ActiveButton>
                                        {isMeSended ? (
                                            <SuccessText>
                                                Доставлено
                                            </SuccessText>
                                        ) : null}
                                        <div
                                            className="grey_link button_list__right"
                                            onClick={onCloseClick}
                                        >
                                            Отменить
                                        </div>
                                    </div>
                                </div>
                                <ErrorFocus />
                            </Form>
                        );
                    }}
                </Formik>
            </PopupFullsizeLayout>
            <NewsletterActivatePopup
                isOpen={isActivateOpen}
                onClose={() => {
                    setIsActivateOpen(false);
                    onCloseClick();
                    clearAllBodyScrollLocks();
                }}
                onOpen={() => setIsActivateOpen(true)}
                handleActivate={handleActivate}
            />
        </>
    );
};

export default OnetimeMailingPopup;
