import React, { useState } from "react";
import styles from "./TopProfile.module.sass";
import Icon from "../generic/Icon/Icon";
import DropDownMenu from "../generic/DropDownMenu/DropDownMenu";
import OutsideClickHandler from "react-outside-click-handler";

const TopProfile = (props) => {
	let { theme, userData, withDropdown = true } = props;
	let isUserDataLength = userData ? Object.keys(userData).length : 0;
	let modifiers = "";
	let [isDropDownOpen, setIsDropDownOpen] = useState(false);

	if (theme === "light") {
		modifiers = styles["profile--light"];
	}

	const onDropdownOutsideClick = () => {
		setIsDropDownOpen(false);
	};

	const onExitClick = () => {
		props.clearAuthUserData();
	};

	const onArrowClick = () => {
		setIsDropDownOpen(!isDropDownOpen);
	};

	let dropdown = {
		links: [
			{to: "/cabinet/referrals", text: "Партнерская программа"}
		],
		footerLinks: [{ text: "Выход", onClick: onExitClick }],
	};

	return (
		<>
			{isUserDataLength ? (
				<div className={`${styles.profile} ${modifiers}`}>
					<div
						className={styles.profile__photo}
						style={{
							backgroundImage: `url(${userData.image})`,
						}}
					></div>

					<div className={styles.profile__info}>
						{userData.fullName ? (
							<div className={styles.profile__name}>
								{userData.fullName}
							</div>
						) : null}

						{/* <div>
							<span className={styles.profile__balance_label}>
								Баланс
							</span>
							:{" "}
							<span className={styles.profile__balance}>
								{userData.ballance}
							</span>
						</div> */}
					</div>

					{withDropdown ? (
						<OutsideClickHandler
							onOutsideClick={onDropdownOutsideClick}
						>
							<div
								className={styles.profile__menu_btn}
								onClick={onArrowClick}
							>
								<Icon
									icon="arrow-down"
									className={styles.profile__menu_btn_icon}
								></Icon>
							</div>

							<DropDownMenu
								className={styles.profile__dropdown}
								isOpen={isDropDownOpen}
								onOutsideClick={onDropdownOutsideClick}
								links={dropdown.links}
								footerLinks={dropdown.footerLinks}
								onExitClick={onExitClick}
							></DropDownMenu>
						</OutsideClickHandler>
					) : null}
				</div>
			) : null}
		</>
	);
};

export default TopProfile;
