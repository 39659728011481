import React from "react";
import Icon from "../Icon/Icon";
import styles from "./Tag.module.sass";

const Tag = (props) => {
	const {
		children,
		theme,
		noHover,
		icon,
		removable = false,
		className,
		onClickRemove,
		onClick = () => {},
	} = props;

	const onCrossClick = (e) => {
		if (onClickRemove) {
			onClickRemove(e, children, icon);
		}
	};

	const onTagClick = (e) => {
		if (onClick) {
			onClick(e, children, icon);
		}
	};

	return (
		<div
			className={`${styles.tag} ${className} ${
				theme === "dark" ? styles["tag--dark"] : null
			} ${noHover === true ? styles["tag--noHover"] : null}`}
			onClick={onTagClick}
		>
			{icon ? <Icon icon={icon} className={styles.tag__icon} /> : null}
			<span className={styles.tag__text}>{children}</span>
			{removable ? (
				<div onClick={onCrossClick}>
					<Icon icon="cross" className={styles.tag__cross} />
				</div>
			) : null}
		</div>
	);
};

export default Tag;
