import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../generic/Button/Button";
import Input from "../../generic/Input/Input";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import * as Yup from "yup";
import Progressbar from "../../generic/Progressbar/Progressbar";
import {
	addSubscribersToGroups,
	getCommunityUtms,
	getSubscribersGroups,
} from "../../../redux/reducers/communitiesReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import generateId from "../../../utils/generateId";
import { utilsAPI } from "../../../api/api";
import { getNoticess } from "../../../redux/reducers/noticesReducer";
import PopupFullsizeLayout from "../PopupFullsizeLayout/PopupFullsizeLayout";
import Preloader from "../../Preloader/Preloader";

const AddSubscribersToGroupPopup = ({
	isOpen,
	onOpen,
	onClose,
	subscribersAmount,
	subscribersTotalAmount,
	filters = {},
	afterSubmit,
}) => {
	const dispatch = useDispatch();
	const { id: community_id } = useParams();
	const [formProgress, setFormProgress] = useState(0);
	const { groups, utms } = useSelector(
		(state) => state.communities.currentCommunity
	);
	const { tooltips = {} } = useSelector(
		(state) => state.tooltips
	);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(getSubscribersGroups(community_id));
		dispatch(getCommunityUtms({ community_id }));
		// setInterval(() => setFormProgress(state => state+1), 100)
	}, [dispatch, community_id]);

	useEffect(() => {
		setFormProgress(0);
	}, [isOpen]);

	// Валидация формы
	const validationSchema = Yup.object().shape({
		groups_id: Yup.array().required("Обязательное поле"),
	});

	let initialValues = {
		groups_id: [],
		add_again: false,
		send_auto_messages: false,
		mark: "",
	};

	const onSubmit = (values) => {
		const key = generateId();
		values = { ...values, ...filters, key };

		setIsLoading(true);
		dispatch(addSubscribersToGroups(values)).then(() => {
			if (typeof afterSubmit === "function") afterSubmit();
		});

		let progressChecker = setInterval(() => {
			utilsAPI.getPercent({ key }).then(({ percent }) => {
				setFormProgress(percent);
				if (percent > 1) setIsLoading(false);
				if (percent === 100) {
					clearInterval(progressChecker);
					setTimeout(() => {
						onClose();
						clearAllBodyScrollLocks();
					}, 500);
					dispatch(getNoticess());
				}
			});
		}, 500);
	};

	const PopupLayout = (props) => {
		if (window.matchMedia("(max-width: 767px)").matches) {
			return <PopupFullsizeLayout {...props} />;
		} else {
			return <PopupMainLayout {...props} />;
		}
	};

	return (
		<PopupLayout
			title="Добавление в группу"
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			{isLoading ? <Preloader /> : null}
			<div style={{ marginBottom: 24, textAlign: "center" }}>
				<div style={{ color: "#545454", marginBottom: 12 }}>
					Выбрано подписчиков:
				</div>
				<div
					style={{
						fontSize: 24,
						marginBottom: 12,
						color: "#000",
					}}
				>
					{subscribersAmount}
				</div>
				{subscribersTotalAmount === subscribersAmount ? (
					<div className="text_error">
						Внимание! Выбраны все подписчики.
					</div>
				) : null}
			</div>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => (
					<Form className="form">
						<div className="form__group_item">
							<Field
								name="groups_id"
								label="Группы подписчиков"
								placeholder="Выбрать"
								type="select"
								options={groups.map((group) => ({
									label: group.name,
									value: group.id,
								}))}
								isMulti
								component={Input}
								tooltip={tooltips.SUBSCRIBER_ADD_TO_GROUP_GROUPS}
							></Field>
						</div>
						<div className="form__group_item">
							<Field
								name="add_again"
								label="Добавить заново"
								type="checkbox"
								size="large"
								component={Input}
								tooltip={tooltips.SUBSCRIBER_FILTER_ADD_AGAIN}
							></Field>
						</div>
						<div className="form__group_item">
							<Field
								name="send_auto_messages"
								label="Отправить автосообщение"
								type="checkbox"
								size="large"
								component={Input}
								tooltip={tooltips.SUBSCRIBER_FILTER_SEND_AUTOMESSAGE}
							></Field>
						</div>
						<div className="form__group_item">
							<Field
								name="mark"
								label="Метка"
								type="select"
								options={utms.map((utm) => ({
									label: utm.name,
									value: utm.id,
								}))}
								placeholder="Выберите метку"
								component={Input}
								tooltip={tooltips.SUBSCRIBER_ADD_TO_GROUP_UTM}
							></Field>
						</div>
						<div className="form__group_item">
							<Progressbar progress={formProgress} />
						</div>
						<div className="form__group_item">
							<div
								className="button_list button_list--fullwidth_md"
								style={{ justifyContent: "flex-end" }}
							>
								<div
									className="grey_link last_on_md"
									onClick={(e) => {
										onClose();
										clearAllBodyScrollLocks();
									}}
								>
									Закрыть
								</div>
								<Button
									icon="play-2"
									onClick={formik.submitForm}
								>
									Начать
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</PopupLayout>
	);
};

export default AddSubscribersToGroupPopup;
