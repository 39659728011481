import React from "react";
import { connect } from "formik";
import animateScrollTo from "../../utils/animateScrollTo";

class ErrorFocus extends React.Component {
	componentDidUpdate(prevProps) {
		const { isSubmitting, isValidating, errors } = prevProps.formik;
		const keys = Object.keys(errors);
		if (keys.length > 0 && isSubmitting && !isValidating) {
			let errorElement = document.querySelector(`[name="${keys[0]}"]`);

			if (errorElement) {
				errorElement = errorElement.closest(".input_container");
			} else {
				errorElement = document.querySelector(".input_container.error");
			}

			if (errorElement) {
				let scrollElement = errorElement.closest(".ps");
				let topPos;
				let scroll;

				if(scrollElement) {
					topPos = errorElement.getBoundingClientRect().top;
					scroll = scrollElement.scrollTop + topPos;
				} else {
					scrollElement = document.querySelector("body");
					scroll = errorElement.getBoundingClientRect().top;
				}

				animateScrollTo(scrollElement, scroll, 500);
			}
		}
	}

	render() {
		return null;
	}
}

export default connect(ErrorFocus);
