import React, { useState } from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import { withRouter } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import Input from "../../generic/Input/Input";
import * as Yup from "yup";
import styles from "./AddStuffPopup.module.sass";
import Button from "../../generic/Button/Button";
import Preloader from "../../Preloader/Preloader";
import SuccessPopup from "../SuccessPopup/SuccessPopup";
import successImg from "../../../assets/images/amico2.svg";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

const AddStuffPopup = (props) => {
	const {
		isOpen,
		onOpenPopup,
		onClosePopup,
		// leaders = [],
		addStaff,
		editStaff,
		isLoading,
		isEditing,
		userData,
		user,
	} = props;

	const [isSuccessOpen, setIsSucessOpen] = useState(false);
	const [addedUser, setAddedUser] = useState({});
	const [responseError, setResponseError] = useState("");

	let successDescription = "";
	switch (addedUser.staff_type) {
		case "LEADER":
			successDescription = `Пользователь ${addedUser.name} успешно добавлен в Администраторы`;
			break;
		case "EDITOR":
			successDescription = `Пользователь ${addedUser.name} успешно добавлен в Редакторы`;
			break;
		case "OBSERVER":
			successDescription = `Пользователь ${addedUser.name} успешно добавлен в Наблюдатели`;
			break;
		default:
			successDescription = "";
			break;
	}

	// const leaderOptions = leaders.map((leader) => ({
	// 	label: leader.name,
	// 	value: leader.id,
	// }));

	const initialValues = {
		staff_type: isEditing ? user.staff_type : "",
		user_link: "",
		// leaders: "",
		all_communities: false,
	};

	const validationSchema = Yup.object().shape({
		user_link: isEditing
			? null
			: Yup.string()
					.required("Обязательное поле")
					.url("Некорректная ссылка"),
		staff_type: Yup.string().required("Обязательное поле"),
	});

	const handleSubmit = (data) => {
		data.community_id = props.match.params.id;

		// Если редактирование доступа
		if (isEditing) {
			data.user_id = user.user_id;
			editStaff(data).then((response) => {
				onClosePopup();
			});

			// Если добавление доступа
		} else {
			addStaff(data).then((response) => {
				if (response.status === "error") {
					setResponseError(response.error_text);
				} else {
					data.name = response.user_name;
					setAddedUser(data);
					onClosePopup();
					setIsSucessOpen(true);
				}
			});
		}
	};

	let subtitle =
		"Введите ссылку на профиль сотрудника и выберите тип полномочий";
	if (isEditing) {
		switch (user.staff_type) {
			case "LEADER":
				subtitle = `Изменить доступ Администратору ${user.name}`;
				break;
			case "EDITOR":
				subtitle = `Изменить доступ Редактору ${user.name}`;
				break;
			case "OBSERVER":
				subtitle = `Изменить доступ Наблюдателю ${user.name}`;
				break;
			default:
				subtitle =
					"Введите ссылку на профиль сотрудника и выберите тип полномочий";
				break;
		}
	}

	return (
		<>
			<PopupMainLayout
				title={`${
					isEditing ? "Изменить доступ" : "Добавление сотрудника"
				}`}
				subtitle={subtitle}
				isOpen={isOpen}
				onOpen={onOpenPopup}
				onClose={onClosePopup}
			>
				{isLoading ? <Preloader /> : null}
				<Formik
					enableReinitialize
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					{(formik) => (
						<Form className="form">
							{!isEditing ? (
								<div className={styles.popup__input_link}>
									<Field
										name="user_link"
										placeholder="Введите ссылку на профиль пользователя Одноклассники"
										type="text"
										theme="flat"
										icon="person"
										component={Input}
										errorMessage={responseError}
										onKeyUp={() => setResponseError("")}
									></Field>
								</div>
							) : null}

							<div className={styles.popup__block}>
								{userData.staff_type === "OWNER" ? (
									<div className={styles.popup__input}>
										<Field
											name="staff_type"
											value="LEADER"
											label="Администратор"
											description="Имеет доступ к управлению сотрудниками"
											type="radio"
											component={Input}
											id="staff_type1"
										></Field>
									</div>
								) : null}
								<div className={styles.popup__input}>
									<Field
										name="staff_type"
										value="EDITOR"
										label="Редактор"
										description="Имеет доступ ко всем функциям рассылок и чат-ботов"
										type="radio"
										placeholder="Моя группа"
										component={Input}
										id="staff_type2"
									></Field>
								</div>
								<div className={styles.popup__input}>
									<Field
										name="staff_type"
										value="OBSERVER"
										label="Наблюдатель"
										description="Может просматривать статистику подписок и отправленных сообщений"
										type="radio"
										component={Input}
										id="staff_type3"
									></Field>
								</div>
							</div>
							{/* <div className={styles.popup__input}>
								<Field
									name="leaders"
									label="Руководитель"
									tooltip="Руководитель"
									type="select"
									isMulti={true}
									options={leaderOptions}
									placeholder=" "
									component={Input}
								></Field>
							</div> */}
							{/* <div className={styles.popup__input}>
								<Field
									name="all_communities"
									label="Добавить во всех сообществах"
									tooltip="Добавить во всех сообществах"
									type="checkbox"
									size="large"
									component={Input}
								></Field>
							</div> */}
							<div className={styles.popup__footer}>
								<div className="button_list button_list--fullwidth_md">
									<a
										href="/"
										className="grey_link"
										onClick={(e) => {
											e.preventDefault();
											onClosePopup();
											clearAllBodyScrollLocks();
										}}
									>
										Отменить
									</a>
									<Button
										icon="save"
										onClick={formik.submitForm}
									>
										Сохранить
									</Button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</PopupMainLayout>
			<SuccessPopup
				isOpen={isSuccessOpen}
				onOpenPopup={() => setIsSucessOpen(true)}
				onClosePopup={() => setIsSucessOpen(false)}
				title="Пользователь добавлен"
				subtitle={successDescription}
				image={successImg}
				closeText="Понятно"
			/>
		</>
	);
};

export default withRouter(AddStuffPopup);
