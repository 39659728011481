import React from "react";
import Modal from "react-modal";
import Icon from "../../generic/Icon/Icon";
import styles from "./PopupMainLayout.module.sass";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import generateId from "../../../utils/generateId";

class PopupMainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.generatedId = generateId();
        this.popupContentEl = null;
    }

    closeModal = () => {
        this.onClose();
        clearAllBodyScrollLocks();

        let menu = document.querySelector("#mobileMenu");
        if (menu && menu.classList.contains("active")) {
            setTimeout(() => {
                disableBodyScroll(menu);
            }, 100);
        }
    };

    onOpen = () => {
        clearAllBodyScrollLocks();
        setTimeout(() => {
            disableBodyScroll(this.popupContentEl);
        }, 100);
    };

    render() {
        let {
            title,
            subtitle,
            children,
            isOpen = false,
            onClose,
            style,
            overlayClassName = "",
            popupClassName = "",
        } = this.props;

        this.onClose = onClose;
        this.isOpen = isOpen;

        return (
            <div>
                <Modal
                    ariaHideApp={false}
                    isOpen={isOpen}
                    onAfterOpen={this.onOpen}
                    onRequestClose={this.closeModal}
                    overlayClassName={`${styles.popup__overlay} ${overlayClassName}`}
                    className={`${styles.popup} ${popupClassName}`}
                    overlayRef={(node) => (this.popupContentEl = node)}
                    id={this.generatedId}
                    style={style}
                >
                    <div
                        onClick={this.closeModal}
                        className={styles.popup__cross}
                    >
                        <Icon
                            icon="cross"
                            className={styles.popup__cross_icon}
                        ></Icon>
                    </div>

                    {title || subtitle ? (
                        <div className={styles.popup__head}>
                            {title ? (
                                <div className={styles.popup__title}>
                                    {title}
                                </div>
                            ) : null}
                            {subtitle ? (
                                <div className={styles.popup__subtitle}>
                                    {subtitle}
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {children}
                </Modal>
            </div>
        );
    }
}

export default PopupMainLayout;
