import React from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import styles from "./NewsletterActivatePopup.module.sass";
import Button from "../../generic/Button/Button";
import characterImg from "../../../assets/images/character-uploading.svg";

const NewsletterActivatePopup = ({
    isOpen,
    onOpen,
    onClose,
    handleActivate = () => {},
    ...rest
}) => {
    return (
        <PopupMainLayout
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
			overlayClassName={styles.popup_overlay}
            {...rest}
        >
            <div className={styles.activate_popup}>
                <img
                    src={characterImg}
                    alt="character"
                    className={styles.activate_popup__img}
                />
                <div className={styles.activate_popup__title}>
                    Выберите действие над рассылкой
                </div>
                <div className="button_list button_list--center">
                    <Button onClick={handleActivate}>
                        Активировать сейчас
                    </Button>
					<div className="link ml0" onClick={onClose}>
						Активировать позже
					</div>
                </div>
            </div>
        </PopupMainLayout>
    );
};

export default NewsletterActivatePopup;
