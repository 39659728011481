import React from "react";
import Icon from "../Icon/Icon";
import styles from "./Legend.module.sass";

const Legend = (props) => {
	const { children, icon, color, className ="" } = props;

	return (
		<div className={`${styles.legend} ${className}`}>
			{icon ? <Icon icon={icon} style={{fill: color}} className={styles.legend__icon} /> : null}
			<div className={styles.legend__text}>{children}</div>
		</div>
	);
};

export default Legend;