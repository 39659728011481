import React, { useEffect, useState } from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import CardList from "../../CardList/CardList";
import OnetimeMailingPopupContainer from "../OnetimeMailingPopup/OnetimeMailingPopupContainer";
import { withRouter } from "react-router-dom";

const CreateMailing = (props) => {
	const { isOpen, onOpenPopup, onClosePopup, onInnerPopupOpen, location } = props;

	const [isOnetimeMailingOpen, updateIsOnetimeMailingOpen] = useState(false);
	const [isAutomessage, updateIsAutomessage] = useState(false);

	const cards = [
		{
			icon: "robot",
			title: "Автосообщение",
			text: "Автоматическое сообщение после оформления подписки",
			onClick: () => {
				updateIsOnetimeMailingOpen(true);
				updateIsAutomessage(true);
				onInnerPopupOpen();
			},
		},
		{
			icon: "mail",
			title: "Разовая рассылка",
			text:
				"Рассылка по выбранным с помощью фильтров подписчикам в определенное время",
			onClick: () => {
				updateIsOnetimeMailingOpen(true);
				updateIsAutomessage(false);
				onInnerPopupOpen();
			},
		},
	];

	useEffect(() => {
		if (
			location.search.indexOf("newsletter=") < 0 &&
			location.search.indexOf("create-newsletter=open") < 0
		) {
			updateIsOnetimeMailingOpen(false);
			updateIsAutomessage(false);
		}
	}, [location.search]);

	return (
		<>
			<PopupMainLayout
				title="Создание рассылки"
				subtitle="Выберите тип рассылки:"
				isOpen={isOpen}
				onOpen={onOpenPopup}
				onClose={onClosePopup}
			>
				<CardList cards={cards}></CardList>
			</PopupMainLayout>

			<OnetimeMailingPopupContainer
				onClosePopup={() => updateIsOnetimeMailingOpen(false)}
				onOpenPopup={() => updateIsOnetimeMailingOpen(true)}
				isOpen={isOnetimeMailingOpen}
				isAutomessage={isAutomessage}
			/>
		</>
	);
};

export default withRouter(CreateMailing);
