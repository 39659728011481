import React from "react";
import styles from "./Alert.module.sass";
import Notice from "../Notice/Notice";

const Alert = (props) => {
	const { isActive, onClose } = props;

	return (
		<div className={`${styles.alert} ${isActive ? styles["active"] : ""}`}>
			<div className={styles.alert__content}>
				<Notice {...props} onRemoveNotice={onClose} />
			</div>
			<div className={styles.alert__overlay} onClick={onClose}></div>
		</div>
	);
};

export default Alert;
