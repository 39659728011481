const getCodeTemplate = (text, shortTemplate = false) => {
    let template;

    switch (text) {
        case "Имя":
            template = "%username%";
            break;
        case "Полное имя":
            template = "%full_username%";
            break;
        case "Пол":
            template = shortTemplate
                ? "[gender]Муж|Жен[/gender]"
                : "[gender]Этот текст увидит парень|Этот текст увидит девушка[/gender]";
            break;
        case "Дата":
            template = "[date]%e %month|+1 day[/date]";
            break;
        case "Отписаться":
            template = "%unsubscribe%";
            break;
        case "Отписаться от всех":
            template = "%unsubscribe_all%";
            break;
        case "Ссылка на диалог":
            template = "%chatlink%";
            break;
        default:
            template = "";
            break;
    }

    return template;
};

export default getCodeTemplate;
