import React from "react";
import styles from "./CardList.module.sass";
import Card from "../Card/Card";

const CardList = (props) => {
	const { cards } = props;

	return (
		<div className={styles.card_list} >
			{cards.map((c, idx) => (
				<div className={styles.card_list__col} key={idx}>
					<Card {...c} className={styles.card_list__card}></Card>
				</div>
			))}
		</div>
	);
};

export default CardList;
