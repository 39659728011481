import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../generic/Button/Button";
import Input from "../../generic/Input/Input";
import Preloader from "../../Preloader/Preloader";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import * as Yup from "yup";
import { useParams, withRouter } from "react-router";
import { useDispatch } from "react-redux";
import {
    getBotsList,
    addBotSubscriber,
} from "../../../redux/reducers/botsReducer";
import { useSelector } from "react-redux";
import { getSubscriberInfo } from "../../../redux/reducers/communitiesReducer";

const AddSubscriberToBot = ({ isOpen, onOpen, onClose }) => {
    const dispatch = useDispatch();
    const { subscriber_id, id: community_id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { botsList } = useSelector((state) => state.bots);
    const { subscriber } = useSelector(
        (state) => state.communities.currentCommunity
    );

    const botsListOptions = botsList
        .filter((bot) => !subscriber.bots.find((b) => b.id === bot.id))
        .map((b) => ({
            label: b.name,
            value: b.id,
        }));

    useEffect(() => {
        dispatch(getBotsList({ community_id }));
    }, [dispatch, community_id]);

    // Валидация формы
    const validationSchema = Yup.object().shape({
        bot: Yup.string().required("Обязательное поле"),
    });

    let initialValues = {
        bot: "",
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        dispatch(
            addBotSubscriber({
                subscriber_id,
                community_id,
                bot_id: data.bot,
            })
        ).then(() => {
            setIsLoading(false);
            onClose();
            clearAllBodyScrollLocks();
            dispatch(getSubscriberInfo({ subscriber_id, community_id }));
        });
    };

    return (
        <PopupMainLayout
            title="Добавление в бота"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            style={{ content: { overflow: "visible" } }}
        >
            {isLoading ? <Preloader /> : null}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form className="form">
                        <div className="form__group_item">
                            <Field
                                name="bot"
                                label="Бот"
                                type="select"
                                options={botsListOptions}
                                placeholder="Выберите бота"
                                component={Input}
                            />
                        </div>
                        <div className="form__group_item">
                            <div
                                className="button_list button_list--fullwidth_md"
                                style={{ justifyContent: "flex-end" }}
                            >
                                <div
                                    className="grey_link last_on_md"
                                    onClick={(e) => {
                                        onClose();
                                        clearAllBodyScrollLocks();
                                    }}
                                >
                                    Отменить
                                </div>
                                <Button icon="save" onClick={formik.submitForm}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </PopupMainLayout>
    );
};

export default withRouter(AddSubscriberToBot);
