import React from "react";
import Icon from "../generic/Icon/Icon";
import styles from "./Card.module.sass";

const Card = (props) => {
	const { icon, title, text, className, onClick } = props;

	return (
		<div className={`${styles.card} ${className}`} onClick={() => onClick()}>
			{icon ? (
				<Icon icon={icon} className={styles.card__icon}></Icon>
			) : null}
			{title ? <div className={styles.card__title}>{title}</div> : null}
			{text ? <div className={styles.card__text}>{text}</div> : null}
		</div>
	);
};

export default Card;
