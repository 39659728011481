import Cookie from "js-cookie";
import { userAPI } from "../../api/api";

const SET_AUTH_USER_DATA = "SET_AUTH_USER_DATA";
const SET_USER_DATA_LOADING = "SET_USER_DATA_LOADING";
const CLEAR_AUTH_USER_DATA = "CLEAR_AUTH_USER_DATA";

const defaultState = {
	userData: {},
	isUserDataLoading: false,
};

const authReducer = (state = defaultState, action) => {
	switch (action.type) {
		case SET_AUTH_USER_DATA: {
			return {
				...state,
				userData: action.userData,
			};
		}
		case CLEAR_AUTH_USER_DATA: {
			Cookie.set("token", "");
			window.location.href = "/";
			return {
				...state,
				userData: {},
			};
		}
		case SET_USER_DATA_LOADING: {
			return {
				...state,
				isUserDataLoading: action.isUserDataLoading,
			};
		}
		default:
			return state;
	}
};

export const setAuthUserData = (userData) => {
	return {
		type: SET_AUTH_USER_DATA,
		userData,
	};
};

export const clearAuthUserData = () => {
	return {
		type: CLEAR_AUTH_USER_DATA,
	};
};

export const setUserDataLoading = (isLoading) => {
	return {
		type: SET_USER_DATA_LOADING,
		isUserDataLoading: isLoading
	};
};

export const getAuthUserData = (community_id) => (dispatch, getState) => {
	let { isUserDataLoading } = getState().auth;
	if (isUserDataLoading) return;

	dispatch(setUserDataLoading(true));
	userAPI.getAuthUser(community_id).then((response) => {
		if (response) {
			dispatch(setAuthUserData(response));
		} else {
			Cookie.remove("token");
			window.location.href = "/";
		}
		dispatch(setUserDataLoading(false));
	});
};

export default authReducer;
