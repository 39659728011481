import React from "react";
import Icon from "../generic/Icon/Icon";
import styles from "./CheckList.module.sass";

// list = [{success: true, text = "text", description = "description"}]
const CheckList = ({ list = [] }) => {
    return list.length ? (
        <div className={styles.check_list}>
            {list.map((item, index) => (
                <div className={styles.check_list__item} key={index}>
                    <div className={styles.check_list__item_head}>
                        {item.success ? (
                            <Icon
                                icon="circle-check"
                                className={styles.check_list__item_icon}
                            />
                        ) : (
                            <Icon
                                icon="circle-warning"
                                className={`${styles.check_list__item_icon} ${
                                    !item.success
                                        ? styles["check_list__item_icon--error"]
                                        : ""
                                }`}
                            />
                        )}
                        {item.text ? (
                            <div className={styles.check_list__item_title}>
                                {item.text}
                            </div>
                        ) : null}
                    </div>
                    {item.description ? (
                        <div
                            className={styles.check_list__item_desc}
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        ></div>
                    ) : null}
                </div>
            ))}
        </div>
    ) : null;
};

export default CheckList;
