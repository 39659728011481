import React, { useState } from "react";
import { useEffect } from "react";
import copyTextToClipboard from "../../utils/copyToClipboard";
import generateId from "../../utils/generateId";
import Button from "../generic/Button/Button";
import CustomScrollbar from "../generic/CustomScrollbar/CustomScrollbar";
import Icon from "../generic/Icon/Icon";
import Input from "../generic/Input/Input";
import Search from "../Search/Search";
import styles from "./UtmList.module.sass";

const UtmList = (props) => {
    let isMobile;
    if (window.matchMedia("(max-width: 1200px)").matches) {
        isMobile = true;
    }

    const [inputs, setInputs] = useState(props.inputs);
    const [sortedInputs, setSortedInputs] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [inputsVisible, setinputsVisible] = useState(1);

    let displayInputs;

    useEffect(() => {
        // Добавляю уникальные id
        let newInputs = props.inputs.map((input) => {
            if (!input.extraId) {
                input.extraId = generateId();
            }
            return input;
        });
        setInputs(newInputs);
        /*eslint-disable */
    }, [JSON.stringify(props.inputs)]);
    /*eslint-enable */

    // Удаление инпута
    const onRemoveInput = (extraId) => {
        let newInputs = inputs.filter((input) => input.extraId !== extraId);
        setInputs(newInputs);
        if (searchText) {
            setSortedInputs(
                sortedInputs.filter((input) => input.extraId !== extraId)
            );
        }
        if (typeof props.onInputRemove === "function")
            props.onInputRemove(newInputs);
    };

    // Изменение поля
    const onInputChange = (e, extraId) => {
        let newInputs = inputs.map((input) => {
            if (input.extraId === extraId) {
                input.value = e.target.value;
            }
            return input;
        });
        setInputs(newInputs);
        if (typeof props.onInputChange === "function")
            props.onInputChange(newInputs);
    };

    // Добавление поля
    const onInputAdd = () => {
        let newInputs = [
            {
                id: 0,
                extraId: generateId(),
                label: "Название",
                value: "",
            },
            ...inputs,
        ];
        setSearchText("");
        setSortedInputs([]);
        setinputsVisible(inputsVisible + 1);
        setInputs(newInputs);
        if (typeof props.onInputAdd === "function")
            props.onInputChange(newInputs);
    };

    // Поиск
    const onSearch = (e) => {
        let newInputs = inputs.filter(
            (input) =>
                input.value
                    .toLowerCase()
                    .indexOf(e.target.value.toLowerCase()) >= 0
        );
        setSearchText(e.target.value);
        setSortedInputs(newInputs);
    };

    // Показать ещё метки
    const onShowMore = () => {
        setinputsVisible(inputsVisible + 4);
    };

    if (sortedInputs.length && searchText) {
        displayInputs = sortedInputs;
    } else {
        displayInputs = inputs;
    }

    return (
        <>
            {inputs.length > 1 ? (
                <div className={styles.search}>
                    <Search
                        onChange={onSearch}
                        placeholder="Поиск по меткам"
                        theme="dark_grey"
                        value={searchText}
                    />
                </div>
            ) : null}
            {!displayInputs.length && searchText ? (
                <div className="mb20">Не найдено</div>
            ) : null}
            {!displayInputs.length ? null : (
                <CustomScrollbar className={styles.list} disablesize="xl">
                    <div className={styles.list__inner}>
                        {displayInputs.map(({ id, ...input }, idx) =>
                            idx < inputsVisible || !isMobile || searchText ? (
                                <div className={styles.list__item} key={idx}>
                                    <div className={styles.list__item_input}>
                                        <Input
                                            {...input}
                                            placeholder="Новая метка"
                                            onChange={(e) =>
                                                onInputChange(e, input.extraId)
                                            }
                                            onRemove={() =>
                                                onRemoveInput(input.extraId)
                                            }
                                            removable={true}
                                        />
                                    </div>
                                    {input.url ? (
                                        <>
                                            <div
                                                className={
                                                    styles.list__item_text
                                                }
                                            >
                                                {input.url}
                                            </div>
                                            <div
                                                className={`link_list ${styles.list__links}`}
                                            >
                                                <div
                                                    onClick={() =>
                                                        copyTextToClipboard(
                                                            input.url
                                                        )
                                                    }
                                                    className="link"
                                                >
                                                    Скопировать ссылку
                                                </div>
                                                <a
                                                    href={input.url}
                                                    className="grey_link"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Icon icon="share" />{" "}
                                                    <span>Перейти</span>
                                                </a>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            ) : null
                        )}
                    </div>
                </CustomScrollbar>
            )}
            <div className="button_list button_list--fullwidth_md">
                {inputsVisible < inputs.length && !searchText ? (
                    <div
                        className="grey_link fs12 d-xl-none d-block"
                        onClick={onShowMore}
                    >
                        Показать еще метки
                    </div>
                ) : null}
                <Button icon="plus" onClick={onInputAdd}>
                    Добавить метку
                </Button>
            </div>
        </>
    );
};

export default UtmList;
