import React from "react";
import { userAPI } from "../../api/api";
import Cookie from "js-cookie";
import { Redirect } from "react-router-dom";
import styles from "./index.module.sass";
import Icon from "../../components/generic/Icon/Icon";
import amicoImg from "../../assets/images/amico.svg";
import Button from "../../components/generic/Button/Button";

const index = (props) => {
	const onButtonClick = () => {
		userAPI.auth();
	};

	if (Cookie.get("token")) {
		return <Redirect to="/community" />;
	}

	return (
		<div className="container">
			<header className={styles.header}>
				<Icon icon="logo" className={styles.logo} />
			</header>
			<div className={styles.content}>
				<div className={styles.banner}>
					<img src={amicoImg} alt="" className={styles.image} />
					<div className={styles.title}>iSEND — рассылка сообщений</div>
					<div className={styles.desc}>
						Приложение позволяет пользователям соцсети Одноклассники
						подписываться на рассылки от сообществ
					</div>
					<Button onClick={onButtonClick}>Авторизоваться</Button>
				</div>
				<div className={styles.link_wrap}>
					Техподдержка сервиса: <a href="https://vk.me/isendpro" className={styles.link} target="_blank" rel="noopener noreferrer">vk.me/isendpro</a>
				</div>
				<div className={styles.link_wrap}>
					Мы во ВКонтакте: <a href="https://vk.com/isendpro" className={styles.link} target="_blank" rel="noopener noreferrer">vk.com/isendpro</a>
				</div>
			</div>
		</div>
	);
};

export default index;
