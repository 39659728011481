import React, { Component } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./CustomScrollbar.sass";
import PerfectScrollbar from "react-perfect-scrollbar";

class CustomScrollbar extends Component {
    componentDidMount() {
        // Делаем видимым при появлении
        if (this._scrollBarRef) {
            this._scrollBarRef.updateScroll();
        }
    }

    render() {
        let { className, children, disablesize } = this.props;
        let isMatchMedia = false;

        // Если скролла нет, то убираем wheelpropagation
        if (this._scrollBarRef) {
            this._scrollBarRef._ps.settings.wheelPropagation = !this
                ._scrollBarRef._ps.scrollbarYActive;
        }

        if (disablesize === "sm") {
            disablesize = 576;
        } else if (disablesize === "md") {
            disablesize = 768;
        } else if (disablesize === "lg") {
            disablesize = 992;
        } else if (disablesize === "xl") {
            disablesize = 1200;
        }

        if (window.matchMedia(`(max-device-width: ${disablesize}px)`).matches) {
            isMatchMedia = true;
        }

        return isMatchMedia ? (
            <div className={className}>{children}</div>
        ) : (
            <PerfectScrollbar
                ref={(ref) => {
                    this._scrollBarRef = ref;
                }}
                {...this.props}
                options={{
                    wheelPropagation: false,
                    maxScrollbarLength: 200,
                }}
                className={`${className} scrollbar`}
            >
                {children}
            </PerfectScrollbar>
        );
    }
}

export default CustomScrollbar;
