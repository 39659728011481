import { newsletterAPI } from "../../api/api";

const SET_NEWSLETTER = "SET_NEWSLETTER";
const SET_NEWSLETTERS_LOADING = "SET_NEWSLETTERS_LOADING";
const SET_NEWSLETTERS_SORTING = "SET_NEWSLETTERS_SORTING";
const SET_RECIPIENTS_COUNT = "SET_RECIPIENTS_COUNT";
const SET_NEWSLETTER_INFO = "SET_NEWSLETTER_INFO";
const SET_NEWSLETTER_INFO_IS_LOADING = "SET_NEWSLETTER_INFO_IS_LOADING";
const SET_SUBSCRIBERS_CITIES = "SET_SUBSCRIBERS_CITIES";
const SET_SUBSCRIBERS_COUNTRIES = "SET_SUBSCRIBERS_COUNTRIES";
const SET_NEWSLETTERS_FIRST_LOADING = "SET_NEWSLETTERS_FIRST_LOADING";

const defaultState = {
	newsletters: [],
	searchedNewsletters: [],
	sortedNewsletters: [],
	isLoading: true,
	newslettersFirstLoad: true,
	sortBy: {
		status: "PENDING",
		type: "all",
	},
	searchText: "",
	recipientsCount: 0,
	currentNewsletterInfo: {},
	currentNewsletterInfoLoading: false,
	subscribersCities: [],
	subscribersCountries: [],
};

const newslettersReducer = (state = defaultState, action) => {
	switch (action.type) {
		case SET_NEWSLETTER: {
			return {
				...state,
				newsletters: action.newsletters,
			};
		}

		case SET_NEWSLETTERS_LOADING: {
			return {
				...state,
				isLoading: action.isLoading,
			};
		}

		case SET_NEWSLETTERS_SORTING: {
			let sortedNewsletters = state.newsletters || [];
			let searchedNewsletters;
			let sortBy = { ...state.sortBy, ...action.sortBy };

			if (state.sortBy.type === "ARCHIVED" && !action.sortBy.type) {
				sortBy.type = "all";
			}

			if (sortBy.type === "ARCHIVED") {
				sortBy.status = "";
				sortedNewsletters = sortedNewsletters.filter(
					(newsletter) => newsletter.type === "ARCHIVED"
				);
			} else {
				sortedNewsletters = sortedNewsletters.filter(
					(newsletter) => newsletter.type !== "ARCHIVED"
				);
				/*eslint-disable */
				for (let sort in sortBy) {
					if (sortBy[sort] !== "all") {
						sortedNewsletters = sortedNewsletters.filter(
							(newsletter) => {
								return newsletter[sort] === sortBy[sort];
							}
						);
					}
				}
				/*eslint-enable */
			}

			if (state.newsletters && !sortedNewsletters.length) {
				sortBy = {
					status: "all",
					type: "all",
				};
				sortedNewsletters = state.newsletters.filter(
					(newsletter) => newsletter.type !== "ARCHIVED"
				);
			}

			if (action.searchText) {
				searchedNewsletters = sortedNewsletters.filter((n) => {
					return n.name
						.toLowerCase()
						.indexOf(action.searchText.toLowerCase()) >= 0
						? true
						: false;
				});

				sortedNewsletters = searchedNewsletters.length
					? searchedNewsletters
					: [];
			}

			return {
				...state,
				sortedNewsletters,
				sortBy,
				searchText: action.searchText,
			};
		}

		case SET_RECIPIENTS_COUNT: {
			return {
				...state,
				recipientsCount: action.recipientsCount,
			};
		}

		case SET_NEWSLETTER_INFO: {
			return {
				...state,
				currentNewsletterInfo: action.currentNewsletterInfo,
			};
		}

		case SET_NEWSLETTER_INFO_IS_LOADING: {
			return {
				...state,
				currentNewsletterInfoLoading: action.isLoading,
			};
		}

		case SET_SUBSCRIBERS_CITIES: {
			return {
				...state,
				subscribersCities: action.cities,
			};
		}

		case SET_SUBSCRIBERS_COUNTRIES: {
			return {
				...state,
				subscribersCountries: action.countries,
			};
		}

		case SET_NEWSLETTERS_FIRST_LOADING: {
			return {
				...state,
				newslettersFirstLoad: action.newslettersFirstLoad,
			};
		}

		default:
			return state;
	}
};

export const setNewsletters = (newsletters) => {
	return {
		type: SET_NEWSLETTER,
		newsletters,
	};
};

export const setFirstLoading = (newslettersFirstLoad) => {
	return {
		type: SET_NEWSLETTERS_FIRST_LOADING,
		newslettersFirstLoad,
	};
};

export const setIsNewslettersLoading = (isLoading) => {
	return {
		type: SET_NEWSLETTERS_LOADING,
		isLoading,
	};
};

export const setNewslettersSorting = (sortBy, searchText) => {
	return {
		type: SET_NEWSLETTERS_SORTING,
		sortBy,
		searchText,
	};
};

export const setRecipientsCount = (recipientsCount) => {
	return {
		type: SET_RECIPIENTS_COUNT,
		recipientsCount,
	};
};

export const setNewsletterInfo = (currentNewsletterInfo) => {
	return {
		type: SET_NEWSLETTER_INFO,
		currentNewsletterInfo,
	};
};

export const setNewsletterInfoIsLoading = (isLoading) => {
	return {
		type: SET_NEWSLETTER_INFO_IS_LOADING,
		isLoading,
	};
};

export const setSubscribersCities = ({ cities }) => {
	return {
		type: SET_SUBSCRIBERS_CITIES,
		cities,
	};
};

export const setSubscribersCountries = ({ countries }) => {
	return {
		type: SET_SUBSCRIBERS_COUNTRIES,
		countries,
	};
};

export const getNewsletters = (id) => (dispatch, getState) => {
	const { sortBy, searchText } = getState().newsletters;
	dispatch(setIsNewslettersLoading(true));
	return newsletterAPI.getNewsletters(id).then((data) => {
		dispatch(setNewsletters(data));
		dispatch(setNewslettersSorting(sortBy, searchText));
		dispatch(setIsNewslettersLoading(false));
		dispatch(setFirstLoading(false));
	});
};

export const createNewsletter = (data, community_id) => (dispatch) => {
	dispatch(setNewsletterInfoIsLoading(true));
	return newsletterAPI.createNewsletter(data).then((data) => {
		dispatch(getNewsletters(community_id));
		dispatch(setNewsletterInfoIsLoading(false));
		return data;
	});
};

export const getRecipientsCount = (data) => (dispatch) => {
	newsletterAPI.getRecipientsCount(data).then((data) => {
		if(data != null) {
			dispatch(setRecipientsCount(data.count));
		}
	});
};

export const getNewsletterInfo = (distribution_id) => (dispatch) => {
	dispatch(setNewsletterInfoIsLoading(true));
	newsletterAPI.getNewsletterInfo(distribution_id).then((data) => {
		dispatch(setNewsletterInfo(data));
		dispatch(setNewsletterInfoIsLoading(false));
	});
};

export const activateNewsletter = (distribution_id, community_id) => (
	dispatch
) => {
	return newsletterAPI.activateNewsletter(distribution_id).then((data) => {
		dispatch(getNewsletters(community_id));
	});
};

export const deactivateNewsletter = (distribution_id, community_id) => (
	dispatch
) => {
	return newsletterAPI.deactivateNewsletter(distribution_id).then((data) => {
		dispatch(getNewsletters(community_id));
	});
};

export const editNewsletter = (data, community_id) => (dispatch) => {
	dispatch(setNewsletterInfoIsLoading(true));
	return newsletterAPI.editNewsletter(data).then(() => {
		dispatch(getNewsletters(community_id));
		dispatch(setNewsletterInfoIsLoading(false));
	});
};

export const removeNewsletter = (newsletter_id, community_id) => (dispatch) => {
	newsletterAPI.removeNewsletter(newsletter_id).then(() => {
		dispatch(getNewsletters(community_id));
	});
};

export const sendMeNewsletter = (data, community_id) => (dispatch) => {
	dispatch(setNewsletterInfoIsLoading(true));
	return newsletterAPI.sendMeNewsletter(data, community_id).then(() => {
		dispatch(setNewsletterInfoIsLoading(false));
	});
};

export const getSubscribersCities = (data) => (dispatch) => {
	newsletterAPI.getSubscribersCities(data).then((response) => {
		dispatch(setSubscribersCities(response));
	});
};

export const getSubscribersCountries = (data) => (dispatch) => {
	newsletterAPI.getSubscribersCountries(data).then((response) => {
		dispatch(setSubscribersCountries(response));
	});
};

export const newsletterCopy = (newsletter_id, community_id) => (dispatch) => {
	return newsletterAPI.newsletterCopy(newsletter_id).then(() => {
		return dispatch(getNewsletters(community_id));
	});
};

export default newslettersReducer;
