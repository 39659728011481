import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../generic/Button/Button";
import Input from "../../generic/Input/Input";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import * as Yup from "yup";
import { useHistory, useParams, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Progressbar from "../../generic/Progressbar/Progressbar";
import {
	addSubscriberToGroups,
	getSubscriberInfo,
} from "../../../redux/reducers/communitiesReducer";
import generateId from "../../../utils/generateId";
import { utilsAPI } from "../../../api/api";
import { getNoticess } from "../../../redux/reducers/noticesReducer";

const AddSubscriberToGroupPopup = ({
	isOpen,
	onOpen,
	onClose,
	afterSubmit,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { groups } = useSelector(
		(state) => state.communities.currentCommunity
	);
	const { tooltips = {} } = useSelector(
		(state) => state.tooltips
	);
	const { subscriber_id, id: community_id } = useParams();
	const [formProgress, setFormProgress] = useState(0);

	useEffect(() => {
		setFormProgress(0);
	}, [isOpen]);

	// Валидация формы
	const validationSchema = Yup.object().shape({
		groups_id: Yup.string().required("Обязательное поле"),
	});

	let initialValues = {
		groups_id: [],
		add_again: false,
		send_auto_messages: false,
	};

	const redirectToSubscribersPage = () => {
		history.push(`/cabinet/community/${community_id}/subscribers`);
	};

	const onSubmit = (data) => {
		const key = generateId();
		data = { ...data, key, subscriber_id, community_id };

		dispatch(addSubscriberToGroups(data)).then(() => {
			if (typeof afterSubmit === "function") afterSubmit();
		});

		let progressChecker = setInterval(() => {
			utilsAPI.getPercent({ key }).then(({ percent }) => {
				setFormProgress(percent);
				if (percent === 100) {
					clearInterval(progressChecker);
					setTimeout(() => {
						onClose();
						clearAllBodyScrollLocks();
					}, 500);
					dispatch(getNoticess());
					dispatch(
						getSubscriberInfo({ subscriber_id, community_id })
					).then((data) => {
						if (data == null) redirectToSubscribersPage();
					});
				}
			});
		}, 500);
	};

	return (
		<PopupMainLayout
			title="Добавить группу"
			subtitle="Выберите группу, в которую хотите добавить пользователя"
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => (
					<Form className="form">
						<div className="form__group_item">
							<Field
								name="groups_id"
								type="select"
								label="Группа"
								placeholder="Выберите группу"
								isMulti
								options={groups.map((group) => ({
									label: group.name,
									value: group.id,
								}))}
								component={Input}
							/>
						</div>
						<div className="form__group_item">
							<Field
								name="add_again"
								type="checkbox"
								tooltip={tooltips.SUBSCRIBER_FILTER_ADD_AGAIN}
								label="Добавить заново"
								size="large"
								component={Input}
							/>
						</div>
						<div className="form__group_item">
							<Field
								name="send_auto_messages"
								type="checkbox"
								tooltip={tooltips.SUBSCRIBER_FILTER_SEND_AUTOMESSAGE}
								label="Отправить автосообщение"
								size="large"
								component={Input}
							/>
						</div>
						<div className="form__group_item">
							<Progressbar progress={formProgress} />
						</div>
						<div className="form__group_item">
							<div
								className="button_list button_list--fullwidth_md"
								style={{ justifyContent: "flex-end" }}
							>
								<div
									className="grey_link last_on_md"
									onClick={(e) => {
										onClose();
										clearAllBodyScrollLocks();
									}}
								>
									Отменить
								</div>
								<Button
									icon="play-2"
									onClick={formik.submitForm}
								>
									Начать
								</Button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</PopupMainLayout>
	);
};

export default withRouter(AddSubscriberToGroupPopup);
