import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Field, Form, Formik } from "formik";
import React from "react";
import Button from "../../generic/Button/Button";
import Input from "../../generic/Input/Input";
import Preloader from "../../Preloader/Preloader";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import * as Yup from "yup";
import { withRouter } from "react-router";

const AddWebhookPopup = ({
    isLoading = false,
    isOpen,
    onOpen,
    onClose,
    addWebhook,
    editWebhook,
    isEditing = false,
    webhook,
    match,
}) => {
    // Валидация формы
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Обязательное поле"),
        link: Yup.string()
            .required("Обязательное поле")
            .url("Некорректная ссылка"),
    });

    let initialValues = {
        name: "",
        link: "",
    };

    if (isEditing && webhook) initialValues = webhook;

    const onSubmit = (data, formik) => {
        if (isEditing) {
            editWebhook({
                ...data,
                community_id: match.params.id,
            }).then((data) => handleSubmitError(data, formik));
        } else {
            addWebhook({
                ...data,
                community_id: match.params.id,
            }).then((data) => handleSubmitError(data, formik));
        }
    };

    const handleSubmitError = (data, formik) => {
        if (data && data.status === "error") {
            formik.setFieldError("link", data.error);
        } else {
            onClose();
        }
    };

    return (
        <PopupMainLayout
            title={isEditing ? "Изменение WebHook'а" : "Добавление WebHook"}
            subtitle="Введите ссылку на страницу и введите название"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
        >
            {isLoading ? <Preloader /> : null}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form className="form">
                        <div className="form__group_item">
                            <Field
                                name="name"
                                label="Название"
                                type="text"
                                placeholder="Название для WebHook"
                                component={Input}
                            ></Field>
                        </div>
                        <div className="form__group_item">
                            <Field
                                name="link"
                                label="Ссылка на ресурс"
                                type="text"
                                placeholder="Url"
                                component={Input}
                            ></Field>
                        </div>
                        <div className="form__group_item">
                            <div
                                className="button_list button_list--fullwidth_md"
                                style={{ justifyContent: "flex-end" }}
                            >
                                <div
                                    className="grey_link last_on_md"
                                    onClick={(e) => {
                                        onClose();
                                        clearAllBodyScrollLocks();
                                    }}
                                >
                                    Отменить
                                </div>
                                <Button icon="save" onClick={formik.submitForm}>
                                    Сохранить
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </PopupMainLayout>
    );
};

export default withRouter(AddWebhookPopup);
