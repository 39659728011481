import React from "react";
import PopupMainLayout from "../PopupMainLayout/PopupMainLayout";
import { Field, Form, Formik } from "formik";
import Input from "../../generic/Input/Input";
import * as Yup from "yup";
import styles from "./AddBot.module.sass";
import Button from "../../generic/Button/Button";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

const AddBot = ({
    isOpen,
    onOpenPopup = () => {},
    onClosePopup = () => {},
    onSave = () => {},
}) => {
    const initialValues = {
        name: "",
        tags: null,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Обязательное поле"),
    });

    const handleSubmit = (data) => {
        onSave(data);
    };

    return (
        <>
            <PopupMainLayout
                title="Добавление бота"
                isOpen={isOpen}
                onOpen={onOpenPopup}
                onClose={onClosePopup}
            >
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {(formik) => (
                        <Form className="form">
                            <div className={styles.popup__block}>
                                <div className={styles.popup__input}>
                                    <Field
                                        name="name"
                                        label="Название"
                                        type="text"
                                        placeholder="Введите название бота"
                                        component={Input}
                                    ></Field>
                                </div>
                                {/* <div className={styles.popup__input}>
                                    <Field
                                        name="tags"
                                        label="Теги"
                                        type="select"
                                        placeholder="Выберите теги"
                                        options={[
                                            { label: "1", value: "value" },
                                        ]}
                                        component={Input}
                                    ></Field>
                                </div> */}
                            </div>
                            <div className={styles.popup__footer}>
                                <div className="button_list button_list--fullwidth_md">
                                    <a
                                        href="/"
                                        className={`${styles.popup__cancel} grey_link`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onClosePopup();
                                            clearAllBodyScrollLocks();
                                        }}
                                    >
                                        Отменить
                                    </a>
                                    <Button
                                        icon="save"
                                        onClick={formik.submitForm}
                                    >
                                        Сохранить
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </PopupMainLayout>
        </>
    );
};

export default AddBot;
