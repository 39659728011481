import { webhooksAPI } from "../../api/api";

const SET_WEBHOOKS = "webhook/SET_WEBHOOKS";
const SET_WEBHOOKS_LOADING = "webhook/SET_WEBHOOKS_LOADING";

const initialState = {
    webhookList: [],
    isLoading: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_WEBHOOKS:
            return { ...state, webhookList: payload };

        case SET_WEBHOOKS_LOADING:
            return { ...state, isLoading: payload };

        default:
            return state;
    }
};

const setWebhooksAction = (webhookList) => ({
    type: SET_WEBHOOKS,
    payload: webhookList,
});

const setWebhooksLoadingAction = (isLoading) => ({
    type: SET_WEBHOOKS_LOADING,
    payload: isLoading,
});

export const addWebhook = (webhook) => (dispatch) => {
    dispatch(setWebhooksLoadingAction(true));
    return webhooksAPI.addWebhook(webhook).then((data) => {
        if (data && data.status === "success" && data.items) {
            dispatch(setWebhooksAction(data.items));
        }
        dispatch(setWebhooksLoadingAction(false));
        return data;
    });
};

export const getWebhooks = (payload) => (dispatch) => {
    dispatch(setWebhooksLoadingAction(true));
    webhooksAPI.getWebhooks(payload).then((data) => {
        dispatch(setWebhooksAction(data));
        dispatch(setWebhooksLoadingAction(false));
    });
};

export const removeWebhook = (payload) => (dispatch) => {
    dispatch(setWebhooksLoadingAction(true));
    return webhooksAPI.removeWebhook(payload).then((data) => {
        dispatch(setWebhooksAction(data.items));
        dispatch(setWebhooksLoadingAction(false));
        return data;
    });
};

export const editWebhook = (payload) => (dispatch) => {
    dispatch(setWebhooksLoadingAction(true));
    return webhooksAPI.editWebhook(payload).then((data) => {
        if (data && data.status === "success" && data.items) {
            dispatch(setWebhooksAction(data.items));
        }
        dispatch(setWebhooksLoadingAction(false));
        return data;
    });
};

export const clearWebhookErrors = (payload) => (dispatch) => {
    dispatch(setWebhooksLoadingAction(true));
    webhooksAPI.clearErrors(payload).then((data) => {
        if (data && data.status === "success" && data.items) {
            dispatch(setWebhooksAction(data.items));
        }
        dispatch(setWebhooksLoadingAction(false));
    });
};
