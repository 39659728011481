import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import { communityAPI } from "../api/api";
import { getCurrentCommunity } from "../redux/reducers/communitiesReducer";
import { getNoticess } from "../redux/reducers/noticesReducer";

const withCommunityRedirect = (Component) => {
	class RedirectComponent extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				isLoading: true,
				tokenCheck: true,
			};
		}

		componentDidMount() {
			this._isMounted = true;

			this.setState({ ...this.state, isLoading: true });

			// Проверка токена сообщества. Если error то редирект на /community
			communityAPI.checkToken(this.community_id).then((data) => {
				let tokenCheck = data.status === "success" ? true : false;
				if (this._isMounted) {
					this.setState({
						...this.state,
						tokenCheck,
					});
				}
			});

			let getCommunity = this.props.getCurrentCommunity(this.community_id);
			if(getCommunity) {
				getCommunity.then(() => {
					if (this._isMounted) {
						this.setState({ ...this.state, isLoading: false });
					}
				});
			}
		}

		componentWillUnmount() {
			this._isMounted = false;
		}

		render() {
			const { match, community } = this.props;
			this.community_id = match.params.id;

			if (this.state.tokenCheck === true) {
				if (this.state.isLoading || community.status) {
					return <Component {...this.props}></Component>;
				} else {
					return <Redirect to="/community" />;
				}
			} else {
				return <Redirect to="/community" />;
			}
		}
	}

	const mstp = (store) => {
		return {
			community: store.communities.currentCommunity.data,
		};
	};

	return compose(
		connect(mstp, { getCurrentCommunity, getNoticess }),
		withRouter
	)(RedirectComponent);
};

export default withCommunityRedirect;
