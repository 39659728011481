import { Field, Form, Formik } from "formik";
import React, { createRef, useEffect, useState } from "react";
import PopupFullsizeLayout from "../PopupFullsizeLayout/PopupFullsizeLayout";
import * as Yup from "yup";
import Input from "../../generic/Input/Input";
import Button from "../../generic/Button/Button";
import getInputImagePreview from "../../../utils/getInputImagePreview";
import TopCommunity from "../../TopCommunity/TopCommunity";
import styles from "./CreateGroupPopup.module.sass";
import Preloader from "../../Preloader/Preloader";
import { useHistory } from "react-router-dom";
import UtmList from "../../UtmList/UtmList";
import { serialize } from "object-to-formdata";
import ErrorFocus from "../../ErrorFocus/ErrorFocus";
import getQueryParams from "../../../utils/getQueryParams";
import getCodeTemplate from "../../../utils/getCodeTemplate";

const CreateGroupPopup = (props) => {
    let {
        onOpenPopup,
        onClosePopup,
        isOpen,
        createGroup,
        editGroup,
        groupData = {},
        groupId,
        groups = [],
        isLoading,
        community,
        match,
        location,
        isGroupPage = true,
        afterSubmit,
		tooltips,
        bots
    } = props;

    const history = useHistory();
    const params = getQueryParams(location.search);
    const [utmsData, setUtmsData] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
	const fullDescriptionInputRef = createRef();

    useEffect(() => {
        if (isOpen && !isLoading && isGroupPage && !params.group) {
            if (groupData && groupData.id) {
                history.push(
                    `/community/${match.params.id}/subscriber-groups?group=${groupData.id}`
                );
            } else {
                history.push(
                    `/community/${match.params.id}/subscriber-groups?create-group=open`
                );
            }
        } /*eslint-disable */
    }, [isOpen, isLoading]); /*eslint-enable */

    useEffect(() => {
        setUtmsData([]);
        if (groupData.utm && Object.keys(groupData.utm).length) {
            let utms = [];
            for (let prop in groupData.utm) {
                utms.push({
                    ...groupData.utm[prop],
                    value: groupData.utm[prop].name,
                    label: "Название",
                });
            }
            setUtmsData(utms);
        }

        setUploadedFiles({
            banner: groupData.banner || "",
            icon: groupData.icon || "",
        });
        /*eslint-disable */
    }, [JSON.stringify(groupData), isOpen]); /*eslint-enable */

    if (groupId == null) {
        groupData = {};
    }

    let community_id = match.params.id;

    // Опции автоисключений
    let autoExcludeOptions = groups
        .filter((g) => g.id !== groupData.id)
        .map((g) => ({ label: g.name, value: g.id }));

    // При клике на закрытие
    const onCloseClick = () => {
        onClosePopup();
        if (isGroupPage) {
            history.push(`/community/${match.params.id}/subscriber-groups/`);
        }
    };

    // Настройки попапа
    let popup = {
        title: "Группа подписчиков",
        onOpen: onOpenPopup,
        onClose: onCloseClick,
        isOpen: isOpen,
    };

    // Значения инпутов поумолчанию
    const initialValues = {
        name: groupData.name || "",
        title: groupData.title || "",
        short_description: groupData.description || "",
        full_description: groupData.full_description || "",
        subscribe_text: groupData.subscribe_text || "Подписаться",
        unsubscribe_text: groupData.unsubscribe_text || "Отписаться",
        auto_exclusion: groupData.auto_exclusion || "",
        page_after_subscribe: groupData.page_after_subscribe || "",
        page_after_unsubscribe: groupData.page_after_unsubscribe || "",
        hidden: groupData.hidden || false,
        banner_file: groupData.banner_file || "",
        icon: "",
        deleted_old_banner: false,
        deleted_old_icon: false,
        utmsData: utmsData,
        show_notifications: groupData.show_notifications || false,
        hide_subscribers_amount: groupData.hide_subscribers_amount || false,
        popup_title_after_subscribe: groupData.popup_title_after_subscribe || "",
        popup_text_after_subscribe: groupData.popup_text_after_subscribe || "",
        popup_title_after_unsubscribe: groupData.popup_title_after_unsubscribe || "",
        popup_text_after_unsubscribe: groupData.popup_text_after_unsubscribe || "",
        hide_unsubscribe_button: groupData.hide_unsubscribe_button || false,
        chat_bot_id: groupData.chat_bot_id || "",
        chat_bot_add_again: groupData.chat_bot_add_again || false,
    };

    // Валидация формы
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Обязательное поле"),
        page_after_subscribe: Yup.string().url("Некорректная ссылка"),
        page_after_unsubscribe: Yup.string().url("Некорректная ссылка"),
    });

    // При изменении баннера
    const onBannerChange = (e, formik) => {
        if (e.target.files[0].size / 1000 < 15000) {
            formik.setFieldValue(e.target.name, e.target.files);

            getInputImagePreview(e.target, (res) =>
                setUploadedFiles({
                    ...uploadedFiles,
                    banner: res,
                })
            );
        } else {
            formik.setFieldError(
                "banner_file",
                "Максимальный размер файла 15 мб"
            );
        }
    };

    // При изменении иконки
    const onIconChange = (e, formik) => {
        if (e.target.files[0].size / 1000 < 15000) {
            formik.setFieldValue(e.target.name, e.target.files[0]);

            getInputImagePreview(e.target, (res) =>
                setUploadedFiles({
                    ...uploadedFiles,
                    icon: res,
                })
            );
        } else {
            formik.setFieldError("icon", "Максимальный размер файла 15 мб");
        }
    };

    // При изменении utm-метки
    const onUtmChange = (inputs, formik) => {
        let newInputs = inputs.filter((input) =>
            input.value.trim().length ? true : false
        );

        formik.setFieldValue("utmsData", inputs);
        formik.setFieldValue(
            "utm[name]",
            newInputs.map((input) => input.value)
        );
        formik.setFieldValue(
            "utm[id]",
            newInputs.map((input) => input.id)
        );
    };

    // Клик по текствовому шаблону
    const onClickCodeTemplate = (e, text, cursorPosition, formik) => {
        let template = getCodeTemplate(text);

        formik.setFieldValue(
            "full_description",
            formik.values.full_description.substring(0, cursorPosition.start) +
                template +
                formik.values.full_description.substring(cursorPosition.start)
        );
    };

    // Отправка формы
    const handleSubmit = (data) => {
        data.utm = {
            name:
                data.utmsData && data.utmsData.length
                    ? data.utmsData
                          .filter((utm) => utm.value.trim().length)
                          .map((utm) => utm.value)
                    : [],
            id:
                data.utmsData && data.utmsData.length
                    ? data.utmsData
                          .filter((utm) => utm.value.trim().length)
                          .map((utm) => utm.id)
                    : [],
        };

        const formData = serialize(data);
        formData.append("community_id", props.match.params.id);

        if (Object.keys(groupData).length) {
            // Редактирование существующей группы
            formData.append("group_id", groupData.id);
            editGroup(formData, community_id).then(() => {
                onCloseClick();
                if (typeof afterSubmit === "function") afterSubmit();
            });
        } else {
            // Создание новой группы
            createGroup(formData, community_id).then(() => {
                onCloseClick();
                if (typeof afterSubmit === "function") afterSubmit();
            });
        }
    };

    const addToBotOptions = bots.map(bot => ({ label: bot.name, value: bot.id }));
    addToBotOptions.unshift({ label: "Не добавлять", value: null });

    const codeTemplatesList = [
        { text: "Имя" },
        { text: "Полное имя" },
        { text: "Пол" },
        { text: "Дата" },
        { text: "Отписаться" },
        { text: "Отписаться от всех" },
    ];

    return (
        <PopupFullsizeLayout {...popup}>
            {isLoading ? <Preloader /> : null}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {(formik) => (
                    <Form className={`form ${styles.popup}`}>
                        <div className="row">
                            <div
                                className={`col-xl-7 ${styles.popup__main_col}`}
                            >
                                <div class="form__bordered_section">
                                    <div className="form__group">
                                        <div className="form__group_item">
                                            <Field
                                                name="name"
                                                label="Название"
                                                type="text"
                                                placeholder="Моя группа"
                                                component={Input}
                                                tooltip={tooltips.GROUP_NAME}
                                            ></Field>
                                        </div>
                                        <div className="form__group_item">
                                            <Field
                                                name="title"
                                                label="Заголовок"
                                                type="text"
                                                placeholder="Оставьте пустым, если заголовок на подписной странице совпадает с названием"
                                                component={Input}
                                                tooltip={tooltips.GROUP_TITLE}
                                            ></Field>
                                        </div>
                                        <div className="form__group_item">
                                            <Field
                                                name="short_description"
                                                label="Краткое описание"
                                                type="textarea"
                                                height="80"
                                                placeholder="Введите текст"
                                                component={Input}
                                                tooltip={tooltips.GROUP_SHORT_DESCRIPTION}
                                            ></Field>
                                        </div>
                                        <div className="form__group_item">
                                            <Field
                                                name="full_description"
                                                label="Описание"
                                                type="textarea"
                                                placeholder="Введите текст"
                                                code_templates={true}
                                                codeTemplatesList={
                                                    codeTemplatesList
                                                }
                                                onClickCodeTemplate={(
                                                    e,
                                                    text,
                                                    icon,
                                                    cursorPosition
                                                ) =>
                                                    onClickCodeTemplate(
                                                        e,
                                                        text,
                                                        cursorPosition,
                                                        formik
                                                    )
                                                }
                                                component={Input}
                                                inputRef={fullDescriptionInputRef}
                                                tooltip={tooltips.GROUP_FULL_DESCRIPTION}
                                            ></Field>
                                        </div>
                                        {autoExcludeOptions.length ? (
                                            <div className="form__group_item">
                                                <Field
                                                    name="auto_exclusion"
                                                    label="Автоисключение"
                                                    type="select"
                                                    placeholder="Выберите"
                                                    component={Input}
                                                    isMulti={true}
                                                    options={autoExcludeOptions}
                                                    tooltip={tooltips.GROUP_AUTO_EXCLUSION}
                                                ></Field>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form__bordered_section">
                                    <div className="form__section_title">Настройки подписки</div>
                                    <div className="form__group_item">
                                        <Field
                                            name="popup_title_after_subscribe"
                                            label="Заголовок окна уведомлений"
                                            type="text"
                                            placeholder="Введите заголовок окна уведомлений"
                                            component={Input}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="popup_text_after_subscribe"
                                            label="Текст окна уведомлений"
                                            type="textarea"
                                            height="80"
                                            placeholder="Введите текст"
                                            component={Input}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="page_after_subscribe"
                                            label="Страница после подписки"
                                            type="text"
                                            placeholder={`Например: https://ok.ru/group/${community.ok_id}/messages`}
                                            component={Input}
                                            tooltip={tooltips.GROUP_PAGE_AFTER_SUBSCRIPTION}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="subscribe_text"
                                            label="Текст кнопки Подписаться"
                                            type="text"
                                            placeholder="Введите текст"
                                            component={Input}
                                            tooltip={tooltips.GROUP_SUBSCRIBE_TEXT}
                                        ></Field>
                                    </div>
                                </div>
                                <div class="form__bordered_section">
                                    <div className="form__section_title">Настройки отписки</div>
                                    <div className="form__group_item">
                                        <Field
                                            name="popup_title_after_unsubscribe"
                                            label="Заголовок окна уведомлений"
                                            type="text"
                                            placeholder="Введите заголовок окна уведомлений"
                                            component={Input}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="popup_text_after_unsubscribe"
                                            label="Текст окна уведомлений"
                                            type="textarea"
                                            height="80"
                                            placeholder="Введите текст"
                                            component={Input}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="page_after_unsubscribe"
                                            label="Страница после отмены подписки"
                                            type="text"
                                            placeholder={`Например: https://ok.ru/group/${community.ok_id}/messages`}
                                            component={Input}
                                            tooltip={tooltips.GROUP_PAGE_AFTER_UNSUBSCRIBE}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="unsubscribe_text"
                                            label="Текст кнопки Отписаться"
                                            type="text"
                                            placeholder="Введите текст"
                                            component={Input}
                                            tooltip={tooltips.GROUP_UNSUBSCRIBE_TEXT}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="hide_unsubscribe_button"
                                            label="Скрыть кнопку отписки"
                                            type="checkbox"
                                            size="large"
                                            component={Input}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="show_notifications"
                                            label="Показывать уведомления"
                                            type="checkbox"
                                            size="large"
                                            component={Input}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="hidden"
                                            label="Скрытая группа"
                                            type="checkbox"
                                            size="large"
                                            component={Input}
                                            tooltip={tooltips.GROUP_HIDDEN}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="hide_subscribers_amount"
                                            label="Скрыть количество подписчиков"
                                            type="checkbox"
                                            size="large"
                                            component={Input}
                                            tooltip={tooltips.GROUP_HIDE_SUBSCRIBERS_AMOUNT}
                                        ></Field>
                                    </div>
                                </div>
                                <div class="form__bordered_section">
                                    <div className="form__section_title">Дополнительное</div>
                                    <div className="form__group_item">
                                        <Field
                                            name="chat_bot_id"
                                            label="Добавление в бота"
                                            type="select"
                                            placeholder="Выберите бота"
                                            component={Input}
                                            options={addToBotOptions}
                                        ></Field>
                                    </div>
                                    <div className="form__group_item">
                                        <Field
                                            name="chat_bot_add_again"
                                            label="Добавить заново"
                                            type="checkbox"
                                            size="large"
                                            component={Input}
                                        ></Field>
                                    </div>
                                </div>
                            </div>

                            {/* Баннер */}
                            <div className="col-xl-5">
                                <div className="block_label">Баннер</div>
                                <div className="grey_block mb24">
                                    <div className="form__group">
                                        {uploadedFiles.banner ? (
                                            <div className="form__group_item">
                                                <img
                                                    src={uploadedFiles.banner}
                                                    className={
                                                        styles.popup__preloaded_img
                                                    }
                                                    alt="banner"
                                                />
                                            </div>
                                        ) : null}

                                        <div className="form__group_item">
                                            <div className="button_list">
                                                <label htmlFor="upload_banner">
                                                    <Button icon="camera">
                                                        Загрузить баннер
                                                    </Button>
                                                    <input
                                                        accept="image/*"
                                                        name="banner_file"
                                                        type="file"
                                                        className="hidden_input"
                                                        id="upload_banner"
                                                        onChange={(e) =>
                                                            onBannerChange(
                                                                e,
                                                                formik
                                                            )
                                                        }
                                                    />
                                                    {formik.errors
                                                        .banner_file ? (
                                                        <div className="input_error">
                                                            {
                                                                formik.errors
                                                                    .banner_file
                                                            }
                                                        </div>
                                                    ) : null}
                                                </label>
                                                <div
                                                    className="grey_link"
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            "banner_file",
                                                            {}
                                                        );
                                                        setUploadedFiles({
                                                            ...uploadedFiles,
                                                            banner: "",
                                                        });
                                                        formik.setFieldValue(
                                                            "deleted_old_banner",
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Сбросить
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Баннер END */}

                                {/* Иконка */}
                                <div className="block_label">Иконка</div>
                                <div className="grey_block mb24">
                                    <div className="form__group">
                                        {uploadedFiles.icon ? (
                                            <div className="form__group_item">
                                                <TopCommunity
                                                    community={{
                                                        image:
                                                            uploadedFiles.icon,
                                                        name:
                                                            formik.values.name,
                                                    }}
                                                ></TopCommunity>
                                            </div>
                                        ) : null}
                                        <div className="form__group_item">
                                            <div className="button_list">
                                                <label htmlFor="upload_icon">
                                                    <Button icon="camera">
                                                        Загрузить иконку
                                                    </Button>
                                                    <input
                                                        accept="image/*"
                                                        name="icon"
                                                        type="file"
                                                        className="hidden_input"
                                                        id="upload_icon"
                                                        onChange={(e) =>
                                                            onIconChange(
                                                                e,
                                                                formik
                                                            )
                                                        }
                                                    />
                                                    {formik.errors.icon ? (
                                                        <div className="input_error">
                                                            {formik.errors.icon}
                                                        </div>
                                                    ) : null}
                                                </label>

                                                <div
                                                    className="grey_link"
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            "icon",
                                                            {}
                                                        );
                                                        setUploadedFiles({
                                                            ...uploadedFiles,
                                                            icon: "",
                                                        });
                                                        formik.setFieldValue(
                                                            "deleted_old_icon",
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Сбросить
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Иконка END */}

                                {/* UTM-метки */}
                                <div className="block_label">Метка</div>
                                <div className="grey_block">
                                    <UtmList
                                        onInputChange={(inputs) =>
                                            onUtmChange(inputs, formik)
                                        }
                                        onInputRemove={(inputs) =>
                                            onUtmChange(inputs, formik)
                                        }
                                        onInputAdd={(inputs) =>
                                            onUtmChange(inputs, formik)
                                        }
                                        inputs={utmsData}
                                    />
                                </div>
                                {/* UTM-метки END */}
                            </div>
                        </div>

                        <div className={styles.popup__footer}>
                            <div className="button_list button_list--fullwidth_md">
                                <Button icon="save" onClick={formik.submitForm}>
                                    Сохранить
                                </Button>
                                <div
                                    className="grey_link button_list__right"
                                    onClick={onClosePopup}
                                >
                                    Отменить
                                </div>
                            </div>
                        </div>
                        <ErrorFocus />
                    </Form>
                )}
            </Formik>
        </PopupFullsizeLayout>
    );
};

export default CreateGroupPopup;
