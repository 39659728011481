import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../Icon/Icon";
import styles from "./IconLink.module.sass";

const IconLink = (props) => {
	const { icon, children, to, className = "", ...rest } = props;
	return to ? (
		<NavLink
			to={to}
			className={`${styles.icon_link} ${className}`}
			{...rest}
		>
			<Icon icon={icon} className={styles.icon_link__icon}></Icon>
			<span className={styles.icon_link__text}>{children}</span>
		</NavLink>
	) : (
		<div className={`${styles.icon_link} ${className}`} {...rest}>
			<Icon icon={icon} className={styles.icon_link__icon}></Icon>
			<span className={styles.icon_link__text}>{children}</span>
		</div>
	);
};

export default IconLink;
