import React from "react";
import styles from "./RoundedButton.module.sass";
import Icon from "../Icon/Icon";

const RoundedButton = (props) => {
	let {
		icon = "",
		text,
		onButtonClick,
		sortby,
		isActive = false,
		amount,
		theme,
		...rest
	} = props;

	const onClick = () => {
		// Клик для сортировки
		if (sortby != null) {
			onButtonClick(sortby);
		}
		if(typeof props.onClick === "function") {
			props.onClick();
		}
	};

	return (
		<div
			className={`${styles.button} ${isActive ? styles["active"] : ""} ${styles[theme]}`}
			{...rest}
			onClick={onClick}
		>
			{icon ? <Icon icon={icon} className={styles.button__icon} /> : null}
			{text ? <span className={styles.button__text}>{text}</span> : null}
			<span className={styles.button__amount}>{amount}</span>
		</div>
	);
};

export default RoundedButton;
